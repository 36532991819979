import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  bigint: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  time: { input: any; output: any; }
  timestamp: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type AddressesByZipCodeOutput = {
  __typename?: 'AddressesByZipCodeOutput';
  line1: Scalars['String']['output'];
  prefecture: AddressesByZipCodeOutputPrefecture;
  zipCode: Scalars['String']['output'];
};

export type AddressesByZipCodeOutputPrefecture = {
  __typename?: 'AddressesByZipCodeOutputPrefecture';
  id: Scalars['Int']['output'];
};

export type ApplicantInput = {
  address?: InputMaybe<ApplicantInputAddress>;
  birth?: InputMaybe<Scalars['date']['input']>;
  customValues?: InputMaybe<Array<EntryFormValue>>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<ApplicantInputGender>;
  group?: InputMaybe<ApplicantInputGroup>;
  job?: InputMaybe<ApplicantInputJob>;
  name?: InputMaybe<ApplicantInputName>;
  nameKana?: InputMaybe<ApplicantInputNameKana>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicantInputAddress = {
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  prefecture: ApplicantInputAddressPrefecture;
  zipCode: Scalars['String']['input'];
};

export type ApplicantInputAddressPrefecture = {
  id: Scalars['Int']['input'];
};

export type ApplicantInputGender = {
  id: Scalars['Int']['input'];
};

export type ApplicantInputGroup = {
  id: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};

export type ApplicantInputJob = {
  id: Scalars['Int']['input'];
};

export type ApplicantInputName = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
};

export type ApplicantInputNameKana = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
};

/** columns and relationships of "article.key_visuals" */
export type ArticleKeyVisuals = {
  __typename?: 'ArticleKeyVisuals';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
};

/** Boolean expression to filter rows from the table "article.key_visuals". All fields are combined with a logical 'AND'. */
export type ArticleKeyVisualsBoolExp = {
  _and?: InputMaybe<Array<ArticleKeyVisualsBoolExp>>;
  _not?: InputMaybe<ArticleKeyVisualsBoolExp>;
  _or?: InputMaybe<Array<ArticleKeyVisualsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
};

/** Ordering options when selecting data from "article.key_visuals". */
export type ArticleKeyVisualsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
};

/** columns and relationships of "article.list_view_thumbnails" */
export type ArticleListViewThumbnails = {
  __typename?: 'ArticleListViewThumbnails';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
};

/** Boolean expression to filter rows from the table "article.list_view_thumbnails". All fields are combined with a logical 'AND'. */
export type ArticleListViewThumbnailsBoolExp = {
  _and?: InputMaybe<Array<ArticleListViewThumbnailsBoolExp>>;
  _not?: InputMaybe<ArticleListViewThumbnailsBoolExp>;
  _or?: InputMaybe<Array<ArticleListViewThumbnailsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
};

/** Ordering options when selecting data from "article.list_view_thumbnails". */
export type ArticleListViewThumbnailsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
};

/** columns and relationships of "article.list_views" */
export type ArticleListViews = {
  __typename?: 'ArticleListViews';
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  thumbnail?: Maybe<ArticleListViewThumbnails>;
};

/** Boolean expression to filter rows from the table "article.list_views". All fields are combined with a logical 'AND'. */
export type ArticleListViewsBoolExp = {
  _and?: InputMaybe<Array<ArticleListViewsBoolExp>>;
  _not?: InputMaybe<ArticleListViewsBoolExp>;
  _or?: InputMaybe<Array<ArticleListViewsBoolExp>>;
  lead?: InputMaybe<StringComparisonExp>;
  thumbnail?: InputMaybe<ArticleListViewThumbnailsBoolExp>;
};

/** Ordering options when selecting data from "article.list_views". */
export type ArticleListViewsOrderBy = {
  lead?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<ArticleListViewThumbnailsOrderBy>;
};

/** columns and relationships of "article.paginations" */
export type ArticlePaginations = {
  __typename?: 'ArticlePaginations';
  /** An object relationship */
  article?: Maybe<Articles>;
  articleId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  firstArticle?: Maybe<Articles>;
  firstArticleId?: Maybe<Scalars['Int']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  pages: Array<ArticlePaginations>;
  /** An aggregate relationship */
  pagesAggregate: ArticlePaginationsAggregate;
  teaser?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "article.paginations" */
export type ArticlePaginationsPagesArgs = {
  distinctOn?: InputMaybe<Array<ArticlePaginationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlePaginationsOrderBy>>;
  where?: InputMaybe<ArticlePaginationsBoolExp>;
};


/** columns and relationships of "article.paginations" */
export type ArticlePaginationsPagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticlePaginationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlePaginationsOrderBy>>;
  where?: InputMaybe<ArticlePaginationsBoolExp>;
};

/** aggregated selection of "article.paginations" */
export type ArticlePaginationsAggregate = {
  __typename?: 'ArticlePaginationsAggregate';
  aggregate?: Maybe<ArticlePaginationsAggregateFields>;
  nodes: Array<ArticlePaginations>;
};

export type ArticlePaginationsAggregateBoolExp = {
  count?: InputMaybe<ArticlePaginationsAggregateBoolExpCount>;
};

/** aggregate fields of "article.paginations" */
export type ArticlePaginationsAggregateFields = {
  __typename?: 'ArticlePaginationsAggregateFields';
  avg?: Maybe<ArticlePaginationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ArticlePaginationsMaxFields>;
  min?: Maybe<ArticlePaginationsMinFields>;
  stddev?: Maybe<ArticlePaginationsStddevFields>;
  stddevPop?: Maybe<ArticlePaginationsStddevPopFields>;
  stddevSamp?: Maybe<ArticlePaginationsStddevSampFields>;
  sum?: Maybe<ArticlePaginationsSumFields>;
  varPop?: Maybe<ArticlePaginationsVarPopFields>;
  varSamp?: Maybe<ArticlePaginationsVarSampFields>;
  variance?: Maybe<ArticlePaginationsVarianceFields>;
};


/** aggregate fields of "article.paginations" */
export type ArticlePaginationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ArticlePaginationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "article.paginations" */
export type ArticlePaginationsAggregateOrderBy = {
  avg?: InputMaybe<ArticlePaginationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ArticlePaginationsMaxOrderBy>;
  min?: InputMaybe<ArticlePaginationsMinOrderBy>;
  stddev?: InputMaybe<ArticlePaginationsStddevOrderBy>;
  stddevPop?: InputMaybe<ArticlePaginationsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ArticlePaginationsStddevSampOrderBy>;
  sum?: InputMaybe<ArticlePaginationsSumOrderBy>;
  varPop?: InputMaybe<ArticlePaginationsVarPopOrderBy>;
  varSamp?: InputMaybe<ArticlePaginationsVarSampOrderBy>;
  variance?: InputMaybe<ArticlePaginationsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ArticlePaginationsAvgFields = {
  __typename?: 'ArticlePaginationsAvgFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "article.paginations" */
export type ArticlePaginationsAvgOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "article.paginations". All fields are combined with a logical 'AND'. */
export type ArticlePaginationsBoolExp = {
  _and?: InputMaybe<Array<ArticlePaginationsBoolExp>>;
  _not?: InputMaybe<ArticlePaginationsBoolExp>;
  _or?: InputMaybe<Array<ArticlePaginationsBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
  articleId?: InputMaybe<IntComparisonExp>;
  firstArticle?: InputMaybe<ArticlesBoolExp>;
  firstArticleId?: InputMaybe<IntComparisonExp>;
  pageNumber?: InputMaybe<IntComparisonExp>;
  pages?: InputMaybe<ArticlePaginationsBoolExp>;
  pagesAggregate?: InputMaybe<ArticlePaginationsAggregateBoolExp>;
  teaser?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ArticlePaginationsMaxFields = {
  __typename?: 'ArticlePaginationsMaxFields';
  articleId?: Maybe<Scalars['Int']['output']>;
  firstArticleId?: Maybe<Scalars['Int']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "article.paginations" */
export type ArticlePaginationsMaxOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
  teaser?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ArticlePaginationsMinFields = {
  __typename?: 'ArticlePaginationsMinFields';
  articleId?: Maybe<Scalars['Int']['output']>;
  firstArticleId?: Maybe<Scalars['Int']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "article.paginations" */
export type ArticlePaginationsMinOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
  teaser?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "article.paginations". */
export type ArticlePaginationsOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
  articleId?: InputMaybe<OrderBy>;
  firstArticle?: InputMaybe<ArticlesOrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
  pagesAggregate?: InputMaybe<ArticlePaginationsAggregateOrderBy>;
  teaser?: InputMaybe<OrderBy>;
};

/** select columns of table "article.paginations" */
export enum ArticlePaginationsSelectColumn {
  /** column name */
  ArticleId = 'articleId',
  /** column name */
  FirstArticleId = 'firstArticleId',
  /** column name */
  PageNumber = 'pageNumber',
  /** column name */
  Teaser = 'teaser'
}

/** aggregate stddev on columns */
export type ArticlePaginationsStddevFields = {
  __typename?: 'ArticlePaginationsStddevFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "article.paginations" */
export type ArticlePaginationsStddevOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ArticlePaginationsStddevPopFields = {
  __typename?: 'ArticlePaginationsStddevPopFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "article.paginations" */
export type ArticlePaginationsStddevPopOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ArticlePaginationsStddevSampFields = {
  __typename?: 'ArticlePaginationsStddevSampFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "article.paginations" */
export type ArticlePaginationsStddevSampOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ArticlePaginationsSumFields = {
  __typename?: 'ArticlePaginationsSumFields';
  articleId?: Maybe<Scalars['Int']['output']>;
  firstArticleId?: Maybe<Scalars['Int']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "article.paginations" */
export type ArticlePaginationsSumOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ArticlePaginationsVarPopFields = {
  __typename?: 'ArticlePaginationsVarPopFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "article.paginations" */
export type ArticlePaginationsVarPopOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ArticlePaginationsVarSampFields = {
  __typename?: 'ArticlePaginationsVarSampFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "article.paginations" */
export type ArticlePaginationsVarSampOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ArticlePaginationsVarianceFields = {
  __typename?: 'ArticlePaginationsVarianceFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  firstArticleId?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "article.paginations" */
export type ArticlePaginationsVarianceOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  firstArticleId?: InputMaybe<OrderBy>;
  pageNumber?: InputMaybe<OrderBy>;
};

/** columns and relationships of "article.rss_prtimes_items" */
export type ArticleRssPrtimesItems = {
  __typename?: 'ArticleRssPrtimesItems';
  contentEncoded: Scalars['String']['output'];
  date: Scalars['String']['output'];
  dcCategory: Scalars['String']['output'];
  dcCorp: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  image: Scalars['String']['output'];
  keyword: Scalars['String']['output'];
  link: Scalars['String']['output'];
  registeredAt: Scalars['timestamp']['output'];
  title: Scalars['String']['output'];
};

/** aggregated selection of "article.rss_prtimes_items" */
export type ArticleRssPrtimesItemsAggregate = {
  __typename?: 'ArticleRssPrtimesItemsAggregate';
  aggregate?: Maybe<ArticleRssPrtimesItemsAggregateFields>;
  nodes: Array<ArticleRssPrtimesItems>;
};

/** aggregate fields of "article.rss_prtimes_items" */
export type ArticleRssPrtimesItemsAggregateFields = {
  __typename?: 'ArticleRssPrtimesItemsAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ArticleRssPrtimesItemsMaxFields>;
  min?: Maybe<ArticleRssPrtimesItemsMinFields>;
};


/** aggregate fields of "article.rss_prtimes_items" */
export type ArticleRssPrtimesItemsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ArticleRssPrtimesItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "article.rss_prtimes_items". All fields are combined with a logical 'AND'. */
export type ArticleRssPrtimesItemsBoolExp = {
  _and?: InputMaybe<Array<ArticleRssPrtimesItemsBoolExp>>;
  _not?: InputMaybe<ArticleRssPrtimesItemsBoolExp>;
  _or?: InputMaybe<Array<ArticleRssPrtimesItemsBoolExp>>;
  contentEncoded?: InputMaybe<StringComparisonExp>;
  date?: InputMaybe<StringComparisonExp>;
  dcCategory?: InputMaybe<StringComparisonExp>;
  dcCorp?: InputMaybe<StringComparisonExp>;
  guid?: InputMaybe<StringComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  keyword?: InputMaybe<StringComparisonExp>;
  link?: InputMaybe<StringComparisonExp>;
  registeredAt?: InputMaybe<TimestampComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ArticleRssPrtimesItemsMaxFields = {
  __typename?: 'ArticleRssPrtimesItemsMaxFields';
  contentEncoded?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  dcCategory?: Maybe<Scalars['String']['output']>;
  dcCorp?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ArticleRssPrtimesItemsMinFields = {
  __typename?: 'ArticleRssPrtimesItemsMinFields';
  contentEncoded?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  dcCategory?: Maybe<Scalars['String']['output']>;
  dcCorp?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "article.rss_prtimes_items". */
export type ArticleRssPrtimesItemsOrderBy = {
  contentEncoded?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  dcCategory?: InputMaybe<OrderBy>;
  dcCorp?: InputMaybe<OrderBy>;
  guid?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  keyword?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  registeredAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** select columns of table "article.rss_prtimes_items" */
export enum ArticleRssPrtimesItemsSelectColumn {
  /** column name */
  ContentEncoded = 'contentEncoded',
  /** column name */
  Date = 'date',
  /** column name */
  DcCategory = 'dcCategory',
  /** column name */
  DcCorp = 'dcCorp',
  /** column name */
  Guid = 'guid',
  /** column name */
  Image = 'image',
  /** column name */
  Keyword = 'keyword',
  /** column name */
  Link = 'link',
  /** column name */
  RegisteredAt = 'registeredAt',
  /** column name */
  Title = 'title'
}

/** Streaming cursor of the table "article_rss_prtimes_items" */
export type ArticleRssPrtimesItemsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ArticleRssPrtimesItemsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ArticleRssPrtimesItemsStreamCursorValueInput = {
  contentEncoded?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  dcCategory?: InputMaybe<Scalars['String']['input']>;
  dcCorp?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  registeredAt?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "article.taggings" */
export type ArticleTaggings = {
  __typename?: 'ArticleTaggings';
  /** An object relationship */
  article?: Maybe<Articles>;
  /** An object relationship */
  tag?: Maybe<ArticleTags>;
};

/** order by aggregate values of table "article.taggings" */
export type ArticleTaggingsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "article.taggings". All fields are combined with a logical 'AND'. */
export type ArticleTaggingsBoolExp = {
  _and?: InputMaybe<Array<ArticleTaggingsBoolExp>>;
  _not?: InputMaybe<ArticleTaggingsBoolExp>;
  _or?: InputMaybe<Array<ArticleTaggingsBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
  tag?: InputMaybe<ArticleTagsBoolExp>;
};

/** Ordering options when selecting data from "article.taggings". */
export type ArticleTaggingsOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
  tag?: InputMaybe<ArticleTagsOrderBy>;
};

/** columns and relationships of "article.tags" */
export type ArticleTags = {
  __typename?: 'ArticleTags';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  taggings: Array<ArticleTaggings>;
};


/** columns and relationships of "article.tags" */
export type ArticleTagsTaggingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTaggingsOrderBy>>;
  where?: InputMaybe<ArticleTaggingsBoolExp>;
};

/** aggregated selection of "article.tags" */
export type ArticleTagsAggregate = {
  __typename?: 'ArticleTagsAggregate';
  aggregate?: Maybe<ArticleTagsAggregateFields>;
  nodes: Array<ArticleTags>;
};

/** aggregate fields of "article.tags" */
export type ArticleTagsAggregateFields = {
  __typename?: 'ArticleTagsAggregateFields';
  avg?: Maybe<ArticleTagsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ArticleTagsMaxFields>;
  min?: Maybe<ArticleTagsMinFields>;
  stddev?: Maybe<ArticleTagsStddevFields>;
  stddevPop?: Maybe<ArticleTagsStddevPopFields>;
  stddevSamp?: Maybe<ArticleTagsStddevSampFields>;
  sum?: Maybe<ArticleTagsSumFields>;
  varPop?: Maybe<ArticleTagsVarPopFields>;
  varSamp?: Maybe<ArticleTagsVarSampFields>;
  variance?: Maybe<ArticleTagsVarianceFields>;
};


/** aggregate fields of "article.tags" */
export type ArticleTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ArticleTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ArticleTagsAvgFields = {
  __typename?: 'ArticleTagsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "article.tags". All fields are combined with a logical 'AND'. */
export type ArticleTagsBoolExp = {
  _and?: InputMaybe<Array<ArticleTagsBoolExp>>;
  _not?: InputMaybe<ArticleTagsBoolExp>;
  _or?: InputMaybe<Array<ArticleTagsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  taggings?: InputMaybe<ArticleTaggingsBoolExp>;
};

/** aggregate max on columns */
export type ArticleTagsMaxFields = {
  __typename?: 'ArticleTagsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ArticleTagsMinFields = {
  __typename?: 'ArticleTagsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "article.tags". */
export type ArticleTagsOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  taggingsAggregate?: InputMaybe<ArticleTaggingsAggregateOrderBy>;
};

/** select columns of table "article.tags" */
export enum ArticleTagsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type ArticleTagsStddevFields = {
  __typename?: 'ArticleTagsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ArticleTagsStddevPopFields = {
  __typename?: 'ArticleTagsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ArticleTagsStddevSampFields = {
  __typename?: 'ArticleTagsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "article_tags" */
export type ArticleTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ArticleTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ArticleTagsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ArticleTagsSumFields = {
  __typename?: 'ArticleTagsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ArticleTagsVarPopFields = {
  __typename?: 'ArticleTagsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ArticleTagsVarSampFields = {
  __typename?: 'ArticleTagsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ArticleTagsVarianceFields = {
  __typename?: 'ArticleTagsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "article.top_page" */
export type ArticleTopPage = {
  __typename?: 'ArticleTopPage';
  id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  mainViewBanners: Array<ArticleTopPageMainViewBanners>;
  /** An aggregate relationship */
  mainViewBannersAggregate: ArticleTopPageMainViewBannersAggregate;
  /** An array relationship */
  pinnedArticles: Array<ArticleTopPagePinnedArticles>;
  /** An aggregate relationship */
  pinnedArticlesAggregate: ArticleTopPagePinnedArticlesAggregate;
};


/** columns and relationships of "article.top_page" */
export type ArticleTopPageMainViewBannersArgs = {
  distinctOn?: InputMaybe<Array<ArticleTopPageMainViewBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTopPageMainViewBannersOrderBy>>;
  where?: InputMaybe<ArticleTopPageMainViewBannersBoolExp>;
};


/** columns and relationships of "article.top_page" */
export type ArticleTopPageMainViewBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticleTopPageMainViewBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTopPageMainViewBannersOrderBy>>;
  where?: InputMaybe<ArticleTopPageMainViewBannersBoolExp>;
};


/** columns and relationships of "article.top_page" */
export type ArticleTopPagePinnedArticlesArgs = {
  distinctOn?: InputMaybe<Array<ArticleTopPagePinnedArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTopPagePinnedArticlesOrderBy>>;
  where?: InputMaybe<ArticleTopPagePinnedArticlesBoolExp>;
};


/** columns and relationships of "article.top_page" */
export type ArticleTopPagePinnedArticlesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticleTopPagePinnedArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTopPagePinnedArticlesOrderBy>>;
  where?: InputMaybe<ArticleTopPagePinnedArticlesBoolExp>;
};

/** Boolean expression to filter rows from the table "article.top_page". All fields are combined with a logical 'AND'. */
export type ArticleTopPageBoolExp = {
  _and?: InputMaybe<Array<ArticleTopPageBoolExp>>;
  _not?: InputMaybe<ArticleTopPageBoolExp>;
  _or?: InputMaybe<Array<ArticleTopPageBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  mainViewBanners?: InputMaybe<ArticleTopPageMainViewBannersBoolExp>;
  mainViewBannersAggregate?: InputMaybe<ArticleTopPageMainViewBannersAggregateBoolExp>;
  pinnedArticles?: InputMaybe<ArticleTopPagePinnedArticlesBoolExp>;
  pinnedArticlesAggregate?: InputMaybe<ArticleTopPagePinnedArticlesAggregateBoolExp>;
};

/** columns and relationships of "article.top_page_main_view_banner_thumbnails" */
export type ArticleTopPageMainViewBannerThumbnails = {
  __typename?: 'ArticleTopPageMainViewBannerThumbnails';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
};

/** Boolean expression to filter rows from the table "article.top_page_main_view_banner_thumbnails". All fields are combined with a logical 'AND'. */
export type ArticleTopPageMainViewBannerThumbnailsBoolExp = {
  _and?: InputMaybe<Array<ArticleTopPageMainViewBannerThumbnailsBoolExp>>;
  _not?: InputMaybe<ArticleTopPageMainViewBannerThumbnailsBoolExp>;
  _or?: InputMaybe<Array<ArticleTopPageMainViewBannerThumbnailsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
};

/** Ordering options when selecting data from "article.top_page_main_view_banner_thumbnails". */
export type ArticleTopPageMainViewBannerThumbnailsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
};

/** columns and relationships of "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBanners = {
  __typename?: 'ArticleTopPageMainViewBanners';
  index?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  thumbnail?: Maybe<ArticleTopPageMainViewBannerThumbnails>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersAggregate = {
  __typename?: 'ArticleTopPageMainViewBannersAggregate';
  aggregate?: Maybe<ArticleTopPageMainViewBannersAggregateFields>;
  nodes: Array<ArticleTopPageMainViewBanners>;
};

export type ArticleTopPageMainViewBannersAggregateBoolExp = {
  count?: InputMaybe<ArticleTopPageMainViewBannersAggregateBoolExpCount>;
};

/** aggregate fields of "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersAggregateFields = {
  __typename?: 'ArticleTopPageMainViewBannersAggregateFields';
  avg?: Maybe<ArticleTopPageMainViewBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ArticleTopPageMainViewBannersMaxFields>;
  min?: Maybe<ArticleTopPageMainViewBannersMinFields>;
  stddev?: Maybe<ArticleTopPageMainViewBannersStddevFields>;
  stddevPop?: Maybe<ArticleTopPageMainViewBannersStddevPopFields>;
  stddevSamp?: Maybe<ArticleTopPageMainViewBannersStddevSampFields>;
  sum?: Maybe<ArticleTopPageMainViewBannersSumFields>;
  varPop?: Maybe<ArticleTopPageMainViewBannersVarPopFields>;
  varSamp?: Maybe<ArticleTopPageMainViewBannersVarSampFields>;
  variance?: Maybe<ArticleTopPageMainViewBannersVarianceFields>;
};


/** aggregate fields of "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ArticleTopPageMainViewBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersAggregateOrderBy = {
  avg?: InputMaybe<ArticleTopPageMainViewBannersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ArticleTopPageMainViewBannersMaxOrderBy>;
  min?: InputMaybe<ArticleTopPageMainViewBannersMinOrderBy>;
  stddev?: InputMaybe<ArticleTopPageMainViewBannersStddevOrderBy>;
  stddevPop?: InputMaybe<ArticleTopPageMainViewBannersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ArticleTopPageMainViewBannersStddevSampOrderBy>;
  sum?: InputMaybe<ArticleTopPageMainViewBannersSumOrderBy>;
  varPop?: InputMaybe<ArticleTopPageMainViewBannersVarPopOrderBy>;
  varSamp?: InputMaybe<ArticleTopPageMainViewBannersVarSampOrderBy>;
  variance?: InputMaybe<ArticleTopPageMainViewBannersVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ArticleTopPageMainViewBannersAvgFields = {
  __typename?: 'ArticleTopPageMainViewBannersAvgFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "article.top_page_main_view_banners". All fields are combined with a logical 'AND'. */
export type ArticleTopPageMainViewBannersBoolExp = {
  _and?: InputMaybe<Array<ArticleTopPageMainViewBannersBoolExp>>;
  _not?: InputMaybe<ArticleTopPageMainViewBannersBoolExp>;
  _or?: InputMaybe<Array<ArticleTopPageMainViewBannersBoolExp>>;
  index?: InputMaybe<BigintComparisonExp>;
  thumbnail?: InputMaybe<ArticleTopPageMainViewBannerThumbnailsBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ArticleTopPageMainViewBannersMaxFields = {
  __typename?: 'ArticleTopPageMainViewBannersMaxFields';
  index?: Maybe<Scalars['bigint']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersMaxOrderBy = {
  index?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ArticleTopPageMainViewBannersMinFields = {
  __typename?: 'ArticleTopPageMainViewBannersMinFields';
  index?: Maybe<Scalars['bigint']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersMinOrderBy = {
  index?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "article.top_page_main_view_banners". */
export type ArticleTopPageMainViewBannersOrderBy = {
  index?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<ArticleTopPageMainViewBannerThumbnailsOrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "article.top_page_main_view_banners" */
export enum ArticleTopPageMainViewBannersSelectColumn {
  /** column name */
  Index = 'index',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type ArticleTopPageMainViewBannersStddevFields = {
  __typename?: 'ArticleTopPageMainViewBannersStddevFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ArticleTopPageMainViewBannersStddevPopFields = {
  __typename?: 'ArticleTopPageMainViewBannersStddevPopFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ArticleTopPageMainViewBannersStddevSampFields = {
  __typename?: 'ArticleTopPageMainViewBannersStddevSampFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ArticleTopPageMainViewBannersSumFields = {
  __typename?: 'ArticleTopPageMainViewBannersSumFields';
  index?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersSumOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ArticleTopPageMainViewBannersVarPopFields = {
  __typename?: 'ArticleTopPageMainViewBannersVarPopFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ArticleTopPageMainViewBannersVarSampFields = {
  __typename?: 'ArticleTopPageMainViewBannersVarSampFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ArticleTopPageMainViewBannersVarianceFields = {
  __typename?: 'ArticleTopPageMainViewBannersVarianceFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "article.top_page_main_view_banners" */
export type ArticleTopPageMainViewBannersVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "article.top_page". */
export type ArticleTopPageOrderBy = {
  id?: InputMaybe<OrderBy>;
  mainViewBannersAggregate?: InputMaybe<ArticleTopPageMainViewBannersAggregateOrderBy>;
  pinnedArticlesAggregate?: InputMaybe<ArticleTopPagePinnedArticlesAggregateOrderBy>;
};

/** columns and relationships of "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticles = {
  __typename?: 'ArticleTopPagePinnedArticles';
  /** An object relationship */
  article?: Maybe<Articles>;
  index?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesAggregate = {
  __typename?: 'ArticleTopPagePinnedArticlesAggregate';
  aggregate?: Maybe<ArticleTopPagePinnedArticlesAggregateFields>;
  nodes: Array<ArticleTopPagePinnedArticles>;
};

export type ArticleTopPagePinnedArticlesAggregateBoolExp = {
  count?: InputMaybe<ArticleTopPagePinnedArticlesAggregateBoolExpCount>;
};

/** aggregate fields of "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesAggregateFields = {
  __typename?: 'ArticleTopPagePinnedArticlesAggregateFields';
  avg?: Maybe<ArticleTopPagePinnedArticlesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ArticleTopPagePinnedArticlesMaxFields>;
  min?: Maybe<ArticleTopPagePinnedArticlesMinFields>;
  stddev?: Maybe<ArticleTopPagePinnedArticlesStddevFields>;
  stddevPop?: Maybe<ArticleTopPagePinnedArticlesStddevPopFields>;
  stddevSamp?: Maybe<ArticleTopPagePinnedArticlesStddevSampFields>;
  sum?: Maybe<ArticleTopPagePinnedArticlesSumFields>;
  varPop?: Maybe<ArticleTopPagePinnedArticlesVarPopFields>;
  varSamp?: Maybe<ArticleTopPagePinnedArticlesVarSampFields>;
  variance?: Maybe<ArticleTopPagePinnedArticlesVarianceFields>;
};


/** aggregate fields of "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ArticleTopPagePinnedArticlesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesAggregateOrderBy = {
  avg?: InputMaybe<ArticleTopPagePinnedArticlesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ArticleTopPagePinnedArticlesMaxOrderBy>;
  min?: InputMaybe<ArticleTopPagePinnedArticlesMinOrderBy>;
  stddev?: InputMaybe<ArticleTopPagePinnedArticlesStddevOrderBy>;
  stddevPop?: InputMaybe<ArticleTopPagePinnedArticlesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ArticleTopPagePinnedArticlesStddevSampOrderBy>;
  sum?: InputMaybe<ArticleTopPagePinnedArticlesSumOrderBy>;
  varPop?: InputMaybe<ArticleTopPagePinnedArticlesVarPopOrderBy>;
  varSamp?: InputMaybe<ArticleTopPagePinnedArticlesVarSampOrderBy>;
  variance?: InputMaybe<ArticleTopPagePinnedArticlesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ArticleTopPagePinnedArticlesAvgFields = {
  __typename?: 'ArticleTopPagePinnedArticlesAvgFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "article.top_page_pinned_articles". All fields are combined with a logical 'AND'. */
export type ArticleTopPagePinnedArticlesBoolExp = {
  _and?: InputMaybe<Array<ArticleTopPagePinnedArticlesBoolExp>>;
  _not?: InputMaybe<ArticleTopPagePinnedArticlesBoolExp>;
  _or?: InputMaybe<Array<ArticleTopPagePinnedArticlesBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
  index?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type ArticleTopPagePinnedArticlesMaxFields = {
  __typename?: 'ArticleTopPagePinnedArticlesMaxFields';
  index?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesMaxOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ArticleTopPagePinnedArticlesMinFields = {
  __typename?: 'ArticleTopPagePinnedArticlesMinFields';
  index?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesMinOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "article.top_page_pinned_articles". */
export type ArticleTopPagePinnedArticlesOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
  index?: InputMaybe<OrderBy>;
};

/** select columns of table "article.top_page_pinned_articles" */
export enum ArticleTopPagePinnedArticlesSelectColumn {
  /** column name */
  Index = 'index'
}

/** aggregate stddev on columns */
export type ArticleTopPagePinnedArticlesStddevFields = {
  __typename?: 'ArticleTopPagePinnedArticlesStddevFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ArticleTopPagePinnedArticlesStddevPopFields = {
  __typename?: 'ArticleTopPagePinnedArticlesStddevPopFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ArticleTopPagePinnedArticlesStddevSampFields = {
  __typename?: 'ArticleTopPagePinnedArticlesStddevSampFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ArticleTopPagePinnedArticlesSumFields = {
  __typename?: 'ArticleTopPagePinnedArticlesSumFields';
  index?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesSumOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ArticleTopPagePinnedArticlesVarPopFields = {
  __typename?: 'ArticleTopPagePinnedArticlesVarPopFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ArticleTopPagePinnedArticlesVarSampFields = {
  __typename?: 'ArticleTopPagePinnedArticlesVarSampFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ArticleTopPagePinnedArticlesVarianceFields = {
  __typename?: 'ArticleTopPagePinnedArticlesVarianceFields';
  index?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "article.top_page_pinned_articles" */
export type ArticleTopPagePinnedArticlesVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** select columns of table "article.top_page" */
export enum ArticleTopPageSelectColumn {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "article_top_page" */
export type ArticleTopPageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ArticleTopPageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ArticleTopPageStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "article.writers" */
export type ArticleWriters = {
  __typename?: 'ArticleWriters';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  image?: Maybe<StrapiFiles>;
  imageFileId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "article.writers". All fields are combined with a logical 'AND'. */
export type ArticleWritersBoolExp = {
  _and?: InputMaybe<Array<ArticleWritersBoolExp>>;
  _not?: InputMaybe<ArticleWritersBoolExp>;
  _or?: InputMaybe<Array<ArticleWritersBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  image?: InputMaybe<StrapiFilesBoolExp>;
  imageFileId?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "article.writers". */
export type ArticleWritersOrderBy = {
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<StrapiFilesOrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "article.writers" */
export enum ArticleWritersSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageFileId = 'imageFileId',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "article_writers" */
export type ArticleWritersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ArticleWritersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ArticleWritersStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imageFileId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "banner.category_top_new_contests" */
export type BannerCategoryTopNewContests = {
  __typename?: 'BannerCategoryTopNewContests';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An array relationship */
  subcategories: Array<BannerCategoryTopSubcategories>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "banner.category_top_new_contests" */
export type BannerCategoryTopNewContestsSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopSubcategoriesOrderBy>>;
  where?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
};

/** aggregated selection of "banner.category_top_new_contests" */
export type BannerCategoryTopNewContestsAggregate = {
  __typename?: 'BannerCategoryTopNewContestsAggregate';
  aggregate?: Maybe<BannerCategoryTopNewContestsAggregateFields>;
  nodes: Array<BannerCategoryTopNewContests>;
};

/** aggregate fields of "banner.category_top_new_contests" */
export type BannerCategoryTopNewContestsAggregateFields = {
  __typename?: 'BannerCategoryTopNewContestsAggregateFields';
  avg?: Maybe<BannerCategoryTopNewContestsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerCategoryTopNewContestsMaxFields>;
  min?: Maybe<BannerCategoryTopNewContestsMinFields>;
  stddev?: Maybe<BannerCategoryTopNewContestsStddevFields>;
  stddevPop?: Maybe<BannerCategoryTopNewContestsStddevPopFields>;
  stddevSamp?: Maybe<BannerCategoryTopNewContestsStddevSampFields>;
  sum?: Maybe<BannerCategoryTopNewContestsSumFields>;
  varPop?: Maybe<BannerCategoryTopNewContestsVarPopFields>;
  varSamp?: Maybe<BannerCategoryTopNewContestsVarSampFields>;
  variance?: Maybe<BannerCategoryTopNewContestsVarianceFields>;
};


/** aggregate fields of "banner.category_top_new_contests" */
export type BannerCategoryTopNewContestsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerCategoryTopNewContestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerCategoryTopNewContestsAvgFields = {
  __typename?: 'BannerCategoryTopNewContestsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.category_top_new_contests". All fields are combined with a logical 'AND'. */
export type BannerCategoryTopNewContestsBoolExp = {
  _and?: InputMaybe<Array<BannerCategoryTopNewContestsBoolExp>>;
  _not?: InputMaybe<BannerCategoryTopNewContestsBoolExp>;
  _or?: InputMaybe<Array<BannerCategoryTopNewContestsBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  subcategories?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerCategoryTopNewContestsMaxFields = {
  __typename?: 'BannerCategoryTopNewContestsMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerCategoryTopNewContestsMinFields = {
  __typename?: 'BannerCategoryTopNewContestsMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.category_top_new_contests". */
export type BannerCategoryTopNewContestsOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  subcategoriesAggregate?: InputMaybe<BannerCategoryTopSubcategoriesAggregateOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.category_top_new_contests" */
export enum BannerCategoryTopNewContestsSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerCategoryTopNewContestsStddevFields = {
  __typename?: 'BannerCategoryTopNewContestsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerCategoryTopNewContestsStddevPopFields = {
  __typename?: 'BannerCategoryTopNewContestsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerCategoryTopNewContestsStddevSampFields = {
  __typename?: 'BannerCategoryTopNewContestsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_category_top_new_contests" */
export type BannerCategoryTopNewContestsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerCategoryTopNewContestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerCategoryTopNewContestsStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerCategoryTopNewContestsSumFields = {
  __typename?: 'BannerCategoryTopNewContestsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerCategoryTopNewContestsVarPopFields = {
  __typename?: 'BannerCategoryTopNewContestsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerCategoryTopNewContestsVarSampFields = {
  __typename?: 'BannerCategoryTopNewContestsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerCategoryTopNewContestsVarianceFields = {
  __typename?: 'BannerCategoryTopNewContestsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.category_top_premium_banners" */
export type BannerCategoryTopPremiumBanners = {
  __typename?: 'BannerCategoryTopPremiumBanners';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An array relationship */
  subcategories: Array<BannerCategoryTopSubcategories>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "banner.category_top_premium_banners" */
export type BannerCategoryTopPremiumBannersSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopSubcategoriesOrderBy>>;
  where?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
};

/** aggregated selection of "banner.category_top_premium_banners" */
export type BannerCategoryTopPremiumBannersAggregate = {
  __typename?: 'BannerCategoryTopPremiumBannersAggregate';
  aggregate?: Maybe<BannerCategoryTopPremiumBannersAggregateFields>;
  nodes: Array<BannerCategoryTopPremiumBanners>;
};

/** aggregate fields of "banner.category_top_premium_banners" */
export type BannerCategoryTopPremiumBannersAggregateFields = {
  __typename?: 'BannerCategoryTopPremiumBannersAggregateFields';
  avg?: Maybe<BannerCategoryTopPremiumBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerCategoryTopPremiumBannersMaxFields>;
  min?: Maybe<BannerCategoryTopPremiumBannersMinFields>;
  stddev?: Maybe<BannerCategoryTopPremiumBannersStddevFields>;
  stddevPop?: Maybe<BannerCategoryTopPremiumBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerCategoryTopPremiumBannersStddevSampFields>;
  sum?: Maybe<BannerCategoryTopPremiumBannersSumFields>;
  varPop?: Maybe<BannerCategoryTopPremiumBannersVarPopFields>;
  varSamp?: Maybe<BannerCategoryTopPremiumBannersVarSampFields>;
  variance?: Maybe<BannerCategoryTopPremiumBannersVarianceFields>;
};


/** aggregate fields of "banner.category_top_premium_banners" */
export type BannerCategoryTopPremiumBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerCategoryTopPremiumBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerCategoryTopPremiumBannersAvgFields = {
  __typename?: 'BannerCategoryTopPremiumBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.category_top_premium_banners". All fields are combined with a logical 'AND'. */
export type BannerCategoryTopPremiumBannersBoolExp = {
  _and?: InputMaybe<Array<BannerCategoryTopPremiumBannersBoolExp>>;
  _not?: InputMaybe<BannerCategoryTopPremiumBannersBoolExp>;
  _or?: InputMaybe<Array<BannerCategoryTopPremiumBannersBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  subcategories?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerCategoryTopPremiumBannersMaxFields = {
  __typename?: 'BannerCategoryTopPremiumBannersMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerCategoryTopPremiumBannersMinFields = {
  __typename?: 'BannerCategoryTopPremiumBannersMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.category_top_premium_banners". */
export type BannerCategoryTopPremiumBannersOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  subcategoriesAggregate?: InputMaybe<BannerCategoryTopSubcategoriesAggregateOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.category_top_premium_banners" */
export enum BannerCategoryTopPremiumBannersSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerCategoryTopPremiumBannersStddevFields = {
  __typename?: 'BannerCategoryTopPremiumBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerCategoryTopPremiumBannersStddevPopFields = {
  __typename?: 'BannerCategoryTopPremiumBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerCategoryTopPremiumBannersStddevSampFields = {
  __typename?: 'BannerCategoryTopPremiumBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_category_top_premium_banners" */
export type BannerCategoryTopPremiumBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerCategoryTopPremiumBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerCategoryTopPremiumBannersStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerCategoryTopPremiumBannersSumFields = {
  __typename?: 'BannerCategoryTopPremiumBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerCategoryTopPremiumBannersVarPopFields = {
  __typename?: 'BannerCategoryTopPremiumBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerCategoryTopPremiumBannersVarSampFields = {
  __typename?: 'BannerCategoryTopPremiumBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerCategoryTopPremiumBannersVarianceFields = {
  __typename?: 'BannerCategoryTopPremiumBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.category_top_standard_banners" */
export type BannerCategoryTopStandardBanners = {
  __typename?: 'BannerCategoryTopStandardBanners';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  organizer?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An array relationship */
  subcategories: Array<BannerCategoryTopSubcategories>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "banner.category_top_standard_banners" */
export type BannerCategoryTopStandardBannersSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopSubcategoriesOrderBy>>;
  where?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
};

/** aggregated selection of "banner.category_top_standard_banners" */
export type BannerCategoryTopStandardBannersAggregate = {
  __typename?: 'BannerCategoryTopStandardBannersAggregate';
  aggregate?: Maybe<BannerCategoryTopStandardBannersAggregateFields>;
  nodes: Array<BannerCategoryTopStandardBanners>;
};

/** aggregate fields of "banner.category_top_standard_banners" */
export type BannerCategoryTopStandardBannersAggregateFields = {
  __typename?: 'BannerCategoryTopStandardBannersAggregateFields';
  avg?: Maybe<BannerCategoryTopStandardBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerCategoryTopStandardBannersMaxFields>;
  min?: Maybe<BannerCategoryTopStandardBannersMinFields>;
  stddev?: Maybe<BannerCategoryTopStandardBannersStddevFields>;
  stddevPop?: Maybe<BannerCategoryTopStandardBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerCategoryTopStandardBannersStddevSampFields>;
  sum?: Maybe<BannerCategoryTopStandardBannersSumFields>;
  varPop?: Maybe<BannerCategoryTopStandardBannersVarPopFields>;
  varSamp?: Maybe<BannerCategoryTopStandardBannersVarSampFields>;
  variance?: Maybe<BannerCategoryTopStandardBannersVarianceFields>;
};


/** aggregate fields of "banner.category_top_standard_banners" */
export type BannerCategoryTopStandardBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerCategoryTopStandardBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerCategoryTopStandardBannersAvgFields = {
  __typename?: 'BannerCategoryTopStandardBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.category_top_standard_banners". All fields are combined with a logical 'AND'. */
export type BannerCategoryTopStandardBannersBoolExp = {
  _and?: InputMaybe<Array<BannerCategoryTopStandardBannersBoolExp>>;
  _not?: InputMaybe<BannerCategoryTopStandardBannersBoolExp>;
  _or?: InputMaybe<Array<BannerCategoryTopStandardBannersBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  subcategories?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerCategoryTopStandardBannersMaxFields = {
  __typename?: 'BannerCategoryTopStandardBannersMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  organizer?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerCategoryTopStandardBannersMinFields = {
  __typename?: 'BannerCategoryTopStandardBannersMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  organizer?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.category_top_standard_banners". */
export type BannerCategoryTopStandardBannersOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  subcategoriesAggregate?: InputMaybe<BannerCategoryTopSubcategoriesAggregateOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.category_top_standard_banners" */
export enum BannerCategoryTopStandardBannersSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerCategoryTopStandardBannersStddevFields = {
  __typename?: 'BannerCategoryTopStandardBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerCategoryTopStandardBannersStddevPopFields = {
  __typename?: 'BannerCategoryTopStandardBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerCategoryTopStandardBannersStddevSampFields = {
  __typename?: 'BannerCategoryTopStandardBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_category_top_standard_banners" */
export type BannerCategoryTopStandardBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerCategoryTopStandardBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerCategoryTopStandardBannersStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerCategoryTopStandardBannersSumFields = {
  __typename?: 'BannerCategoryTopStandardBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerCategoryTopStandardBannersVarPopFields = {
  __typename?: 'BannerCategoryTopStandardBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerCategoryTopStandardBannersVarSampFields = {
  __typename?: 'BannerCategoryTopStandardBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerCategoryTopStandardBannersVarianceFields = {
  __typename?: 'BannerCategoryTopStandardBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategories = {
  __typename?: 'BannerCategoryTopSubcategories';
  /** An object relationship */
  subcategory?: Maybe<ContestSubcategories>;
  subcategoryId?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesAggregateOrderBy = {
  avg?: InputMaybe<BannerCategoryTopSubcategoriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BannerCategoryTopSubcategoriesMaxOrderBy>;
  min?: InputMaybe<BannerCategoryTopSubcategoriesMinOrderBy>;
  stddev?: InputMaybe<BannerCategoryTopSubcategoriesStddevOrderBy>;
  stddevPop?: InputMaybe<BannerCategoryTopSubcategoriesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<BannerCategoryTopSubcategoriesStddevSampOrderBy>;
  sum?: InputMaybe<BannerCategoryTopSubcategoriesSumOrderBy>;
  varPop?: InputMaybe<BannerCategoryTopSubcategoriesVarPopOrderBy>;
  varSamp?: InputMaybe<BannerCategoryTopSubcategoriesVarSampOrderBy>;
  variance?: InputMaybe<BannerCategoryTopSubcategoriesVarianceOrderBy>;
};

/** order by avg() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesAvgOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "banner.category_top_subcategories". All fields are combined with a logical 'AND'. */
export type BannerCategoryTopSubcategoriesBoolExp = {
  _and?: InputMaybe<Array<BannerCategoryTopSubcategoriesBoolExp>>;
  _not?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
  _or?: InputMaybe<Array<BannerCategoryTopSubcategoriesBoolExp>>;
  subcategory?: InputMaybe<ContestSubcategoriesBoolExp>;
  subcategoryId?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesMaxOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesMinOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "banner.category_top_subcategories". */
export type BannerCategoryTopSubcategoriesOrderBy = {
  subcategory?: InputMaybe<ContestSubcategoriesOrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.category_top_subcategories" */
export enum BannerCategoryTopSubcategoriesSelectColumn {
  /** column name */
  SubcategoryId = 'subcategoryId'
}

/** order by stddev() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesStddevOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesStddevPopOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesStddevSampOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "banner_category_top_subcategories" */
export type BannerCategoryTopSubcategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerCategoryTopSubcategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerCategoryTopSubcategoriesStreamCursorValueInput = {
  subcategoryId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesSumOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesVarPopOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesVarSampOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "banner.category_top_subcategories" */
export type BannerCategoryTopSubcategoriesVarianceOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "banner.company_banners" */
export type BannerCompanyBanners = {
  __typename?: 'BannerCompanyBanners';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.company_banners" */
export type BannerCompanyBannersAggregate = {
  __typename?: 'BannerCompanyBannersAggregate';
  aggregate?: Maybe<BannerCompanyBannersAggregateFields>;
  nodes: Array<BannerCompanyBanners>;
};

/** aggregate fields of "banner.company_banners" */
export type BannerCompanyBannersAggregateFields = {
  __typename?: 'BannerCompanyBannersAggregateFields';
  avg?: Maybe<BannerCompanyBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerCompanyBannersMaxFields>;
  min?: Maybe<BannerCompanyBannersMinFields>;
  stddev?: Maybe<BannerCompanyBannersStddevFields>;
  stddevPop?: Maybe<BannerCompanyBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerCompanyBannersStddevSampFields>;
  sum?: Maybe<BannerCompanyBannersSumFields>;
  varPop?: Maybe<BannerCompanyBannersVarPopFields>;
  varSamp?: Maybe<BannerCompanyBannersVarSampFields>;
  variance?: Maybe<BannerCompanyBannersVarianceFields>;
};


/** aggregate fields of "banner.company_banners" */
export type BannerCompanyBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerCompanyBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerCompanyBannersAvgFields = {
  __typename?: 'BannerCompanyBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.company_banners". All fields are combined with a logical 'AND'. */
export type BannerCompanyBannersBoolExp = {
  _and?: InputMaybe<Array<BannerCompanyBannersBoolExp>>;
  _not?: InputMaybe<BannerCompanyBannersBoolExp>;
  _or?: InputMaybe<Array<BannerCompanyBannersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerCompanyBannersMaxFields = {
  __typename?: 'BannerCompanyBannersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerCompanyBannersMinFields = {
  __typename?: 'BannerCompanyBannersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.company_banners". */
export type BannerCompanyBannersOrderBy = {
  id?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.company_banners" */
export enum BannerCompanyBannersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerCompanyBannersStddevFields = {
  __typename?: 'BannerCompanyBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerCompanyBannersStddevPopFields = {
  __typename?: 'BannerCompanyBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerCompanyBannersStddevSampFields = {
  __typename?: 'BannerCompanyBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_company_banners" */
export type BannerCompanyBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerCompanyBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerCompanyBannersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerCompanyBannersSumFields = {
  __typename?: 'BannerCompanyBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerCompanyBannersVarPopFields = {
  __typename?: 'BannerCompanyBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerCompanyBannersVarSampFields = {
  __typename?: 'BannerCompanyBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerCompanyBannersVarianceFields = {
  __typename?: 'BannerCompanyBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.contest_category_banners" */
export type BannerContestCategoryBanners = {
  __typename?: 'BannerContestCategoryBanners';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.contest_category_banners" */
export type BannerContestCategoryBannersAggregate = {
  __typename?: 'BannerContestCategoryBannersAggregate';
  aggregate?: Maybe<BannerContestCategoryBannersAggregateFields>;
  nodes: Array<BannerContestCategoryBanners>;
};

export type BannerContestCategoryBannersAggregateBoolExp = {
  count?: InputMaybe<BannerContestCategoryBannersAggregateBoolExpCount>;
};

/** aggregate fields of "banner.contest_category_banners" */
export type BannerContestCategoryBannersAggregateFields = {
  __typename?: 'BannerContestCategoryBannersAggregateFields';
  avg?: Maybe<BannerContestCategoryBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerContestCategoryBannersMaxFields>;
  min?: Maybe<BannerContestCategoryBannersMinFields>;
  stddev?: Maybe<BannerContestCategoryBannersStddevFields>;
  stddevPop?: Maybe<BannerContestCategoryBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerContestCategoryBannersStddevSampFields>;
  sum?: Maybe<BannerContestCategoryBannersSumFields>;
  varPop?: Maybe<BannerContestCategoryBannersVarPopFields>;
  varSamp?: Maybe<BannerContestCategoryBannersVarSampFields>;
  variance?: Maybe<BannerContestCategoryBannersVarianceFields>;
};


/** aggregate fields of "banner.contest_category_banners" */
export type BannerContestCategoryBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersAggregateOrderBy = {
  avg?: InputMaybe<BannerContestCategoryBannersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BannerContestCategoryBannersMaxOrderBy>;
  min?: InputMaybe<BannerContestCategoryBannersMinOrderBy>;
  stddev?: InputMaybe<BannerContestCategoryBannersStddevOrderBy>;
  stddevPop?: InputMaybe<BannerContestCategoryBannersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<BannerContestCategoryBannersStddevSampOrderBy>;
  sum?: InputMaybe<BannerContestCategoryBannersSumOrderBy>;
  varPop?: InputMaybe<BannerContestCategoryBannersVarPopOrderBy>;
  varSamp?: InputMaybe<BannerContestCategoryBannersVarSampOrderBy>;
  variance?: InputMaybe<BannerContestCategoryBannersVarianceOrderBy>;
};

/** aggregate avg on columns */
export type BannerContestCategoryBannersAvgFields = {
  __typename?: 'BannerContestCategoryBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "banner.contest_category_banners". All fields are combined with a logical 'AND'. */
export type BannerContestCategoryBannersBoolExp = {
  _and?: InputMaybe<Array<BannerContestCategoryBannersBoolExp>>;
  _not?: InputMaybe<BannerContestCategoryBannersBoolExp>;
  _or?: InputMaybe<Array<BannerContestCategoryBannersBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerContestCategoryBannersMaxFields = {
  __typename?: 'BannerContestCategoryBannersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type BannerContestCategoryBannersMinFields = {
  __typename?: 'BannerContestCategoryBannersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "banner.contest_category_banners". */
export type BannerContestCategoryBannersOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  id?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.contest_category_banners" */
export enum BannerContestCategoryBannersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerContestCategoryBannersStddevFields = {
  __typename?: 'BannerContestCategoryBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type BannerContestCategoryBannersStddevPopFields = {
  __typename?: 'BannerContestCategoryBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type BannerContestCategoryBannersStddevSampFields = {
  __typename?: 'BannerContestCategoryBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "banner_contest_category_banners" */
export type BannerContestCategoryBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerContestCategoryBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerContestCategoryBannersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerContestCategoryBannersSumFields = {
  __typename?: 'BannerContestCategoryBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type BannerContestCategoryBannersVarPopFields = {
  __typename?: 'BannerContestCategoryBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type BannerContestCategoryBannersVarSampFields = {
  __typename?: 'BannerContestCategoryBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type BannerContestCategoryBannersVarianceFields = {
  __typename?: 'BannerContestCategoryBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "banner.contest_category_banners" */
export type BannerContestCategoryBannersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "banner.contest_top_banners" */
export type BannerContestTopBanners = {
  __typename?: 'BannerContestTopBanners';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.contest_top_banners" */
export type BannerContestTopBannersAggregate = {
  __typename?: 'BannerContestTopBannersAggregate';
  aggregate?: Maybe<BannerContestTopBannersAggregateFields>;
  nodes: Array<BannerContestTopBanners>;
};

/** aggregate fields of "banner.contest_top_banners" */
export type BannerContestTopBannersAggregateFields = {
  __typename?: 'BannerContestTopBannersAggregateFields';
  avg?: Maybe<BannerContestTopBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerContestTopBannersMaxFields>;
  min?: Maybe<BannerContestTopBannersMinFields>;
  stddev?: Maybe<BannerContestTopBannersStddevFields>;
  stddevPop?: Maybe<BannerContestTopBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerContestTopBannersStddevSampFields>;
  sum?: Maybe<BannerContestTopBannersSumFields>;
  varPop?: Maybe<BannerContestTopBannersVarPopFields>;
  varSamp?: Maybe<BannerContestTopBannersVarSampFields>;
  variance?: Maybe<BannerContestTopBannersVarianceFields>;
};


/** aggregate fields of "banner.contest_top_banners" */
export type BannerContestTopBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerContestTopBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerContestTopBannersAvgFields = {
  __typename?: 'BannerContestTopBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.contest_top_banners". All fields are combined with a logical 'AND'. */
export type BannerContestTopBannersBoolExp = {
  _and?: InputMaybe<Array<BannerContestTopBannersBoolExp>>;
  _not?: InputMaybe<BannerContestTopBannersBoolExp>;
  _or?: InputMaybe<Array<BannerContestTopBannersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerContestTopBannersMaxFields = {
  __typename?: 'BannerContestTopBannersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerContestTopBannersMinFields = {
  __typename?: 'BannerContestTopBannersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.contest_top_banners". */
export type BannerContestTopBannersOrderBy = {
  id?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.contest_top_banners" */
export enum BannerContestTopBannersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerContestTopBannersStddevFields = {
  __typename?: 'BannerContestTopBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerContestTopBannersStddevPopFields = {
  __typename?: 'BannerContestTopBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerContestTopBannersStddevSampFields = {
  __typename?: 'BannerContestTopBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_contest_top_banners" */
export type BannerContestTopBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerContestTopBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerContestTopBannersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerContestTopBannersSumFields = {
  __typename?: 'BannerContestTopBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerContestTopBannersVarPopFields = {
  __typename?: 'BannerContestTopBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerContestTopBannersVarSampFields = {
  __typename?: 'BannerContestTopBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerContestTopBannersVarianceFields = {
  __typename?: 'BannerContestTopBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.footer_banners" */
export type BannerFooterBanners = {
  __typename?: 'BannerFooterBanners';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.footer_banners" */
export type BannerFooterBannersAggregate = {
  __typename?: 'BannerFooterBannersAggregate';
  aggregate?: Maybe<BannerFooterBannersAggregateFields>;
  nodes: Array<BannerFooterBanners>;
};

/** aggregate fields of "banner.footer_banners" */
export type BannerFooterBannersAggregateFields = {
  __typename?: 'BannerFooterBannersAggregateFields';
  avg?: Maybe<BannerFooterBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerFooterBannersMaxFields>;
  min?: Maybe<BannerFooterBannersMinFields>;
  stddev?: Maybe<BannerFooterBannersStddevFields>;
  stddevPop?: Maybe<BannerFooterBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerFooterBannersStddevSampFields>;
  sum?: Maybe<BannerFooterBannersSumFields>;
  varPop?: Maybe<BannerFooterBannersVarPopFields>;
  varSamp?: Maybe<BannerFooterBannersVarSampFields>;
  variance?: Maybe<BannerFooterBannersVarianceFields>;
};


/** aggregate fields of "banner.footer_banners" */
export type BannerFooterBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerFooterBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerFooterBannersAvgFields = {
  __typename?: 'BannerFooterBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.footer_banners". All fields are combined with a logical 'AND'. */
export type BannerFooterBannersBoolExp = {
  _and?: InputMaybe<Array<BannerFooterBannersBoolExp>>;
  _not?: InputMaybe<BannerFooterBannersBoolExp>;
  _or?: InputMaybe<Array<BannerFooterBannersBoolExp>>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerFooterBannersMaxFields = {
  __typename?: 'BannerFooterBannersMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerFooterBannersMinFields = {
  __typename?: 'BannerFooterBannersMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.footer_banners". */
export type BannerFooterBannersOrderBy = {
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.footer_banners" */
export enum BannerFooterBannersSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerFooterBannersStddevFields = {
  __typename?: 'BannerFooterBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerFooterBannersStddevPopFields = {
  __typename?: 'BannerFooterBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerFooterBannersStddevSampFields = {
  __typename?: 'BannerFooterBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_footer_banners" */
export type BannerFooterBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerFooterBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerFooterBannersStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerFooterBannersSumFields = {
  __typename?: 'BannerFooterBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerFooterBannersVarPopFields = {
  __typename?: 'BannerFooterBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerFooterBannersVarSampFields = {
  __typename?: 'BannerFooterBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerFooterBannersVarianceFields = {
  __typename?: 'BannerFooterBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.new_contests" */
export type BannerNewContests = {
  __typename?: 'BannerNewContests';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.new_contests" */
export type BannerNewContestsAggregate = {
  __typename?: 'BannerNewContestsAggregate';
  aggregate?: Maybe<BannerNewContestsAggregateFields>;
  nodes: Array<BannerNewContests>;
};

/** aggregate fields of "banner.new_contests" */
export type BannerNewContestsAggregateFields = {
  __typename?: 'BannerNewContestsAggregateFields';
  avg?: Maybe<BannerNewContestsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerNewContestsMaxFields>;
  min?: Maybe<BannerNewContestsMinFields>;
  stddev?: Maybe<BannerNewContestsStddevFields>;
  stddevPop?: Maybe<BannerNewContestsStddevPopFields>;
  stddevSamp?: Maybe<BannerNewContestsStddevSampFields>;
  sum?: Maybe<BannerNewContestsSumFields>;
  varPop?: Maybe<BannerNewContestsVarPopFields>;
  varSamp?: Maybe<BannerNewContestsVarSampFields>;
  variance?: Maybe<BannerNewContestsVarianceFields>;
};


/** aggregate fields of "banner.new_contests" */
export type BannerNewContestsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerNewContestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerNewContestsAvgFields = {
  __typename?: 'BannerNewContestsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.new_contests". All fields are combined with a logical 'AND'. */
export type BannerNewContestsBoolExp = {
  _and?: InputMaybe<Array<BannerNewContestsBoolExp>>;
  _not?: InputMaybe<BannerNewContestsBoolExp>;
  _or?: InputMaybe<Array<BannerNewContestsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerNewContestsMaxFields = {
  __typename?: 'BannerNewContestsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerNewContestsMinFields = {
  __typename?: 'BannerNewContestsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.new_contests". */
export type BannerNewContestsOrderBy = {
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.new_contests" */
export enum BannerNewContestsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerNewContestsStddevFields = {
  __typename?: 'BannerNewContestsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerNewContestsStddevPopFields = {
  __typename?: 'BannerNewContestsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerNewContestsStddevSampFields = {
  __typename?: 'BannerNewContestsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_new_contests" */
export type BannerNewContestsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerNewContestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerNewContestsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerNewContestsSumFields = {
  __typename?: 'BannerNewContestsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerNewContestsVarPopFields = {
  __typename?: 'BannerNewContestsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerNewContestsVarSampFields = {
  __typename?: 'BannerNewContestsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerNewContestsVarianceFields = {
  __typename?: 'BannerNewContestsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.periods" */
export type BannerPeriods = {
  __typename?: 'BannerPeriods';
  start?: Maybe<Scalars['timestamp']['output']>;
};

/** Boolean expression to filter rows from the table "banner.periods". All fields are combined with a logical 'AND'. */
export type BannerPeriodsBoolExp = {
  _and?: InputMaybe<Array<BannerPeriodsBoolExp>>;
  _not?: InputMaybe<BannerPeriodsBoolExp>;
  _or?: InputMaybe<Array<BannerPeriodsBoolExp>>;
  start?: InputMaybe<TimestampComparisonExp>;
};

/** Ordering options when selecting data from "banner.periods". */
export type BannerPeriodsOrderBy = {
  start?: InputMaybe<OrderBy>;
};

/** columns and relationships of "banner.pickup_banners" */
export type BannerPickupBanners = {
  __typename?: 'BannerPickupBanners';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.pickup_banners" */
export type BannerPickupBannersAggregate = {
  __typename?: 'BannerPickupBannersAggregate';
  aggregate?: Maybe<BannerPickupBannersAggregateFields>;
  nodes: Array<BannerPickupBanners>;
};

/** aggregate fields of "banner.pickup_banners" */
export type BannerPickupBannersAggregateFields = {
  __typename?: 'BannerPickupBannersAggregateFields';
  avg?: Maybe<BannerPickupBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerPickupBannersMaxFields>;
  min?: Maybe<BannerPickupBannersMinFields>;
  stddev?: Maybe<BannerPickupBannersStddevFields>;
  stddevPop?: Maybe<BannerPickupBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerPickupBannersStddevSampFields>;
  sum?: Maybe<BannerPickupBannersSumFields>;
  varPop?: Maybe<BannerPickupBannersVarPopFields>;
  varSamp?: Maybe<BannerPickupBannersVarSampFields>;
  variance?: Maybe<BannerPickupBannersVarianceFields>;
};


/** aggregate fields of "banner.pickup_banners" */
export type BannerPickupBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerPickupBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerPickupBannersAvgFields = {
  __typename?: 'BannerPickupBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.pickup_banners". All fields are combined with a logical 'AND'. */
export type BannerPickupBannersBoolExp = {
  _and?: InputMaybe<Array<BannerPickupBannersBoolExp>>;
  _not?: InputMaybe<BannerPickupBannersBoolExp>;
  _or?: InputMaybe<Array<BannerPickupBannersBoolExp>>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerPickupBannersMaxFields = {
  __typename?: 'BannerPickupBannersMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerPickupBannersMinFields = {
  __typename?: 'BannerPickupBannersMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.pickup_banners". */
export type BannerPickupBannersOrderBy = {
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.pickup_banners" */
export enum BannerPickupBannersSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerPickupBannersStddevFields = {
  __typename?: 'BannerPickupBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerPickupBannersStddevPopFields = {
  __typename?: 'BannerPickupBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerPickupBannersStddevSampFields = {
  __typename?: 'BannerPickupBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_pickup_banners" */
export type BannerPickupBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerPickupBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerPickupBannersStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerPickupBannersSumFields = {
  __typename?: 'BannerPickupBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerPickupBannersVarPopFields = {
  __typename?: 'BannerPickupBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerPickupBannersVarSampFields = {
  __typename?: 'BannerPickupBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerPickupBannersVarianceFields = {
  __typename?: 'BannerPickupBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.premium_banners" */
export type BannerPremiumBanners = {
  __typename?: 'BannerPremiumBanners';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.premium_banners" */
export type BannerPremiumBannersAggregate = {
  __typename?: 'BannerPremiumBannersAggregate';
  aggregate?: Maybe<BannerPremiumBannersAggregateFields>;
  nodes: Array<BannerPremiumBanners>;
};

/** aggregate fields of "banner.premium_banners" */
export type BannerPremiumBannersAggregateFields = {
  __typename?: 'BannerPremiumBannersAggregateFields';
  avg?: Maybe<BannerPremiumBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerPremiumBannersMaxFields>;
  min?: Maybe<BannerPremiumBannersMinFields>;
  stddev?: Maybe<BannerPremiumBannersStddevFields>;
  stddevPop?: Maybe<BannerPremiumBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerPremiumBannersStddevSampFields>;
  sum?: Maybe<BannerPremiumBannersSumFields>;
  varPop?: Maybe<BannerPremiumBannersVarPopFields>;
  varSamp?: Maybe<BannerPremiumBannersVarSampFields>;
  variance?: Maybe<BannerPremiumBannersVarianceFields>;
};


/** aggregate fields of "banner.premium_banners" */
export type BannerPremiumBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerPremiumBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerPremiumBannersAvgFields = {
  __typename?: 'BannerPremiumBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.premium_banners". All fields are combined with a logical 'AND'. */
export type BannerPremiumBannersBoolExp = {
  _and?: InputMaybe<Array<BannerPremiumBannersBoolExp>>;
  _not?: InputMaybe<BannerPremiumBannersBoolExp>;
  _or?: InputMaybe<Array<BannerPremiumBannersBoolExp>>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerPremiumBannersMaxFields = {
  __typename?: 'BannerPremiumBannersMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerPremiumBannersMinFields = {
  __typename?: 'BannerPremiumBannersMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.premium_banners". */
export type BannerPremiumBannersOrderBy = {
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.premium_banners" */
export enum BannerPremiumBannersSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerPremiumBannersStddevFields = {
  __typename?: 'BannerPremiumBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerPremiumBannersStddevPopFields = {
  __typename?: 'BannerPremiumBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerPremiumBannersStddevSampFields = {
  __typename?: 'BannerPremiumBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_premium_banners" */
export type BannerPremiumBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerPremiumBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerPremiumBannersStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerPremiumBannersSumFields = {
  __typename?: 'BannerPremiumBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerPremiumBannersVarPopFields = {
  __typename?: 'BannerPremiumBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerPremiumBannersVarSampFields = {
  __typename?: 'BannerPremiumBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerPremiumBannersVarianceFields = {
  __typename?: 'BannerPremiumBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.rectangle_banners" */
export type BannerRectangleBanners = {
  __typename?: 'BannerRectangleBanners';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.rectangle_banners" */
export type BannerRectangleBannersAggregate = {
  __typename?: 'BannerRectangleBannersAggregate';
  aggregate?: Maybe<BannerRectangleBannersAggregateFields>;
  nodes: Array<BannerRectangleBanners>;
};

/** aggregate fields of "banner.rectangle_banners" */
export type BannerRectangleBannersAggregateFields = {
  __typename?: 'BannerRectangleBannersAggregateFields';
  avg?: Maybe<BannerRectangleBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerRectangleBannersMaxFields>;
  min?: Maybe<BannerRectangleBannersMinFields>;
  stddev?: Maybe<BannerRectangleBannersStddevFields>;
  stddevPop?: Maybe<BannerRectangleBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerRectangleBannersStddevSampFields>;
  sum?: Maybe<BannerRectangleBannersSumFields>;
  varPop?: Maybe<BannerRectangleBannersVarPopFields>;
  varSamp?: Maybe<BannerRectangleBannersVarSampFields>;
  variance?: Maybe<BannerRectangleBannersVarianceFields>;
};


/** aggregate fields of "banner.rectangle_banners" */
export type BannerRectangleBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerRectangleBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerRectangleBannersAvgFields = {
  __typename?: 'BannerRectangleBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.rectangle_banners". All fields are combined with a logical 'AND'. */
export type BannerRectangleBannersBoolExp = {
  _and?: InputMaybe<Array<BannerRectangleBannersBoolExp>>;
  _not?: InputMaybe<BannerRectangleBannersBoolExp>;
  _or?: InputMaybe<Array<BannerRectangleBannersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerRectangleBannersMaxFields = {
  __typename?: 'BannerRectangleBannersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerRectangleBannersMinFields = {
  __typename?: 'BannerRectangleBannersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.rectangle_banners". */
export type BannerRectangleBannersOrderBy = {
  id?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.rectangle_banners" */
export enum BannerRectangleBannersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerRectangleBannersStddevFields = {
  __typename?: 'BannerRectangleBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerRectangleBannersStddevPopFields = {
  __typename?: 'BannerRectangleBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerRectangleBannersStddevSampFields = {
  __typename?: 'BannerRectangleBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_rectangle_banners" */
export type BannerRectangleBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerRectangleBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerRectangleBannersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerRectangleBannersSumFields = {
  __typename?: 'BannerRectangleBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerRectangleBannersVarPopFields = {
  __typename?: 'BannerRectangleBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerRectangleBannersVarSampFields = {
  __typename?: 'BannerRectangleBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerRectangleBannersVarianceFields = {
  __typename?: 'BannerRectangleBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.search_result_contests" */
export type BannerSearchResultContests = {
  __typename?: 'BannerSearchResultContests';
  /** An object relationship */
  contest?: Maybe<Contests>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.search_result_contests" */
export type BannerSearchResultContestsAggregate = {
  __typename?: 'BannerSearchResultContestsAggregate';
  aggregate?: Maybe<BannerSearchResultContestsAggregateFields>;
  nodes: Array<BannerSearchResultContests>;
};

/** aggregate fields of "banner.search_result_contests" */
export type BannerSearchResultContestsAggregateFields = {
  __typename?: 'BannerSearchResultContestsAggregateFields';
  avg?: Maybe<BannerSearchResultContestsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerSearchResultContestsMaxFields>;
  min?: Maybe<BannerSearchResultContestsMinFields>;
  stddev?: Maybe<BannerSearchResultContestsStddevFields>;
  stddevPop?: Maybe<BannerSearchResultContestsStddevPopFields>;
  stddevSamp?: Maybe<BannerSearchResultContestsStddevSampFields>;
  sum?: Maybe<BannerSearchResultContestsSumFields>;
  varPop?: Maybe<BannerSearchResultContestsVarPopFields>;
  varSamp?: Maybe<BannerSearchResultContestsVarSampFields>;
  variance?: Maybe<BannerSearchResultContestsVarianceFields>;
};


/** aggregate fields of "banner.search_result_contests" */
export type BannerSearchResultContestsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerSearchResultContestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerSearchResultContestsAvgFields = {
  __typename?: 'BannerSearchResultContestsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.search_result_contests". All fields are combined with a logical 'AND'. */
export type BannerSearchResultContestsBoolExp = {
  _and?: InputMaybe<Array<BannerSearchResultContestsBoolExp>>;
  _not?: InputMaybe<BannerSearchResultContestsBoolExp>;
  _or?: InputMaybe<Array<BannerSearchResultContestsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerSearchResultContestsMaxFields = {
  __typename?: 'BannerSearchResultContestsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerSearchResultContestsMinFields = {
  __typename?: 'BannerSearchResultContestsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.search_result_contests". */
export type BannerSearchResultContestsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  id?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.search_result_contests" */
export enum BannerSearchResultContestsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerSearchResultContestsStddevFields = {
  __typename?: 'BannerSearchResultContestsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerSearchResultContestsStddevPopFields = {
  __typename?: 'BannerSearchResultContestsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerSearchResultContestsStddevSampFields = {
  __typename?: 'BannerSearchResultContestsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_search_result_contests" */
export type BannerSearchResultContestsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerSearchResultContestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerSearchResultContestsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerSearchResultContestsSumFields = {
  __typename?: 'BannerSearchResultContestsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerSearchResultContestsVarPopFields = {
  __typename?: 'BannerSearchResultContestsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerSearchResultContestsVarSampFields = {
  __typename?: 'BannerSearchResultContestsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerSearchResultContestsVarianceFields = {
  __typename?: 'BannerSearchResultContestsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.sidebar_banners" */
export type BannerSidebarBanners = {
  __typename?: 'BannerSidebarBanners';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.sidebar_banners" */
export type BannerSidebarBannersAggregate = {
  __typename?: 'BannerSidebarBannersAggregate';
  aggregate?: Maybe<BannerSidebarBannersAggregateFields>;
  nodes: Array<BannerSidebarBanners>;
};

/** aggregate fields of "banner.sidebar_banners" */
export type BannerSidebarBannersAggregateFields = {
  __typename?: 'BannerSidebarBannersAggregateFields';
  avg?: Maybe<BannerSidebarBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerSidebarBannersMaxFields>;
  min?: Maybe<BannerSidebarBannersMinFields>;
  stddev?: Maybe<BannerSidebarBannersStddevFields>;
  stddevPop?: Maybe<BannerSidebarBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerSidebarBannersStddevSampFields>;
  sum?: Maybe<BannerSidebarBannersSumFields>;
  varPop?: Maybe<BannerSidebarBannersVarPopFields>;
  varSamp?: Maybe<BannerSidebarBannersVarSampFields>;
  variance?: Maybe<BannerSidebarBannersVarianceFields>;
};


/** aggregate fields of "banner.sidebar_banners" */
export type BannerSidebarBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerSidebarBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerSidebarBannersAvgFields = {
  __typename?: 'BannerSidebarBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.sidebar_banners". All fields are combined with a logical 'AND'. */
export type BannerSidebarBannersBoolExp = {
  _and?: InputMaybe<Array<BannerSidebarBannersBoolExp>>;
  _not?: InputMaybe<BannerSidebarBannersBoolExp>;
  _or?: InputMaybe<Array<BannerSidebarBannersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerSidebarBannersMaxFields = {
  __typename?: 'BannerSidebarBannersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerSidebarBannersMinFields = {
  __typename?: 'BannerSidebarBannersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.sidebar_banners". */
export type BannerSidebarBannersOrderBy = {
  id?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.sidebar_banners" */
export enum BannerSidebarBannersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerSidebarBannersStddevFields = {
  __typename?: 'BannerSidebarBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerSidebarBannersStddevPopFields = {
  __typename?: 'BannerSidebarBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerSidebarBannersStddevSampFields = {
  __typename?: 'BannerSidebarBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_sidebar_banners" */
export type BannerSidebarBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerSidebarBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerSidebarBannersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerSidebarBannersSumFields = {
  __typename?: 'BannerSidebarBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerSidebarBannersVarPopFields = {
  __typename?: 'BannerSidebarBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerSidebarBannersVarSampFields = {
  __typename?: 'BannerSidebarBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerSidebarBannersVarianceFields = {
  __typename?: 'BannerSidebarBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.standard_banners" */
export type BannerStandardBanners = {
  __typename?: 'BannerStandardBanners';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.standard_banners" */
export type BannerStandardBannersAggregate = {
  __typename?: 'BannerStandardBannersAggregate';
  aggregate?: Maybe<BannerStandardBannersAggregateFields>;
  nodes: Array<BannerStandardBanners>;
};

/** aggregate fields of "banner.standard_banners" */
export type BannerStandardBannersAggregateFields = {
  __typename?: 'BannerStandardBannersAggregateFields';
  avg?: Maybe<BannerStandardBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerStandardBannersMaxFields>;
  min?: Maybe<BannerStandardBannersMinFields>;
  stddev?: Maybe<BannerStandardBannersStddevFields>;
  stddevPop?: Maybe<BannerStandardBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerStandardBannersStddevSampFields>;
  sum?: Maybe<BannerStandardBannersSumFields>;
  varPop?: Maybe<BannerStandardBannersVarPopFields>;
  varSamp?: Maybe<BannerStandardBannersVarSampFields>;
  variance?: Maybe<BannerStandardBannersVarianceFields>;
};


/** aggregate fields of "banner.standard_banners" */
export type BannerStandardBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerStandardBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerStandardBannersAvgFields = {
  __typename?: 'BannerStandardBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.standard_banners". All fields are combined with a logical 'AND'. */
export type BannerStandardBannersBoolExp = {
  _and?: InputMaybe<Array<BannerStandardBannersBoolExp>>;
  _not?: InputMaybe<BannerStandardBannersBoolExp>;
  _or?: InputMaybe<Array<BannerStandardBannersBoolExp>>;
  deadline?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerStandardBannersMaxFields = {
  __typename?: 'BannerStandardBannersMaxFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerStandardBannersMinFields = {
  __typename?: 'BannerStandardBannersMinFields';
  deadline?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.standard_banners". */
export type BannerStandardBannersOrderBy = {
  deadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.standard_banners" */
export enum BannerStandardBannersSelectColumn {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerStandardBannersStddevFields = {
  __typename?: 'BannerStandardBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerStandardBannersStddevPopFields = {
  __typename?: 'BannerStandardBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerStandardBannersStddevSampFields = {
  __typename?: 'BannerStandardBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_standard_banners" */
export type BannerStandardBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerStandardBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerStandardBannersStreamCursorValueInput = {
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerStandardBannersSumFields = {
  __typename?: 'BannerStandardBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerStandardBannersVarPopFields = {
  __typename?: 'BannerStandardBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerStandardBannersVarSampFields = {
  __typename?: 'BannerStandardBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerStandardBannersVarianceFields = {
  __typename?: 'BannerStandardBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.super_banners" */
export type BannerSuperBanners = {
  __typename?: 'BannerSuperBanners';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  /** An object relationship */
  thumbnail?: Maybe<BannerThumbnails>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.super_banners" */
export type BannerSuperBannersAggregate = {
  __typename?: 'BannerSuperBannersAggregate';
  aggregate?: Maybe<BannerSuperBannersAggregateFields>;
  nodes: Array<BannerSuperBanners>;
};

/** aggregate fields of "banner.super_banners" */
export type BannerSuperBannersAggregateFields = {
  __typename?: 'BannerSuperBannersAggregateFields';
  avg?: Maybe<BannerSuperBannersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerSuperBannersMaxFields>;
  min?: Maybe<BannerSuperBannersMinFields>;
  stddev?: Maybe<BannerSuperBannersStddevFields>;
  stddevPop?: Maybe<BannerSuperBannersStddevPopFields>;
  stddevSamp?: Maybe<BannerSuperBannersStddevSampFields>;
  sum?: Maybe<BannerSuperBannersSumFields>;
  varPop?: Maybe<BannerSuperBannersVarPopFields>;
  varSamp?: Maybe<BannerSuperBannersVarSampFields>;
  variance?: Maybe<BannerSuperBannersVarianceFields>;
};


/** aggregate fields of "banner.super_banners" */
export type BannerSuperBannersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerSuperBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerSuperBannersAvgFields = {
  __typename?: 'BannerSuperBannersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.super_banners". All fields are combined with a logical 'AND'. */
export type BannerSuperBannersBoolExp = {
  _and?: InputMaybe<Array<BannerSuperBannersBoolExp>>;
  _not?: InputMaybe<BannerSuperBannersBoolExp>;
  _or?: InputMaybe<Array<BannerSuperBannersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  thumbnail?: InputMaybe<BannerThumbnailsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerSuperBannersMaxFields = {
  __typename?: 'BannerSuperBannersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerSuperBannersMinFields = {
  __typename?: 'BannerSuperBannersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.super_banners". */
export type BannerSuperBannersOrderBy = {
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  thumbnail?: InputMaybe<BannerThumbnailsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.super_banners" */
export enum BannerSuperBannersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerSuperBannersStddevFields = {
  __typename?: 'BannerSuperBannersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerSuperBannersStddevPopFields = {
  __typename?: 'BannerSuperBannersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerSuperBannersStddevSampFields = {
  __typename?: 'BannerSuperBannersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_super_banners" */
export type BannerSuperBannersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerSuperBannersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerSuperBannersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerSuperBannersSumFields = {
  __typename?: 'BannerSuperBannersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerSuperBannersVarPopFields = {
  __typename?: 'BannerSuperBannersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerSuperBannersVarSampFields = {
  __typename?: 'BannerSuperBannersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerSuperBannersVarianceFields = {
  __typename?: 'BannerSuperBannersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.text_links" */
export type BannerTextLinks = {
  __typename?: 'BannerTextLinks';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  period?: Maybe<BannerPeriods>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "banner.text_links" */
export type BannerTextLinksAggregate = {
  __typename?: 'BannerTextLinksAggregate';
  aggregate?: Maybe<BannerTextLinksAggregateFields>;
  nodes: Array<BannerTextLinks>;
};

/** aggregate fields of "banner.text_links" */
export type BannerTextLinksAggregateFields = {
  __typename?: 'BannerTextLinksAggregateFields';
  avg?: Maybe<BannerTextLinksAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BannerTextLinksMaxFields>;
  min?: Maybe<BannerTextLinksMinFields>;
  stddev?: Maybe<BannerTextLinksStddevFields>;
  stddevPop?: Maybe<BannerTextLinksStddevPopFields>;
  stddevSamp?: Maybe<BannerTextLinksStddevSampFields>;
  sum?: Maybe<BannerTextLinksSumFields>;
  varPop?: Maybe<BannerTextLinksVarPopFields>;
  varSamp?: Maybe<BannerTextLinksVarSampFields>;
  variance?: Maybe<BannerTextLinksVarianceFields>;
};


/** aggregate fields of "banner.text_links" */
export type BannerTextLinksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BannerTextLinksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BannerTextLinksAvgFields = {
  __typename?: 'BannerTextLinksAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "banner.text_links". All fields are combined with a logical 'AND'. */
export type BannerTextLinksBoolExp = {
  _and?: InputMaybe<Array<BannerTextLinksBoolExp>>;
  _not?: InputMaybe<BannerTextLinksBoolExp>;
  _or?: InputMaybe<Array<BannerTextLinksBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  period?: InputMaybe<BannerPeriodsBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BannerTextLinksMaxFields = {
  __typename?: 'BannerTextLinksMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BannerTextLinksMinFields = {
  __typename?: 'BannerTextLinksMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "banner.text_links". */
export type BannerTextLinksOrderBy = {
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  period?: InputMaybe<BannerPeriodsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "banner.text_links" */
export enum BannerTextLinksSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type BannerTextLinksStddevFields = {
  __typename?: 'BannerTextLinksStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type BannerTextLinksStddevPopFields = {
  __typename?: 'BannerTextLinksStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type BannerTextLinksStddevSampFields = {
  __typename?: 'BannerTextLinksStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "banner_text_links" */
export type BannerTextLinksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BannerTextLinksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BannerTextLinksStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BannerTextLinksSumFields = {
  __typename?: 'BannerTextLinksSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type BannerTextLinksVarPopFields = {
  __typename?: 'BannerTextLinksVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type BannerTextLinksVarSampFields = {
  __typename?: 'BannerTextLinksVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BannerTextLinksVarianceFields = {
  __typename?: 'BannerTextLinksVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "banner.thumbnails" */
export type BannerThumbnails = {
  __typename?: 'BannerThumbnails';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
};

/** Boolean expression to filter rows from the table "banner.thumbnails". All fields are combined with a logical 'AND'. */
export type BannerThumbnailsBoolExp = {
  _and?: InputMaybe<Array<BannerThumbnailsBoolExp>>;
  _not?: InputMaybe<BannerThumbnailsBoolExp>;
  _or?: InputMaybe<Array<BannerThumbnailsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
};

/** Ordering options when selecting data from "banner.thumbnails". */
export type BannerThumbnailsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CheckoutCartInputItem = {
  price: CheckoutCartInputItemPrice;
  quantity: Scalars['Int']['input'];
};

export type CheckoutCartInputItemPrice = {
  id: Scalars['String']['input'];
};

export type CheckoutCartOutput = {
  __typename?: 'CheckoutCartOutput';
  checkoutUrl: Scalars['String']['output'];
};

export type ConnectedCustomer = {
  __typename?: 'ConnectedCustomer';
  id: Scalars['String']['output'];
  orders: Orders;
};


export type ConnectedCustomerOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "contest.categories" */
export type ContestCategories = {
  __typename?: 'ContestCategories';
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articlesAggregate: ArticlesAggregate;
  /** An array relationship */
  banners: Array<BannerContestCategoryBanners>;
  /** An aggregate relationship */
  bannersAggregate: BannerContestCategoryBannersAggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nameEn: Scalars['String']['output'];
  /** An array relationship */
  subcategories: Array<ContestSubcategories>;
  /** An aggregate relationship */
  subcategoriesAggregate: ContestSubcategoriesAggregate;
};


/** columns and relationships of "contest.categories" */
export type ContestCategoriesArticlesArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


/** columns and relationships of "contest.categories" */
export type ContestCategoriesArticlesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


/** columns and relationships of "contest.categories" */
export type ContestCategoriesBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestCategoryBannersOrderBy>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


/** columns and relationships of "contest.categories" */
export type ContestCategoriesBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestCategoryBannersOrderBy>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


/** columns and relationships of "contest.categories" */
export type ContestCategoriesSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestSubcategoriesOrderBy>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};


/** columns and relationships of "contest.categories" */
export type ContestCategoriesSubcategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestSubcategoriesOrderBy>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};

/** aggregated selection of "contest.categories" */
export type ContestCategoriesAggregate = {
  __typename?: 'ContestCategoriesAggregate';
  aggregate?: Maybe<ContestCategoriesAggregateFields>;
  nodes: Array<ContestCategories>;
};

/** aggregate fields of "contest.categories" */
export type ContestCategoriesAggregateFields = {
  __typename?: 'ContestCategoriesAggregateFields';
  avg?: Maybe<ContestCategoriesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestCategoriesMaxFields>;
  min?: Maybe<ContestCategoriesMinFields>;
  stddev?: Maybe<ContestCategoriesStddevFields>;
  stddevPop?: Maybe<ContestCategoriesStddevPopFields>;
  stddevSamp?: Maybe<ContestCategoriesStddevSampFields>;
  sum?: Maybe<ContestCategoriesSumFields>;
  varPop?: Maybe<ContestCategoriesVarPopFields>;
  varSamp?: Maybe<ContestCategoriesVarSampFields>;
  variance?: Maybe<ContestCategoriesVarianceFields>;
};


/** aggregate fields of "contest.categories" */
export type ContestCategoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestCategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ContestCategoriesAvgFields = {
  __typename?: 'ContestCategoriesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contest.categories". All fields are combined with a logical 'AND'. */
export type ContestCategoriesBoolExp = {
  _and?: InputMaybe<Array<ContestCategoriesBoolExp>>;
  _not?: InputMaybe<ContestCategoriesBoolExp>;
  _or?: InputMaybe<Array<ContestCategoriesBoolExp>>;
  articles?: InputMaybe<ArticlesBoolExp>;
  articlesAggregate?: InputMaybe<ArticlesAggregateBoolExp>;
  banners?: InputMaybe<BannerContestCategoryBannersBoolExp>;
  bannersAggregate?: InputMaybe<BannerContestCategoryBannersAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameEn?: InputMaybe<StringComparisonExp>;
  subcategories?: InputMaybe<ContestSubcategoriesBoolExp>;
  subcategoriesAggregate?: InputMaybe<ContestSubcategoriesAggregateBoolExp>;
};

/** aggregate max on columns */
export type ContestCategoriesMaxFields = {
  __typename?: 'ContestCategoriesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContestCategoriesMinFields = {
  __typename?: 'ContestCategoriesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "contest.categories". */
export type ContestCategoriesOrderBy = {
  articlesAggregate?: InputMaybe<ArticlesAggregateOrderBy>;
  bannersAggregate?: InputMaybe<BannerContestCategoryBannersAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  subcategoriesAggregate?: InputMaybe<ContestSubcategoriesAggregateOrderBy>;
};

/** select columns of table "contest.categories" */
export enum ContestCategoriesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn'
}

/** aggregate stddev on columns */
export type ContestCategoriesStddevFields = {
  __typename?: 'ContestCategoriesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ContestCategoriesStddevPopFields = {
  __typename?: 'ContestCategoriesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ContestCategoriesStddevSampFields = {
  __typename?: 'ContestCategoriesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contest_categories" */
export type ContestCategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContestCategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContestCategoriesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ContestCategoriesSumFields = {
  __typename?: 'ContestCategoriesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ContestCategoriesVarPopFields = {
  __typename?: 'ContestCategoriesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ContestCategoriesVarSampFields = {
  __typename?: 'ContestCategoriesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ContestCategoriesVarianceFields = {
  __typename?: 'ContestCategoriesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "contest.descriptions" */
export type ContestDescriptions = {
  __typename?: 'ContestDescriptions';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  detail: Scalars['String']['output'];
  entryAddress: Scalars['String']['output'];
  entryFee?: Maybe<Scalars['String']['output']>;
  entryRule?: Maybe<Scalars['String']['output']>;
  juror?: Maybe<Scalars['String']['output']>;
  lead: Scalars['String']['output'];
  prize?: Maybe<Scalars['String']['output']>;
  prizeTop: Scalars['String']['output'];
  qualification?: Maybe<Scalars['String']['output']>;
  resultPublication?: Maybe<Scalars['String']['output']>;
  right?: Maybe<Scalars['String']['output']>;
  supplement?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "contest.descriptions". All fields are combined with a logical 'AND'. */
export type ContestDescriptionsBoolExp = {
  _and?: InputMaybe<Array<ContestDescriptionsBoolExp>>;
  _not?: InputMaybe<ContestDescriptionsBoolExp>;
  _or?: InputMaybe<Array<ContestDescriptionsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  detail?: InputMaybe<StringComparisonExp>;
  entryAddress?: InputMaybe<StringComparisonExp>;
  entryFee?: InputMaybe<StringComparisonExp>;
  entryRule?: InputMaybe<StringComparisonExp>;
  juror?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  prize?: InputMaybe<StringComparisonExp>;
  prizeTop?: InputMaybe<StringComparisonExp>;
  qualification?: InputMaybe<StringComparisonExp>;
  resultPublication?: InputMaybe<StringComparisonExp>;
  right?: InputMaybe<StringComparisonExp>;
  supplement?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "contest.descriptions". */
export type ContestDescriptionsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  detail?: InputMaybe<OrderBy>;
  entryAddress?: InputMaybe<OrderBy>;
  entryFee?: InputMaybe<OrderBy>;
  entryRule?: InputMaybe<OrderBy>;
  juror?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  prize?: InputMaybe<OrderBy>;
  prizeTop?: InputMaybe<OrderBy>;
  qualification?: InputMaybe<OrderBy>;
  resultPublication?: InputMaybe<OrderBy>;
  right?: InputMaybe<OrderBy>;
  supplement?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.entries" */
export type ContestEntries = {
  __typename?: 'ContestEntries';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['date']['output']>;
  endTime?: Maybe<Scalars['time']['output']>;
  lastTotal?: Maybe<Scalars['Int']['output']>;
  requiresSignup?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['date']['output']>;
  startTime?: Maybe<Scalars['time']['output']>;
  /** An object relationship */
  status?: Maybe<ContestEntryStatuses>;
  /** An object relationship */
  statusSnapshot?: Maybe<ContestEntryStatusSnapshots>;
};

/** Boolean expression to filter rows from the table "contest.entries". All fields are combined with a logical 'AND'. */
export type ContestEntriesBoolExp = {
  _and?: InputMaybe<Array<ContestEntriesBoolExp>>;
  _not?: InputMaybe<ContestEntriesBoolExp>;
  _or?: InputMaybe<Array<ContestEntriesBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  endDate?: InputMaybe<DateComparisonExp>;
  endTime?: InputMaybe<TimeComparisonExp>;
  lastTotal?: InputMaybe<IntComparisonExp>;
  requiresSignup?: InputMaybe<BooleanComparisonExp>;
  startDate?: InputMaybe<DateComparisonExp>;
  startTime?: InputMaybe<TimeComparisonExp>;
  status?: InputMaybe<ContestEntryStatusesBoolExp>;
  statusSnapshot?: InputMaybe<ContestEntryStatusSnapshotsBoolExp>;
};

/** Ordering options when selecting data from "contest.entries". */
export type ContestEntriesOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  lastTotal?: InputMaybe<OrderBy>;
  requiresSignup?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<ContestEntryStatusesOrderBy>;
  statusSnapshot?: InputMaybe<ContestEntryStatusSnapshotsOrderBy>;
};

/** columns and relationships of "contest.entry_status_snapshots" */
export type ContestEntryStatusSnapshots = {
  __typename?: 'ContestEntryStatusSnapshots';
  value?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "contest.entry_status_snapshots". All fields are combined with a logical 'AND'. */
export type ContestEntryStatusSnapshotsBoolExp = {
  _and?: InputMaybe<Array<ContestEntryStatusSnapshotsBoolExp>>;
  _not?: InputMaybe<ContestEntryStatusSnapshotsBoolExp>;
  _or?: InputMaybe<Array<ContestEntryStatusSnapshotsBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "contest.entry_status_snapshots". */
export type ContestEntryStatusSnapshotsOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.entry_statuses" */
export type ContestEntryStatuses = {
  __typename?: 'ContestEntryStatuses';
  value?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "contest.entry_statuses". All fields are combined with a logical 'AND'. */
export type ContestEntryStatusesBoolExp = {
  _and?: InputMaybe<Array<ContestEntryStatusesBoolExp>>;
  _not?: InputMaybe<ContestEntryStatusesBoolExp>;
  _or?: InputMaybe<Array<ContestEntryStatusesBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "contest.entry_statuses". */
export type ContestEntryStatusesOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.jobs" */
export type ContestJobs = {
  __typename?: 'ContestJobs';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  qualifications: Array<ContestQualifications>;
  /** An aggregate relationship */
  qualificationsAggregate: ContestQualificationsAggregate;
};


/** columns and relationships of "contest.jobs" */
export type ContestJobsQualificationsArgs = {
  distinctOn?: InputMaybe<Array<ContestQualificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestQualificationsOrderBy>>;
  where?: InputMaybe<ContestQualificationsBoolExp>;
};


/** columns and relationships of "contest.jobs" */
export type ContestJobsQualificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestQualificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestQualificationsOrderBy>>;
  where?: InputMaybe<ContestQualificationsBoolExp>;
};

/** aggregated selection of "contest.jobs" */
export type ContestJobsAggregate = {
  __typename?: 'ContestJobsAggregate';
  aggregate?: Maybe<ContestJobsAggregateFields>;
  nodes: Array<ContestJobs>;
};

/** aggregate fields of "contest.jobs" */
export type ContestJobsAggregateFields = {
  __typename?: 'ContestJobsAggregateFields';
  avg?: Maybe<ContestJobsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestJobsMaxFields>;
  min?: Maybe<ContestJobsMinFields>;
  stddev?: Maybe<ContestJobsStddevFields>;
  stddevPop?: Maybe<ContestJobsStddevPopFields>;
  stddevSamp?: Maybe<ContestJobsStddevSampFields>;
  sum?: Maybe<ContestJobsSumFields>;
  varPop?: Maybe<ContestJobsVarPopFields>;
  varSamp?: Maybe<ContestJobsVarSampFields>;
  variance?: Maybe<ContestJobsVarianceFields>;
};


/** aggregate fields of "contest.jobs" */
export type ContestJobsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestJobsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ContestJobsAvgFields = {
  __typename?: 'ContestJobsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contest.jobs". All fields are combined with a logical 'AND'. */
export type ContestJobsBoolExp = {
  _and?: InputMaybe<Array<ContestJobsBoolExp>>;
  _not?: InputMaybe<ContestJobsBoolExp>;
  _or?: InputMaybe<Array<ContestJobsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  qualifications?: InputMaybe<ContestQualificationsBoolExp>;
  qualificationsAggregate?: InputMaybe<ContestQualificationsAggregateBoolExp>;
};

/** aggregate max on columns */
export type ContestJobsMaxFields = {
  __typename?: 'ContestJobsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContestJobsMinFields = {
  __typename?: 'ContestJobsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "contest.jobs". */
export type ContestJobsOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  qualificationsAggregate?: InputMaybe<ContestQualificationsAggregateOrderBy>;
};

/** select columns of table "contest.jobs" */
export enum ContestJobsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type ContestJobsStddevFields = {
  __typename?: 'ContestJobsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ContestJobsStddevPopFields = {
  __typename?: 'ContestJobsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ContestJobsStddevSampFields = {
  __typename?: 'ContestJobsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contest_jobs" */
export type ContestJobsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContestJobsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContestJobsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ContestJobsSumFields = {
  __typename?: 'ContestJobsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ContestJobsVarPopFields = {
  __typename?: 'ContestJobsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ContestJobsVarSampFields = {
  __typename?: 'ContestJobsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ContestJobsVarianceFields = {
  __typename?: 'ContestJobsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "contest.likes" */
export type ContestLikes = {
  __typename?: 'ContestLikes';
  /** An object relationship */
  contest: Contests;
  /** An object relationship */
  creator?: Maybe<Creators>;
  likedAt: Scalars['timestamp']['output'];
};

/** aggregated selection of "contest.likes" */
export type ContestLikesAggregate = {
  __typename?: 'ContestLikesAggregate';
  aggregate?: Maybe<ContestLikesAggregateFields>;
  nodes: Array<ContestLikes>;
};

export type ContestLikesAggregateBoolExp = {
  count?: InputMaybe<ContestLikesAggregateBoolExpCount>;
};

/** aggregate fields of "contest.likes" */
export type ContestLikesAggregateFields = {
  __typename?: 'ContestLikesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ContestLikesMaxFields>;
  min?: Maybe<ContestLikesMinFields>;
};


/** aggregate fields of "contest.likes" */
export type ContestLikesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestLikesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.likes" */
export type ContestLikesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestLikesMaxOrderBy>;
  min?: InputMaybe<ContestLikesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "contest.likes". All fields are combined with a logical 'AND'. */
export type ContestLikesBoolExp = {
  _and?: InputMaybe<Array<ContestLikesBoolExp>>;
  _not?: InputMaybe<ContestLikesBoolExp>;
  _or?: InputMaybe<Array<ContestLikesBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  creator?: InputMaybe<CreatorsBoolExp>;
  likedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type ContestLikesMaxFields = {
  __typename?: 'ContestLikesMaxFields';
  likedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "contest.likes" */
export type ContestLikesMaxOrderBy = {
  likedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestLikesMinFields = {
  __typename?: 'ContestLikesMinFields';
  likedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "contest.likes" */
export type ContestLikesMinOrderBy = {
  likedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.likes". */
export type ContestLikesOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  creator?: InputMaybe<CreatorsOrderBy>;
  likedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.likes" */
export enum ContestLikesSelectColumn {
  /** column name */
  LikedAt = 'likedAt'
}

/** columns and relationships of "contest.localizations" */
export type ContestLocalizations = {
  __typename?: 'ContestLocalizations';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  /** An object relationship */
  prefecture: Prefectures;
  prefectureId: Scalars['Int']['output'];
};

/** aggregated selection of "contest.localizations" */
export type ContestLocalizationsAggregate = {
  __typename?: 'ContestLocalizationsAggregate';
  aggregate?: Maybe<ContestLocalizationsAggregateFields>;
  nodes: Array<ContestLocalizations>;
};

export type ContestLocalizationsAggregateBoolExp = {
  count?: InputMaybe<ContestLocalizationsAggregateBoolExpCount>;
};

/** aggregate fields of "contest.localizations" */
export type ContestLocalizationsAggregateFields = {
  __typename?: 'ContestLocalizationsAggregateFields';
  avg?: Maybe<ContestLocalizationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestLocalizationsMaxFields>;
  min?: Maybe<ContestLocalizationsMinFields>;
  stddev?: Maybe<ContestLocalizationsStddevFields>;
  stddevPop?: Maybe<ContestLocalizationsStddevPopFields>;
  stddevSamp?: Maybe<ContestLocalizationsStddevSampFields>;
  sum?: Maybe<ContestLocalizationsSumFields>;
  varPop?: Maybe<ContestLocalizationsVarPopFields>;
  varSamp?: Maybe<ContestLocalizationsVarSampFields>;
  variance?: Maybe<ContestLocalizationsVarianceFields>;
};


/** aggregate fields of "contest.localizations" */
export type ContestLocalizationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestLocalizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.localizations" */
export type ContestLocalizationsAggregateOrderBy = {
  avg?: InputMaybe<ContestLocalizationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestLocalizationsMaxOrderBy>;
  min?: InputMaybe<ContestLocalizationsMinOrderBy>;
  stddev?: InputMaybe<ContestLocalizationsStddevOrderBy>;
  stddevPop?: InputMaybe<ContestLocalizationsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContestLocalizationsStddevSampOrderBy>;
  sum?: InputMaybe<ContestLocalizationsSumOrderBy>;
  varPop?: InputMaybe<ContestLocalizationsVarPopOrderBy>;
  varSamp?: InputMaybe<ContestLocalizationsVarSampOrderBy>;
  variance?: InputMaybe<ContestLocalizationsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ContestLocalizationsAvgFields = {
  __typename?: 'ContestLocalizationsAvgFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contest.localizations" */
export type ContestLocalizationsAvgOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contest.localizations". All fields are combined with a logical 'AND'. */
export type ContestLocalizationsBoolExp = {
  _and?: InputMaybe<Array<ContestLocalizationsBoolExp>>;
  _not?: InputMaybe<ContestLocalizationsBoolExp>;
  _or?: InputMaybe<Array<ContestLocalizationsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  prefecture?: InputMaybe<PrefecturesBoolExp>;
  prefectureId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type ContestLocalizationsMaxFields = {
  __typename?: 'ContestLocalizationsMaxFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  prefectureId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "contest.localizations" */
export type ContestLocalizationsMaxOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestLocalizationsMinFields = {
  __typename?: 'ContestLocalizationsMinFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  prefectureId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "contest.localizations" */
export type ContestLocalizationsMinOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.localizations". */
export type ContestLocalizationsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  prefecture?: InputMaybe<PrefecturesOrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.localizations" */
export enum ContestLocalizationsSelectColumn {
  /** column name */
  ContestId = 'contestId',
  /** column name */
  PrefectureId = 'prefectureId'
}

/** aggregate stddev on columns */
export type ContestLocalizationsStddevFields = {
  __typename?: 'ContestLocalizationsStddevFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contest.localizations" */
export type ContestLocalizationsStddevOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContestLocalizationsStddevPopFields = {
  __typename?: 'ContestLocalizationsStddevPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "contest.localizations" */
export type ContestLocalizationsStddevPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContestLocalizationsStddevSampFields = {
  __typename?: 'ContestLocalizationsStddevSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "contest.localizations" */
export type ContestLocalizationsStddevSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContestLocalizationsSumFields = {
  __typename?: 'ContestLocalizationsSumFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  prefectureId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contest.localizations" */
export type ContestLocalizationsSumOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ContestLocalizationsVarPopFields = {
  __typename?: 'ContestLocalizationsVarPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "contest.localizations" */
export type ContestLocalizationsVarPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContestLocalizationsVarSampFields = {
  __typename?: 'ContestLocalizationsVarSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "contest.localizations" */
export type ContestLocalizationsVarSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContestLocalizationsVarianceFields = {
  __typename?: 'ContestLocalizationsVarianceFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  prefectureId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contest.localizations" */
export type ContestLocalizationsVarianceOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  prefectureId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.organizers" */
export type ContestOrganizers = {
  __typename?: 'ContestOrganizers';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  organization: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** Boolean expression to filter rows from the table "contest.organizers". All fields are combined with a logical 'AND'. */
export type ContestOrganizersBoolExp = {
  _and?: InputMaybe<Array<ContestOrganizersBoolExp>>;
  _not?: InputMaybe<ContestOrganizersBoolExp>;
  _or?: InputMaybe<Array<ContestOrganizersBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  organization?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "contest.organizers". */
export type ContestOrganizersOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** columns and relationships of "contest.prizes" */
export type ContestPrizes = {
  __typename?: 'ContestPrizes';
  acceptanceTotalGe?: Maybe<Scalars['Int']['output']>;
  acceptanceTotalLt?: Maybe<Scalars['Int']['output']>;
  amountTop?: Maybe<Scalars['Int']['output']>;
  amountTotal?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  participationAward?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "contest.prizes". All fields are combined with a logical 'AND'. */
export type ContestPrizesBoolExp = {
  _and?: InputMaybe<Array<ContestPrizesBoolExp>>;
  _not?: InputMaybe<ContestPrizesBoolExp>;
  _or?: InputMaybe<Array<ContestPrizesBoolExp>>;
  acceptanceTotalGe?: InputMaybe<IntComparisonExp>;
  acceptanceTotalLt?: InputMaybe<IntComparisonExp>;
  amountTop?: InputMaybe<IntComparisonExp>;
  amountTotal?: InputMaybe<IntComparisonExp>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  participationAward?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "contest.prizes". */
export type ContestPrizesOrderBy = {
  acceptanceTotalGe?: InputMaybe<OrderBy>;
  acceptanceTotalLt?: InputMaybe<OrderBy>;
  amountTop?: InputMaybe<OrderBy>;
  amountTotal?: InputMaybe<OrderBy>;
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  participationAward?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.qualifications" */
export type ContestQualifications = {
  __typename?: 'ContestQualifications';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  /** An object relationship */
  job: ContestJobs;
  jobId: Scalars['Int']['output'];
};

/** aggregated selection of "contest.qualifications" */
export type ContestQualificationsAggregate = {
  __typename?: 'ContestQualificationsAggregate';
  aggregate?: Maybe<ContestQualificationsAggregateFields>;
  nodes: Array<ContestQualifications>;
};

export type ContestQualificationsAggregateBoolExp = {
  count?: InputMaybe<ContestQualificationsAggregateBoolExpCount>;
};

/** aggregate fields of "contest.qualifications" */
export type ContestQualificationsAggregateFields = {
  __typename?: 'ContestQualificationsAggregateFields';
  avg?: Maybe<ContestQualificationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestQualificationsMaxFields>;
  min?: Maybe<ContestQualificationsMinFields>;
  stddev?: Maybe<ContestQualificationsStddevFields>;
  stddevPop?: Maybe<ContestQualificationsStddevPopFields>;
  stddevSamp?: Maybe<ContestQualificationsStddevSampFields>;
  sum?: Maybe<ContestQualificationsSumFields>;
  varPop?: Maybe<ContestQualificationsVarPopFields>;
  varSamp?: Maybe<ContestQualificationsVarSampFields>;
  variance?: Maybe<ContestQualificationsVarianceFields>;
};


/** aggregate fields of "contest.qualifications" */
export type ContestQualificationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestQualificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.qualifications" */
export type ContestQualificationsAggregateOrderBy = {
  avg?: InputMaybe<ContestQualificationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestQualificationsMaxOrderBy>;
  min?: InputMaybe<ContestQualificationsMinOrderBy>;
  stddev?: InputMaybe<ContestQualificationsStddevOrderBy>;
  stddevPop?: InputMaybe<ContestQualificationsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContestQualificationsStddevSampOrderBy>;
  sum?: InputMaybe<ContestQualificationsSumOrderBy>;
  varPop?: InputMaybe<ContestQualificationsVarPopOrderBy>;
  varSamp?: InputMaybe<ContestQualificationsVarSampOrderBy>;
  variance?: InputMaybe<ContestQualificationsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ContestQualificationsAvgFields = {
  __typename?: 'ContestQualificationsAvgFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contest.qualifications" */
export type ContestQualificationsAvgOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contest.qualifications". All fields are combined with a logical 'AND'. */
export type ContestQualificationsBoolExp = {
  _and?: InputMaybe<Array<ContestQualificationsBoolExp>>;
  _not?: InputMaybe<ContestQualificationsBoolExp>;
  _or?: InputMaybe<Array<ContestQualificationsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  job?: InputMaybe<ContestJobsBoolExp>;
  jobId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type ContestQualificationsMaxFields = {
  __typename?: 'ContestQualificationsMaxFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "contest.qualifications" */
export type ContestQualificationsMaxOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestQualificationsMinFields = {
  __typename?: 'ContestQualificationsMinFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "contest.qualifications" */
export type ContestQualificationsMinOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.qualifications". */
export type ContestQualificationsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  job?: InputMaybe<ContestJobsOrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.qualifications" */
export enum ContestQualificationsSelectColumn {
  /** column name */
  ContestId = 'contestId',
  /** column name */
  JobId = 'jobId'
}

/** aggregate stddev on columns */
export type ContestQualificationsStddevFields = {
  __typename?: 'ContestQualificationsStddevFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contest.qualifications" */
export type ContestQualificationsStddevOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContestQualificationsStddevPopFields = {
  __typename?: 'ContestQualificationsStddevPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "contest.qualifications" */
export type ContestQualificationsStddevPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContestQualificationsStddevSampFields = {
  __typename?: 'ContestQualificationsStddevSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "contest.qualifications" */
export type ContestQualificationsStddevSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContestQualificationsSumFields = {
  __typename?: 'ContestQualificationsSumFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contest.qualifications" */
export type ContestQualificationsSumOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ContestQualificationsVarPopFields = {
  __typename?: 'ContestQualificationsVarPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "contest.qualifications" */
export type ContestQualificationsVarPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContestQualificationsVarSampFields = {
  __typename?: 'ContestQualificationsVarSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "contest.qualifications" */
export type ContestQualificationsVarSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContestQualificationsVarianceFields = {
  __typename?: 'ContestQualificationsVarianceFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contest.qualifications" */
export type ContestQualificationsVarianceOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  jobId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.references" */
export type ContestReferences = {
  __typename?: 'ContestReferences';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  /** An object relationship */
  file: Files;
  fileId: Scalars['Int']['output'];
};

/** aggregated selection of "contest.references" */
export type ContestReferencesAggregate = {
  __typename?: 'ContestReferencesAggregate';
  aggregate?: Maybe<ContestReferencesAggregateFields>;
  nodes: Array<ContestReferences>;
};

export type ContestReferencesAggregateBoolExp = {
  count?: InputMaybe<ContestReferencesAggregateBoolExpCount>;
};

/** aggregate fields of "contest.references" */
export type ContestReferencesAggregateFields = {
  __typename?: 'ContestReferencesAggregateFields';
  avg?: Maybe<ContestReferencesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestReferencesMaxFields>;
  min?: Maybe<ContestReferencesMinFields>;
  stddev?: Maybe<ContestReferencesStddevFields>;
  stddevPop?: Maybe<ContestReferencesStddevPopFields>;
  stddevSamp?: Maybe<ContestReferencesStddevSampFields>;
  sum?: Maybe<ContestReferencesSumFields>;
  varPop?: Maybe<ContestReferencesVarPopFields>;
  varSamp?: Maybe<ContestReferencesVarSampFields>;
  variance?: Maybe<ContestReferencesVarianceFields>;
};


/** aggregate fields of "contest.references" */
export type ContestReferencesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestReferencesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.references" */
export type ContestReferencesAggregateOrderBy = {
  avg?: InputMaybe<ContestReferencesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestReferencesMaxOrderBy>;
  min?: InputMaybe<ContestReferencesMinOrderBy>;
  stddev?: InputMaybe<ContestReferencesStddevOrderBy>;
  stddevPop?: InputMaybe<ContestReferencesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContestReferencesStddevSampOrderBy>;
  sum?: InputMaybe<ContestReferencesSumOrderBy>;
  varPop?: InputMaybe<ContestReferencesVarPopOrderBy>;
  varSamp?: InputMaybe<ContestReferencesVarSampOrderBy>;
  variance?: InputMaybe<ContestReferencesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ContestReferencesAvgFields = {
  __typename?: 'ContestReferencesAvgFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contest.references" */
export type ContestReferencesAvgOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contest.references". All fields are combined with a logical 'AND'. */
export type ContestReferencesBoolExp = {
  _and?: InputMaybe<Array<ContestReferencesBoolExp>>;
  _not?: InputMaybe<ContestReferencesBoolExp>;
  _or?: InputMaybe<Array<ContestReferencesBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  file?: InputMaybe<FilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type ContestReferencesMaxFields = {
  __typename?: 'ContestReferencesMaxFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  fileId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "contest.references" */
export type ContestReferencesMaxOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestReferencesMinFields = {
  __typename?: 'ContestReferencesMinFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  fileId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "contest.references" */
export type ContestReferencesMinOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.references". */
export type ContestReferencesOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  file?: InputMaybe<FilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.references" */
export enum ContestReferencesSelectColumn {
  /** column name */
  ContestId = 'contestId',
  /** column name */
  FileId = 'fileId'
}

/** aggregate stddev on columns */
export type ContestReferencesStddevFields = {
  __typename?: 'ContestReferencesStddevFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contest.references" */
export type ContestReferencesStddevOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContestReferencesStddevPopFields = {
  __typename?: 'ContestReferencesStddevPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "contest.references" */
export type ContestReferencesStddevPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContestReferencesStddevSampFields = {
  __typename?: 'ContestReferencesStddevSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "contest.references" */
export type ContestReferencesStddevSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContestReferencesSumFields = {
  __typename?: 'ContestReferencesSumFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  fileId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contest.references" */
export type ContestReferencesSumOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ContestReferencesVarPopFields = {
  __typename?: 'ContestReferencesVarPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "contest.references" */
export type ContestReferencesVarPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContestReferencesVarSampFields = {
  __typename?: 'ContestReferencesVarSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "contest.references" */
export type ContestReferencesVarSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContestReferencesVarianceFields = {
  __typename?: 'ContestReferencesVarianceFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  fileId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contest.references" */
export type ContestReferencesVarianceOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.restrictions" */
export type ContestRestrictions = {
  __typename?: 'ContestRestrictions';
  canAnnouncementToApplicant: Scalars['Boolean']['output'];
  canChatToApplicant: Scalars['Boolean']['output'];
  /** An array relationship */
  contests: Array<Contests>;
  /** An aggregate relationship */
  contestsAggregate: ContestsAggregate;
  id: Scalars['Int']['output'];
  maxWorkFileSizeMb: Scalars['Int']['output'];
};


/** columns and relationships of "contest.restrictions" */
export type ContestRestrictionsContestsArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


/** columns and relationships of "contest.restrictions" */
export type ContestRestrictionsContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};

/** aggregated selection of "contest.restrictions" */
export type ContestRestrictionsAggregate = {
  __typename?: 'ContestRestrictionsAggregate';
  aggregate?: Maybe<ContestRestrictionsAggregateFields>;
  nodes: Array<ContestRestrictions>;
};

/** aggregate fields of "contest.restrictions" */
export type ContestRestrictionsAggregateFields = {
  __typename?: 'ContestRestrictionsAggregateFields';
  avg?: Maybe<ContestRestrictionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestRestrictionsMaxFields>;
  min?: Maybe<ContestRestrictionsMinFields>;
  stddev?: Maybe<ContestRestrictionsStddevFields>;
  stddevPop?: Maybe<ContestRestrictionsStddevPopFields>;
  stddevSamp?: Maybe<ContestRestrictionsStddevSampFields>;
  sum?: Maybe<ContestRestrictionsSumFields>;
  varPop?: Maybe<ContestRestrictionsVarPopFields>;
  varSamp?: Maybe<ContestRestrictionsVarSampFields>;
  variance?: Maybe<ContestRestrictionsVarianceFields>;
};


/** aggregate fields of "contest.restrictions" */
export type ContestRestrictionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestRestrictionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ContestRestrictionsAvgFields = {
  __typename?: 'ContestRestrictionsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contest.restrictions". All fields are combined with a logical 'AND'. */
export type ContestRestrictionsBoolExp = {
  _and?: InputMaybe<Array<ContestRestrictionsBoolExp>>;
  _not?: InputMaybe<ContestRestrictionsBoolExp>;
  _or?: InputMaybe<Array<ContestRestrictionsBoolExp>>;
  canAnnouncementToApplicant?: InputMaybe<BooleanComparisonExp>;
  canChatToApplicant?: InputMaybe<BooleanComparisonExp>;
  contests?: InputMaybe<ContestsBoolExp>;
  contestsAggregate?: InputMaybe<ContestsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  maxWorkFileSizeMb?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type ContestRestrictionsMaxFields = {
  __typename?: 'ContestRestrictionsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type ContestRestrictionsMinFields = {
  __typename?: 'ContestRestrictionsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "contest.restrictions". */
export type ContestRestrictionsOrderBy = {
  canAnnouncementToApplicant?: InputMaybe<OrderBy>;
  canChatToApplicant?: InputMaybe<OrderBy>;
  contestsAggregate?: InputMaybe<ContestsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorkFileSizeMb?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.restrictions" */
export enum ContestRestrictionsSelectColumn {
  /** column name */
  CanAnnouncementToApplicant = 'canAnnouncementToApplicant',
  /** column name */
  CanChatToApplicant = 'canChatToApplicant',
  /** column name */
  Id = 'id',
  /** column name */
  MaxWorkFileSizeMb = 'maxWorkFileSizeMb'
}

/** aggregate stddev on columns */
export type ContestRestrictionsStddevFields = {
  __typename?: 'ContestRestrictionsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ContestRestrictionsStddevPopFields = {
  __typename?: 'ContestRestrictionsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ContestRestrictionsStddevSampFields = {
  __typename?: 'ContestRestrictionsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contest_restrictions" */
export type ContestRestrictionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContestRestrictionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContestRestrictionsStreamCursorValueInput = {
  canAnnouncementToApplicant?: InputMaybe<Scalars['Boolean']['input']>;
  canChatToApplicant?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  maxWorkFileSizeMb?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ContestRestrictionsSumFields = {
  __typename?: 'ContestRestrictionsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ContestRestrictionsVarPopFields = {
  __typename?: 'ContestRestrictionsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ContestRestrictionsVarSampFields = {
  __typename?: 'ContestRestrictionsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ContestRestrictionsVarianceFields = {
  __typename?: 'ContestRestrictionsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  maxWorkFileSizeMb?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "contest.result_publications" */
export type ContestResultPublications = {
  __typename?: 'ContestResultPublications';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  isHidden: Scalars['Boolean']['output'];
  month: Scalars['Int']['output'];
  /** An object relationship */
  monthlyPeriod: MonthlyPeriods;
  monthlyPeriodId: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "contest.result_publications". All fields are combined with a logical 'AND'. */
export type ContestResultPublicationsBoolExp = {
  _and?: InputMaybe<Array<ContestResultPublicationsBoolExp>>;
  _not?: InputMaybe<ContestResultPublicationsBoolExp>;
  _or?: InputMaybe<Array<ContestResultPublicationsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  isHidden?: InputMaybe<BooleanComparisonExp>;
  month?: InputMaybe<IntComparisonExp>;
  monthlyPeriod?: InputMaybe<MonthlyPeriodsBoolExp>;
  monthlyPeriodId?: InputMaybe<IntComparisonExp>;
  year?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "contest.result_publications". */
export type ContestResultPublicationsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  isHidden?: InputMaybe<OrderBy>;
  month?: InputMaybe<OrderBy>;
  monthlyPeriod?: InputMaybe<MonthlyPeriodsOrderBy>;
  monthlyPeriodId?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.status_snapshots" */
export type ContestStatusSnapshots = {
  __typename?: 'ContestStatusSnapshots';
  value?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "contest.status_snapshots". All fields are combined with a logical 'AND'. */
export type ContestStatusSnapshotsBoolExp = {
  _and?: InputMaybe<Array<ContestStatusSnapshotsBoolExp>>;
  _not?: InputMaybe<ContestStatusSnapshotsBoolExp>;
  _or?: InputMaybe<Array<ContestStatusSnapshotsBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "contest.status_snapshots". */
export type ContestStatusSnapshotsOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.statuses" */
export type ContestStatuses = {
  __typename?: 'ContestStatuses';
  /** An object relationship */
  contest?: Maybe<Contests>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "contest.statuses". All fields are combined with a logical 'AND'. */
export type ContestStatusesBoolExp = {
  _and?: InputMaybe<Array<ContestStatusesBoolExp>>;
  _not?: InputMaybe<ContestStatusesBoolExp>;
  _or?: InputMaybe<Array<ContestStatusesBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "contest.statuses". */
export type ContestStatusesOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.subcategories" */
export type ContestSubcategories = {
  __typename?: 'ContestSubcategories';
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articlesAggregate: ArticlesAggregate;
  /** An object relationship */
  category: ContestCategories;
  categoryId: Scalars['Int']['output'];
  /** An array relationship */
  contests: Array<Contests>;
  /** An aggregate relationship */
  contestsAggregate: ContestsAggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nameEn: Scalars['String']['output'];
};


/** columns and relationships of "contest.subcategories" */
export type ContestSubcategoriesArticlesArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


/** columns and relationships of "contest.subcategories" */
export type ContestSubcategoriesArticlesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


/** columns and relationships of "contest.subcategories" */
export type ContestSubcategoriesContestsArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


/** columns and relationships of "contest.subcategories" */
export type ContestSubcategoriesContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};

/** aggregated selection of "contest.subcategories" */
export type ContestSubcategoriesAggregate = {
  __typename?: 'ContestSubcategoriesAggregate';
  aggregate?: Maybe<ContestSubcategoriesAggregateFields>;
  nodes: Array<ContestSubcategories>;
};

export type ContestSubcategoriesAggregateBoolExp = {
  count?: InputMaybe<ContestSubcategoriesAggregateBoolExpCount>;
};

/** aggregate fields of "contest.subcategories" */
export type ContestSubcategoriesAggregateFields = {
  __typename?: 'ContestSubcategoriesAggregateFields';
  avg?: Maybe<ContestSubcategoriesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestSubcategoriesMaxFields>;
  min?: Maybe<ContestSubcategoriesMinFields>;
  stddev?: Maybe<ContestSubcategoriesStddevFields>;
  stddevPop?: Maybe<ContestSubcategoriesStddevPopFields>;
  stddevSamp?: Maybe<ContestSubcategoriesStddevSampFields>;
  sum?: Maybe<ContestSubcategoriesSumFields>;
  varPop?: Maybe<ContestSubcategoriesVarPopFields>;
  varSamp?: Maybe<ContestSubcategoriesVarSampFields>;
  variance?: Maybe<ContestSubcategoriesVarianceFields>;
};


/** aggregate fields of "contest.subcategories" */
export type ContestSubcategoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.subcategories" */
export type ContestSubcategoriesAggregateOrderBy = {
  avg?: InputMaybe<ContestSubcategoriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestSubcategoriesMaxOrderBy>;
  min?: InputMaybe<ContestSubcategoriesMinOrderBy>;
  stddev?: InputMaybe<ContestSubcategoriesStddevOrderBy>;
  stddevPop?: InputMaybe<ContestSubcategoriesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContestSubcategoriesStddevSampOrderBy>;
  sum?: InputMaybe<ContestSubcategoriesSumOrderBy>;
  varPop?: InputMaybe<ContestSubcategoriesVarPopOrderBy>;
  varSamp?: InputMaybe<ContestSubcategoriesVarSampOrderBy>;
  variance?: InputMaybe<ContestSubcategoriesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ContestSubcategoriesAvgFields = {
  __typename?: 'ContestSubcategoriesAvgFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contest.subcategories" */
export type ContestSubcategoriesAvgOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contest.subcategories". All fields are combined with a logical 'AND'. */
export type ContestSubcategoriesBoolExp = {
  _and?: InputMaybe<Array<ContestSubcategoriesBoolExp>>;
  _not?: InputMaybe<ContestSubcategoriesBoolExp>;
  _or?: InputMaybe<Array<ContestSubcategoriesBoolExp>>;
  articles?: InputMaybe<ArticlesBoolExp>;
  articlesAggregate?: InputMaybe<ArticlesAggregateBoolExp>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  contests?: InputMaybe<ContestsBoolExp>;
  contestsAggregate?: InputMaybe<ContestsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameEn?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ContestSubcategoriesMaxFields = {
  __typename?: 'ContestSubcategoriesMaxFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "contest.subcategories" */
export type ContestSubcategoriesMaxOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestSubcategoriesMinFields = {
  __typename?: 'ContestSubcategoriesMinFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "contest.subcategories" */
export type ContestSubcategoriesMinOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.subcategories". */
export type ContestSubcategoriesOrderBy = {
  articlesAggregate?: InputMaybe<ArticlesAggregateOrderBy>;
  category?: InputMaybe<ContestCategoriesOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestsAggregate?: InputMaybe<ContestsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.subcategories" */
export enum ContestSubcategoriesSelectColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn'
}

/** aggregate stddev on columns */
export type ContestSubcategoriesStddevFields = {
  __typename?: 'ContestSubcategoriesStddevFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contest.subcategories" */
export type ContestSubcategoriesStddevOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContestSubcategoriesStddevPopFields = {
  __typename?: 'ContestSubcategoriesStddevPopFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "contest.subcategories" */
export type ContestSubcategoriesStddevPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContestSubcategoriesStddevSampFields = {
  __typename?: 'ContestSubcategoriesStddevSampFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "contest.subcategories" */
export type ContestSubcategoriesStddevSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "contest_subcategories" */
export type ContestSubcategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContestSubcategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContestSubcategoriesStreamCursorValueInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ContestSubcategoriesSumFields = {
  __typename?: 'ContestSubcategoriesSumFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contest.subcategories" */
export type ContestSubcategoriesSumOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ContestSubcategoriesVarPopFields = {
  __typename?: 'ContestSubcategoriesVarPopFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "contest.subcategories" */
export type ContestSubcategoriesVarPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContestSubcategoriesVarSampFields = {
  __typename?: 'ContestSubcategoriesVarSampFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "contest.subcategories" */
export type ContestSubcategoriesVarSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContestSubcategoriesVarianceFields = {
  __typename?: 'ContestSubcategoriesVarianceFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contest.subcategories" */
export type ContestSubcategoriesVarianceOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.taggings" */
export type ContestTaggings = {
  __typename?: 'ContestTaggings';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  /** An object relationship */
  tag: ContestTags;
  tagId: Scalars['Int']['output'];
};

/** aggregated selection of "contest.taggings" */
export type ContestTaggingsAggregate = {
  __typename?: 'ContestTaggingsAggregate';
  aggregate?: Maybe<ContestTaggingsAggregateFields>;
  nodes: Array<ContestTaggings>;
};

export type ContestTaggingsAggregateBoolExp = {
  count?: InputMaybe<ContestTaggingsAggregateBoolExpCount>;
};

/** aggregate fields of "contest.taggings" */
export type ContestTaggingsAggregateFields = {
  __typename?: 'ContestTaggingsAggregateFields';
  avg?: Maybe<ContestTaggingsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestTaggingsMaxFields>;
  min?: Maybe<ContestTaggingsMinFields>;
  stddev?: Maybe<ContestTaggingsStddevFields>;
  stddevPop?: Maybe<ContestTaggingsStddevPopFields>;
  stddevSamp?: Maybe<ContestTaggingsStddevSampFields>;
  sum?: Maybe<ContestTaggingsSumFields>;
  varPop?: Maybe<ContestTaggingsVarPopFields>;
  varSamp?: Maybe<ContestTaggingsVarSampFields>;
  variance?: Maybe<ContestTaggingsVarianceFields>;
};


/** aggregate fields of "contest.taggings" */
export type ContestTaggingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestTaggingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.taggings" */
export type ContestTaggingsAggregateOrderBy = {
  avg?: InputMaybe<ContestTaggingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestTaggingsMaxOrderBy>;
  min?: InputMaybe<ContestTaggingsMinOrderBy>;
  stddev?: InputMaybe<ContestTaggingsStddevOrderBy>;
  stddevPop?: InputMaybe<ContestTaggingsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContestTaggingsStddevSampOrderBy>;
  sum?: InputMaybe<ContestTaggingsSumOrderBy>;
  varPop?: InputMaybe<ContestTaggingsVarPopOrderBy>;
  varSamp?: InputMaybe<ContestTaggingsVarSampOrderBy>;
  variance?: InputMaybe<ContestTaggingsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ContestTaggingsAvgFields = {
  __typename?: 'ContestTaggingsAvgFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contest.taggings" */
export type ContestTaggingsAvgOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contest.taggings". All fields are combined with a logical 'AND'. */
export type ContestTaggingsBoolExp = {
  _and?: InputMaybe<Array<ContestTaggingsBoolExp>>;
  _not?: InputMaybe<ContestTaggingsBoolExp>;
  _or?: InputMaybe<Array<ContestTaggingsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  tag?: InputMaybe<ContestTagsBoolExp>;
  tagId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type ContestTaggingsMaxFields = {
  __typename?: 'ContestTaggingsMaxFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  tagId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "contest.taggings" */
export type ContestTaggingsMaxOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestTaggingsMinFields = {
  __typename?: 'ContestTaggingsMinFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  tagId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "contest.taggings" */
export type ContestTaggingsMinOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.taggings". */
export type ContestTaggingsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  tag?: InputMaybe<ContestTagsOrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.taggings" */
export enum ContestTaggingsSelectColumn {
  /** column name */
  ContestId = 'contestId',
  /** column name */
  TagId = 'tagId'
}

/** aggregate stddev on columns */
export type ContestTaggingsStddevFields = {
  __typename?: 'ContestTaggingsStddevFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contest.taggings" */
export type ContestTaggingsStddevOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContestTaggingsStddevPopFields = {
  __typename?: 'ContestTaggingsStddevPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "contest.taggings" */
export type ContestTaggingsStddevPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContestTaggingsStddevSampFields = {
  __typename?: 'ContestTaggingsStddevSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "contest.taggings" */
export type ContestTaggingsStddevSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContestTaggingsSumFields = {
  __typename?: 'ContestTaggingsSumFields';
  contestId?: Maybe<Scalars['Int']['output']>;
  tagId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contest.taggings" */
export type ContestTaggingsSumOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ContestTaggingsVarPopFields = {
  __typename?: 'ContestTaggingsVarPopFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "contest.taggings" */
export type ContestTaggingsVarPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContestTaggingsVarSampFields = {
  __typename?: 'ContestTaggingsVarSampFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "contest.taggings" */
export type ContestTaggingsVarSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContestTaggingsVarianceFields = {
  __typename?: 'ContestTaggingsVarianceFields';
  contestId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contest.taggings" */
export type ContestTaggingsVarianceOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "contest.tags" */
export type ContestTags = {
  __typename?: 'ContestTags';
  id: Scalars['Int']['output'];
  isSelectable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  taggings: Array<ContestTaggings>;
  /** An aggregate relationship */
  taggingsAggregate: ContestTaggingsAggregate;
};


/** columns and relationships of "contest.tags" */
export type ContestTagsTaggingsArgs = {
  distinctOn?: InputMaybe<Array<ContestTaggingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTaggingsOrderBy>>;
  where?: InputMaybe<ContestTaggingsBoolExp>;
};


/** columns and relationships of "contest.tags" */
export type ContestTagsTaggingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestTaggingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTaggingsOrderBy>>;
  where?: InputMaybe<ContestTaggingsBoolExp>;
};

/** aggregated selection of "contest.tags" */
export type ContestTagsAggregate = {
  __typename?: 'ContestTagsAggregate';
  aggregate?: Maybe<ContestTagsAggregateFields>;
  nodes: Array<ContestTags>;
};

/** aggregate fields of "contest.tags" */
export type ContestTagsAggregateFields = {
  __typename?: 'ContestTagsAggregateFields';
  avg?: Maybe<ContestTagsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestTagsMaxFields>;
  min?: Maybe<ContestTagsMinFields>;
  stddev?: Maybe<ContestTagsStddevFields>;
  stddevPop?: Maybe<ContestTagsStddevPopFields>;
  stddevSamp?: Maybe<ContestTagsStddevSampFields>;
  sum?: Maybe<ContestTagsSumFields>;
  varPop?: Maybe<ContestTagsVarPopFields>;
  varSamp?: Maybe<ContestTagsVarSampFields>;
  variance?: Maybe<ContestTagsVarianceFields>;
};


/** aggregate fields of "contest.tags" */
export type ContestTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ContestTagsAvgFields = {
  __typename?: 'ContestTagsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contest.tags". All fields are combined with a logical 'AND'. */
export type ContestTagsBoolExp = {
  _and?: InputMaybe<Array<ContestTagsBoolExp>>;
  _not?: InputMaybe<ContestTagsBoolExp>;
  _or?: InputMaybe<Array<ContestTagsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  isSelectable?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  taggings?: InputMaybe<ContestTaggingsBoolExp>;
  taggingsAggregate?: InputMaybe<ContestTaggingsAggregateBoolExp>;
};

/** aggregate max on columns */
export type ContestTagsMaxFields = {
  __typename?: 'ContestTagsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContestTagsMinFields = {
  __typename?: 'ContestTagsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "contest.tags". */
export type ContestTagsOrderBy = {
  id?: InputMaybe<OrderBy>;
  isSelectable?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  taggingsAggregate?: InputMaybe<ContestTaggingsAggregateOrderBy>;
};

/** select columns of table "contest.tags" */
export enum ContestTagsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsSelectable = 'isSelectable',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type ContestTagsStddevFields = {
  __typename?: 'ContestTagsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ContestTagsStddevPopFields = {
  __typename?: 'ContestTagsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ContestTagsStddevSampFields = {
  __typename?: 'ContestTagsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contest_tags" */
export type ContestTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContestTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContestTagsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isSelectable?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ContestTagsSumFields = {
  __typename?: 'ContestTagsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ContestTagsVarPopFields = {
  __typename?: 'ContestTagsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ContestTagsVarSampFields = {
  __typename?: 'ContestTagsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ContestTagsVarianceFields = {
  __typename?: 'ContestTagsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "contest.thumbnails" */
export type ContestThumbnails = {
  __typename?: 'ContestThumbnails';
  /** An object relationship */
  contest: Contests;
  contestId: Scalars['Int']['output'];
  /** An object relationship */
  file: Files;
  fileId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "contest.thumbnails". All fields are combined with a logical 'AND'. */
export type ContestThumbnailsBoolExp = {
  _and?: InputMaybe<Array<ContestThumbnailsBoolExp>>;
  _not?: InputMaybe<ContestThumbnailsBoolExp>;
  _or?: InputMaybe<Array<ContestThumbnailsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  file?: InputMaybe<FilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "contest.thumbnails". */
export type ContestThumbnailsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  file?: InputMaybe<FilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "corporate.case_studies" */
export type CorporateCaseStudies = {
  __typename?: 'CorporateCaseStudies';
  body?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  contest?: Maybe<Contests>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  keyVisual?: Maybe<StrapiFiles>;
  organizer?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "corporate.case_studies" */
export type CorporateCaseStudiesAggregate = {
  __typename?: 'CorporateCaseStudiesAggregate';
  aggregate?: Maybe<CorporateCaseStudiesAggregateFields>;
  nodes: Array<CorporateCaseStudies>;
};

/** aggregate fields of "corporate.case_studies" */
export type CorporateCaseStudiesAggregateFields = {
  __typename?: 'CorporateCaseStudiesAggregateFields';
  avg?: Maybe<CorporateCaseStudiesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateCaseStudiesMaxFields>;
  min?: Maybe<CorporateCaseStudiesMinFields>;
  stddev?: Maybe<CorporateCaseStudiesStddevFields>;
  stddevPop?: Maybe<CorporateCaseStudiesStddevPopFields>;
  stddevSamp?: Maybe<CorporateCaseStudiesStddevSampFields>;
  sum?: Maybe<CorporateCaseStudiesSumFields>;
  varPop?: Maybe<CorporateCaseStudiesVarPopFields>;
  varSamp?: Maybe<CorporateCaseStudiesVarSampFields>;
  variance?: Maybe<CorporateCaseStudiesVarianceFields>;
};


/** aggregate fields of "corporate.case_studies" */
export type CorporateCaseStudiesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateCaseStudiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CorporateCaseStudiesAvgFields = {
  __typename?: 'CorporateCaseStudiesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "corporate.case_studies". All fields are combined with a logical 'AND'. */
export type CorporateCaseStudiesBoolExp = {
  _and?: InputMaybe<Array<CorporateCaseStudiesBoolExp>>;
  _not?: InputMaybe<CorporateCaseStudiesBoolExp>;
  _or?: InputMaybe<Array<CorporateCaseStudiesBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  contest?: InputMaybe<ContestsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  keyVisual?: InputMaybe<StrapiFilesBoolExp>;
  organizer?: InputMaybe<StringComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type CorporateCaseStudiesMaxFields = {
  __typename?: 'CorporateCaseStudiesMaxFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organizer?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type CorporateCaseStudiesMinFields = {
  __typename?: 'CorporateCaseStudiesMinFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organizer?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "corporate.case_studies". */
export type CorporateCaseStudiesOrderBy = {
  body?: InputMaybe<OrderBy>;
  contest?: InputMaybe<ContestsOrderBy>;
  id?: InputMaybe<OrderBy>;
  keyVisual?: InputMaybe<StrapiFilesOrderBy>;
  organizer?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.case_studies" */
export enum CorporateCaseStudiesSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type CorporateCaseStudiesStddevFields = {
  __typename?: 'CorporateCaseStudiesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CorporateCaseStudiesStddevPopFields = {
  __typename?: 'CorporateCaseStudiesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CorporateCaseStudiesStddevSampFields = {
  __typename?: 'CorporateCaseStudiesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "corporate_case_studies" */
export type CorporateCaseStudiesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateCaseStudiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateCaseStudiesStreamCursorValueInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type CorporateCaseStudiesSumFields = {
  __typename?: 'CorporateCaseStudiesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CorporateCaseStudiesVarPopFields = {
  __typename?: 'CorporateCaseStudiesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CorporateCaseStudiesVarSampFields = {
  __typename?: 'CorporateCaseStudiesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CorporateCaseStudiesVarianceFields = {
  __typename?: 'CorporateCaseStudiesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "corporate.ceo_blog_categories" */
export type CorporateCeoBlogCategories = {
  __typename?: 'CorporateCeoBlogCategories';
  /** An array relationship */
  categorizations: Array<CorporateCeoBlogCategorizations>;
  /** An aggregate relationship */
  categorizationsAggregate: CorporateCeoBlogCategorizationsAggregate;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "corporate.ceo_blog_categories" */
export type CorporateCeoBlogCategoriesCategorizationsArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


/** columns and relationships of "corporate.ceo_blog_categories" */
export type CorporateCeoBlogCategoriesCategorizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};

/** aggregated selection of "corporate.ceo_blog_categories" */
export type CorporateCeoBlogCategoriesAggregate = {
  __typename?: 'CorporateCeoBlogCategoriesAggregate';
  aggregate?: Maybe<CorporateCeoBlogCategoriesAggregateFields>;
  nodes: Array<CorporateCeoBlogCategories>;
};

/** aggregate fields of "corporate.ceo_blog_categories" */
export type CorporateCeoBlogCategoriesAggregateFields = {
  __typename?: 'CorporateCeoBlogCategoriesAggregateFields';
  avg?: Maybe<CorporateCeoBlogCategoriesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateCeoBlogCategoriesMaxFields>;
  min?: Maybe<CorporateCeoBlogCategoriesMinFields>;
  stddev?: Maybe<CorporateCeoBlogCategoriesStddevFields>;
  stddevPop?: Maybe<CorporateCeoBlogCategoriesStddevPopFields>;
  stddevSamp?: Maybe<CorporateCeoBlogCategoriesStddevSampFields>;
  sum?: Maybe<CorporateCeoBlogCategoriesSumFields>;
  varPop?: Maybe<CorporateCeoBlogCategoriesVarPopFields>;
  varSamp?: Maybe<CorporateCeoBlogCategoriesVarSampFields>;
  variance?: Maybe<CorporateCeoBlogCategoriesVarianceFields>;
};


/** aggregate fields of "corporate.ceo_blog_categories" */
export type CorporateCeoBlogCategoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateCeoBlogCategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CorporateCeoBlogCategoriesAvgFields = {
  __typename?: 'CorporateCeoBlogCategoriesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "corporate.ceo_blog_categories". All fields are combined with a logical 'AND'. */
export type CorporateCeoBlogCategoriesBoolExp = {
  _and?: InputMaybe<Array<CorporateCeoBlogCategoriesBoolExp>>;
  _not?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
  _or?: InputMaybe<Array<CorporateCeoBlogCategoriesBoolExp>>;
  categorizations?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
  categorizationsAggregate?: InputMaybe<CorporateCeoBlogCategorizationsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type CorporateCeoBlogCategoriesMaxFields = {
  __typename?: 'CorporateCeoBlogCategoriesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CorporateCeoBlogCategoriesMinFields = {
  __typename?: 'CorporateCeoBlogCategoriesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "corporate.ceo_blog_categories". */
export type CorporateCeoBlogCategoriesOrderBy = {
  categorizationsAggregate?: InputMaybe<CorporateCeoBlogCategorizationsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.ceo_blog_categories" */
export enum CorporateCeoBlogCategoriesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type CorporateCeoBlogCategoriesStddevFields = {
  __typename?: 'CorporateCeoBlogCategoriesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CorporateCeoBlogCategoriesStddevPopFields = {
  __typename?: 'CorporateCeoBlogCategoriesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CorporateCeoBlogCategoriesStddevSampFields = {
  __typename?: 'CorporateCeoBlogCategoriesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "corporate_ceo_blog_categories" */
export type CorporateCeoBlogCategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateCeoBlogCategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateCeoBlogCategoriesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CorporateCeoBlogCategoriesSumFields = {
  __typename?: 'CorporateCeoBlogCategoriesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CorporateCeoBlogCategoriesVarPopFields = {
  __typename?: 'CorporateCeoBlogCategoriesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CorporateCeoBlogCategoriesVarSampFields = {
  __typename?: 'CorporateCeoBlogCategoriesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CorporateCeoBlogCategoriesVarianceFields = {
  __typename?: 'CorporateCeoBlogCategoriesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizations = {
  __typename?: 'CorporateCeoBlogCategorizations';
  /** An object relationship */
  blog?: Maybe<CorporateCeoBlogs>;
  blogId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  category?: Maybe<CorporateCeoBlogCategories>;
  categoryId?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsAggregate = {
  __typename?: 'CorporateCeoBlogCategorizationsAggregate';
  aggregate?: Maybe<CorporateCeoBlogCategorizationsAggregateFields>;
  nodes: Array<CorporateCeoBlogCategorizations>;
};

export type CorporateCeoBlogCategorizationsAggregateBoolExp = {
  count?: InputMaybe<CorporateCeoBlogCategorizationsAggregateBoolExpCount>;
};

/** aggregate fields of "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsAggregateFields = {
  __typename?: 'CorporateCeoBlogCategorizationsAggregateFields';
  avg?: Maybe<CorporateCeoBlogCategorizationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateCeoBlogCategorizationsMaxFields>;
  min?: Maybe<CorporateCeoBlogCategorizationsMinFields>;
  stddev?: Maybe<CorporateCeoBlogCategorizationsStddevFields>;
  stddevPop?: Maybe<CorporateCeoBlogCategorizationsStddevPopFields>;
  stddevSamp?: Maybe<CorporateCeoBlogCategorizationsStddevSampFields>;
  sum?: Maybe<CorporateCeoBlogCategorizationsSumFields>;
  varPop?: Maybe<CorporateCeoBlogCategorizationsVarPopFields>;
  varSamp?: Maybe<CorporateCeoBlogCategorizationsVarSampFields>;
  variance?: Maybe<CorporateCeoBlogCategorizationsVarianceFields>;
};


/** aggregate fields of "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsAggregateOrderBy = {
  avg?: InputMaybe<CorporateCeoBlogCategorizationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CorporateCeoBlogCategorizationsMaxOrderBy>;
  min?: InputMaybe<CorporateCeoBlogCategorizationsMinOrderBy>;
  stddev?: InputMaybe<CorporateCeoBlogCategorizationsStddevOrderBy>;
  stddevPop?: InputMaybe<CorporateCeoBlogCategorizationsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CorporateCeoBlogCategorizationsStddevSampOrderBy>;
  sum?: InputMaybe<CorporateCeoBlogCategorizationsSumOrderBy>;
  varPop?: InputMaybe<CorporateCeoBlogCategorizationsVarPopOrderBy>;
  varSamp?: InputMaybe<CorporateCeoBlogCategorizationsVarSampOrderBy>;
  variance?: InputMaybe<CorporateCeoBlogCategorizationsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CorporateCeoBlogCategorizationsAvgFields = {
  __typename?: 'CorporateCeoBlogCategorizationsAvgFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsAvgOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "corporate.ceo_blog_categorizations". All fields are combined with a logical 'AND'. */
export type CorporateCeoBlogCategorizationsBoolExp = {
  _and?: InputMaybe<Array<CorporateCeoBlogCategorizationsBoolExp>>;
  _not?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
  _or?: InputMaybe<Array<CorporateCeoBlogCategorizationsBoolExp>>;
  blog?: InputMaybe<CorporateCeoBlogsBoolExp>;
  blogId?: InputMaybe<IntComparisonExp>;
  category?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type CorporateCeoBlogCategorizationsMaxFields = {
  __typename?: 'CorporateCeoBlogCategorizationsMaxFields';
  blogId?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsMaxOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CorporateCeoBlogCategorizationsMinFields = {
  __typename?: 'CorporateCeoBlogCategorizationsMinFields';
  blogId?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsMinOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "corporate.ceo_blog_categorizations". */
export type CorporateCeoBlogCategorizationsOrderBy = {
  blog?: InputMaybe<CorporateCeoBlogsOrderBy>;
  blogId?: InputMaybe<OrderBy>;
  category?: InputMaybe<CorporateCeoBlogCategoriesOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.ceo_blog_categorizations" */
export enum CorporateCeoBlogCategorizationsSelectColumn {
  /** column name */
  BlogId = 'blogId',
  /** column name */
  CategoryId = 'categoryId'
}

/** aggregate stddev on columns */
export type CorporateCeoBlogCategorizationsStddevFields = {
  __typename?: 'CorporateCeoBlogCategorizationsStddevFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsStddevOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CorporateCeoBlogCategorizationsStddevPopFields = {
  __typename?: 'CorporateCeoBlogCategorizationsStddevPopFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsStddevPopOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CorporateCeoBlogCategorizationsStddevSampFields = {
  __typename?: 'CorporateCeoBlogCategorizationsStddevSampFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsStddevSampOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "corporate_ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateCeoBlogCategorizationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateCeoBlogCategorizationsStreamCursorValueInput = {
  blogId?: InputMaybe<Scalars['Int']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type CorporateCeoBlogCategorizationsSumFields = {
  __typename?: 'CorporateCeoBlogCategorizationsSumFields';
  blogId?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsSumOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CorporateCeoBlogCategorizationsVarPopFields = {
  __typename?: 'CorporateCeoBlogCategorizationsVarPopFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsVarPopOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CorporateCeoBlogCategorizationsVarSampFields = {
  __typename?: 'CorporateCeoBlogCategorizationsVarSampFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsVarSampOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CorporateCeoBlogCategorizationsVarianceFields = {
  __typename?: 'CorporateCeoBlogCategorizationsVarianceFields';
  blogId?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "corporate.ceo_blog_categorizations" */
export type CorporateCeoBlogCategorizationsVarianceOrderBy = {
  blogId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "corporate.ceo_blogs" */
export type CorporateCeoBlogs = {
  __typename?: 'CorporateCeoBlogs';
  body?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  categorizations: Array<CorporateCeoBlogCategorizations>;
  /** An aggregate relationship */
  categorizationsAggregate: CorporateCeoBlogCategorizationsAggregate;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "corporate.ceo_blogs" */
export type CorporateCeoBlogsCategorizationsArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


/** columns and relationships of "corporate.ceo_blogs" */
export type CorporateCeoBlogsCategorizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};

/** aggregated selection of "corporate.ceo_blogs" */
export type CorporateCeoBlogsAggregate = {
  __typename?: 'CorporateCeoBlogsAggregate';
  aggregate?: Maybe<CorporateCeoBlogsAggregateFields>;
  nodes: Array<CorporateCeoBlogs>;
};

/** aggregate fields of "corporate.ceo_blogs" */
export type CorporateCeoBlogsAggregateFields = {
  __typename?: 'CorporateCeoBlogsAggregateFields';
  avg?: Maybe<CorporateCeoBlogsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateCeoBlogsMaxFields>;
  min?: Maybe<CorporateCeoBlogsMinFields>;
  stddev?: Maybe<CorporateCeoBlogsStddevFields>;
  stddevPop?: Maybe<CorporateCeoBlogsStddevPopFields>;
  stddevSamp?: Maybe<CorporateCeoBlogsStddevSampFields>;
  sum?: Maybe<CorporateCeoBlogsSumFields>;
  varPop?: Maybe<CorporateCeoBlogsVarPopFields>;
  varSamp?: Maybe<CorporateCeoBlogsVarSampFields>;
  variance?: Maybe<CorporateCeoBlogsVarianceFields>;
};


/** aggregate fields of "corporate.ceo_blogs" */
export type CorporateCeoBlogsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateCeoBlogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CorporateCeoBlogsAvgFields = {
  __typename?: 'CorporateCeoBlogsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "corporate.ceo_blogs". All fields are combined with a logical 'AND'. */
export type CorporateCeoBlogsBoolExp = {
  _and?: InputMaybe<Array<CorporateCeoBlogsBoolExp>>;
  _not?: InputMaybe<CorporateCeoBlogsBoolExp>;
  _or?: InputMaybe<Array<CorporateCeoBlogsBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  categorizations?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
  categorizationsAggregate?: InputMaybe<CorporateCeoBlogCategorizationsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type CorporateCeoBlogsMaxFields = {
  __typename?: 'CorporateCeoBlogsMaxFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type CorporateCeoBlogsMinFields = {
  __typename?: 'CorporateCeoBlogsMinFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "corporate.ceo_blogs". */
export type CorporateCeoBlogsOrderBy = {
  body?: InputMaybe<OrderBy>;
  categorizationsAggregate?: InputMaybe<CorporateCeoBlogCategorizationsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.ceo_blogs" */
export enum CorporateCeoBlogsSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type CorporateCeoBlogsStddevFields = {
  __typename?: 'CorporateCeoBlogsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CorporateCeoBlogsStddevPopFields = {
  __typename?: 'CorporateCeoBlogsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CorporateCeoBlogsStddevSampFields = {
  __typename?: 'CorporateCeoBlogsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "corporate_ceo_blogs" */
export type CorporateCeoBlogsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateCeoBlogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateCeoBlogsStreamCursorValueInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type CorporateCeoBlogsSumFields = {
  __typename?: 'CorporateCeoBlogsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CorporateCeoBlogsVarPopFields = {
  __typename?: 'CorporateCeoBlogsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CorporateCeoBlogsVarSampFields = {
  __typename?: 'CorporateCeoBlogsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CorporateCeoBlogsVarianceFields = {
  __typename?: 'CorporateCeoBlogsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "corporate.contest_knowledges" */
export type CorporateContestKnowledges = {
  __typename?: 'CorporateContestKnowledges';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  keyVisual?: Maybe<StrapiFiles>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "corporate.contest_knowledges" */
export type CorporateContestKnowledgesAggregate = {
  __typename?: 'CorporateContestKnowledgesAggregate';
  aggregate?: Maybe<CorporateContestKnowledgesAggregateFields>;
  nodes: Array<CorporateContestKnowledges>;
};

/** aggregate fields of "corporate.contest_knowledges" */
export type CorporateContestKnowledgesAggregateFields = {
  __typename?: 'CorporateContestKnowledgesAggregateFields';
  avg?: Maybe<CorporateContestKnowledgesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateContestKnowledgesMaxFields>;
  min?: Maybe<CorporateContestKnowledgesMinFields>;
  stddev?: Maybe<CorporateContestKnowledgesStddevFields>;
  stddevPop?: Maybe<CorporateContestKnowledgesStddevPopFields>;
  stddevSamp?: Maybe<CorporateContestKnowledgesStddevSampFields>;
  sum?: Maybe<CorporateContestKnowledgesSumFields>;
  varPop?: Maybe<CorporateContestKnowledgesVarPopFields>;
  varSamp?: Maybe<CorporateContestKnowledgesVarSampFields>;
  variance?: Maybe<CorporateContestKnowledgesVarianceFields>;
};


/** aggregate fields of "corporate.contest_knowledges" */
export type CorporateContestKnowledgesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateContestKnowledgesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CorporateContestKnowledgesAvgFields = {
  __typename?: 'CorporateContestKnowledgesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "corporate.contest_knowledges". All fields are combined with a logical 'AND'. */
export type CorporateContestKnowledgesBoolExp = {
  _and?: InputMaybe<Array<CorporateContestKnowledgesBoolExp>>;
  _not?: InputMaybe<CorporateContestKnowledgesBoolExp>;
  _or?: InputMaybe<Array<CorporateContestKnowledgesBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  keyVisual?: InputMaybe<StrapiFilesBoolExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type CorporateContestKnowledgesMaxFields = {
  __typename?: 'CorporateContestKnowledgesMaxFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type CorporateContestKnowledgesMinFields = {
  __typename?: 'CorporateContestKnowledgesMinFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "corporate.contest_knowledges". */
export type CorporateContestKnowledgesOrderBy = {
  body?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keyVisual?: InputMaybe<StrapiFilesOrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.contest_knowledges" */
export enum CorporateContestKnowledgesSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type CorporateContestKnowledgesStddevFields = {
  __typename?: 'CorporateContestKnowledgesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CorporateContestKnowledgesStddevPopFields = {
  __typename?: 'CorporateContestKnowledgesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CorporateContestKnowledgesStddevSampFields = {
  __typename?: 'CorporateContestKnowledgesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "corporate_contest_knowledges" */
export type CorporateContestKnowledgesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateContestKnowledgesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateContestKnowledgesStreamCursorValueInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type CorporateContestKnowledgesSumFields = {
  __typename?: 'CorporateContestKnowledgesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CorporateContestKnowledgesVarPopFields = {
  __typename?: 'CorporateContestKnowledgesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CorporateContestKnowledgesVarSampFields = {
  __typename?: 'CorporateContestKnowledgesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CorporateContestKnowledgesVarianceFields = {
  __typename?: 'CorporateContestKnowledgesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "corporate.employee_blog_categories" */
export type CorporateEmployeeBlogCategories = {
  __typename?: 'CorporateEmployeeBlogCategories';
  /** An array relationship */
  blogs: Array<CorporateEmployeeBlogs>;
  /** An aggregate relationship */
  blogsAggregate: CorporateEmployeeBlogsAggregate;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "corporate.employee_blog_categories" */
export type CorporateEmployeeBlogCategoriesBlogsArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogsOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};


/** columns and relationships of "corporate.employee_blog_categories" */
export type CorporateEmployeeBlogCategoriesBlogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogsOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};

/** aggregated selection of "corporate.employee_blog_categories" */
export type CorporateEmployeeBlogCategoriesAggregate = {
  __typename?: 'CorporateEmployeeBlogCategoriesAggregate';
  aggregate?: Maybe<CorporateEmployeeBlogCategoriesAggregateFields>;
  nodes: Array<CorporateEmployeeBlogCategories>;
};

/** aggregate fields of "corporate.employee_blog_categories" */
export type CorporateEmployeeBlogCategoriesAggregateFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesAggregateFields';
  avg?: Maybe<CorporateEmployeeBlogCategoriesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateEmployeeBlogCategoriesMaxFields>;
  min?: Maybe<CorporateEmployeeBlogCategoriesMinFields>;
  stddev?: Maybe<CorporateEmployeeBlogCategoriesStddevFields>;
  stddevPop?: Maybe<CorporateEmployeeBlogCategoriesStddevPopFields>;
  stddevSamp?: Maybe<CorporateEmployeeBlogCategoriesStddevSampFields>;
  sum?: Maybe<CorporateEmployeeBlogCategoriesSumFields>;
  varPop?: Maybe<CorporateEmployeeBlogCategoriesVarPopFields>;
  varSamp?: Maybe<CorporateEmployeeBlogCategoriesVarSampFields>;
  variance?: Maybe<CorporateEmployeeBlogCategoriesVarianceFields>;
};


/** aggregate fields of "corporate.employee_blog_categories" */
export type CorporateEmployeeBlogCategoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateEmployeeBlogCategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CorporateEmployeeBlogCategoriesAvgFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "corporate.employee_blog_categories". All fields are combined with a logical 'AND'. */
export type CorporateEmployeeBlogCategoriesBoolExp = {
  _and?: InputMaybe<Array<CorporateEmployeeBlogCategoriesBoolExp>>;
  _not?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
  _or?: InputMaybe<Array<CorporateEmployeeBlogCategoriesBoolExp>>;
  blogs?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
  blogsAggregate?: InputMaybe<CorporateEmployeeBlogsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type CorporateEmployeeBlogCategoriesMaxFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CorporateEmployeeBlogCategoriesMinFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "corporate.employee_blog_categories". */
export type CorporateEmployeeBlogCategoriesOrderBy = {
  blogsAggregate?: InputMaybe<CorporateEmployeeBlogsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.employee_blog_categories" */
export enum CorporateEmployeeBlogCategoriesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type CorporateEmployeeBlogCategoriesStddevFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CorporateEmployeeBlogCategoriesStddevPopFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CorporateEmployeeBlogCategoriesStddevSampFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "corporate_employee_blog_categories" */
export type CorporateEmployeeBlogCategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateEmployeeBlogCategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateEmployeeBlogCategoriesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CorporateEmployeeBlogCategoriesSumFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CorporateEmployeeBlogCategoriesVarPopFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CorporateEmployeeBlogCategoriesVarSampFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CorporateEmployeeBlogCategoriesVarianceFields = {
  __typename?: 'CorporateEmployeeBlogCategoriesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "corporate.employee_blogs" */
export type CorporateEmployeeBlogs = {
  __typename?: 'CorporateEmployeeBlogs';
  body?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  category?: Maybe<CorporateEmployeeBlogCategories>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "corporate.employee_blogs" */
export type CorporateEmployeeBlogsAggregate = {
  __typename?: 'CorporateEmployeeBlogsAggregate';
  aggregate?: Maybe<CorporateEmployeeBlogsAggregateFields>;
  nodes: Array<CorporateEmployeeBlogs>;
};

export type CorporateEmployeeBlogsAggregateBoolExp = {
  count?: InputMaybe<CorporateEmployeeBlogsAggregateBoolExpCount>;
};

/** aggregate fields of "corporate.employee_blogs" */
export type CorporateEmployeeBlogsAggregateFields = {
  __typename?: 'CorporateEmployeeBlogsAggregateFields';
  avg?: Maybe<CorporateEmployeeBlogsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateEmployeeBlogsMaxFields>;
  min?: Maybe<CorporateEmployeeBlogsMinFields>;
  stddev?: Maybe<CorporateEmployeeBlogsStddevFields>;
  stddevPop?: Maybe<CorporateEmployeeBlogsStddevPopFields>;
  stddevSamp?: Maybe<CorporateEmployeeBlogsStddevSampFields>;
  sum?: Maybe<CorporateEmployeeBlogsSumFields>;
  varPop?: Maybe<CorporateEmployeeBlogsVarPopFields>;
  varSamp?: Maybe<CorporateEmployeeBlogsVarSampFields>;
  variance?: Maybe<CorporateEmployeeBlogsVarianceFields>;
};


/** aggregate fields of "corporate.employee_blogs" */
export type CorporateEmployeeBlogsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsAggregateOrderBy = {
  avg?: InputMaybe<CorporateEmployeeBlogsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CorporateEmployeeBlogsMaxOrderBy>;
  min?: InputMaybe<CorporateEmployeeBlogsMinOrderBy>;
  stddev?: InputMaybe<CorporateEmployeeBlogsStddevOrderBy>;
  stddevPop?: InputMaybe<CorporateEmployeeBlogsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CorporateEmployeeBlogsStddevSampOrderBy>;
  sum?: InputMaybe<CorporateEmployeeBlogsSumOrderBy>;
  varPop?: InputMaybe<CorporateEmployeeBlogsVarPopOrderBy>;
  varSamp?: InputMaybe<CorporateEmployeeBlogsVarSampOrderBy>;
  variance?: InputMaybe<CorporateEmployeeBlogsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CorporateEmployeeBlogsAvgFields = {
  __typename?: 'CorporateEmployeeBlogsAvgFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsAvgOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "corporate.employee_blogs". All fields are combined with a logical 'AND'. */
export type CorporateEmployeeBlogsBoolExp = {
  _and?: InputMaybe<Array<CorporateEmployeeBlogsBoolExp>>;
  _not?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
  _or?: InputMaybe<Array<CorporateEmployeeBlogsBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  category?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type CorporateEmployeeBlogsMaxFields = {
  __typename?: 'CorporateEmployeeBlogsMaxFields';
  body?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsMaxOrderBy = {
  body?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CorporateEmployeeBlogsMinFields = {
  __typename?: 'CorporateEmployeeBlogsMinFields';
  body?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsMinOrderBy = {
  body?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "corporate.employee_blogs". */
export type CorporateEmployeeBlogsOrderBy = {
  body?: InputMaybe<OrderBy>;
  category?: InputMaybe<CorporateEmployeeBlogCategoriesOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.employee_blogs" */
export enum CorporateEmployeeBlogsSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type CorporateEmployeeBlogsStddevFields = {
  __typename?: 'CorporateEmployeeBlogsStddevFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsStddevOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CorporateEmployeeBlogsStddevPopFields = {
  __typename?: 'CorporateEmployeeBlogsStddevPopFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsStddevPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CorporateEmployeeBlogsStddevSampFields = {
  __typename?: 'CorporateEmployeeBlogsStddevSampFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsStddevSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "corporate_employee_blogs" */
export type CorporateEmployeeBlogsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateEmployeeBlogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateEmployeeBlogsStreamCursorValueInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type CorporateEmployeeBlogsSumFields = {
  __typename?: 'CorporateEmployeeBlogsSumFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsSumOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CorporateEmployeeBlogsVarPopFields = {
  __typename?: 'CorporateEmployeeBlogsVarPopFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsVarPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CorporateEmployeeBlogsVarSampFields = {
  __typename?: 'CorporateEmployeeBlogsVarSampFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsVarSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CorporateEmployeeBlogsVarianceFields = {
  __typename?: 'CorporateEmployeeBlogsVarianceFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "corporate.employee_blogs" */
export type CorporateEmployeeBlogsVarianceOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "corporate.recruitment_records" */
export type CorporateRecruitmentRecords = {
  __typename?: 'CorporateRecruitmentRecords';
  label?: Maybe<Scalars['String']['output']>;
  recruitmentId?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsAggregateOrderBy = {
  avg?: InputMaybe<CorporateRecruitmentRecordsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CorporateRecruitmentRecordsMaxOrderBy>;
  min?: InputMaybe<CorporateRecruitmentRecordsMinOrderBy>;
  stddev?: InputMaybe<CorporateRecruitmentRecordsStddevOrderBy>;
  stddevPop?: InputMaybe<CorporateRecruitmentRecordsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CorporateRecruitmentRecordsStddevSampOrderBy>;
  sum?: InputMaybe<CorporateRecruitmentRecordsSumOrderBy>;
  varPop?: InputMaybe<CorporateRecruitmentRecordsVarPopOrderBy>;
  varSamp?: InputMaybe<CorporateRecruitmentRecordsVarSampOrderBy>;
  variance?: InputMaybe<CorporateRecruitmentRecordsVarianceOrderBy>;
};

/** order by avg() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsAvgOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "corporate.recruitment_records". All fields are combined with a logical 'AND'. */
export type CorporateRecruitmentRecordsBoolExp = {
  _and?: InputMaybe<Array<CorporateRecruitmentRecordsBoolExp>>;
  _not?: InputMaybe<CorporateRecruitmentRecordsBoolExp>;
  _or?: InputMaybe<Array<CorporateRecruitmentRecordsBoolExp>>;
  label?: InputMaybe<StringComparisonExp>;
  recruitmentId?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsMaxOrderBy = {
  label?: InputMaybe<OrderBy>;
  recruitmentId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsMinOrderBy = {
  label?: InputMaybe<OrderBy>;
  recruitmentId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "corporate.recruitment_records". */
export type CorporateRecruitmentRecordsOrderBy = {
  label?: InputMaybe<OrderBy>;
  recruitmentId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.recruitment_records" */
export enum CorporateRecruitmentRecordsSelectColumn {
  /** column name */
  Label = 'label',
  /** column name */
  RecruitmentId = 'recruitmentId',
  /** column name */
  Value = 'value'
}

/** order by stddev() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsStddevOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsStddevPopOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsStddevSampOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsSumOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsVarPopOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsVarSampOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "corporate.recruitment_records" */
export type CorporateRecruitmentRecordsVarianceOrderBy = {
  recruitmentId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "corporate.recruitments" */
export type CorporateRecruitments = {
  __typename?: 'CorporateRecruitments';
  id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  records: Array<CorporateRecruitmentRecords>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "corporate.recruitments" */
export type CorporateRecruitmentsRecordsArgs = {
  distinctOn?: InputMaybe<Array<CorporateRecruitmentRecordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateRecruitmentRecordsOrderBy>>;
  where?: InputMaybe<CorporateRecruitmentRecordsBoolExp>;
};

/** Boolean expression to filter rows from the table "corporate.recruitments". All fields are combined with a logical 'AND'. */
export type CorporateRecruitmentsBoolExp = {
  _and?: InputMaybe<Array<CorporateRecruitmentsBoolExp>>;
  _not?: InputMaybe<CorporateRecruitmentsBoolExp>;
  _or?: InputMaybe<Array<CorporateRecruitmentsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  records?: InputMaybe<CorporateRecruitmentRecordsBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** Ordering options when selecting data from "corporate.recruitments". */
export type CorporateRecruitmentsOrderBy = {
  id?: InputMaybe<OrderBy>;
  recordsAggregate?: InputMaybe<CorporateRecruitmentRecordsAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.recruitments" */
export enum CorporateRecruitmentsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Streaming cursor of the table "corporate_recruitments" */
export type CorporateRecruitmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateRecruitmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateRecruitmentsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** columns and relationships of "corporate.sales_document_contents" */
export type CorporateSalesDocumentContents = {
  __typename?: 'CorporateSalesDocumentContents';
  heading?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "corporate.sales_document_contents" */
export type CorporateSalesDocumentContentsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CorporateSalesDocumentContentsMaxOrderBy>;
  min?: InputMaybe<CorporateSalesDocumentContentsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "corporate.sales_document_contents". All fields are combined with a logical 'AND'. */
export type CorporateSalesDocumentContentsBoolExp = {
  _and?: InputMaybe<Array<CorporateSalesDocumentContentsBoolExp>>;
  _not?: InputMaybe<CorporateSalesDocumentContentsBoolExp>;
  _or?: InputMaybe<Array<CorporateSalesDocumentContentsBoolExp>>;
  heading?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "corporate.sales_document_contents" */
export type CorporateSalesDocumentContentsMaxOrderBy = {
  heading?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "corporate.sales_document_contents" */
export type CorporateSalesDocumentContentsMinOrderBy = {
  heading?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "corporate.sales_document_contents". */
export type CorporateSalesDocumentContentsOrderBy = {
  heading?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.sales_document_contents" */
export enum CorporateSalesDocumentContentsSelectColumn {
  /** column name */
  Heading = 'heading'
}

/** columns and relationships of "corporate.sales_documents" */
export type CorporateSalesDocuments = {
  __typename?: 'CorporateSalesDocuments';
  /** An array relationship */
  contents: Array<CorporateSalesDocumentContents>;
  /** An object relationship */
  coverImage?: Maybe<StrapiFiles>;
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "corporate.sales_documents" */
export type CorporateSalesDocumentsContentsArgs = {
  distinctOn?: InputMaybe<Array<CorporateSalesDocumentContentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateSalesDocumentContentsOrderBy>>;
  where?: InputMaybe<CorporateSalesDocumentContentsBoolExp>;
};

/** aggregated selection of "corporate.sales_documents" */
export type CorporateSalesDocumentsAggregate = {
  __typename?: 'CorporateSalesDocumentsAggregate';
  aggregate?: Maybe<CorporateSalesDocumentsAggregateFields>;
  nodes: Array<CorporateSalesDocuments>;
};

/** aggregate fields of "corporate.sales_documents" */
export type CorporateSalesDocumentsAggregateFields = {
  __typename?: 'CorporateSalesDocumentsAggregateFields';
  avg?: Maybe<CorporateSalesDocumentsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CorporateSalesDocumentsMaxFields>;
  min?: Maybe<CorporateSalesDocumentsMinFields>;
  stddev?: Maybe<CorporateSalesDocumentsStddevFields>;
  stddevPop?: Maybe<CorporateSalesDocumentsStddevPopFields>;
  stddevSamp?: Maybe<CorporateSalesDocumentsStddevSampFields>;
  sum?: Maybe<CorporateSalesDocumentsSumFields>;
  varPop?: Maybe<CorporateSalesDocumentsVarPopFields>;
  varSamp?: Maybe<CorporateSalesDocumentsVarSampFields>;
  variance?: Maybe<CorporateSalesDocumentsVarianceFields>;
};


/** aggregate fields of "corporate.sales_documents" */
export type CorporateSalesDocumentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CorporateSalesDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CorporateSalesDocumentsAvgFields = {
  __typename?: 'CorporateSalesDocumentsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "corporate.sales_documents". All fields are combined with a logical 'AND'. */
export type CorporateSalesDocumentsBoolExp = {
  _and?: InputMaybe<Array<CorporateSalesDocumentsBoolExp>>;
  _not?: InputMaybe<CorporateSalesDocumentsBoolExp>;
  _or?: InputMaybe<Array<CorporateSalesDocumentsBoolExp>>;
  contents?: InputMaybe<CorporateSalesDocumentContentsBoolExp>;
  coverImage?: InputMaybe<StrapiFilesBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  file?: InputMaybe<StrapiFilesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type CorporateSalesDocumentsMaxFields = {
  __typename?: 'CorporateSalesDocumentsMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type CorporateSalesDocumentsMinFields = {
  __typename?: 'CorporateSalesDocumentsMinFields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "corporate.sales_documents". */
export type CorporateSalesDocumentsOrderBy = {
  contentsAggregate?: InputMaybe<CorporateSalesDocumentContentsAggregateOrderBy>;
  coverImage?: InputMaybe<StrapiFilesOrderBy>;
  description?: InputMaybe<OrderBy>;
  file?: InputMaybe<StrapiFilesOrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "corporate.sales_documents" */
export enum CorporateSalesDocumentsSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type CorporateSalesDocumentsStddevFields = {
  __typename?: 'CorporateSalesDocumentsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CorporateSalesDocumentsStddevPopFields = {
  __typename?: 'CorporateSalesDocumentsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CorporateSalesDocumentsStddevSampFields = {
  __typename?: 'CorporateSalesDocumentsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "corporate_sales_documents" */
export type CorporateSalesDocumentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CorporateSalesDocumentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CorporateSalesDocumentsStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type CorporateSalesDocumentsSumFields = {
  __typename?: 'CorporateSalesDocumentsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CorporateSalesDocumentsVarPopFields = {
  __typename?: 'CorporateSalesDocumentsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CorporateSalesDocumentsVarSampFields = {
  __typename?: 'CorporateSalesDocumentsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CorporateSalesDocumentsVarianceFields = {
  __typename?: 'CorporateSalesDocumentsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "creator.followees" */
export type CreatorFollowees = {
  __typename?: 'CreatorFollowees';
  id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "creator.followee_is_followee" */
  isFollowee?: Maybe<Scalars['Boolean']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profileImage?: Maybe<Files>;
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "creator.followees" */
export type CreatorFolloweesAggregate = {
  __typename?: 'CreatorFolloweesAggregate';
  aggregate?: Maybe<CreatorFolloweesAggregateFields>;
  nodes: Array<CreatorFollowees>;
};

export type CreatorFolloweesAggregateBoolExp = {
  count?: InputMaybe<CreatorFolloweesAggregateBoolExpCount>;
};

/** aggregate fields of "creator.followees" */
export type CreatorFolloweesAggregateFields = {
  __typename?: 'CreatorFolloweesAggregateFields';
  avg?: Maybe<CreatorFolloweesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorFolloweesMaxFields>;
  min?: Maybe<CreatorFolloweesMinFields>;
  stddev?: Maybe<CreatorFolloweesStddevFields>;
  stddevPop?: Maybe<CreatorFolloweesStddevPopFields>;
  stddevSamp?: Maybe<CreatorFolloweesStddevSampFields>;
  sum?: Maybe<CreatorFolloweesSumFields>;
  varPop?: Maybe<CreatorFolloweesVarPopFields>;
  varSamp?: Maybe<CreatorFolloweesVarSampFields>;
  variance?: Maybe<CreatorFolloweesVarianceFields>;
};


/** aggregate fields of "creator.followees" */
export type CreatorFolloweesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorFolloweesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "creator.followees" */
export type CreatorFolloweesAggregateOrderBy = {
  avg?: InputMaybe<CreatorFolloweesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorFolloweesMaxOrderBy>;
  min?: InputMaybe<CreatorFolloweesMinOrderBy>;
  stddev?: InputMaybe<CreatorFolloweesStddevOrderBy>;
  stddevPop?: InputMaybe<CreatorFolloweesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CreatorFolloweesStddevSampOrderBy>;
  sum?: InputMaybe<CreatorFolloweesSumOrderBy>;
  varPop?: InputMaybe<CreatorFolloweesVarPopOrderBy>;
  varSamp?: InputMaybe<CreatorFolloweesVarSampOrderBy>;
  variance?: InputMaybe<CreatorFolloweesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CreatorFolloweesAvgFields = {
  __typename?: 'CreatorFolloweesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "creator.followees" */
export type CreatorFolloweesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "creator.followees". All fields are combined with a logical 'AND'. */
export type CreatorFolloweesBoolExp = {
  _and?: InputMaybe<Array<CreatorFolloweesBoolExp>>;
  _not?: InputMaybe<CreatorFolloweesBoolExp>;
  _or?: InputMaybe<Array<CreatorFolloweesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  isFollowee?: InputMaybe<BooleanComparisonExp>;
  nickname?: InputMaybe<StringComparisonExp>;
  profile?: InputMaybe<StringComparisonExp>;
  profileImage?: InputMaybe<FilesBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** aggregate max on columns */
export type CreatorFolloweesMaxFields = {
  __typename?: 'CreatorFolloweesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "creator.followees" */
export type CreatorFolloweesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorFolloweesMinFields = {
  __typename?: 'CreatorFolloweesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "creator.followees" */
export type CreatorFolloweesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "creator.followees". */
export type CreatorFolloweesOrderBy = {
  id?: InputMaybe<OrderBy>;
  isFollowee?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  profileImage?: InputMaybe<FilesOrderBy>;
  url?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** select columns of table "creator.followees" */
export enum CreatorFolloweesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Profile = 'profile',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type CreatorFolloweesStddevFields = {
  __typename?: 'CreatorFolloweesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "creator.followees" */
export type CreatorFolloweesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CreatorFolloweesStddevPopFields = {
  __typename?: 'CreatorFolloweesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "creator.followees" */
export type CreatorFolloweesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CreatorFolloweesStddevSampFields = {
  __typename?: 'CreatorFolloweesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "creator.followees" */
export type CreatorFolloweesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CreatorFolloweesSumFields = {
  __typename?: 'CreatorFolloweesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "creator.followees" */
export type CreatorFolloweesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CreatorFolloweesVarPopFields = {
  __typename?: 'CreatorFolloweesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "creator.followees" */
export type CreatorFolloweesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CreatorFolloweesVarSampFields = {
  __typename?: 'CreatorFolloweesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "creator.followees" */
export type CreatorFolloweesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CreatorFolloweesVarianceFields = {
  __typename?: 'CreatorFolloweesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "creator.followees" */
export type CreatorFolloweesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "creator.followers" */
export type CreatorFollowers = {
  __typename?: 'CreatorFollowers';
  id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "creator.follower_is_followee" */
  isFollowee?: Maybe<Scalars['Boolean']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profileImage?: Maybe<Files>;
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "creator.followers" */
export type CreatorFollowersAggregate = {
  __typename?: 'CreatorFollowersAggregate';
  aggregate?: Maybe<CreatorFollowersAggregateFields>;
  nodes: Array<CreatorFollowers>;
};

export type CreatorFollowersAggregateBoolExp = {
  count?: InputMaybe<CreatorFollowersAggregateBoolExpCount>;
};

/** aggregate fields of "creator.followers" */
export type CreatorFollowersAggregateFields = {
  __typename?: 'CreatorFollowersAggregateFields';
  avg?: Maybe<CreatorFollowersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorFollowersMaxFields>;
  min?: Maybe<CreatorFollowersMinFields>;
  stddev?: Maybe<CreatorFollowersStddevFields>;
  stddevPop?: Maybe<CreatorFollowersStddevPopFields>;
  stddevSamp?: Maybe<CreatorFollowersStddevSampFields>;
  sum?: Maybe<CreatorFollowersSumFields>;
  varPop?: Maybe<CreatorFollowersVarPopFields>;
  varSamp?: Maybe<CreatorFollowersVarSampFields>;
  variance?: Maybe<CreatorFollowersVarianceFields>;
};


/** aggregate fields of "creator.followers" */
export type CreatorFollowersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorFollowersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "creator.followers" */
export type CreatorFollowersAggregateOrderBy = {
  avg?: InputMaybe<CreatorFollowersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorFollowersMaxOrderBy>;
  min?: InputMaybe<CreatorFollowersMinOrderBy>;
  stddev?: InputMaybe<CreatorFollowersStddevOrderBy>;
  stddevPop?: InputMaybe<CreatorFollowersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CreatorFollowersStddevSampOrderBy>;
  sum?: InputMaybe<CreatorFollowersSumOrderBy>;
  varPop?: InputMaybe<CreatorFollowersVarPopOrderBy>;
  varSamp?: InputMaybe<CreatorFollowersVarSampOrderBy>;
  variance?: InputMaybe<CreatorFollowersVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CreatorFollowersAvgFields = {
  __typename?: 'CreatorFollowersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "creator.followers" */
export type CreatorFollowersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "creator.followers". All fields are combined with a logical 'AND'. */
export type CreatorFollowersBoolExp = {
  _and?: InputMaybe<Array<CreatorFollowersBoolExp>>;
  _not?: InputMaybe<CreatorFollowersBoolExp>;
  _or?: InputMaybe<Array<CreatorFollowersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  isFollowee?: InputMaybe<BooleanComparisonExp>;
  nickname?: InputMaybe<StringComparisonExp>;
  profile?: InputMaybe<StringComparisonExp>;
  profileImage?: InputMaybe<FilesBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** aggregate max on columns */
export type CreatorFollowersMaxFields = {
  __typename?: 'CreatorFollowersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "creator.followers" */
export type CreatorFollowersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorFollowersMinFields = {
  __typename?: 'CreatorFollowersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "creator.followers" */
export type CreatorFollowersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "creator.followers". */
export type CreatorFollowersOrderBy = {
  id?: InputMaybe<OrderBy>;
  isFollowee?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  profileImage?: InputMaybe<FilesOrderBy>;
  url?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** select columns of table "creator.followers" */
export enum CreatorFollowersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Profile = 'profile',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type CreatorFollowersStddevFields = {
  __typename?: 'CreatorFollowersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "creator.followers" */
export type CreatorFollowersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CreatorFollowersStddevPopFields = {
  __typename?: 'CreatorFollowersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "creator.followers" */
export type CreatorFollowersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CreatorFollowersStddevSampFields = {
  __typename?: 'CreatorFollowersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "creator.followers" */
export type CreatorFollowersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CreatorFollowersSumFields = {
  __typename?: 'CreatorFollowersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "creator.followers" */
export type CreatorFollowersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CreatorFollowersVarPopFields = {
  __typename?: 'CreatorFollowersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "creator.followers" */
export type CreatorFollowersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CreatorFollowersVarSampFields = {
  __typename?: 'CreatorFollowersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "creator.followers" */
export type CreatorFollowersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CreatorFollowersVarianceFields = {
  __typename?: 'CreatorFollowersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "creator.followers" */
export type CreatorFollowersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "creator.interest_categories" */
export type CreatorInterestCategories = {
  __typename?: 'CreatorInterestCategories';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  categoryId: Scalars['Int']['output'];
  /** An object relationship */
  creator: Creators;
  creatorId: Scalars['Int']['output'];
};

/** aggregated selection of "creator.interest_categories" */
export type CreatorInterestCategoriesAggregate = {
  __typename?: 'CreatorInterestCategoriesAggregate';
  aggregate?: Maybe<CreatorInterestCategoriesAggregateFields>;
  nodes: Array<CreatorInterestCategories>;
};

export type CreatorInterestCategoriesAggregateBoolExp = {
  count?: InputMaybe<CreatorInterestCategoriesAggregateBoolExpCount>;
};

/** aggregate fields of "creator.interest_categories" */
export type CreatorInterestCategoriesAggregateFields = {
  __typename?: 'CreatorInterestCategoriesAggregateFields';
  avg?: Maybe<CreatorInterestCategoriesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorInterestCategoriesMaxFields>;
  min?: Maybe<CreatorInterestCategoriesMinFields>;
  stddev?: Maybe<CreatorInterestCategoriesStddevFields>;
  stddevPop?: Maybe<CreatorInterestCategoriesStddevPopFields>;
  stddevSamp?: Maybe<CreatorInterestCategoriesStddevSampFields>;
  sum?: Maybe<CreatorInterestCategoriesSumFields>;
  varPop?: Maybe<CreatorInterestCategoriesVarPopFields>;
  varSamp?: Maybe<CreatorInterestCategoriesVarSampFields>;
  variance?: Maybe<CreatorInterestCategoriesVarianceFields>;
};


/** aggregate fields of "creator.interest_categories" */
export type CreatorInterestCategoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "creator.interest_categories" */
export type CreatorInterestCategoriesAggregateOrderBy = {
  avg?: InputMaybe<CreatorInterestCategoriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorInterestCategoriesMaxOrderBy>;
  min?: InputMaybe<CreatorInterestCategoriesMinOrderBy>;
  stddev?: InputMaybe<CreatorInterestCategoriesStddevOrderBy>;
  stddevPop?: InputMaybe<CreatorInterestCategoriesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CreatorInterestCategoriesStddevSampOrderBy>;
  sum?: InputMaybe<CreatorInterestCategoriesSumOrderBy>;
  varPop?: InputMaybe<CreatorInterestCategoriesVarPopOrderBy>;
  varSamp?: InputMaybe<CreatorInterestCategoriesVarSampOrderBy>;
  variance?: InputMaybe<CreatorInterestCategoriesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CreatorInterestCategoriesAvgFields = {
  __typename?: 'CreatorInterestCategoriesAvgFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesAvgOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "creator.interest_categories". All fields are combined with a logical 'AND'. */
export type CreatorInterestCategoriesBoolExp = {
  _and?: InputMaybe<Array<CreatorInterestCategoriesBoolExp>>;
  _not?: InputMaybe<CreatorInterestCategoriesBoolExp>;
  _or?: InputMaybe<Array<CreatorInterestCategoriesBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  creator?: InputMaybe<CreatorsBoolExp>;
  creatorId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type CreatorInterestCategoriesMaxFields = {
  __typename?: 'CreatorInterestCategoriesMaxFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  creatorId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesMaxOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorInterestCategoriesMinFields = {
  __typename?: 'CreatorInterestCategoriesMinFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  creatorId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesMinOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "creator.interest_categories". */
export type CreatorInterestCategoriesOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  creator?: InputMaybe<CreatorsOrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** select columns of table "creator.interest_categories" */
export enum CreatorInterestCategoriesSelectColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatorId = 'creatorId'
}

/** aggregate stddev on columns */
export type CreatorInterestCategoriesStddevFields = {
  __typename?: 'CreatorInterestCategoriesStddevFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesStddevOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CreatorInterestCategoriesStddevPopFields = {
  __typename?: 'CreatorInterestCategoriesStddevPopFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesStddevPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CreatorInterestCategoriesStddevSampFields = {
  __typename?: 'CreatorInterestCategoriesStddevSampFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesStddevSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "creator_interest_categories" */
export type CreatorInterestCategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CreatorInterestCategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CreatorInterestCategoriesStreamCursorValueInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type CreatorInterestCategoriesSumFields = {
  __typename?: 'CreatorInterestCategoriesSumFields';
  categoryId?: Maybe<Scalars['Int']['output']>;
  creatorId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesSumOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CreatorInterestCategoriesVarPopFields = {
  __typename?: 'CreatorInterestCategoriesVarPopFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesVarPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CreatorInterestCategoriesVarSampFields = {
  __typename?: 'CreatorInterestCategoriesVarSampFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesVarSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CreatorInterestCategoriesVarianceFields = {
  __typename?: 'CreatorInterestCategoriesVarianceFields';
  categoryId?: Maybe<Scalars['Float']['output']>;
  creatorId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "creator.interest_categories" */
export type CreatorInterestCategoriesVarianceOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  creatorId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "creator.jobs" */
export type CreatorJobs = {
  __typename?: 'CreatorJobs';
  /** An array relationship */
  creators: Array<Creators>;
  /** An aggregate relationship */
  creatorsAggregate: CreatorsAggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "creator.jobs" */
export type CreatorJobsCreatorsArgs = {
  distinctOn?: InputMaybe<Array<CreatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorsOrderBy>>;
  where?: InputMaybe<CreatorsBoolExp>;
};


/** columns and relationships of "creator.jobs" */
export type CreatorJobsCreatorsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorsOrderBy>>;
  where?: InputMaybe<CreatorsBoolExp>;
};

/** aggregated selection of "creator.jobs" */
export type CreatorJobsAggregate = {
  __typename?: 'CreatorJobsAggregate';
  aggregate?: Maybe<CreatorJobsAggregateFields>;
  nodes: Array<CreatorJobs>;
};

/** aggregate fields of "creator.jobs" */
export type CreatorJobsAggregateFields = {
  __typename?: 'CreatorJobsAggregateFields';
  avg?: Maybe<CreatorJobsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorJobsMaxFields>;
  min?: Maybe<CreatorJobsMinFields>;
  stddev?: Maybe<CreatorJobsStddevFields>;
  stddevPop?: Maybe<CreatorJobsStddevPopFields>;
  stddevSamp?: Maybe<CreatorJobsStddevSampFields>;
  sum?: Maybe<CreatorJobsSumFields>;
  varPop?: Maybe<CreatorJobsVarPopFields>;
  varSamp?: Maybe<CreatorJobsVarSampFields>;
  variance?: Maybe<CreatorJobsVarianceFields>;
};


/** aggregate fields of "creator.jobs" */
export type CreatorJobsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorJobsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CreatorJobsAvgFields = {
  __typename?: 'CreatorJobsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "creator.jobs". All fields are combined with a logical 'AND'. */
export type CreatorJobsBoolExp = {
  _and?: InputMaybe<Array<CreatorJobsBoolExp>>;
  _not?: InputMaybe<CreatorJobsBoolExp>;
  _or?: InputMaybe<Array<CreatorJobsBoolExp>>;
  creators?: InputMaybe<CreatorsBoolExp>;
  creatorsAggregate?: InputMaybe<CreatorsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type CreatorJobsMaxFields = {
  __typename?: 'CreatorJobsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CreatorJobsMinFields = {
  __typename?: 'CreatorJobsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "creator.jobs". */
export type CreatorJobsOrderBy = {
  creatorsAggregate?: InputMaybe<CreatorsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "creator.jobs" */
export enum CreatorJobsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type CreatorJobsStddevFields = {
  __typename?: 'CreatorJobsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CreatorJobsStddevPopFields = {
  __typename?: 'CreatorJobsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CreatorJobsStddevSampFields = {
  __typename?: 'CreatorJobsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "creator_jobs" */
export type CreatorJobsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CreatorJobsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CreatorJobsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CreatorJobsSumFields = {
  __typename?: 'CreatorJobsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CreatorJobsVarPopFields = {
  __typename?: 'CreatorJobsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CreatorJobsVarSampFields = {
  __typename?: 'CreatorJobsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CreatorJobsVarianceFields = {
  __typename?: 'CreatorJobsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "creator.notifications" */
export type CreatorNotifications = {
  __typename?: 'CreatorNotifications';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "creator.notifications" */
export type CreatorNotificationsAggregate = {
  __typename?: 'CreatorNotificationsAggregate';
  aggregate?: Maybe<CreatorNotificationsAggregateFields>;
  nodes: Array<CreatorNotifications>;
};

/** aggregate fields of "creator.notifications" */
export type CreatorNotificationsAggregateFields = {
  __typename?: 'CreatorNotificationsAggregateFields';
  avg?: Maybe<CreatorNotificationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorNotificationsMaxFields>;
  min?: Maybe<CreatorNotificationsMinFields>;
  stddev?: Maybe<CreatorNotificationsStddevFields>;
  stddevPop?: Maybe<CreatorNotificationsStddevPopFields>;
  stddevSamp?: Maybe<CreatorNotificationsStddevSampFields>;
  sum?: Maybe<CreatorNotificationsSumFields>;
  varPop?: Maybe<CreatorNotificationsVarPopFields>;
  varSamp?: Maybe<CreatorNotificationsVarSampFields>;
  variance?: Maybe<CreatorNotificationsVarianceFields>;
};


/** aggregate fields of "creator.notifications" */
export type CreatorNotificationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorNotificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CreatorNotificationsAvgFields = {
  __typename?: 'CreatorNotificationsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "creator.notifications". All fields are combined with a logical 'AND'. */
export type CreatorNotificationsBoolExp = {
  _and?: InputMaybe<Array<CreatorNotificationsBoolExp>>;
  _not?: InputMaybe<CreatorNotificationsBoolExp>;
  _or?: InputMaybe<Array<CreatorNotificationsBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type CreatorNotificationsMaxFields = {
  __typename?: 'CreatorNotificationsMaxFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type CreatorNotificationsMinFields = {
  __typename?: 'CreatorNotificationsMinFields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "creator.notifications". */
export type CreatorNotificationsOrderBy = {
  body?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "creator.notifications" */
export enum CreatorNotificationsSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type CreatorNotificationsStddevFields = {
  __typename?: 'CreatorNotificationsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CreatorNotificationsStddevPopFields = {
  __typename?: 'CreatorNotificationsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CreatorNotificationsStddevSampFields = {
  __typename?: 'CreatorNotificationsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "creator_notifications" */
export type CreatorNotificationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CreatorNotificationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CreatorNotificationsStreamCursorValueInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type CreatorNotificationsSumFields = {
  __typename?: 'CreatorNotificationsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type CreatorNotificationsVarPopFields = {
  __typename?: 'CreatorNotificationsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CreatorNotificationsVarSampFields = {
  __typename?: 'CreatorNotificationsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CreatorNotificationsVarianceFields = {
  __typename?: 'CreatorNotificationsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "creator.post_likes" */
export type CreatorPostLikes = {
  __typename?: 'CreatorPostLikes';
  /** An object relationship */
  creator?: Maybe<Creators>;
  likedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  post: CreatorPosts;
};

/** aggregated selection of "creator.post_likes" */
export type CreatorPostLikesAggregate = {
  __typename?: 'CreatorPostLikesAggregate';
  aggregate?: Maybe<CreatorPostLikesAggregateFields>;
  nodes: Array<CreatorPostLikes>;
};

export type CreatorPostLikesAggregateBoolExp = {
  count?: InputMaybe<CreatorPostLikesAggregateBoolExpCount>;
};

/** aggregate fields of "creator.post_likes" */
export type CreatorPostLikesAggregateFields = {
  __typename?: 'CreatorPostLikesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorPostLikesMaxFields>;
  min?: Maybe<CreatorPostLikesMinFields>;
};


/** aggregate fields of "creator.post_likes" */
export type CreatorPostLikesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorPostLikesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "creator.post_likes" */
export type CreatorPostLikesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorPostLikesMaxOrderBy>;
  min?: InputMaybe<CreatorPostLikesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "creator.post_likes". All fields are combined with a logical 'AND'. */
export type CreatorPostLikesBoolExp = {
  _and?: InputMaybe<Array<CreatorPostLikesBoolExp>>;
  _not?: InputMaybe<CreatorPostLikesBoolExp>;
  _or?: InputMaybe<Array<CreatorPostLikesBoolExp>>;
  creator?: InputMaybe<CreatorsBoolExp>;
  likedAt?: InputMaybe<TimestampComparisonExp>;
  post?: InputMaybe<CreatorPostsBoolExp>;
};

/** aggregate max on columns */
export type CreatorPostLikesMaxFields = {
  __typename?: 'CreatorPostLikesMaxFields';
  likedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "creator.post_likes" */
export type CreatorPostLikesMaxOrderBy = {
  likedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorPostLikesMinFields = {
  __typename?: 'CreatorPostLikesMinFields';
  likedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "creator.post_likes" */
export type CreatorPostLikesMinOrderBy = {
  likedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "creator.post_likes". */
export type CreatorPostLikesOrderBy = {
  creator?: InputMaybe<CreatorsOrderBy>;
  likedAt?: InputMaybe<OrderBy>;
  post?: InputMaybe<CreatorPostsOrderBy>;
};

/** select columns of table "creator.post_likes" */
export enum CreatorPostLikesSelectColumn {
  /** column name */
  LikedAt = 'likedAt'
}

/** columns and relationships of "creator.posts" */
export type CreatorPosts = {
  __typename?: 'CreatorPosts';
  /** An array relationship */
  childPosts: Array<CreatorPosts>;
  /** An aggregate relationship */
  childPostsAggregate: CreatorPostsAggregate;
  /** An object relationship */
  creator?: Maybe<Creators>;
  creatorId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  image?: Maybe<Files>;
  imageFileId?: Maybe<Scalars['Int']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  /** A computed field, executes function "creator.post_is_liked" */
  isLiked?: Maybe<Scalars['Boolean']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  likeTotal: Scalars['Int']['output'];
  /** An array relationship */
  likes: Array<CreatorPostLikes>;
  /** An aggregate relationship */
  likesAggregate: CreatorPostLikesAggregate;
  /** An object relationship */
  parentPost?: Maybe<CreatorPosts>;
  parentPostId?: Maybe<Scalars['Int']['output']>;
  publishedAt: Scalars['timestamp']['output'];
  text?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "creator.posts" */
export type CreatorPostsChildPostsArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


/** columns and relationships of "creator.posts" */
export type CreatorPostsChildPostsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


/** columns and relationships of "creator.posts" */
export type CreatorPostsLikesArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostLikesOrderBy>>;
  where?: InputMaybe<CreatorPostLikesBoolExp>;
};


/** columns and relationships of "creator.posts" */
export type CreatorPostsLikesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostLikesOrderBy>>;
  where?: InputMaybe<CreatorPostLikesBoolExp>;
};

/** aggregated selection of "creator.posts" */
export type CreatorPostsAggregate = {
  __typename?: 'CreatorPostsAggregate';
  aggregate?: Maybe<CreatorPostsAggregateFields>;
  nodes: Array<CreatorPosts>;
};

export type CreatorPostsAggregateBoolExp = {
  bool_and?: InputMaybe<CreatorPostsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CreatorPostsAggregateBoolExpBool_Or>;
  count?: InputMaybe<CreatorPostsAggregateBoolExpCount>;
};

/** aggregate fields of "creator.posts" */
export type CreatorPostsAggregateFields = {
  __typename?: 'CreatorPostsAggregateFields';
  avg?: Maybe<CreatorPostsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorPostsMaxFields>;
  min?: Maybe<CreatorPostsMinFields>;
  stddev?: Maybe<CreatorPostsStddevFields>;
  stddevPop?: Maybe<CreatorPostsStddevPopFields>;
  stddevSamp?: Maybe<CreatorPostsStddevSampFields>;
  sum?: Maybe<CreatorPostsSumFields>;
  varPop?: Maybe<CreatorPostsVarPopFields>;
  varSamp?: Maybe<CreatorPostsVarSampFields>;
  variance?: Maybe<CreatorPostsVarianceFields>;
};


/** aggregate fields of "creator.posts" */
export type CreatorPostsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "creator.posts" */
export type CreatorPostsAggregateOrderBy = {
  avg?: InputMaybe<CreatorPostsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorPostsMaxOrderBy>;
  min?: InputMaybe<CreatorPostsMinOrderBy>;
  stddev?: InputMaybe<CreatorPostsStddevOrderBy>;
  stddevPop?: InputMaybe<CreatorPostsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CreatorPostsStddevSampOrderBy>;
  sum?: InputMaybe<CreatorPostsSumOrderBy>;
  varPop?: InputMaybe<CreatorPostsVarPopOrderBy>;
  varSamp?: InputMaybe<CreatorPostsVarSampOrderBy>;
  variance?: InputMaybe<CreatorPostsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CreatorPostsAvgFields = {
  __typename?: 'CreatorPostsAvgFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "creator.posts" */
export type CreatorPostsAvgOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "creator.posts". All fields are combined with a logical 'AND'. */
export type CreatorPostsBoolExp = {
  _and?: InputMaybe<Array<CreatorPostsBoolExp>>;
  _not?: InputMaybe<CreatorPostsBoolExp>;
  _or?: InputMaybe<Array<CreatorPostsBoolExp>>;
  childPosts?: InputMaybe<CreatorPostsBoolExp>;
  childPostsAggregate?: InputMaybe<CreatorPostsAggregateBoolExp>;
  creator?: InputMaybe<CreatorsBoolExp>;
  creatorId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  image?: InputMaybe<FilesBoolExp>;
  imageFileId?: InputMaybe<IntComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  isLiked?: InputMaybe<BooleanComparisonExp>;
  isPrivate?: InputMaybe<BooleanComparisonExp>;
  likeTotal?: InputMaybe<IntComparisonExp>;
  likes?: InputMaybe<CreatorPostLikesBoolExp>;
  likesAggregate?: InputMaybe<CreatorPostLikesAggregateBoolExp>;
  parentPost?: InputMaybe<CreatorPostsBoolExp>;
  parentPostId?: InputMaybe<IntComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type CreatorPostsMaxFields = {
  __typename?: 'CreatorPostsMaxFields';
  creatorId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageFileId?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  parentPostId?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "creator.posts" */
export type CreatorPostsMaxOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorPostsMinFields = {
  __typename?: 'CreatorPostsMinFields';
  creatorId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageFileId?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  parentPostId?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "creator.posts" */
export type CreatorPostsMinOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "creator.posts". */
export type CreatorPostsOrderBy = {
  childPostsAggregate?: InputMaybe<CreatorPostsAggregateOrderBy>;
  creator?: InputMaybe<CreatorsOrderBy>;
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<FilesOrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  isLiked?: InputMaybe<OrderBy>;
  isPrivate?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  likesAggregate?: InputMaybe<CreatorPostLikesAggregateOrderBy>;
  parentPost?: InputMaybe<CreatorPostsOrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** select columns of table "creator.posts" */
export enum CreatorPostsSelectColumn {
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageFileId = 'imageFileId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsPrivate = 'isPrivate',
  /** column name */
  LikeTotal = 'likeTotal',
  /** column name */
  ParentPostId = 'parentPostId',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Text = 'text'
}

/** select "creatorPostsAggregateBoolExpBool_andArgumentsColumns" columns of table "creator.posts" */
export enum CreatorPostsSelectColumnCreatorPostsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsPrivate = 'isPrivate'
}

/** select "creatorPostsAggregateBoolExpBool_orArgumentsColumns" columns of table "creator.posts" */
export enum CreatorPostsSelectColumnCreatorPostsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsPrivate = 'isPrivate'
}

/** aggregate stddev on columns */
export type CreatorPostsStddevFields = {
  __typename?: 'CreatorPostsStddevFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "creator.posts" */
export type CreatorPostsStddevOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CreatorPostsStddevPopFields = {
  __typename?: 'CreatorPostsStddevPopFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "creator.posts" */
export type CreatorPostsStddevPopOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CreatorPostsStddevSampFields = {
  __typename?: 'CreatorPostsStddevSampFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "creator.posts" */
export type CreatorPostsStddevSampOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "creator_posts" */
export type CreatorPostsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CreatorPostsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CreatorPostsStreamCursorValueInput = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imageFileId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  likeTotal?: InputMaybe<Scalars['Int']['input']>;
  parentPostId?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CreatorPostsSumFields = {
  __typename?: 'CreatorPostsSumFields';
  creatorId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageFileId?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  parentPostId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "creator.posts" */
export type CreatorPostsSumOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CreatorPostsVarPopFields = {
  __typename?: 'CreatorPostsVarPopFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "creator.posts" */
export type CreatorPostsVarPopOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CreatorPostsVarSampFields = {
  __typename?: 'CreatorPostsVarSampFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "creator.posts" */
export type CreatorPostsVarSampOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CreatorPostsVarianceFields = {
  __typename?: 'CreatorPostsVarianceFields';
  creatorId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  imageFileId?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  parentPostId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "creator.posts" */
export type CreatorPostsVarianceOrderBy = {
  creatorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileId?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  parentPostId?: InputMaybe<OrderBy>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export type Customer = {
  __typename?: 'Customer';
  digitalMagazineFeatures: DigitalMagazines;
  digitalMagazines: DigitalMagazines;
  id: Scalars['String']['output'];
  orders: Orders;
  portal: CustomerPortal;
  shipping?: Maybe<CustomerShipping>;
};


export type CustomerDigitalMagazineFeaturesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerDigitalMagazinesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPortal = {
  __typename?: 'CustomerPortal';
  url: Scalars['String']['output'];
};

export type CustomerShipping = {
  __typename?: 'CustomerShipping';
  address?: Maybe<Address>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

export type DigitalMagazine = {
  __typename?: 'DigitalMagazine';
  file: DigitalMagazineFile;
  id: Scalars['Int']['output'];
  releasedAt: Scalars['Date']['output'];
  thumbnail: DigitalMagazineThumbnail;
  title: Scalars['String']['output'];
  volumeName: Scalars['String']['output'];
};

export type DigitalMagazineFile = {
  __typename?: 'DigitalMagazineFile';
  url: Scalars['String']['output'];
};

export type DigitalMagazineThumbnail = {
  __typename?: 'DigitalMagazineThumbnail';
  url: Scalars['String']['output'];
};

export type DigitalMagazines = {
  __typename?: 'DigitalMagazines';
  data: Array<DigitalMagazine>;
  hasMore: Scalars['Boolean']['output'];
};

/** columns and relationships of "entry.agreements" */
export type EntryAgreements = {
  __typename?: 'EntryAgreements';
  /** An array relationship */
  forms: Array<EntryForms>;
  /** An aggregate relationship */
  formsAggregate: EntryFormsAggregate;
  id: Scalars['Int']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  terms: Scalars['String']['output'];
};


/** columns and relationships of "entry.agreements" */
export type EntryAgreementsFormsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


/** columns and relationships of "entry.agreements" */
export type EntryAgreementsFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.agreements". All fields are combined with a logical 'AND'. */
export type EntryAgreementsBoolExp = {
  _and?: InputMaybe<Array<EntryAgreementsBoolExp>>;
  _not?: InputMaybe<EntryAgreementsBoolExp>;
  _or?: InputMaybe<Array<EntryAgreementsBoolExp>>;
  forms?: InputMaybe<EntryFormsBoolExp>;
  formsAggregate?: InputMaybe<EntryFormsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  terms?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.agreements". */
export type EntryAgreementsOrderBy = {
  formsAggregate?: InputMaybe<EntryFormsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  terms?: InputMaybe<OrderBy>;
};

export type EntryContestInputForm = {
  id: Scalars['Int']['input'];
};

export type EntryContestInputWork = {
  values: Array<EntryFormValue>;
};

export type EntryContestOutput = {
  __typename?: 'EntryContestOutput';
  entryId: Scalars['Int']['output'];
};

/** columns and relationships of "entry.custom_fields" */
export type EntryCustomFields = {
  __typename?: 'EntryCustomFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

/** aggregated selection of "entry.custom_fields" */
export type EntryCustomFieldsAggregate = {
  __typename?: 'EntryCustomFieldsAggregate';
  aggregate?: Maybe<EntryCustomFieldsAggregateFields>;
  nodes: Array<EntryCustomFields>;
};

export type EntryCustomFieldsAggregateBoolExp = {
  count?: InputMaybe<EntryCustomFieldsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.custom_fields" */
export type EntryCustomFieldsAggregateFields = {
  __typename?: 'EntryCustomFieldsAggregateFields';
  avg?: Maybe<EntryCustomFieldsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryCustomFieldsMaxFields>;
  min?: Maybe<EntryCustomFieldsMinFields>;
  stddev?: Maybe<EntryCustomFieldsStddevFields>;
  stddevPop?: Maybe<EntryCustomFieldsStddevPopFields>;
  stddevSamp?: Maybe<EntryCustomFieldsStddevSampFields>;
  sum?: Maybe<EntryCustomFieldsSumFields>;
  varPop?: Maybe<EntryCustomFieldsVarPopFields>;
  varSamp?: Maybe<EntryCustomFieldsVarSampFields>;
  variance?: Maybe<EntryCustomFieldsVarianceFields>;
};


/** aggregate fields of "entry.custom_fields" */
export type EntryCustomFieldsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryCustomFieldsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.custom_fields" */
export type EntryCustomFieldsAggregateOrderBy = {
  avg?: InputMaybe<EntryCustomFieldsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryCustomFieldsMaxOrderBy>;
  min?: InputMaybe<EntryCustomFieldsMinOrderBy>;
  stddev?: InputMaybe<EntryCustomFieldsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryCustomFieldsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryCustomFieldsStddevSampOrderBy>;
  sum?: InputMaybe<EntryCustomFieldsSumOrderBy>;
  varPop?: InputMaybe<EntryCustomFieldsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryCustomFieldsVarSampOrderBy>;
  variance?: InputMaybe<EntryCustomFieldsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryCustomFieldsAvgFields = {
  __typename?: 'EntryCustomFieldsAvgFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsAvgOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.custom_fields". All fields are combined with a logical 'AND'. */
export type EntryCustomFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryCustomFieldsBoolExp>>;
  _not?: InputMaybe<EntryCustomFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryCustomFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type EntryCustomFieldsMaxFields = {
  __typename?: 'EntryCustomFieldsMaxFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsMaxOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryCustomFieldsMinFields = {
  __typename?: 'EntryCustomFieldsMinFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsMinOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.custom_fields". */
export type EntryCustomFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.custom_fields" */
export enum EntryCustomFieldsSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  FormId = 'formId',
  /** column name */
  SortOrder = 'sortOrder'
}

/** aggregate stddev on columns */
export type EntryCustomFieldsStddevFields = {
  __typename?: 'EntryCustomFieldsStddevFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsStddevOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryCustomFieldsStddevPopFields = {
  __typename?: 'EntryCustomFieldsStddevPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsStddevPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryCustomFieldsStddevSampFields = {
  __typename?: 'EntryCustomFieldsStddevSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsStddevSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryCustomFieldsSumFields = {
  __typename?: 'EntryCustomFieldsSumFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsSumOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryCustomFieldsVarPopFields = {
  __typename?: 'EntryCustomFieldsVarPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsVarPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryCustomFieldsVarSampFields = {
  __typename?: 'EntryCustomFieldsVarSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsVarSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryCustomFieldsVarianceFields = {
  __typename?: 'EntryCustomFieldsVarianceFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.custom_fields" */
export type EntryCustomFieldsVarianceOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptions = {
  __typename?: 'EntryFormCheckboxFieldOptions';
  /** An object relationship */
  cascade?: Maybe<EntryFormFieldCascades>;
  cascadeId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  checkbox: EntryFormCheckboxFields;
  /** An object relationship */
  entryFeeCascade?: Maybe<EntryFormFieldEntryFeeCascades>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsAggregate = {
  __typename?: 'EntryFormCheckboxFieldOptionsAggregate';
  aggregate?: Maybe<EntryFormCheckboxFieldOptionsAggregateFields>;
  nodes: Array<EntryFormCheckboxFieldOptions>;
};

export type EntryFormCheckboxFieldOptionsAggregateBoolExp = {
  count?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsAggregateFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsAggregateFields';
  avg?: Maybe<EntryFormCheckboxFieldOptionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormCheckboxFieldOptionsMaxFields>;
  min?: Maybe<EntryFormCheckboxFieldOptionsMinFields>;
  stddev?: Maybe<EntryFormCheckboxFieldOptionsStddevFields>;
  stddevPop?: Maybe<EntryFormCheckboxFieldOptionsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormCheckboxFieldOptionsStddevSampFields>;
  sum?: Maybe<EntryFormCheckboxFieldOptionsSumFields>;
  varPop?: Maybe<EntryFormCheckboxFieldOptionsVarPopFields>;
  varSamp?: Maybe<EntryFormCheckboxFieldOptionsVarSampFields>;
  variance?: Maybe<EntryFormCheckboxFieldOptionsVarianceFields>;
};


/** aggregate fields of "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormCheckboxFieldOptionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormCheckboxFieldOptionsMaxOrderBy>;
  min?: InputMaybe<EntryFormCheckboxFieldOptionsMinOrderBy>;
  stddev?: InputMaybe<EntryFormCheckboxFieldOptionsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormCheckboxFieldOptionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormCheckboxFieldOptionsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormCheckboxFieldOptionsSumOrderBy>;
  varPop?: InputMaybe<EntryFormCheckboxFieldOptionsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormCheckboxFieldOptionsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormCheckboxFieldOptionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormCheckboxFieldOptionsAvgFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsAvgFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsAvgOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_checkbox_field_options". All fields are combined with a logical 'AND'. */
export type EntryFormCheckboxFieldOptionsBoolExp = {
  _and?: InputMaybe<Array<EntryFormCheckboxFieldOptionsBoolExp>>;
  _not?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
  _or?: InputMaybe<Array<EntryFormCheckboxFieldOptionsBoolExp>>;
  cascade?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  cascadeId?: InputMaybe<IntComparisonExp>;
  checkbox?: InputMaybe<EntryFormCheckboxFieldsBoolExp>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  entryFeeCascadeId?: InputMaybe<IntComparisonExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type EntryFormCheckboxFieldOptionsMaxFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsMaxFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsMaxOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormCheckboxFieldOptionsMinFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsMinFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsMinOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_checkbox_field_options". */
export type EntryFormCheckboxFieldOptionsOrderBy = {
  cascade?: InputMaybe<EntryFormFieldCascadesOrderBy>;
  cascadeId?: InputMaybe<OrderBy>;
  checkbox?: InputMaybe<EntryFormCheckboxFieldsOrderBy>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesOrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_checkbox_field_options" */
export enum EntryFormCheckboxFieldOptionsSelectColumn {
  /** column name */
  CascadeId = 'cascadeId',
  /** column name */
  EntryFeeCascadeId = 'entryFeeCascadeId',
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type EntryFormCheckboxFieldOptionsStddevFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsStddevFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsStddevOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormCheckboxFieldOptionsStddevPopFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsStddevPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsStddevPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormCheckboxFieldOptionsStddevSampFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsStddevSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsStddevSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormCheckboxFieldOptionsSumFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsSumFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsSumOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormCheckboxFieldOptionsVarPopFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsVarPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsVarPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormCheckboxFieldOptionsVarSampFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsVarSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsVarSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormCheckboxFieldOptionsVarianceFields = {
  __typename?: 'EntryFormCheckboxFieldOptionsVarianceFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_checkbox_field_options" */
export type EntryFormCheckboxFieldOptionsVarianceOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_checkbox_fields" */
export type EntryFormCheckboxFields = {
  __typename?: 'EntryFormCheckboxFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An array relationship */
  options: Array<EntryFormCheckboxFieldOptions>;
  /** An aggregate relationship */
  optionsAggregate: EntryFormCheckboxFieldOptionsAggregate;
};


/** columns and relationships of "entry.form_checkbox_fields" */
export type EntryFormCheckboxFieldsOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_checkbox_fields" */
export type EntryFormCheckboxFieldsOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.form_checkbox_fields". All fields are combined with a logical 'AND'. */
export type EntryFormCheckboxFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormCheckboxFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormCheckboxFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormCheckboxFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  options?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
  optionsAggregate?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateBoolExp>;
};

/** Ordering options when selecting data from "entry.form_checkbox_fields". */
export type EntryFormCheckboxFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  optionsAggregate?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateOrderBy>;
};

export type EntryFormCheckboxValue = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_checkbox_values" */
export type EntryFormCheckboxValues = {
  __typename?: 'EntryFormCheckboxValues';
  id: Scalars['Int']['output'];
  /** An object relationship */
  parent: EntryFormValues;
  value: Scalars['String']['output'];
  valueId: Scalars['Int']['output'];
};

/** aggregated selection of "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesAggregate = {
  __typename?: 'EntryFormCheckboxValuesAggregate';
  aggregate?: Maybe<EntryFormCheckboxValuesAggregateFields>;
  nodes: Array<EntryFormCheckboxValues>;
};

export type EntryFormCheckboxValuesAggregateBoolExp = {
  count?: InputMaybe<EntryFormCheckboxValuesAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesAggregateFields = {
  __typename?: 'EntryFormCheckboxValuesAggregateFields';
  avg?: Maybe<EntryFormCheckboxValuesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormCheckboxValuesMaxFields>;
  min?: Maybe<EntryFormCheckboxValuesMinFields>;
  stddev?: Maybe<EntryFormCheckboxValuesStddevFields>;
  stddevPop?: Maybe<EntryFormCheckboxValuesStddevPopFields>;
  stddevSamp?: Maybe<EntryFormCheckboxValuesStddevSampFields>;
  sum?: Maybe<EntryFormCheckboxValuesSumFields>;
  varPop?: Maybe<EntryFormCheckboxValuesVarPopFields>;
  varSamp?: Maybe<EntryFormCheckboxValuesVarSampFields>;
  variance?: Maybe<EntryFormCheckboxValuesVarianceFields>;
};


/** aggregate fields of "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormCheckboxValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesAggregateOrderBy = {
  avg?: InputMaybe<EntryFormCheckboxValuesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormCheckboxValuesMaxOrderBy>;
  min?: InputMaybe<EntryFormCheckboxValuesMinOrderBy>;
  stddev?: InputMaybe<EntryFormCheckboxValuesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormCheckboxValuesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormCheckboxValuesStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormCheckboxValuesSumOrderBy>;
  varPop?: InputMaybe<EntryFormCheckboxValuesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormCheckboxValuesVarSampOrderBy>;
  variance?: InputMaybe<EntryFormCheckboxValuesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormCheckboxValuesAvgFields = {
  __typename?: 'EntryFormCheckboxValuesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_checkbox_values". All fields are combined with a logical 'AND'. */
export type EntryFormCheckboxValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormCheckboxValuesBoolExp>>;
  _not?: InputMaybe<EntryFormCheckboxValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormCheckboxValuesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type EntryFormCheckboxValuesMaxFields = {
  __typename?: 'EntryFormCheckboxValuesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormCheckboxValuesMinFields = {
  __typename?: 'EntryFormCheckboxValuesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_checkbox_values". */
export type EntryFormCheckboxValuesOrderBy = {
  id?: InputMaybe<OrderBy>;
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_checkbox_values" */
export enum EntryFormCheckboxValuesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
  /** column name */
  ValueId = 'valueId'
}

/** aggregate stddev on columns */
export type EntryFormCheckboxValuesStddevFields = {
  __typename?: 'EntryFormCheckboxValuesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormCheckboxValuesStddevPopFields = {
  __typename?: 'EntryFormCheckboxValuesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormCheckboxValuesStddevSampFields = {
  __typename?: 'EntryFormCheckboxValuesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormCheckboxValuesSumFields = {
  __typename?: 'EntryFormCheckboxValuesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
  valueId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormCheckboxValuesVarPopFields = {
  __typename?: 'EntryFormCheckboxValuesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormCheckboxValuesVarSampFields = {
  __typename?: 'EntryFormCheckboxValuesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormCheckboxValuesVarianceFields = {
  __typename?: 'EntryFormCheckboxValuesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  valueId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_checkbox_values" */
export type EntryFormCheckboxValuesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascades = {
  __typename?: 'EntryFormFieldCascades';
  /** An array relationship */
  checkboxOptions: Array<EntryFormCheckboxFieldOptions>;
  /** An aggregate relationship */
  checkboxOptionsAggregate: EntryFormCheckboxFieldOptionsAggregate;
  conditionFieldLabel: Scalars['String']['output'];
  conditionGroupFieldLabel?: Maybe<Scalars['String']['output']>;
  conditionValue: Scalars['String']['output'];
  /** An object relationship */
  form: EntryForms;
  /** An array relationship */
  formFields: Array<EntryFormFields>;
  /** An aggregate relationship */
  formFieldsAggregate: EntryFormFieldsAggregate;
  formId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  radioOptions: Array<EntryFormRadioFieldOptions>;
  /** An aggregate relationship */
  radioOptionsAggregate: EntryFormRadioFieldOptionsAggregate;
  /** An array relationship */
  selectboxOptions: Array<EntryFormSelectboxFieldOptions>;
  /** An aggregate relationship */
  selectboxOptionsAggregate: EntryFormSelectboxFieldOptionsAggregate;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesCheckboxOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesCheckboxOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesFormFieldsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldsOrderBy>>;
  where?: InputMaybe<EntryFormFieldsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesFormFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldsOrderBy>>;
  where?: InputMaybe<EntryFormFieldsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesRadioOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormRadioFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesRadioOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormRadioFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesSelectboxOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormSelectboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_cascades" */
export type EntryFormFieldCascadesSelectboxOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormSelectboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
};

/** aggregated selection of "entry.form_field_cascades" */
export type EntryFormFieldCascadesAggregate = {
  __typename?: 'EntryFormFieldCascadesAggregate';
  aggregate?: Maybe<EntryFormFieldCascadesAggregateFields>;
  nodes: Array<EntryFormFieldCascades>;
};

export type EntryFormFieldCascadesAggregateBoolExp = {
  count?: InputMaybe<EntryFormFieldCascadesAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_field_cascades" */
export type EntryFormFieldCascadesAggregateFields = {
  __typename?: 'EntryFormFieldCascadesAggregateFields';
  avg?: Maybe<EntryFormFieldCascadesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormFieldCascadesMaxFields>;
  min?: Maybe<EntryFormFieldCascadesMinFields>;
  stddev?: Maybe<EntryFormFieldCascadesStddevFields>;
  stddevPop?: Maybe<EntryFormFieldCascadesStddevPopFields>;
  stddevSamp?: Maybe<EntryFormFieldCascadesStddevSampFields>;
  sum?: Maybe<EntryFormFieldCascadesSumFields>;
  varPop?: Maybe<EntryFormFieldCascadesVarPopFields>;
  varSamp?: Maybe<EntryFormFieldCascadesVarSampFields>;
  variance?: Maybe<EntryFormFieldCascadesVarianceFields>;
};


/** aggregate fields of "entry.form_field_cascades" */
export type EntryFormFieldCascadesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormFieldCascadesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesAggregateOrderBy = {
  avg?: InputMaybe<EntryFormFieldCascadesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormFieldCascadesMaxOrderBy>;
  min?: InputMaybe<EntryFormFieldCascadesMinOrderBy>;
  stddev?: InputMaybe<EntryFormFieldCascadesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormFieldCascadesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormFieldCascadesStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormFieldCascadesSumOrderBy>;
  varPop?: InputMaybe<EntryFormFieldCascadesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormFieldCascadesVarSampOrderBy>;
  variance?: InputMaybe<EntryFormFieldCascadesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormFieldCascadesAvgFields = {
  __typename?: 'EntryFormFieldCascadesAvgFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesAvgOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_field_cascades". All fields are combined with a logical 'AND'. */
export type EntryFormFieldCascadesBoolExp = {
  _and?: InputMaybe<Array<EntryFormFieldCascadesBoolExp>>;
  _not?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  _or?: InputMaybe<Array<EntryFormFieldCascadesBoolExp>>;
  checkboxOptions?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
  checkboxOptionsAggregate?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateBoolExp>;
  conditionFieldLabel?: InputMaybe<StringComparisonExp>;
  conditionGroupFieldLabel?: InputMaybe<StringComparisonExp>;
  conditionValue?: InputMaybe<StringComparisonExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formFields?: InputMaybe<EntryFormFieldsBoolExp>;
  formFieldsAggregate?: InputMaybe<EntryFormFieldsAggregateBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  radioOptions?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
  radioOptionsAggregate?: InputMaybe<EntryFormRadioFieldOptionsAggregateBoolExp>;
  selectboxOptions?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
  selectboxOptionsAggregate?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateBoolExp>;
};

/** aggregate max on columns */
export type EntryFormFieldCascadesMaxFields = {
  __typename?: 'EntryFormFieldCascadesMaxFields';
  conditionFieldLabel?: Maybe<Scalars['String']['output']>;
  conditionGroupFieldLabel?: Maybe<Scalars['String']['output']>;
  conditionValue?: Maybe<Scalars['String']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesMaxOrderBy = {
  conditionFieldLabel?: InputMaybe<OrderBy>;
  conditionGroupFieldLabel?: InputMaybe<OrderBy>;
  conditionValue?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormFieldCascadesMinFields = {
  __typename?: 'EntryFormFieldCascadesMinFields';
  conditionFieldLabel?: Maybe<Scalars['String']['output']>;
  conditionGroupFieldLabel?: Maybe<Scalars['String']['output']>;
  conditionValue?: Maybe<Scalars['String']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesMinOrderBy = {
  conditionFieldLabel?: InputMaybe<OrderBy>;
  conditionGroupFieldLabel?: InputMaybe<OrderBy>;
  conditionValue?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_field_cascades". */
export type EntryFormFieldCascadesOrderBy = {
  checkboxOptionsAggregate?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateOrderBy>;
  conditionFieldLabel?: InputMaybe<OrderBy>;
  conditionGroupFieldLabel?: InputMaybe<OrderBy>;
  conditionValue?: InputMaybe<OrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  formFieldsAggregate?: InputMaybe<EntryFormFieldsAggregateOrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  radioOptionsAggregate?: InputMaybe<EntryFormRadioFieldOptionsAggregateOrderBy>;
  selectboxOptionsAggregate?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateOrderBy>;
};

/** select columns of table "entry.form_field_cascades" */
export enum EntryFormFieldCascadesSelectColumn {
  /** column name */
  ConditionFieldLabel = 'conditionFieldLabel',
  /** column name */
  ConditionGroupFieldLabel = 'conditionGroupFieldLabel',
  /** column name */
  ConditionValue = 'conditionValue',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type EntryFormFieldCascadesStddevFields = {
  __typename?: 'EntryFormFieldCascadesStddevFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesStddevOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormFieldCascadesStddevPopFields = {
  __typename?: 'EntryFormFieldCascadesStddevPopFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesStddevPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormFieldCascadesStddevSampFields = {
  __typename?: 'EntryFormFieldCascadesStddevSampFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesStddevSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormFieldCascadesSumFields = {
  __typename?: 'EntryFormFieldCascadesSumFields';
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesSumOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormFieldCascadesVarPopFields = {
  __typename?: 'EntryFormFieldCascadesVarPopFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesVarPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormFieldCascadesVarSampFields = {
  __typename?: 'EntryFormFieldCascadesVarSampFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesVarSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormFieldCascadesVarianceFields = {
  __typename?: 'EntryFormFieldCascadesVarianceFields';
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_field_cascades" */
export type EntryFormFieldCascadesVarianceOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascades = {
  __typename?: 'EntryFormFieldEntryFeeCascades';
  /** An array relationship */
  checkboxOptions: Array<EntryFormCheckboxFieldOptions>;
  /** An aggregate relationship */
  checkboxOptionsAggregate: EntryFormCheckboxFieldOptionsAggregate;
  conditionPriceId: Scalars['String']['output'];
  conditionQuantityGte: Scalars['Int']['output'];
  /** An object relationship */
  form: EntryForms;
  /** An array relationship */
  formFields: Array<EntryFormFields>;
  /** An aggregate relationship */
  formFieldsAggregate: EntryFormFieldsAggregate;
  formId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  radioOptions: Array<EntryFormRadioFieldOptions>;
  /** An aggregate relationship */
  radioOptionsAggregate: EntryFormRadioFieldOptionsAggregate;
  /** An array relationship */
  selectboxOptions: Array<EntryFormSelectboxFieldOptions>;
  /** An aggregate relationship */
  selectboxOptionsAggregate: EntryFormSelectboxFieldOptionsAggregate;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesCheckboxOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesCheckboxOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesFormFieldsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldsOrderBy>>;
  where?: InputMaybe<EntryFormFieldsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesFormFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldsOrderBy>>;
  where?: InputMaybe<EntryFormFieldsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesRadioOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormRadioFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesRadioOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormRadioFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesSelectboxOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormSelectboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesSelectboxOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormSelectboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
};

/** aggregated selection of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesAggregate = {
  __typename?: 'EntryFormFieldEntryFeeCascadesAggregate';
  aggregate?: Maybe<EntryFormFieldEntryFeeCascadesAggregateFields>;
  nodes: Array<EntryFormFieldEntryFeeCascades>;
};

export type EntryFormFieldEntryFeeCascadesAggregateBoolExp = {
  count?: InputMaybe<EntryFormFieldEntryFeeCascadesAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesAggregateFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesAggregateFields';
  avg?: Maybe<EntryFormFieldEntryFeeCascadesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormFieldEntryFeeCascadesMaxFields>;
  min?: Maybe<EntryFormFieldEntryFeeCascadesMinFields>;
  stddev?: Maybe<EntryFormFieldEntryFeeCascadesStddevFields>;
  stddevPop?: Maybe<EntryFormFieldEntryFeeCascadesStddevPopFields>;
  stddevSamp?: Maybe<EntryFormFieldEntryFeeCascadesStddevSampFields>;
  sum?: Maybe<EntryFormFieldEntryFeeCascadesSumFields>;
  varPop?: Maybe<EntryFormFieldEntryFeeCascadesVarPopFields>;
  varSamp?: Maybe<EntryFormFieldEntryFeeCascadesVarSampFields>;
  variance?: Maybe<EntryFormFieldEntryFeeCascadesVarianceFields>;
};


/** aggregate fields of "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesAggregateOrderBy = {
  avg?: InputMaybe<EntryFormFieldEntryFeeCascadesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormFieldEntryFeeCascadesMaxOrderBy>;
  min?: InputMaybe<EntryFormFieldEntryFeeCascadesMinOrderBy>;
  stddev?: InputMaybe<EntryFormFieldEntryFeeCascadesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormFieldEntryFeeCascadesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormFieldEntryFeeCascadesStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormFieldEntryFeeCascadesSumOrderBy>;
  varPop?: InputMaybe<EntryFormFieldEntryFeeCascadesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormFieldEntryFeeCascadesVarSampOrderBy>;
  variance?: InputMaybe<EntryFormFieldEntryFeeCascadesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormFieldEntryFeeCascadesAvgFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesAvgFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesAvgOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_field_entry_fee_cascades". All fields are combined with a logical 'AND'. */
export type EntryFormFieldEntryFeeCascadesBoolExp = {
  _and?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesBoolExp>>;
  _not?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  _or?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesBoolExp>>;
  checkboxOptions?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
  checkboxOptionsAggregate?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateBoolExp>;
  conditionPriceId?: InputMaybe<StringComparisonExp>;
  conditionQuantityGte?: InputMaybe<IntComparisonExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formFields?: InputMaybe<EntryFormFieldsBoolExp>;
  formFieldsAggregate?: InputMaybe<EntryFormFieldsAggregateBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  radioOptions?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
  radioOptionsAggregate?: InputMaybe<EntryFormRadioFieldOptionsAggregateBoolExp>;
  selectboxOptions?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
  selectboxOptionsAggregate?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateBoolExp>;
};

/** aggregate max on columns */
export type EntryFormFieldEntryFeeCascadesMaxFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesMaxFields';
  conditionPriceId?: Maybe<Scalars['String']['output']>;
  conditionQuantityGte?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesMaxOrderBy = {
  conditionPriceId?: InputMaybe<OrderBy>;
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormFieldEntryFeeCascadesMinFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesMinFields';
  conditionPriceId?: Maybe<Scalars['String']['output']>;
  conditionQuantityGte?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesMinOrderBy = {
  conditionPriceId?: InputMaybe<OrderBy>;
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_field_entry_fee_cascades". */
export type EntryFormFieldEntryFeeCascadesOrderBy = {
  checkboxOptionsAggregate?: InputMaybe<EntryFormCheckboxFieldOptionsAggregateOrderBy>;
  conditionPriceId?: InputMaybe<OrderBy>;
  conditionQuantityGte?: InputMaybe<OrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  formFieldsAggregate?: InputMaybe<EntryFormFieldsAggregateOrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  radioOptionsAggregate?: InputMaybe<EntryFormRadioFieldOptionsAggregateOrderBy>;
  selectboxOptionsAggregate?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateOrderBy>;
};

/** select columns of table "entry.form_field_entry_fee_cascades" */
export enum EntryFormFieldEntryFeeCascadesSelectColumn {
  /** column name */
  ConditionPriceId = 'conditionPriceId',
  /** column name */
  ConditionQuantityGte = 'conditionQuantityGte',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type EntryFormFieldEntryFeeCascadesStddevFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesStddevFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesStddevOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormFieldEntryFeeCascadesStddevPopFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesStddevPopFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesStddevPopOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormFieldEntryFeeCascadesStddevSampFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesStddevSampFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesStddevSampOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormFieldEntryFeeCascadesSumFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesSumFields';
  conditionQuantityGte?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesSumOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormFieldEntryFeeCascadesVarPopFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesVarPopFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesVarPopOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormFieldEntryFeeCascadesVarSampFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesVarSampFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesVarSampOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormFieldEntryFeeCascadesVarianceFields = {
  __typename?: 'EntryFormFieldEntryFeeCascadesVarianceFields';
  conditionQuantityGte?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_field_entry_fee_cascades" */
export type EntryFormFieldEntryFeeCascadesVarianceOrderBy = {
  conditionQuantityGte?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascades = {
  __typename?: 'EntryFormFieldGroupEntryCascades';
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isHidden: Scalars['Boolean']['output'];
};

/** order by aggregate values of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesAggregateOrderBy = {
  avg?: InputMaybe<EntryFormFieldGroupEntryCascadesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormFieldGroupEntryCascadesMaxOrderBy>;
  min?: InputMaybe<EntryFormFieldGroupEntryCascadesMinOrderBy>;
  stddev?: InputMaybe<EntryFormFieldGroupEntryCascadesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormFieldGroupEntryCascadesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormFieldGroupEntryCascadesStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormFieldGroupEntryCascadesSumOrderBy>;
  varPop?: InputMaybe<EntryFormFieldGroupEntryCascadesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormFieldGroupEntryCascadesVarSampOrderBy>;
  variance?: InputMaybe<EntryFormFieldGroupEntryCascadesVarianceOrderBy>;
};

/** order by avg() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesAvgOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_field_group_entry_cascades". All fields are combined with a logical 'AND'. */
export type EntryFormFieldGroupEntryCascadesBoolExp = {
  _and?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesBoolExp>>;
  _not?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
  _or?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesBoolExp>>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isHidden?: InputMaybe<BooleanComparisonExp>;
};

/** order by max() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesMaxOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesMinOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_field_group_entry_cascades". */
export type EntryFormFieldGroupEntryCascadesOrderBy = {
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isHidden?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_field_group_entry_cascades" */
export enum EntryFormFieldGroupEntryCascadesSelectColumn {
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden'
}

/** order by stddev() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesStddevOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesStddevPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesStddevSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "entry_form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EntryFormFieldGroupEntryCascadesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EntryFormFieldGroupEntryCascadesStreamCursorValueInput = {
  formId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by sum() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesSumOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesVarPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesVarSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "entry.form_field_group_entry_cascades" */
export type EntryFormFieldGroupEntryCascadesVarianceOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_field_groupings" */
export type EntryFormFieldGroupings = {
  __typename?: 'EntryFormFieldGroupings';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An object relationship */
  groupField: EntryFormGroupFields;
  groupFieldId: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

/** order by aggregate values of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormFieldGroupingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormFieldGroupingsMaxOrderBy>;
  min?: InputMaybe<EntryFormFieldGroupingsMinOrderBy>;
  stddev?: InputMaybe<EntryFormFieldGroupingsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormFieldGroupingsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormFieldGroupingsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormFieldGroupingsSumOrderBy>;
  varPop?: InputMaybe<EntryFormFieldGroupingsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormFieldGroupingsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormFieldGroupingsVarianceOrderBy>;
};

/** order by avg() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsAvgOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_field_groupings". All fields are combined with a logical 'AND'. */
export type EntryFormFieldGroupingsBoolExp = {
  _and?: InputMaybe<Array<EntryFormFieldGroupingsBoolExp>>;
  _not?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
  _or?: InputMaybe<Array<EntryFormFieldGroupingsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  groupField?: InputMaybe<EntryFormGroupFieldsBoolExp>;
  groupFieldId?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsMaxOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsMinOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_field_groupings". */
export type EntryFormFieldGroupingsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  groupField?: InputMaybe<EntryFormGroupFieldsOrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_field_groupings" */
export enum EntryFormFieldGroupingsSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  GroupFieldId = 'groupFieldId',
  /** column name */
  SortOrder = 'sortOrder'
}

/** order by stddev() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsStddevOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsStddevPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsStddevSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "entry_form_field_groupings" */
export type EntryFormFieldGroupingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EntryFormFieldGroupingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EntryFormFieldGroupingsStreamCursorValueInput = {
  fieldId?: InputMaybe<Scalars['Int']['input']>;
  groupFieldId?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsSumOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsVarPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsVarSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "entry.form_field_groupings" */
export type EntryFormFieldGroupingsVarianceOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  groupFieldId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_field_repeat_settings" */
export type EntryFormFieldRepeatSettings = {
  __typename?: 'EntryFormFieldRepeatSettings';
  fieldId: Scalars['Int']['output'];
  maxRepeat: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_field_repeat_settings". All fields are combined with a logical 'AND'. */
export type EntryFormFieldRepeatSettingsBoolExp = {
  _and?: InputMaybe<Array<EntryFormFieldRepeatSettingsBoolExp>>;
  _not?: InputMaybe<EntryFormFieldRepeatSettingsBoolExp>;
  _or?: InputMaybe<Array<EntryFormFieldRepeatSettingsBoolExp>>;
  fieldId?: InputMaybe<IntComparisonExp>;
  maxRepeat?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_field_repeat_settings". */
export type EntryFormFieldRepeatSettingsOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  maxRepeat?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_field_repeat_settings" */
export enum EntryFormFieldRepeatSettingsSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  MaxRepeat = 'maxRepeat'
}

/** Streaming cursor of the table "entry_form_field_repeat_settings" */
export type EntryFormFieldRepeatSettingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EntryFormFieldRepeatSettingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EntryFormFieldRepeatSettingsStreamCursorValueInput = {
  fieldId?: InputMaybe<Scalars['Int']['input']>;
  maxRepeat?: InputMaybe<Scalars['Int']['input']>;
};

export enum EntryFormFieldTypeEnum {
  Checkbox = 'CHECKBOX',
  File = 'FILE',
  Group = 'GROUP',
  Image = 'IMAGE',
  Radio = 'RADIO',
  Selectbox = 'SELECTBOX',
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  Url = 'URL'
}

/** Boolean expression to compare columns of type "EntryFormFieldTypeEnum". All fields are combined with logical 'AND'. */
export type EntryFormFieldTypeEnumComparisonExp = {
  _eq?: InputMaybe<EntryFormFieldTypeEnum>;
  _in?: InputMaybe<Array<EntryFormFieldTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<EntryFormFieldTypeEnum>;
  _nin?: InputMaybe<Array<EntryFormFieldTypeEnum>>;
};

/** columns and relationships of "entry.form_fields" */
export type EntryFormFields = {
  __typename?: 'EntryFormFields';
  /** An object relationship */
  cascade?: Maybe<EntryFormFieldCascades>;
  cascadeId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  checkbox?: Maybe<EntryFormCheckboxFields>;
  /** An object relationship */
  customField?: Maybe<EntryCustomFields>;
  /** An object relationship */
  entryFeeCascade?: Maybe<EntryFormFieldEntryFeeCascades>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  file?: Maybe<EntryFormFileFields>;
  /** An object relationship */
  form: EntryForms;
  /** An object relationship */
  group?: Maybe<EntryFormGroupFields>;
  /** An object relationship */
  groupEntryCascade?: Maybe<EntryFormFieldGroupEntryCascades>;
  groupEntryCascadeId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  grouping?: Maybe<EntryFormFieldGroupings>;
  helperText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  image?: Maybe<EntryFormImageFields>;
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  /** An object relationship */
  radio?: Maybe<EntryFormRadioFields>;
  /** An object relationship */
  repeatSetting?: Maybe<EntryFormFieldRepeatSettings>;
  /** An object relationship */
  selectbox?: Maybe<EntryFormSelectboxFields>;
  /** An object relationship */
  text?: Maybe<EntryFormTextFields>;
  /** An object relationship */
  textarea?: Maybe<EntryFormTextareaFields>;
  type: EntryFormFieldTypeEnum;
  /** An object relationship */
  url?: Maybe<EntryFormUrlFields>;
  /** An array relationship */
  values: Array<EntryFormValues>;
  /** An aggregate relationship */
  valuesAggregate: EntryFormValuesAggregate;
  /** An object relationship */
  workField?: Maybe<EntryWorkFields>;
};


/** columns and relationships of "entry.form_fields" */
export type EntryFormFieldsValuesArgs = {
  distinctOn?: InputMaybe<Array<EntryFormValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormValuesOrderBy>>;
  where?: InputMaybe<EntryFormValuesBoolExp>;
};


/** columns and relationships of "entry.form_fields" */
export type EntryFormFieldsValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormValuesOrderBy>>;
  where?: InputMaybe<EntryFormValuesBoolExp>;
};

/** aggregated selection of "entry.form_fields" */
export type EntryFormFieldsAggregate = {
  __typename?: 'EntryFormFieldsAggregate';
  aggregate?: Maybe<EntryFormFieldsAggregateFields>;
  nodes: Array<EntryFormFields>;
};

export type EntryFormFieldsAggregateBoolExp = {
  bool_and?: InputMaybe<EntryFormFieldsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<EntryFormFieldsAggregateBoolExpBool_Or>;
  count?: InputMaybe<EntryFormFieldsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_fields" */
export type EntryFormFieldsAggregateFields = {
  __typename?: 'EntryFormFieldsAggregateFields';
  avg?: Maybe<EntryFormFieldsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormFieldsMaxFields>;
  min?: Maybe<EntryFormFieldsMinFields>;
  stddev?: Maybe<EntryFormFieldsStddevFields>;
  stddevPop?: Maybe<EntryFormFieldsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormFieldsStddevSampFields>;
  sum?: Maybe<EntryFormFieldsSumFields>;
  varPop?: Maybe<EntryFormFieldsVarPopFields>;
  varSamp?: Maybe<EntryFormFieldsVarSampFields>;
  variance?: Maybe<EntryFormFieldsVarianceFields>;
};


/** aggregate fields of "entry.form_fields" */
export type EntryFormFieldsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormFieldsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_fields" */
export type EntryFormFieldsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormFieldsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormFieldsMaxOrderBy>;
  min?: InputMaybe<EntryFormFieldsMinOrderBy>;
  stddev?: InputMaybe<EntryFormFieldsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormFieldsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormFieldsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormFieldsSumOrderBy>;
  varPop?: InputMaybe<EntryFormFieldsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormFieldsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormFieldsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormFieldsAvgFields = {
  __typename?: 'EntryFormFieldsAvgFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_fields" */
export type EntryFormFieldsAvgOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_fields". All fields are combined with a logical 'AND'. */
export type EntryFormFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormFieldsBoolExp>>;
  cascade?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  cascadeId?: InputMaybe<IntComparisonExp>;
  checkbox?: InputMaybe<EntryFormCheckboxFieldsBoolExp>;
  customField?: InputMaybe<EntryCustomFieldsBoolExp>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  entryFeeCascadeId?: InputMaybe<IntComparisonExp>;
  file?: InputMaybe<EntryFormFileFieldsBoolExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  group?: InputMaybe<EntryFormGroupFieldsBoolExp>;
  groupEntryCascade?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
  groupEntryCascadeId?: InputMaybe<IntComparisonExp>;
  grouping?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
  helperText?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  image?: InputMaybe<EntryFormImageFieldsBoolExp>;
  isHidden?: InputMaybe<BooleanComparisonExp>;
  isRequired?: InputMaybe<BooleanComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  radio?: InputMaybe<EntryFormRadioFieldsBoolExp>;
  repeatSetting?: InputMaybe<EntryFormFieldRepeatSettingsBoolExp>;
  selectbox?: InputMaybe<EntryFormSelectboxFieldsBoolExp>;
  text?: InputMaybe<EntryFormTextFieldsBoolExp>;
  textarea?: InputMaybe<EntryFormTextareaFieldsBoolExp>;
  type?: InputMaybe<EntryFormFieldTypeEnumComparisonExp>;
  url?: InputMaybe<EntryFormUrlFieldsBoolExp>;
  values?: InputMaybe<EntryFormValuesBoolExp>;
  valuesAggregate?: InputMaybe<EntryFormValuesAggregateBoolExp>;
  workField?: InputMaybe<EntryWorkFieldsBoolExp>;
};

/** aggregate max on columns */
export type EntryFormFieldsMaxFields = {
  __typename?: 'EntryFormFieldsMaxFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Int']['output']>;
  helperText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.form_fields" */
export type EntryFormFieldsMaxOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  helperText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormFieldsMinFields = {
  __typename?: 'EntryFormFieldsMinFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Int']['output']>;
  helperText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.form_fields" */
export type EntryFormFieldsMinOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  helperText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_fields". */
export type EntryFormFieldsOrderBy = {
  cascade?: InputMaybe<EntryFormFieldCascadesOrderBy>;
  cascadeId?: InputMaybe<OrderBy>;
  checkbox?: InputMaybe<EntryFormCheckboxFieldsOrderBy>;
  customField?: InputMaybe<EntryCustomFieldsOrderBy>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesOrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  file?: InputMaybe<EntryFormFileFieldsOrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  group?: InputMaybe<EntryFormGroupFieldsOrderBy>;
  groupEntryCascade?: InputMaybe<EntryFormFieldGroupEntryCascadesOrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  grouping?: InputMaybe<EntryFormFieldGroupingsOrderBy>;
  helperText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<EntryFormImageFieldsOrderBy>;
  isHidden?: InputMaybe<OrderBy>;
  isRequired?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  radio?: InputMaybe<EntryFormRadioFieldsOrderBy>;
  repeatSetting?: InputMaybe<EntryFormFieldRepeatSettingsOrderBy>;
  selectbox?: InputMaybe<EntryFormSelectboxFieldsOrderBy>;
  text?: InputMaybe<EntryFormTextFieldsOrderBy>;
  textarea?: InputMaybe<EntryFormTextareaFieldsOrderBy>;
  type?: InputMaybe<OrderBy>;
  url?: InputMaybe<EntryFormUrlFieldsOrderBy>;
  valuesAggregate?: InputMaybe<EntryFormValuesAggregateOrderBy>;
  workField?: InputMaybe<EntryWorkFieldsOrderBy>;
};

/** select columns of table "entry.form_fields" */
export enum EntryFormFieldsSelectColumn {
  /** column name */
  CascadeId = 'cascadeId',
  /** column name */
  EntryFeeCascadeId = 'entryFeeCascadeId',
  /** column name */
  GroupEntryCascadeId = 'groupEntryCascadeId',
  /** column name */
  HelperText = 'helperText',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  Label = 'label',
  /** column name */
  Type = 'type'
}

/** select "entryFormFieldsAggregateBoolExpBool_andArgumentsColumns" columns of table "entry.form_fields" */
export enum EntryFormFieldsSelectColumnEntryFormFieldsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsRequired = 'isRequired'
}

/** select "entryFormFieldsAggregateBoolExpBool_orArgumentsColumns" columns of table "entry.form_fields" */
export enum EntryFormFieldsSelectColumnEntryFormFieldsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsRequired = 'isRequired'
}

/** aggregate stddev on columns */
export type EntryFormFieldsStddevFields = {
  __typename?: 'EntryFormFieldsStddevFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_fields" */
export type EntryFormFieldsStddevOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormFieldsStddevPopFields = {
  __typename?: 'EntryFormFieldsStddevPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_fields" */
export type EntryFormFieldsStddevPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormFieldsStddevSampFields = {
  __typename?: 'EntryFormFieldsStddevSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_fields" */
export type EntryFormFieldsStddevSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormFieldsSumFields = {
  __typename?: 'EntryFormFieldsSumFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_fields" */
export type EntryFormFieldsSumOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormFieldsVarPopFields = {
  __typename?: 'EntryFormFieldsVarPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_fields" */
export type EntryFormFieldsVarPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormFieldsVarSampFields = {
  __typename?: 'EntryFormFieldsVarSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_fields" */
export type EntryFormFieldsVarSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormFieldsVarianceFields = {
  __typename?: 'EntryFormFieldsVarianceFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  groupEntryCascadeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_fields" */
export type EntryFormFieldsVarianceOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensions = {
  __typename?: 'EntryFormFileFieldExtensions';
  fieldId: Scalars['Int']['output'];
  /** An object relationship */
  file: EntryFormFileFields;
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsAggregate = {
  __typename?: 'EntryFormFileFieldExtensionsAggregate';
  aggregate?: Maybe<EntryFormFileFieldExtensionsAggregateFields>;
  nodes: Array<EntryFormFileFieldExtensions>;
};

export type EntryFormFileFieldExtensionsAggregateBoolExp = {
  count?: InputMaybe<EntryFormFileFieldExtensionsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsAggregateFields = {
  __typename?: 'EntryFormFileFieldExtensionsAggregateFields';
  avg?: Maybe<EntryFormFileFieldExtensionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormFileFieldExtensionsMaxFields>;
  min?: Maybe<EntryFormFileFieldExtensionsMinFields>;
  stddev?: Maybe<EntryFormFileFieldExtensionsStddevFields>;
  stddevPop?: Maybe<EntryFormFileFieldExtensionsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormFileFieldExtensionsStddevSampFields>;
  sum?: Maybe<EntryFormFileFieldExtensionsSumFields>;
  varPop?: Maybe<EntryFormFileFieldExtensionsVarPopFields>;
  varSamp?: Maybe<EntryFormFileFieldExtensionsVarSampFields>;
  variance?: Maybe<EntryFormFileFieldExtensionsVarianceFields>;
};


/** aggregate fields of "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormFileFieldExtensionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormFileFieldExtensionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormFileFieldExtensionsMaxOrderBy>;
  min?: InputMaybe<EntryFormFileFieldExtensionsMinOrderBy>;
  stddev?: InputMaybe<EntryFormFileFieldExtensionsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormFileFieldExtensionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormFileFieldExtensionsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormFileFieldExtensionsSumOrderBy>;
  varPop?: InputMaybe<EntryFormFileFieldExtensionsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormFileFieldExtensionsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormFileFieldExtensionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormFileFieldExtensionsAvgFields = {
  __typename?: 'EntryFormFileFieldExtensionsAvgFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsAvgOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_file_field_extensions". All fields are combined with a logical 'AND'. */
export type EntryFormFileFieldExtensionsBoolExp = {
  _and?: InputMaybe<Array<EntryFormFileFieldExtensionsBoolExp>>;
  _not?: InputMaybe<EntryFormFileFieldExtensionsBoolExp>;
  _or?: InputMaybe<Array<EntryFormFileFieldExtensionsBoolExp>>;
  fieldId?: InputMaybe<IntComparisonExp>;
  file?: InputMaybe<EntryFormFileFieldsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type EntryFormFileFieldExtensionsMaxFields = {
  __typename?: 'EntryFormFileFieldExtensionsMaxFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsMaxOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormFileFieldExtensionsMinFields = {
  __typename?: 'EntryFormFileFieldExtensionsMinFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsMinOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_file_field_extensions". */
export type EntryFormFileFieldExtensionsOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  file?: InputMaybe<EntryFormFileFieldsOrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_file_field_extensions" */
export enum EntryFormFileFieldExtensionsSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type EntryFormFileFieldExtensionsStddevFields = {
  __typename?: 'EntryFormFileFieldExtensionsStddevFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsStddevOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormFileFieldExtensionsStddevPopFields = {
  __typename?: 'EntryFormFileFieldExtensionsStddevPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsStddevPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormFileFieldExtensionsStddevSampFields = {
  __typename?: 'EntryFormFileFieldExtensionsStddevSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsStddevSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormFileFieldExtensionsSumFields = {
  __typename?: 'EntryFormFileFieldExtensionsSumFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsSumOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormFileFieldExtensionsVarPopFields = {
  __typename?: 'EntryFormFileFieldExtensionsVarPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsVarPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormFileFieldExtensionsVarSampFields = {
  __typename?: 'EntryFormFileFieldExtensionsVarSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsVarSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormFileFieldExtensionsVarianceFields = {
  __typename?: 'EntryFormFileFieldExtensionsVarianceFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_file_field_extensions" */
export type EntryFormFileFieldExtensionsVarianceOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_file_fields" */
export type EntryFormFileFields = {
  __typename?: 'EntryFormFileFields';
  /** An array relationship */
  extensions: Array<EntryFormFileFieldExtensions>;
  /** An aggregate relationship */
  extensionsAggregate: EntryFormFileFieldExtensionsAggregate;
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
};


/** columns and relationships of "entry.form_file_fields" */
export type EntryFormFileFieldsExtensionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFileFieldExtensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFileFieldExtensionsOrderBy>>;
  where?: InputMaybe<EntryFormFileFieldExtensionsBoolExp>;
};


/** columns and relationships of "entry.form_file_fields" */
export type EntryFormFileFieldsExtensionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFileFieldExtensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFileFieldExtensionsOrderBy>>;
  where?: InputMaybe<EntryFormFileFieldExtensionsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.form_file_fields". All fields are combined with a logical 'AND'. */
export type EntryFormFileFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormFileFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormFileFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormFileFieldsBoolExp>>;
  extensions?: InputMaybe<EntryFormFileFieldExtensionsBoolExp>;
  extensionsAggregate?: InputMaybe<EntryFormFileFieldExtensionsAggregateBoolExp>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_file_fields". */
export type EntryFormFileFieldsOrderBy = {
  extensionsAggregate?: InputMaybe<EntryFormFileFieldExtensionsAggregateOrderBy>;
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
};

export type EntryFormFileValue = {
  file: EntryFormFileValueFile;
};

export type EntryFormFileValueFile = {
  url: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_file_values" */
export type EntryFormFileValues = {
  __typename?: 'EntryFormFileValues';
  /** An object relationship */
  file: Files;
  fileId: Scalars['Int']['output'];
  /** An object relationship */
  parent: EntryFormValues;
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_file_values". All fields are combined with a logical 'AND'. */
export type EntryFormFileValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormFileValuesBoolExp>>;
  _not?: InputMaybe<EntryFormFileValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormFileValuesBoolExp>>;
  file?: InputMaybe<FilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_file_values". */
export type EntryFormFileValuesOrderBy = {
  file?: InputMaybe<FilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_group_fields" */
export type EntryFormGroupFields = {
  __typename?: 'EntryFormGroupFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An array relationship */
  groupings: Array<EntryFormFieldGroupings>;
};


/** columns and relationships of "entry.form_group_fields" */
export type EntryFormGroupFieldsGroupingsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldGroupingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldGroupingsOrderBy>>;
  where?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.form_group_fields". All fields are combined with a logical 'AND'. */
export type EntryFormGroupFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormGroupFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormGroupFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormGroupFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  groupings?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
};

/** Ordering options when selecting data from "entry.form_group_fields". */
export type EntryFormGroupFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  groupingsAggregate?: InputMaybe<EntryFormFieldGroupingsAggregateOrderBy>;
};

/** select columns of table "entry.form_group_fields" */
export enum EntryFormGroupFieldsSelectColumn {
  /** column name */
  FieldId = 'fieldId'
}

/** Streaming cursor of the table "entry_form_group_fields" */
export type EntryFormGroupFieldsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EntryFormGroupFieldsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EntryFormGroupFieldsStreamCursorValueInput = {
  fieldId?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensions = {
  __typename?: 'EntryFormImageFieldExtensions';
  /** An object relationship */
  field: EntryFormImageFields;
  fieldId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsAggregate = {
  __typename?: 'EntryFormImageFieldExtensionsAggregate';
  aggregate?: Maybe<EntryFormImageFieldExtensionsAggregateFields>;
  nodes: Array<EntryFormImageFieldExtensions>;
};

export type EntryFormImageFieldExtensionsAggregateBoolExp = {
  count?: InputMaybe<EntryFormImageFieldExtensionsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsAggregateFields = {
  __typename?: 'EntryFormImageFieldExtensionsAggregateFields';
  avg?: Maybe<EntryFormImageFieldExtensionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormImageFieldExtensionsMaxFields>;
  min?: Maybe<EntryFormImageFieldExtensionsMinFields>;
  stddev?: Maybe<EntryFormImageFieldExtensionsStddevFields>;
  stddevPop?: Maybe<EntryFormImageFieldExtensionsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormImageFieldExtensionsStddevSampFields>;
  sum?: Maybe<EntryFormImageFieldExtensionsSumFields>;
  varPop?: Maybe<EntryFormImageFieldExtensionsVarPopFields>;
  varSamp?: Maybe<EntryFormImageFieldExtensionsVarSampFields>;
  variance?: Maybe<EntryFormImageFieldExtensionsVarianceFields>;
};


/** aggregate fields of "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormImageFieldExtensionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormImageFieldExtensionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormImageFieldExtensionsMaxOrderBy>;
  min?: InputMaybe<EntryFormImageFieldExtensionsMinOrderBy>;
  stddev?: InputMaybe<EntryFormImageFieldExtensionsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormImageFieldExtensionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormImageFieldExtensionsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormImageFieldExtensionsSumOrderBy>;
  varPop?: InputMaybe<EntryFormImageFieldExtensionsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormImageFieldExtensionsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormImageFieldExtensionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormImageFieldExtensionsAvgFields = {
  __typename?: 'EntryFormImageFieldExtensionsAvgFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsAvgOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_image_field_extensions". All fields are combined with a logical 'AND'. */
export type EntryFormImageFieldExtensionsBoolExp = {
  _and?: InputMaybe<Array<EntryFormImageFieldExtensionsBoolExp>>;
  _not?: InputMaybe<EntryFormImageFieldExtensionsBoolExp>;
  _or?: InputMaybe<Array<EntryFormImageFieldExtensionsBoolExp>>;
  field?: InputMaybe<EntryFormImageFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type EntryFormImageFieldExtensionsMaxFields = {
  __typename?: 'EntryFormImageFieldExtensionsMaxFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsMaxOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormImageFieldExtensionsMinFields = {
  __typename?: 'EntryFormImageFieldExtensionsMinFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsMinOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_image_field_extensions". */
export type EntryFormImageFieldExtensionsOrderBy = {
  field?: InputMaybe<EntryFormImageFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_image_field_extensions" */
export enum EntryFormImageFieldExtensionsSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type EntryFormImageFieldExtensionsStddevFields = {
  __typename?: 'EntryFormImageFieldExtensionsStddevFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsStddevOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormImageFieldExtensionsStddevPopFields = {
  __typename?: 'EntryFormImageFieldExtensionsStddevPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsStddevPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormImageFieldExtensionsStddevSampFields = {
  __typename?: 'EntryFormImageFieldExtensionsStddevSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsStddevSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormImageFieldExtensionsSumFields = {
  __typename?: 'EntryFormImageFieldExtensionsSumFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsSumOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormImageFieldExtensionsVarPopFields = {
  __typename?: 'EntryFormImageFieldExtensionsVarPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsVarPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormImageFieldExtensionsVarSampFields = {
  __typename?: 'EntryFormImageFieldExtensionsVarSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsVarSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormImageFieldExtensionsVarianceFields = {
  __typename?: 'EntryFormImageFieldExtensionsVarianceFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_image_field_extensions" */
export type EntryFormImageFieldExtensionsVarianceOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_image_fields" */
export type EntryFormImageFields = {
  __typename?: 'EntryFormImageFields';
  /** An array relationship */
  extensions: Array<EntryFormImageFieldExtensions>;
  /** An aggregate relationship */
  extensionsAggregate: EntryFormImageFieldExtensionsAggregate;
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
};


/** columns and relationships of "entry.form_image_fields" */
export type EntryFormImageFieldsExtensionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormImageFieldExtensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormImageFieldExtensionsOrderBy>>;
  where?: InputMaybe<EntryFormImageFieldExtensionsBoolExp>;
};


/** columns and relationships of "entry.form_image_fields" */
export type EntryFormImageFieldsExtensionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormImageFieldExtensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormImageFieldExtensionsOrderBy>>;
  where?: InputMaybe<EntryFormImageFieldExtensionsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.form_image_fields". All fields are combined with a logical 'AND'. */
export type EntryFormImageFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormImageFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormImageFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormImageFieldsBoolExp>>;
  extensions?: InputMaybe<EntryFormImageFieldExtensionsBoolExp>;
  extensionsAggregate?: InputMaybe<EntryFormImageFieldExtensionsAggregateBoolExp>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_image_fields". */
export type EntryFormImageFieldsOrderBy = {
  extensionsAggregate?: InputMaybe<EntryFormImageFieldExtensionsAggregateOrderBy>;
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
};

export type EntryFormImageValue = {
  file: EntryFormImageValueFile;
};

export type EntryFormImageValueFile = {
  url: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_image_values" */
export type EntryFormImageValues = {
  __typename?: 'EntryFormImageValues';
  /** An object relationship */
  file: Files;
  fileId: Scalars['Int']['output'];
  /** An object relationship */
  parent: EntryFormValues;
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_image_values". All fields are combined with a logical 'AND'. */
export type EntryFormImageValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormImageValuesBoolExp>>;
  _not?: InputMaybe<EntryFormImageValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormImageValuesBoolExp>>;
  file?: InputMaybe<FilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_image_values". */
export type EntryFormImageValuesOrderBy = {
  file?: InputMaybe<FilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_precautions" */
export type EntryFormPrecautions = {
  __typename?: 'EntryFormPrecautions';
  formId: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_precautions". All fields are combined with a logical 'AND'. */
export type EntryFormPrecautionsBoolExp = {
  _and?: InputMaybe<Array<EntryFormPrecautionsBoolExp>>;
  _not?: InputMaybe<EntryFormPrecautionsBoolExp>;
  _or?: InputMaybe<Array<EntryFormPrecautionsBoolExp>>;
  formId?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_precautions". */
export type EntryFormPrecautionsOrderBy = {
  formId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_precautions" */
export enum EntryFormPrecautionsSelectColumn {
  /** column name */
  FormId = 'formId',
  /** column name */
  Type = 'type'
}

/** Streaming cursor of the table "entry_form_precautions" */
export type EntryFormPrecautionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EntryFormPrecautionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EntryFormPrecautionsStreamCursorValueInput = {
  formId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptions = {
  __typename?: 'EntryFormRadioFieldOptions';
  /** An object relationship */
  cascade?: Maybe<EntryFormFieldCascades>;
  cascadeId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  entryFeeCascade?: Maybe<EntryFormFieldEntryFeeCascades>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  /** An object relationship */
  radio: EntryFormRadioFields;
  sortOrder: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsAggregate = {
  __typename?: 'EntryFormRadioFieldOptionsAggregate';
  aggregate?: Maybe<EntryFormRadioFieldOptionsAggregateFields>;
  nodes: Array<EntryFormRadioFieldOptions>;
};

export type EntryFormRadioFieldOptionsAggregateBoolExp = {
  count?: InputMaybe<EntryFormRadioFieldOptionsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsAggregateFields = {
  __typename?: 'EntryFormRadioFieldOptionsAggregateFields';
  avg?: Maybe<EntryFormRadioFieldOptionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormRadioFieldOptionsMaxFields>;
  min?: Maybe<EntryFormRadioFieldOptionsMinFields>;
  stddev?: Maybe<EntryFormRadioFieldOptionsStddevFields>;
  stddevPop?: Maybe<EntryFormRadioFieldOptionsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormRadioFieldOptionsStddevSampFields>;
  sum?: Maybe<EntryFormRadioFieldOptionsSumFields>;
  varPop?: Maybe<EntryFormRadioFieldOptionsVarPopFields>;
  varSamp?: Maybe<EntryFormRadioFieldOptionsVarSampFields>;
  variance?: Maybe<EntryFormRadioFieldOptionsVarianceFields>;
};


/** aggregate fields of "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormRadioFieldOptionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormRadioFieldOptionsMaxOrderBy>;
  min?: InputMaybe<EntryFormRadioFieldOptionsMinOrderBy>;
  stddev?: InputMaybe<EntryFormRadioFieldOptionsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormRadioFieldOptionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormRadioFieldOptionsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormRadioFieldOptionsSumOrderBy>;
  varPop?: InputMaybe<EntryFormRadioFieldOptionsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormRadioFieldOptionsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormRadioFieldOptionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormRadioFieldOptionsAvgFields = {
  __typename?: 'EntryFormRadioFieldOptionsAvgFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsAvgOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_radio_field_options". All fields are combined with a logical 'AND'. */
export type EntryFormRadioFieldOptionsBoolExp = {
  _and?: InputMaybe<Array<EntryFormRadioFieldOptionsBoolExp>>;
  _not?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
  _or?: InputMaybe<Array<EntryFormRadioFieldOptionsBoolExp>>;
  cascade?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  cascadeId?: InputMaybe<IntComparisonExp>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  entryFeeCascadeId?: InputMaybe<IntComparisonExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  radio?: InputMaybe<EntryFormRadioFieldsBoolExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type EntryFormRadioFieldOptionsMaxFields = {
  __typename?: 'EntryFormRadioFieldOptionsMaxFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsMaxOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormRadioFieldOptionsMinFields = {
  __typename?: 'EntryFormRadioFieldOptionsMinFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsMinOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_radio_field_options". */
export type EntryFormRadioFieldOptionsOrderBy = {
  cascade?: InputMaybe<EntryFormFieldCascadesOrderBy>;
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesOrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  radio?: InputMaybe<EntryFormRadioFieldsOrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_radio_field_options" */
export enum EntryFormRadioFieldOptionsSelectColumn {
  /** column name */
  CascadeId = 'cascadeId',
  /** column name */
  EntryFeeCascadeId = 'entryFeeCascadeId',
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type EntryFormRadioFieldOptionsStddevFields = {
  __typename?: 'EntryFormRadioFieldOptionsStddevFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsStddevOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormRadioFieldOptionsStddevPopFields = {
  __typename?: 'EntryFormRadioFieldOptionsStddevPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsStddevPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormRadioFieldOptionsStddevSampFields = {
  __typename?: 'EntryFormRadioFieldOptionsStddevSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsStddevSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormRadioFieldOptionsSumFields = {
  __typename?: 'EntryFormRadioFieldOptionsSumFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsSumOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormRadioFieldOptionsVarPopFields = {
  __typename?: 'EntryFormRadioFieldOptionsVarPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsVarPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormRadioFieldOptionsVarSampFields = {
  __typename?: 'EntryFormRadioFieldOptionsVarSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsVarSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormRadioFieldOptionsVarianceFields = {
  __typename?: 'EntryFormRadioFieldOptionsVarianceFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_radio_field_options" */
export type EntryFormRadioFieldOptionsVarianceOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_radio_fields" */
export type EntryFormRadioFields = {
  __typename?: 'EntryFormRadioFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An array relationship */
  options: Array<EntryFormRadioFieldOptions>;
  /** An aggregate relationship */
  optionsAggregate: EntryFormRadioFieldOptionsAggregate;
};


/** columns and relationships of "entry.form_radio_fields" */
export type EntryFormRadioFieldsOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormRadioFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_radio_fields" */
export type EntryFormRadioFieldsOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormRadioFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.form_radio_fields". All fields are combined with a logical 'AND'. */
export type EntryFormRadioFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormRadioFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormRadioFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormRadioFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  options?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
  optionsAggregate?: InputMaybe<EntryFormRadioFieldOptionsAggregateBoolExp>;
};

/** Ordering options when selecting data from "entry.form_radio_fields". */
export type EntryFormRadioFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  optionsAggregate?: InputMaybe<EntryFormRadioFieldOptionsAggregateOrderBy>;
};

export type EntryFormRadioValue = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_radio_values" */
export type EntryFormRadioValues = {
  __typename?: 'EntryFormRadioValues';
  /** An object relationship */
  parent: EntryFormValues;
  value: Scalars['String']['output'];
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_radio_values". All fields are combined with a logical 'AND'. */
export type EntryFormRadioValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormRadioValuesBoolExp>>;
  _not?: InputMaybe<EntryFormRadioValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormRadioValuesBoolExp>>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_radio_values". */
export type EntryFormRadioValuesOrderBy = {
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptions = {
  __typename?: 'EntryFormSelectboxFieldOptions';
  /** An object relationship */
  cascade?: Maybe<EntryFormFieldCascades>;
  cascadeId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  entryFeeCascade?: Maybe<EntryFormFieldEntryFeeCascades>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  /** An object relationship */
  selectbox: EntryFormSelectboxFields;
  sortOrder: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsAggregate = {
  __typename?: 'EntryFormSelectboxFieldOptionsAggregate';
  aggregate?: Maybe<EntryFormSelectboxFieldOptionsAggregateFields>;
  nodes: Array<EntryFormSelectboxFieldOptions>;
};

export type EntryFormSelectboxFieldOptionsAggregateBoolExp = {
  count?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsAggregateFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsAggregateFields';
  avg?: Maybe<EntryFormSelectboxFieldOptionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormSelectboxFieldOptionsMaxFields>;
  min?: Maybe<EntryFormSelectboxFieldOptionsMinFields>;
  stddev?: Maybe<EntryFormSelectboxFieldOptionsStddevFields>;
  stddevPop?: Maybe<EntryFormSelectboxFieldOptionsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormSelectboxFieldOptionsStddevSampFields>;
  sum?: Maybe<EntryFormSelectboxFieldOptionsSumFields>;
  varPop?: Maybe<EntryFormSelectboxFieldOptionsVarPopFields>;
  varSamp?: Maybe<EntryFormSelectboxFieldOptionsVarSampFields>;
  variance?: Maybe<EntryFormSelectboxFieldOptionsVarianceFields>;
};


/** aggregate fields of "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormSelectboxFieldOptionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormSelectboxFieldOptionsMaxOrderBy>;
  min?: InputMaybe<EntryFormSelectboxFieldOptionsMinOrderBy>;
  stddev?: InputMaybe<EntryFormSelectboxFieldOptionsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormSelectboxFieldOptionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormSelectboxFieldOptionsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormSelectboxFieldOptionsSumOrderBy>;
  varPop?: InputMaybe<EntryFormSelectboxFieldOptionsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormSelectboxFieldOptionsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormSelectboxFieldOptionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormSelectboxFieldOptionsAvgFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsAvgFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsAvgOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_selectbox_field_options". All fields are combined with a logical 'AND'. */
export type EntryFormSelectboxFieldOptionsBoolExp = {
  _and?: InputMaybe<Array<EntryFormSelectboxFieldOptionsBoolExp>>;
  _not?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
  _or?: InputMaybe<Array<EntryFormSelectboxFieldOptionsBoolExp>>;
  cascade?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  cascadeId?: InputMaybe<IntComparisonExp>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  entryFeeCascadeId?: InputMaybe<IntComparisonExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  selectbox?: InputMaybe<EntryFormSelectboxFieldsBoolExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type EntryFormSelectboxFieldOptionsMaxFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsMaxFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsMaxOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormSelectboxFieldOptionsMinFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsMinFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsMinOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_selectbox_field_options". */
export type EntryFormSelectboxFieldOptionsOrderBy = {
  cascade?: InputMaybe<EntryFormFieldCascadesOrderBy>;
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascade?: InputMaybe<EntryFormFieldEntryFeeCascadesOrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  selectbox?: InputMaybe<EntryFormSelectboxFieldsOrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.form_selectbox_field_options" */
export enum EntryFormSelectboxFieldOptionsSelectColumn {
  /** column name */
  CascadeId = 'cascadeId',
  /** column name */
  EntryFeeCascadeId = 'entryFeeCascadeId',
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type EntryFormSelectboxFieldOptionsStddevFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsStddevFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsStddevOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormSelectboxFieldOptionsStddevPopFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsStddevPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsStddevPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormSelectboxFieldOptionsStddevSampFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsStddevSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsStddevSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormSelectboxFieldOptionsSumFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsSumFields';
  cascadeId?: Maybe<Scalars['Int']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsSumOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormSelectboxFieldOptionsVarPopFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsVarPopFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsVarPopOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormSelectboxFieldOptionsVarSampFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsVarSampFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsVarSampOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormSelectboxFieldOptionsVarianceFields = {
  __typename?: 'EntryFormSelectboxFieldOptionsVarianceFields';
  cascadeId?: Maybe<Scalars['Float']['output']>;
  entryFeeCascadeId?: Maybe<Scalars['Float']['output']>;
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_selectbox_field_options" */
export type EntryFormSelectboxFieldOptionsVarianceOrderBy = {
  cascadeId?: InputMaybe<OrderBy>;
  entryFeeCascadeId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_selectbox_fields" */
export type EntryFormSelectboxFields = {
  __typename?: 'EntryFormSelectboxFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An array relationship */
  options: Array<EntryFormSelectboxFieldOptions>;
  /** An aggregate relationship */
  optionsAggregate: EntryFormSelectboxFieldOptionsAggregate;
};


/** columns and relationships of "entry.form_selectbox_fields" */
export type EntryFormSelectboxFieldsOptionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormSelectboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
};


/** columns and relationships of "entry.form_selectbox_fields" */
export type EntryFormSelectboxFieldsOptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormSelectboxFieldOptionsOrderBy>>;
  where?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.form_selectbox_fields". All fields are combined with a logical 'AND'. */
export type EntryFormSelectboxFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormSelectboxFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormSelectboxFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormSelectboxFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  options?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
  optionsAggregate?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateBoolExp>;
};

/** Ordering options when selecting data from "entry.form_selectbox_fields". */
export type EntryFormSelectboxFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  optionsAggregate?: InputMaybe<EntryFormSelectboxFieldOptionsAggregateOrderBy>;
};

export type EntryFormSelectboxValue = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_selectbox_values" */
export type EntryFormSelectboxValues = {
  __typename?: 'EntryFormSelectboxValues';
  /** An object relationship */
  parent: EntryFormValues;
  value: Scalars['String']['output'];
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_selectbox_values". All fields are combined with a logical 'AND'. */
export type EntryFormSelectboxValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormSelectboxValuesBoolExp>>;
  _not?: InputMaybe<EntryFormSelectboxValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormSelectboxValuesBoolExp>>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_selectbox_values". */
export type EntryFormSelectboxValuesOrderBy = {
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_static_fields" */
export type EntryFormStaticFields = {
  __typename?: 'EntryFormStaticFields';
  /** An object relationship */
  applicantAddress?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantBirth?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantGender?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantGroupAddress?: Maybe<ApplicantGroupFields>;
  /** An object relationship */
  applicantGroupGradeClass?: Maybe<ApplicantGroupFields>;
  /** An object relationship */
  applicantGroupName?: Maybe<ApplicantGroupFields>;
  /** An object relationship */
  applicantGroupPhoneNumber?: Maybe<ApplicantGroupFields>;
  /** An object relationship */
  applicantGroupRepresentativeKana?: Maybe<ApplicantGroupFields>;
  /** An object relationship */
  applicantGroupRepresentativeName?: Maybe<ApplicantGroupFields>;
  /** An object relationship */
  applicantJob?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantName?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantNameKana?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantNickname?: Maybe<ApplicantFields>;
  /** An object relationship */
  applicantPhoneNumber?: Maybe<ApplicantFields>;
  /** An object relationship */
  groupEntryCascade?: Maybe<EntryFormFieldGroupEntryCascades>;
  groupEntryCascadeId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isHidden: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_static_fields". All fields are combined with a logical 'AND'. */
export type EntryFormStaticFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormStaticFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormStaticFieldsBoolExp>>;
  applicantAddress?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantBirth?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantGender?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantGroupAddress?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantGroupGradeClass?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantGroupName?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantGroupPhoneNumber?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantGroupRepresentativeKana?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantGroupRepresentativeName?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantJob?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantName?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantNameKana?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantNickname?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantPhoneNumber?: InputMaybe<ApplicantFieldsBoolExp>;
  groupEntryCascade?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
  groupEntryCascadeId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isHidden?: InputMaybe<BooleanComparisonExp>;
  isRequired?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_static_fields". */
export type EntryFormStaticFieldsOrderBy = {
  applicantAddress?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantBirth?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantGender?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantGroupAddress?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantGroupGradeClass?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantGroupName?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantGroupPhoneNumber?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantGroupRepresentativeKana?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantGroupRepresentativeName?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantJob?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantName?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantNameKana?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantNickname?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantPhoneNumber?: InputMaybe<ApplicantFieldsOrderBy>;
  groupEntryCascade?: InputMaybe<EntryFormFieldGroupEntryCascadesOrderBy>;
  groupEntryCascadeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isHidden?: InputMaybe<OrderBy>;
  isRequired?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_text_fields" */
export type EntryFormTextFields = {
  __typename?: 'EntryFormTextFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  maxLength?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "entry.form_text_fields". All fields are combined with a logical 'AND'. */
export type EntryFormTextFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormTextFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormTextFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormTextFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  maxLength?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_text_fields". */
export type EntryFormTextFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

export type EntryFormTextValue = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_text_values" */
export type EntryFormTextValues = {
  __typename?: 'EntryFormTextValues';
  /** An object relationship */
  parent: EntryFormValues;
  value: Scalars['String']['output'];
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_text_values". All fields are combined with a logical 'AND'. */
export type EntryFormTextValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormTextValuesBoolExp>>;
  _not?: InputMaybe<EntryFormTextValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormTextValuesBoolExp>>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_text_values". */
export type EntryFormTextValuesOrderBy = {
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_textarea_fields" */
export type EntryFormTextareaFields = {
  __typename?: 'EntryFormTextareaFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  maxLength?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "entry.form_textarea_fields". All fields are combined with a logical 'AND'. */
export type EntryFormTextareaFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormTextareaFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormTextareaFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormTextareaFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  maxLength?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_textarea_fields". */
export type EntryFormTextareaFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

export type EntryFormTextareaValue = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_textarea_values" */
export type EntryFormTextareaValues = {
  __typename?: 'EntryFormTextareaValues';
  /** An object relationship */
  parent: EntryFormValues;
  value: Scalars['String']['output'];
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_textarea_values". All fields are combined with a logical 'AND'. */
export type EntryFormTextareaValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormTextareaValuesBoolExp>>;
  _not?: InputMaybe<EntryFormTextareaValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormTextareaValuesBoolExp>>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_textarea_values". */
export type EntryFormTextareaValuesOrderBy = {
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.form_url_fields" */
export type EntryFormUrlFields = {
  __typename?: 'EntryFormUrlFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  maxLength?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "entry.form_url_fields". All fields are combined with a logical 'AND'. */
export type EntryFormUrlFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryFormUrlFieldsBoolExp>>;
  _not?: InputMaybe<EntryFormUrlFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryFormUrlFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  maxLength?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_url_fields". */
export type EntryFormUrlFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

export type EntryFormUrlValue = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "entry.form_url_values" */
export type EntryFormUrlValues = {
  __typename?: 'EntryFormUrlValues';
  /** An object relationship */
  parent: EntryFormValues;
  value: Scalars['String']['output'];
  valueId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.form_url_values". All fields are combined with a logical 'AND'. */
export type EntryFormUrlValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormUrlValuesBoolExp>>;
  _not?: InputMaybe<EntryFormUrlValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormUrlValuesBoolExp>>;
  parent?: InputMaybe<EntryFormValuesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
  valueId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.form_url_values". */
export type EntryFormUrlValuesOrderBy = {
  parent?: InputMaybe<EntryFormValuesOrderBy>;
  value?: InputMaybe<OrderBy>;
  valueId?: InputMaybe<OrderBy>;
};

export type EntryFormValue = {
  checkbox?: InputMaybe<Array<EntryFormCheckboxValue>>;
  field: EntryFormValueField;
  file?: InputMaybe<EntryFormFileValue>;
  image?: InputMaybe<EntryFormImageValue>;
  radio?: InputMaybe<EntryFormRadioValue>;
  selectbox?: InputMaybe<EntryFormSelectboxValue>;
  text?: InputMaybe<EntryFormTextValue>;
  textarea?: InputMaybe<EntryFormTextareaValue>;
  url?: InputMaybe<EntryFormUrlValue>;
};

export type EntryFormValueField = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "entry.form_values" */
export type EntryFormValues = {
  __typename?: 'EntryFormValues';
  /** An array relationship */
  checkbox: Array<EntryFormCheckboxValues>;
  /** An aggregate relationship */
  checkboxAggregate: EntryFormCheckboxValuesAggregate;
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An object relationship */
  file?: Maybe<EntryFormFileValues>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  image?: Maybe<EntryFormImageValues>;
  /** An object relationship */
  radio?: Maybe<EntryFormRadioValues>;
  /** An object relationship */
  selectbox?: Maybe<EntryFormSelectboxValues>;
  /** An object relationship */
  text?: Maybe<EntryFormTextValues>;
  /** An object relationship */
  textarea?: Maybe<EntryFormTextareaValues>;
  /** An object relationship */
  url?: Maybe<EntryFormUrlValues>;
  /** An object relationship */
  workValue?: Maybe<EntryWorkValues>;
};


/** columns and relationships of "entry.form_values" */
export type EntryFormValuesCheckboxArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxValuesOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxValuesBoolExp>;
};


/** columns and relationships of "entry.form_values" */
export type EntryFormValuesCheckboxAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormCheckboxValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormCheckboxValuesOrderBy>>;
  where?: InputMaybe<EntryFormCheckboxValuesBoolExp>;
};

/** aggregated selection of "entry.form_values" */
export type EntryFormValuesAggregate = {
  __typename?: 'EntryFormValuesAggregate';
  aggregate?: Maybe<EntryFormValuesAggregateFields>;
  nodes: Array<EntryFormValues>;
};

export type EntryFormValuesAggregateBoolExp = {
  count?: InputMaybe<EntryFormValuesAggregateBoolExpCount>;
};

/** aggregate fields of "entry.form_values" */
export type EntryFormValuesAggregateFields = {
  __typename?: 'EntryFormValuesAggregateFields';
  avg?: Maybe<EntryFormValuesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormValuesMaxFields>;
  min?: Maybe<EntryFormValuesMinFields>;
  stddev?: Maybe<EntryFormValuesStddevFields>;
  stddevPop?: Maybe<EntryFormValuesStddevPopFields>;
  stddevSamp?: Maybe<EntryFormValuesStddevSampFields>;
  sum?: Maybe<EntryFormValuesSumFields>;
  varPop?: Maybe<EntryFormValuesVarPopFields>;
  varSamp?: Maybe<EntryFormValuesVarSampFields>;
  variance?: Maybe<EntryFormValuesVarianceFields>;
};


/** aggregate fields of "entry.form_values" */
export type EntryFormValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.form_values" */
export type EntryFormValuesAggregateOrderBy = {
  avg?: InputMaybe<EntryFormValuesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormValuesMaxOrderBy>;
  min?: InputMaybe<EntryFormValuesMinOrderBy>;
  stddev?: InputMaybe<EntryFormValuesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormValuesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormValuesStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormValuesSumOrderBy>;
  varPop?: InputMaybe<EntryFormValuesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormValuesVarSampOrderBy>;
  variance?: InputMaybe<EntryFormValuesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormValuesAvgFields = {
  __typename?: 'EntryFormValuesAvgFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.form_values" */
export type EntryFormValuesAvgOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.form_values". All fields are combined with a logical 'AND'. */
export type EntryFormValuesBoolExp = {
  _and?: InputMaybe<Array<EntryFormValuesBoolExp>>;
  _not?: InputMaybe<EntryFormValuesBoolExp>;
  _or?: InputMaybe<Array<EntryFormValuesBoolExp>>;
  checkbox?: InputMaybe<EntryFormCheckboxValuesBoolExp>;
  checkboxAggregate?: InputMaybe<EntryFormCheckboxValuesAggregateBoolExp>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  file?: InputMaybe<EntryFormFileValuesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  image?: InputMaybe<EntryFormImageValuesBoolExp>;
  radio?: InputMaybe<EntryFormRadioValuesBoolExp>;
  selectbox?: InputMaybe<EntryFormSelectboxValuesBoolExp>;
  text?: InputMaybe<EntryFormTextValuesBoolExp>;
  textarea?: InputMaybe<EntryFormTextareaValuesBoolExp>;
  url?: InputMaybe<EntryFormUrlValuesBoolExp>;
  workValue?: InputMaybe<EntryWorkValuesBoolExp>;
};

/** aggregate max on columns */
export type EntryFormValuesMaxFields = {
  __typename?: 'EntryFormValuesMaxFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.form_values" */
export type EntryFormValuesMaxOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormValuesMinFields = {
  __typename?: 'EntryFormValuesMinFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.form_values" */
export type EntryFormValuesMinOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.form_values". */
export type EntryFormValuesOrderBy = {
  checkboxAggregate?: InputMaybe<EntryFormCheckboxValuesAggregateOrderBy>;
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  file?: InputMaybe<EntryFormFileValuesOrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<EntryFormImageValuesOrderBy>;
  radio?: InputMaybe<EntryFormRadioValuesOrderBy>;
  selectbox?: InputMaybe<EntryFormSelectboxValuesOrderBy>;
  text?: InputMaybe<EntryFormTextValuesOrderBy>;
  textarea?: InputMaybe<EntryFormTextareaValuesOrderBy>;
  url?: InputMaybe<EntryFormUrlValuesOrderBy>;
  workValue?: InputMaybe<EntryWorkValuesOrderBy>;
};

/** select columns of table "entry.form_values" */
export enum EntryFormValuesSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type EntryFormValuesStddevFields = {
  __typename?: 'EntryFormValuesStddevFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.form_values" */
export type EntryFormValuesStddevOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormValuesStddevPopFields = {
  __typename?: 'EntryFormValuesStddevPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.form_values" */
export type EntryFormValuesStddevPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormValuesStddevSampFields = {
  __typename?: 'EntryFormValuesStddevSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.form_values" */
export type EntryFormValuesStddevSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFormValuesSumFields = {
  __typename?: 'EntryFormValuesSumFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.form_values" */
export type EntryFormValuesSumOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormValuesVarPopFields = {
  __typename?: 'EntryFormValuesVarPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.form_values" */
export type EntryFormValuesVarPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormValuesVarSampFields = {
  __typename?: 'EntryFormValuesVarSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.form_values" */
export type EntryFormValuesVarSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormValuesVarianceFields = {
  __typename?: 'EntryFormValuesVarianceFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.form_values" */
export type EntryFormValuesVarianceOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.forms" */
export type EntryForms = {
  __typename?: 'EntryForms';
  /** An object relationship */
  _applicantFields?: Maybe<ApplicantFields>;
  /** An object relationship */
  agreement: EntryAgreements;
  /** An object relationship */
  applicantFields: ApplicantFields;
  applicantFieldsId: Scalars['Int']['output'];
  /** An object relationship */
  contest?: Maybe<Contests>;
  contestId: Scalars['Int']['output'];
  /** An array relationship */
  customFields: Array<EntryCustomFields>;
  /** An aggregate relationship */
  customFieldsAggregate: EntryCustomFieldsAggregate;
  /** An object relationship */
  entryAcceptedEmail: EntryAcceptedEmails;
  /** An object relationship */
  entryAcceptedLink?: Maybe<EntryAcceptedLinks>;
  /** An object relationship */
  entryCompletedTweet?: Maybe<EntryCompletedTweets>;
  /** An array relationship */
  fieldCascades: Array<EntryFormFieldCascades>;
  /** An aggregate relationship */
  fieldCascadesAggregate: EntryFormFieldCascadesAggregate;
  /** An array relationship */
  fieldEntryFeeCascades: Array<EntryFormFieldEntryFeeCascades>;
  /** An aggregate relationship */
  fieldEntryFeeCascadesAggregate: EntryFormFieldEntryFeeCascadesAggregate;
  /** An array relationship */
  fieldGroupEntryCascades: Array<EntryFormFieldGroupEntryCascades>;
  id: Scalars['Int']['output'];
  maxWorks: Scalars['Int']['output'];
  /** An object relationship */
  mypage?: Maybe<ApplicantMypages>;
  /** An object relationship */
  precaution?: Maybe<EntryFormPrecautions>;
  url: Scalars['String']['output'];
  /** An array relationship */
  workFields: Array<EntryWorkFields>;
  /** An aggregate relationship */
  workFieldsAggregate: EntryWorkFieldsAggregate;
  /** An array relationship */
  works: Array<EntryWorks>;
  /** An aggregate relationship */
  worksAggregate: EntryWorksAggregate;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsCustomFieldsArgs = {
  distinctOn?: InputMaybe<Array<EntryCustomFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryCustomFieldsOrderBy>>;
  where?: InputMaybe<EntryCustomFieldsBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsCustomFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryCustomFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryCustomFieldsOrderBy>>;
  where?: InputMaybe<EntryCustomFieldsBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsFieldCascadesArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldCascadesBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsFieldCascadesAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldCascadesBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsFieldEntryFeeCascadesArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsFieldEntryFeeCascadesAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsFieldGroupEntryCascadesArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsWorkFieldsArgs = {
  distinctOn?: InputMaybe<Array<EntryWorkFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryWorkFieldsOrderBy>>;
  where?: InputMaybe<EntryWorkFieldsBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsWorkFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryWorkFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryWorkFieldsOrderBy>>;
  where?: InputMaybe<EntryWorkFieldsBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsWorksArgs = {
  distinctOn?: InputMaybe<Array<EntryWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryWorksOrderBy>>;
  where?: InputMaybe<EntryWorksBoolExp>;
};


/** columns and relationships of "entry.forms" */
export type EntryFormsWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryWorksOrderBy>>;
  where?: InputMaybe<EntryWorksBoolExp>;
};

/** aggregated selection of "entry.forms" */
export type EntryFormsAggregate = {
  __typename?: 'EntryFormsAggregate';
  aggregate?: Maybe<EntryFormsAggregateFields>;
  nodes: Array<EntryForms>;
};

export type EntryFormsAggregateBoolExp = {
  count?: InputMaybe<EntryFormsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.forms" */
export type EntryFormsAggregateFields = {
  __typename?: 'EntryFormsAggregateFields';
  avg?: Maybe<EntryFormsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFormsMaxFields>;
  min?: Maybe<EntryFormsMinFields>;
  stddev?: Maybe<EntryFormsStddevFields>;
  stddevPop?: Maybe<EntryFormsStddevPopFields>;
  stddevSamp?: Maybe<EntryFormsStddevSampFields>;
  sum?: Maybe<EntryFormsSumFields>;
  varPop?: Maybe<EntryFormsVarPopFields>;
  varSamp?: Maybe<EntryFormsVarSampFields>;
  variance?: Maybe<EntryFormsVarianceFields>;
};


/** aggregate fields of "entry.forms" */
export type EntryFormsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFormsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.forms" */
export type EntryFormsAggregateOrderBy = {
  avg?: InputMaybe<EntryFormsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFormsMaxOrderBy>;
  min?: InputMaybe<EntryFormsMinOrderBy>;
  stddev?: InputMaybe<EntryFormsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFormsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFormsStddevSampOrderBy>;
  sum?: InputMaybe<EntryFormsSumOrderBy>;
  varPop?: InputMaybe<EntryFormsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFormsVarSampOrderBy>;
  variance?: InputMaybe<EntryFormsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFormsAvgFields = {
  __typename?: 'EntryFormsAvgFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.forms" */
export type EntryFormsAvgOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.forms". All fields are combined with a logical 'AND'. */
export type EntryFormsBoolExp = {
  _and?: InputMaybe<Array<EntryFormsBoolExp>>;
  _applicantFields?: InputMaybe<ApplicantFieldsBoolExp>;
  _not?: InputMaybe<EntryFormsBoolExp>;
  _or?: InputMaybe<Array<EntryFormsBoolExp>>;
  agreement?: InputMaybe<EntryAgreementsBoolExp>;
  applicantFields?: InputMaybe<ApplicantFieldsBoolExp>;
  applicantFieldsId?: InputMaybe<IntComparisonExp>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  customFields?: InputMaybe<EntryCustomFieldsBoolExp>;
  customFieldsAggregate?: InputMaybe<EntryCustomFieldsAggregateBoolExp>;
  entryAcceptedEmail?: InputMaybe<EntryAcceptedEmailsBoolExp>;
  entryAcceptedLink?: InputMaybe<EntryAcceptedLinksBoolExp>;
  entryCompletedTweet?: InputMaybe<EntryCompletedTweetsBoolExp>;
  fieldCascades?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  fieldCascadesAggregate?: InputMaybe<EntryFormFieldCascadesAggregateBoolExp>;
  fieldEntryFeeCascades?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  fieldEntryFeeCascadesAggregate?: InputMaybe<EntryFormFieldEntryFeeCascadesAggregateBoolExp>;
  fieldGroupEntryCascades?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  maxWorks?: InputMaybe<IntComparisonExp>;
  mypage?: InputMaybe<ApplicantMypagesBoolExp>;
  precaution?: InputMaybe<EntryFormPrecautionsBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
  workFields?: InputMaybe<EntryWorkFieldsBoolExp>;
  workFieldsAggregate?: InputMaybe<EntryWorkFieldsAggregateBoolExp>;
  works?: InputMaybe<EntryWorksBoolExp>;
  worksAggregate?: InputMaybe<EntryWorksAggregateBoolExp>;
};

/** aggregate max on columns */
export type EntryFormsMaxFields = {
  __typename?: 'EntryFormsMaxFields';
  applicantFieldsId?: Maybe<Scalars['Int']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  maxWorks?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entry.forms" */
export type EntryFormsMaxOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFormsMinFields = {
  __typename?: 'EntryFormsMinFields';
  applicantFieldsId?: Maybe<Scalars['Int']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  maxWorks?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entry.forms" */
export type EntryFormsMinOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.forms". */
export type EntryFormsOrderBy = {
  _applicantFields?: InputMaybe<ApplicantFieldsOrderBy>;
  agreement?: InputMaybe<EntryAgreementsOrderBy>;
  applicantFields?: InputMaybe<ApplicantFieldsOrderBy>;
  applicantFieldsId?: InputMaybe<OrderBy>;
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  customFieldsAggregate?: InputMaybe<EntryCustomFieldsAggregateOrderBy>;
  entryAcceptedEmail?: InputMaybe<EntryAcceptedEmailsOrderBy>;
  entryAcceptedLink?: InputMaybe<EntryAcceptedLinksOrderBy>;
  entryCompletedTweet?: InputMaybe<EntryCompletedTweetsOrderBy>;
  fieldCascadesAggregate?: InputMaybe<EntryFormFieldCascadesAggregateOrderBy>;
  fieldEntryFeeCascadesAggregate?: InputMaybe<EntryFormFieldEntryFeeCascadesAggregateOrderBy>;
  fieldGroupEntryCascadesAggregate?: InputMaybe<EntryFormFieldGroupEntryCascadesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypage?: InputMaybe<ApplicantMypagesOrderBy>;
  precaution?: InputMaybe<EntryFormPrecautionsOrderBy>;
  url?: InputMaybe<OrderBy>;
  workFieldsAggregate?: InputMaybe<EntryWorkFieldsAggregateOrderBy>;
  worksAggregate?: InputMaybe<EntryWorksAggregateOrderBy>;
};

/** select columns of table "entry.forms" */
export enum EntryFormsSelectColumn {
  /** column name */
  ApplicantFieldsId = 'applicantFieldsId',
  /** column name */
  ContestId = 'contestId',
  /** column name */
  Id = 'id',
  /** column name */
  MaxWorks = 'maxWorks',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type EntryFormsStddevFields = {
  __typename?: 'EntryFormsStddevFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.forms" */
export type EntryFormsStddevOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFormsStddevPopFields = {
  __typename?: 'EntryFormsStddevPopFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.forms" */
export type EntryFormsStddevPopOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFormsStddevSampFields = {
  __typename?: 'EntryFormsStddevSampFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.forms" */
export type EntryFormsStddevSampOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "entry_forms" */
export type EntryFormsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EntryFormsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EntryFormsStreamCursorValueInput = {
  applicantFieldsId?: InputMaybe<Scalars['Int']['input']>;
  contestId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  maxWorks?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type EntryFormsSumFields = {
  __typename?: 'EntryFormsSumFields';
  applicantFieldsId?: Maybe<Scalars['Int']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  maxWorks?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.forms" */
export type EntryFormsSumOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFormsVarPopFields = {
  __typename?: 'EntryFormsVarPopFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.forms" */
export type EntryFormsVarPopOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFormsVarSampFields = {
  __typename?: 'EntryFormsVarSampFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.forms" */
export type EntryFormsVarSampOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFormsVarianceFields = {
  __typename?: 'EntryFormsVarianceFields';
  applicantFieldsId?: Maybe<Scalars['Float']['output']>;
  contestId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.forms" */
export type EntryFormsVarianceOrderBy = {
  applicantFieldsId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.mypage_custom_links" */
export type EntryMypageCustomLinks = {
  __typename?: 'EntryMypageCustomLinks';
  id: Scalars['Int']['output'];
  /** An object relationship */
  mypage: ApplicantMypages;
  mypageId: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** order by aggregate values of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksAggregateOrderBy = {
  avg?: InputMaybe<EntryMypageCustomLinksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryMypageCustomLinksMaxOrderBy>;
  min?: InputMaybe<EntryMypageCustomLinksMinOrderBy>;
  stddev?: InputMaybe<EntryMypageCustomLinksStddevOrderBy>;
  stddevPop?: InputMaybe<EntryMypageCustomLinksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryMypageCustomLinksStddevSampOrderBy>;
  sum?: InputMaybe<EntryMypageCustomLinksSumOrderBy>;
  varPop?: InputMaybe<EntryMypageCustomLinksVarPopOrderBy>;
  varSamp?: InputMaybe<EntryMypageCustomLinksVarSampOrderBy>;
  variance?: InputMaybe<EntryMypageCustomLinksVarianceOrderBy>;
};

/** order by avg() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.mypage_custom_links". All fields are combined with a logical 'AND'. */
export type EntryMypageCustomLinksBoolExp = {
  _and?: InputMaybe<Array<EntryMypageCustomLinksBoolExp>>;
  _not?: InputMaybe<EntryMypageCustomLinksBoolExp>;
  _or?: InputMaybe<Array<EntryMypageCustomLinksBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  mypage?: InputMaybe<ApplicantMypagesBoolExp>;
  mypageId?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.mypage_custom_links". */
export type EntryMypageCustomLinksOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypage?: InputMaybe<ApplicantMypagesOrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.mypage_custom_links" */
export enum EntryMypageCustomLinksSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MypageId = 'mypageId',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  Text = 'text',
  /** column name */
  Url = 'url'
}

/** order by stddev() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "entry.mypage_custom_links" */
export type EntryMypageCustomLinksVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.work_fields" */
export type EntryWorkFields = {
  __typename?: 'EntryWorkFields';
  /** An object relationship */
  field: EntryFormFields;
  fieldId: Scalars['Int']['output'];
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  sortOrder: Scalars['Int']['output'];
};

/** aggregated selection of "entry.work_fields" */
export type EntryWorkFieldsAggregate = {
  __typename?: 'EntryWorkFieldsAggregate';
  aggregate?: Maybe<EntryWorkFieldsAggregateFields>;
  nodes: Array<EntryWorkFields>;
};

export type EntryWorkFieldsAggregateBoolExp = {
  count?: InputMaybe<EntryWorkFieldsAggregateBoolExpCount>;
};

/** aggregate fields of "entry.work_fields" */
export type EntryWorkFieldsAggregateFields = {
  __typename?: 'EntryWorkFieldsAggregateFields';
  avg?: Maybe<EntryWorkFieldsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryWorkFieldsMaxFields>;
  min?: Maybe<EntryWorkFieldsMinFields>;
  stddev?: Maybe<EntryWorkFieldsStddevFields>;
  stddevPop?: Maybe<EntryWorkFieldsStddevPopFields>;
  stddevSamp?: Maybe<EntryWorkFieldsStddevSampFields>;
  sum?: Maybe<EntryWorkFieldsSumFields>;
  varPop?: Maybe<EntryWorkFieldsVarPopFields>;
  varSamp?: Maybe<EntryWorkFieldsVarSampFields>;
  variance?: Maybe<EntryWorkFieldsVarianceFields>;
};


/** aggregate fields of "entry.work_fields" */
export type EntryWorkFieldsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryWorkFieldsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.work_fields" */
export type EntryWorkFieldsAggregateOrderBy = {
  avg?: InputMaybe<EntryWorkFieldsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryWorkFieldsMaxOrderBy>;
  min?: InputMaybe<EntryWorkFieldsMinOrderBy>;
  stddev?: InputMaybe<EntryWorkFieldsStddevOrderBy>;
  stddevPop?: InputMaybe<EntryWorkFieldsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryWorkFieldsStddevSampOrderBy>;
  sum?: InputMaybe<EntryWorkFieldsSumOrderBy>;
  varPop?: InputMaybe<EntryWorkFieldsVarPopOrderBy>;
  varSamp?: InputMaybe<EntryWorkFieldsVarSampOrderBy>;
  variance?: InputMaybe<EntryWorkFieldsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryWorkFieldsAvgFields = {
  __typename?: 'EntryWorkFieldsAvgFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.work_fields" */
export type EntryWorkFieldsAvgOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.work_fields". All fields are combined with a logical 'AND'. */
export type EntryWorkFieldsBoolExp = {
  _and?: InputMaybe<Array<EntryWorkFieldsBoolExp>>;
  _not?: InputMaybe<EntryWorkFieldsBoolExp>;
  _or?: InputMaybe<Array<EntryWorkFieldsBoolExp>>;
  field?: InputMaybe<EntryFormFieldsBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type EntryWorkFieldsMaxFields = {
  __typename?: 'EntryWorkFieldsMaxFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.work_fields" */
export type EntryWorkFieldsMaxOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryWorkFieldsMinFields = {
  __typename?: 'EntryWorkFieldsMinFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.work_fields" */
export type EntryWorkFieldsMinOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.work_fields". */
export type EntryWorkFieldsOrderBy = {
  field?: InputMaybe<EntryFormFieldsOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.work_fields" */
export enum EntryWorkFieldsSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  FormId = 'formId',
  /** column name */
  SortOrder = 'sortOrder'
}

/** aggregate stddev on columns */
export type EntryWorkFieldsStddevFields = {
  __typename?: 'EntryWorkFieldsStddevFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.work_fields" */
export type EntryWorkFieldsStddevOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryWorkFieldsStddevPopFields = {
  __typename?: 'EntryWorkFieldsStddevPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.work_fields" */
export type EntryWorkFieldsStddevPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryWorkFieldsStddevSampFields = {
  __typename?: 'EntryWorkFieldsStddevSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.work_fields" */
export type EntryWorkFieldsStddevSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryWorkFieldsSumFields = {
  __typename?: 'EntryWorkFieldsSumFields';
  fieldId?: Maybe<Scalars['Int']['output']>;
  formId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.work_fields" */
export type EntryWorkFieldsSumOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryWorkFieldsVarPopFields = {
  __typename?: 'EntryWorkFieldsVarPopFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.work_fields" */
export type EntryWorkFieldsVarPopOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryWorkFieldsVarSampFields = {
  __typename?: 'EntryWorkFieldsVarSampFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.work_fields" */
export type EntryWorkFieldsVarSampOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryWorkFieldsVarianceFields = {
  __typename?: 'EntryWorkFieldsVarianceFields';
  fieldId?: Maybe<Scalars['Float']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.work_fields" */
export type EntryWorkFieldsVarianceOrderBy = {
  fieldId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.work_values" */
export type EntryWorkValues = {
  __typename?: 'EntryWorkValues';
  /** An object relationship */
  value: EntryFormValues;
  valueId: Scalars['Int']['output'];
  /** An object relationship */
  work: EntryWorks;
  workId: Scalars['Int']['output'];
};

/** aggregated selection of "entry.work_values" */
export type EntryWorkValuesAggregate = {
  __typename?: 'EntryWorkValuesAggregate';
  aggregate?: Maybe<EntryWorkValuesAggregateFields>;
  nodes: Array<EntryWorkValues>;
};

export type EntryWorkValuesAggregateBoolExp = {
  count?: InputMaybe<EntryWorkValuesAggregateBoolExpCount>;
};

/** aggregate fields of "entry.work_values" */
export type EntryWorkValuesAggregateFields = {
  __typename?: 'EntryWorkValuesAggregateFields';
  avg?: Maybe<EntryWorkValuesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryWorkValuesMaxFields>;
  min?: Maybe<EntryWorkValuesMinFields>;
  stddev?: Maybe<EntryWorkValuesStddevFields>;
  stddevPop?: Maybe<EntryWorkValuesStddevPopFields>;
  stddevSamp?: Maybe<EntryWorkValuesStddevSampFields>;
  sum?: Maybe<EntryWorkValuesSumFields>;
  varPop?: Maybe<EntryWorkValuesVarPopFields>;
  varSamp?: Maybe<EntryWorkValuesVarSampFields>;
  variance?: Maybe<EntryWorkValuesVarianceFields>;
};


/** aggregate fields of "entry.work_values" */
export type EntryWorkValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryWorkValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.work_values" */
export type EntryWorkValuesAggregateOrderBy = {
  avg?: InputMaybe<EntryWorkValuesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryWorkValuesMaxOrderBy>;
  min?: InputMaybe<EntryWorkValuesMinOrderBy>;
  stddev?: InputMaybe<EntryWorkValuesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryWorkValuesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryWorkValuesStddevSampOrderBy>;
  sum?: InputMaybe<EntryWorkValuesSumOrderBy>;
  varPop?: InputMaybe<EntryWorkValuesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryWorkValuesVarSampOrderBy>;
  variance?: InputMaybe<EntryWorkValuesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryWorkValuesAvgFields = {
  __typename?: 'EntryWorkValuesAvgFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.work_values" */
export type EntryWorkValuesAvgOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.work_values". All fields are combined with a logical 'AND'. */
export type EntryWorkValuesBoolExp = {
  _and?: InputMaybe<Array<EntryWorkValuesBoolExp>>;
  _not?: InputMaybe<EntryWorkValuesBoolExp>;
  _or?: InputMaybe<Array<EntryWorkValuesBoolExp>>;
  value?: InputMaybe<EntryFormValuesBoolExp>;
  valueId?: InputMaybe<IntComparisonExp>;
  work?: InputMaybe<EntryWorksBoolExp>;
  workId?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type EntryWorkValuesMaxFields = {
  __typename?: 'EntryWorkValuesMaxFields';
  valueId?: Maybe<Scalars['Int']['output']>;
  workId?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.work_values" */
export type EntryWorkValuesMaxOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryWorkValuesMinFields = {
  __typename?: 'EntryWorkValuesMinFields';
  valueId?: Maybe<Scalars['Int']['output']>;
  workId?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.work_values" */
export type EntryWorkValuesMinOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.work_values". */
export type EntryWorkValuesOrderBy = {
  value?: InputMaybe<EntryFormValuesOrderBy>;
  valueId?: InputMaybe<OrderBy>;
  work?: InputMaybe<EntryWorksOrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.work_values" */
export enum EntryWorkValuesSelectColumn {
  /** column name */
  ValueId = 'valueId',
  /** column name */
  WorkId = 'workId'
}

/** aggregate stddev on columns */
export type EntryWorkValuesStddevFields = {
  __typename?: 'EntryWorkValuesStddevFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.work_values" */
export type EntryWorkValuesStddevOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryWorkValuesStddevPopFields = {
  __typename?: 'EntryWorkValuesStddevPopFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.work_values" */
export type EntryWorkValuesStddevPopOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryWorkValuesStddevSampFields = {
  __typename?: 'EntryWorkValuesStddevSampFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.work_values" */
export type EntryWorkValuesStddevSampOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryWorkValuesSumFields = {
  __typename?: 'EntryWorkValuesSumFields';
  valueId?: Maybe<Scalars['Int']['output']>;
  workId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.work_values" */
export type EntryWorkValuesSumOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryWorkValuesVarPopFields = {
  __typename?: 'EntryWorkValuesVarPopFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.work_values" */
export type EntryWorkValuesVarPopOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryWorkValuesVarSampFields = {
  __typename?: 'EntryWorkValuesVarSampFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.work_values" */
export type EntryWorkValuesVarSampOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryWorkValuesVarianceFields = {
  __typename?: 'EntryWorkValuesVarianceFields';
  valueId?: Maybe<Scalars['Float']['output']>;
  workId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.work_values" */
export type EntryWorkValuesVarianceOrderBy = {
  valueId?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.works" */
export type EntryWorks = {
  __typename?: 'EntryWorks';
  entryNumber: Scalars['Int']['output'];
  /** An object relationship */
  form: EntryForms;
  id: Scalars['Int']['output'];
  /** An array relationship */
  values: Array<EntryWorkValues>;
  /** An aggregate relationship */
  valuesAggregate: EntryWorkValuesAggregate;
};


/** columns and relationships of "entry.works" */
export type EntryWorksValuesArgs = {
  distinctOn?: InputMaybe<Array<EntryWorkValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryWorkValuesOrderBy>>;
  where?: InputMaybe<EntryWorkValuesBoolExp>;
};


/** columns and relationships of "entry.works" */
export type EntryWorksValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryWorkValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryWorkValuesOrderBy>>;
  where?: InputMaybe<EntryWorkValuesBoolExp>;
};

/** aggregated selection of "entry.works" */
export type EntryWorksAggregate = {
  __typename?: 'EntryWorksAggregate';
  aggregate?: Maybe<EntryWorksAggregateFields>;
  nodes: Array<EntryWorks>;
};

export type EntryWorksAggregateBoolExp = {
  count?: InputMaybe<EntryWorksAggregateBoolExpCount>;
};

/** aggregate fields of "entry.works" */
export type EntryWorksAggregateFields = {
  __typename?: 'EntryWorksAggregateFields';
  avg?: Maybe<EntryWorksAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryWorksMaxFields>;
  min?: Maybe<EntryWorksMinFields>;
  stddev?: Maybe<EntryWorksStddevFields>;
  stddevPop?: Maybe<EntryWorksStddevPopFields>;
  stddevSamp?: Maybe<EntryWorksStddevSampFields>;
  sum?: Maybe<EntryWorksSumFields>;
  varPop?: Maybe<EntryWorksVarPopFields>;
  varSamp?: Maybe<EntryWorksVarSampFields>;
  variance?: Maybe<EntryWorksVarianceFields>;
};


/** aggregate fields of "entry.works" */
export type EntryWorksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryWorksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.works" */
export type EntryWorksAggregateOrderBy = {
  avg?: InputMaybe<EntryWorksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryWorksMaxOrderBy>;
  min?: InputMaybe<EntryWorksMinOrderBy>;
  stddev?: InputMaybe<EntryWorksStddevOrderBy>;
  stddevPop?: InputMaybe<EntryWorksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryWorksStddevSampOrderBy>;
  sum?: InputMaybe<EntryWorksSumOrderBy>;
  varPop?: InputMaybe<EntryWorksVarPopOrderBy>;
  varSamp?: InputMaybe<EntryWorksVarSampOrderBy>;
  variance?: InputMaybe<EntryWorksVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryWorksAvgFields = {
  __typename?: 'EntryWorksAvgFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.works" */
export type EntryWorksAvgOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.works". All fields are combined with a logical 'AND'. */
export type EntryWorksBoolExp = {
  _and?: InputMaybe<Array<EntryWorksBoolExp>>;
  _not?: InputMaybe<EntryWorksBoolExp>;
  _or?: InputMaybe<Array<EntryWorksBoolExp>>;
  entryNumber?: InputMaybe<IntComparisonExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  values?: InputMaybe<EntryWorkValuesBoolExp>;
  valuesAggregate?: InputMaybe<EntryWorkValuesAggregateBoolExp>;
};

/** aggregate max on columns */
export type EntryWorksMaxFields = {
  __typename?: 'EntryWorksMaxFields';
  entryNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.works" */
export type EntryWorksMaxOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryWorksMinFields = {
  __typename?: 'EntryWorksMinFields';
  entryNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.works" */
export type EntryWorksMinOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.works". */
export type EntryWorksOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  id?: InputMaybe<OrderBy>;
  valuesAggregate?: InputMaybe<EntryWorkValuesAggregateOrderBy>;
};

/** select columns of table "entry.works" */
export enum EntryWorksSelectColumn {
  /** column name */
  EntryNumber = 'entryNumber',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type EntryWorksStddevFields = {
  __typename?: 'EntryWorksStddevFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.works" */
export type EntryWorksStddevOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryWorksStddevPopFields = {
  __typename?: 'EntryWorksStddevPopFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.works" */
export type EntryWorksStddevPopOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryWorksStddevSampFields = {
  __typename?: 'EntryWorksStddevSampFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.works" */
export type EntryWorksStddevSampOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryWorksSumFields = {
  __typename?: 'EntryWorksSumFields';
  entryNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.works" */
export type EntryWorksSumOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryWorksVarPopFields = {
  __typename?: 'EntryWorksVarPopFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.works" */
export type EntryWorksVarPopOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryWorksVarSampFields = {
  __typename?: 'EntryWorksVarSampFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.works" */
export type EntryWorksVarSampOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryWorksVarianceFields = {
  __typename?: 'EntryWorksVarianceFields';
  entryNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.works" */
export type EntryWorksVarianceOrderBy = {
  entryNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'Files';
  id: Scalars['Int']['output'];
  /** An object relationship */
  image?: Maybe<Images>;
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type FilesBoolExp = {
  _and?: InputMaybe<Array<FilesBoolExp>>;
  _not?: InputMaybe<FilesBoolExp>;
  _or?: InputMaybe<Array<FilesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  image?: InputMaybe<ImagesBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "files". */
export type FilesOrderBy = {
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImagesOrderBy>;
  name?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type ForgotPasswordForApplicantInputMypage = {
  id: Scalars['Int']['input'];
};

export type ForgotPasswordForApplicantInputPasswordResetEmail = {
  body: Scalars['String']['input'];
  from: ForgotPasswordForApplicantInputPasswordResetEmailFrom;
  placeholder: ForgotPasswordForApplicantInputPasswordResetEmailPlaceholder;
  subject: Scalars['String']['input'];
  to: ForgotPasswordForApplicantInputPasswordResetEmailTo;
};

export type ForgotPasswordForApplicantInputPasswordResetEmailFrom = {
  name: Scalars['String']['input'];
};

export type ForgotPasswordForApplicantInputPasswordResetEmailPlaceholder = {
  passwordResetToken: Scalars['String']['input'];
};

export type ForgotPasswordForApplicantInputPasswordResetEmailTo = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordForApplicantOutput = {
  __typename?: 'ForgotPasswordForApplicantOutput';
  email: Scalars['String']['output'];
};

export type ForgotPasswordInputPasswordResetEmail = {
  body: Scalars['String']['input'];
  from: ForgotPasswordInputPasswordResetEmailFrom;
  placeholder: ForgotPasswordInputPasswordResetEmailPlaceholder;
  subject: Scalars['String']['input'];
  to: ForgotPasswordInputPasswordResetEmailTo;
};

export type ForgotPasswordInputPasswordResetEmailFrom = {
  name: Scalars['String']['input'];
};

export type ForgotPasswordInputPasswordResetEmailPlaceholder = {
  passwordResetToken: Scalars['String']['input'];
};

export type ForgotPasswordInputPasswordResetEmailTo = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordOutput = {
  __typename?: 'ForgotPasswordOutput';
  email: Scalars['String']['output'];
};

/** columns and relationships of "genders" */
export type Genders = {
  __typename?: 'Genders';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "genders" */
export type GendersAggregate = {
  __typename?: 'GendersAggregate';
  aggregate?: Maybe<GendersAggregateFields>;
  nodes: Array<Genders>;
};

/** aggregate fields of "genders" */
export type GendersAggregateFields = {
  __typename?: 'GendersAggregateFields';
  avg?: Maybe<GendersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GendersMaxFields>;
  min?: Maybe<GendersMinFields>;
  stddev?: Maybe<GendersStddevFields>;
  stddevPop?: Maybe<GendersStddevPopFields>;
  stddevSamp?: Maybe<GendersStddevSampFields>;
  sum?: Maybe<GendersSumFields>;
  varPop?: Maybe<GendersVarPopFields>;
  varSamp?: Maybe<GendersVarSampFields>;
  variance?: Maybe<GendersVarianceFields>;
};


/** aggregate fields of "genders" */
export type GendersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GendersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GendersAvgFields = {
  __typename?: 'GendersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "genders". All fields are combined with a logical 'AND'. */
export type GendersBoolExp = {
  _and?: InputMaybe<Array<GendersBoolExp>>;
  _not?: InputMaybe<GendersBoolExp>;
  _or?: InputMaybe<Array<GendersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type GendersMaxFields = {
  __typename?: 'GendersMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type GendersMinFields = {
  __typename?: 'GendersMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "genders". */
export type GendersOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "genders" */
export enum GendersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type GendersStddevFields = {
  __typename?: 'GendersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type GendersStddevPopFields = {
  __typename?: 'GendersStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type GendersStddevSampFields = {
  __typename?: 'GendersStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "genders" */
export type GendersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GendersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GendersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type GendersSumFields = {
  __typename?: 'GendersSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type GendersVarPopFields = {
  __typename?: 'GendersVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type GendersVarSampFields = {
  __typename?: 'GendersVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GendersVarianceFields = {
  __typename?: 'GendersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type GenerateUrlForPutFileOutput = {
  __typename?: 'GenerateUrlForPutFileOutput';
  url: Scalars['String']['output'];
};

/** columns and relationships of "images" */
export type Images = {
  __typename?: 'Images';
  /** An object relationship */
  file: Files;
  fileId: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type ImagesBoolExp = {
  _and?: InputMaybe<Array<ImagesBoolExp>>;
  _not?: InputMaybe<ImagesBoolExp>;
  _or?: InputMaybe<Array<ImagesBoolExp>>;
  file?: InputMaybe<FilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "images". */
export type ImagesOrderBy = {
  file?: InputMaybe<FilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "images" */
export enum ImagesSelectColumn {
  /** column name */
  FileId = 'fileId',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "images" */
export type ImagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ImagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImagesStreamCursorValueInput = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InquireInputConfirmationEmail = {
  body: Scalars['String']['input'];
  from: InquireInputConfirmationEmailFrom;
  subject: Scalars['String']['input'];
  to: InquireInputConfirmationEmailTo;
};

export type InquireInputConfirmationEmailFrom = {
  name: Scalars['String']['input'];
};

export type InquireInputConfirmationEmailTo = {
  email: Scalars['String']['input'];
};

export type InquireInputInquiryEmail = {
  body: Scalars['String']['input'];
  from: InquireInputInquiryEmailFrom;
  replyTo?: InputMaybe<InquireInputInquiryEmailReplyTo>;
  subject: Scalars['String']['input'];
  to: InquireInputInquiryEmailTo;
};

export type InquireInputInquiryEmailFrom = {
  name: Scalars['String']['input'];
};

export type InquireInputInquiryEmailReplyTo = {
  email: Scalars['String']['input'];
};

export type InquireInputInquiryEmailTo = {
  email: Scalars['String']['input'];
};

export type InquireOutput = {
  __typename?: 'InquireOutput';
  ok: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

export type LikeContestInputContest = {
  id: Scalars['Int']['input'];
};

export type LikeContestOutput = {
  __typename?: 'LikeContestOutput';
  contest?: Maybe<Contests>;
  contestId: Scalars['Int']['output'];
};

export type LikeWorkInputWork = {
  id: Scalars['Int']['input'];
};

export type LikeWorkOutput = {
  __typename?: 'LikeWorkOutput';
  work?: Maybe<ResultWorks>;
  workId: Scalars['Int']['output'];
};

/** columns and relationships of "magazine.digital_features" */
export type MagazineDigitalFeatures = {
  __typename?: 'MagazineDigitalFeatures';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  magazine?: Maybe<Magazines>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "magazine.digital_features". All fields are combined with a logical 'AND'. */
export type MagazineDigitalFeaturesBoolExp = {
  _and?: InputMaybe<Array<MagazineDigitalFeaturesBoolExp>>;
  _not?: InputMaybe<MagazineDigitalFeaturesBoolExp>;
  _or?: InputMaybe<Array<MagazineDigitalFeaturesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  magazine?: InputMaybe<MagazinesBoolExp>;
  productId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "magazine.digital_features". */
export type MagazineDigitalFeaturesOrderBy = {
  id?: InputMaybe<OrderBy>;
  magazine?: InputMaybe<MagazinesOrderBy>;
  productId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "magazine.digitals" */
export type MagazineDigitals = {
  __typename?: 'MagazineDigitals';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  magazine?: Maybe<Magazines>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "magazine.digitals". All fields are combined with a logical 'AND'. */
export type MagazineDigitalsBoolExp = {
  _and?: InputMaybe<Array<MagazineDigitalsBoolExp>>;
  _not?: InputMaybe<MagazineDigitalsBoolExp>;
  _or?: InputMaybe<Array<MagazineDigitalsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  magazine?: InputMaybe<MagazinesBoolExp>;
  productId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "magazine.digitals". */
export type MagazineDigitalsOrderBy = {
  id?: InputMaybe<OrderBy>;
  magazine?: InputMaybe<MagazinesOrderBy>;
  productId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "magazine.introductions" */
export type MagazineIntroductions = {
  __typename?: 'MagazineIntroductions';
  /** An object relationship */
  article?: Maybe<Articles>;
};

/** Boolean expression to filter rows from the table "magazine.introductions". All fields are combined with a logical 'AND'. */
export type MagazineIntroductionsBoolExp = {
  _and?: InputMaybe<Array<MagazineIntroductionsBoolExp>>;
  _not?: InputMaybe<MagazineIntroductionsBoolExp>;
  _or?: InputMaybe<Array<MagazineIntroductionsBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
};

/** Ordering options when selecting data from "magazine.introductions". */
export type MagazineIntroductionsOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
};

/** columns and relationships of "magazine.papers" */
export type MagazinePapers = {
  __typename?: 'MagazinePapers';
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  magazine?: Maybe<Magazines>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "magazine.papers". All fields are combined with a logical 'AND'. */
export type MagazinePapersBoolExp = {
  _and?: InputMaybe<Array<MagazinePapersBoolExp>>;
  _not?: InputMaybe<MagazinePapersBoolExp>;
  _or?: InputMaybe<Array<MagazinePapersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  magazine?: InputMaybe<MagazinesBoolExp>;
  productId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "magazine.papers". */
export type MagazinePapersOrderBy = {
  id?: InputMaybe<OrderBy>;
  magazine?: InputMaybe<MagazinesOrderBy>;
  productId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "magazine.subscription" */
export type MagazineSubscription = {
  __typename?: 'MagazineSubscription';
  /** An object relationship */
  digital?: Maybe<MagazineSubscriptionDigital>;
  /** An array relationship */
  firstMagazines: Array<MagazineSubscriptionFirstMagazines>;
  /** An aggregate relationship */
  firstMagazinesAggregate: MagazineSubscriptionFirstMagazinesAggregate;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  paper?: Maybe<MagazineSubscriptionPaper>;
};


/** columns and relationships of "magazine.subscription" */
export type MagazineSubscriptionFirstMagazinesArgs = {
  distinctOn?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesOrderBy>>;
  where?: InputMaybe<MagazineSubscriptionFirstMagazinesBoolExp>;
};


/** columns and relationships of "magazine.subscription" */
export type MagazineSubscriptionFirstMagazinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesOrderBy>>;
  where?: InputMaybe<MagazineSubscriptionFirstMagazinesBoolExp>;
};

/** Boolean expression to filter rows from the table "magazine.subscription". All fields are combined with a logical 'AND'. */
export type MagazineSubscriptionBoolExp = {
  _and?: InputMaybe<Array<MagazineSubscriptionBoolExp>>;
  _not?: InputMaybe<MagazineSubscriptionBoolExp>;
  _or?: InputMaybe<Array<MagazineSubscriptionBoolExp>>;
  digital?: InputMaybe<MagazineSubscriptionDigitalBoolExp>;
  firstMagazines?: InputMaybe<MagazineSubscriptionFirstMagazinesBoolExp>;
  firstMagazinesAggregate?: InputMaybe<MagazineSubscriptionFirstMagazinesAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  paper?: InputMaybe<MagazineSubscriptionPaperBoolExp>;
};

/** columns and relationships of "magazine.subscription_digital" */
export type MagazineSubscriptionDigital = {
  __typename?: 'MagazineSubscriptionDigital';
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "magazine.subscription_digital". All fields are combined with a logical 'AND'. */
export type MagazineSubscriptionDigitalBoolExp = {
  _and?: InputMaybe<Array<MagazineSubscriptionDigitalBoolExp>>;
  _not?: InputMaybe<MagazineSubscriptionDigitalBoolExp>;
  _or?: InputMaybe<Array<MagazineSubscriptionDigitalBoolExp>>;
  productId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "magazine.subscription_digital". */
export type MagazineSubscriptionDigitalOrderBy = {
  productId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazines = {
  __typename?: 'MagazineSubscriptionFirstMagazines';
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  releasedAt?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  thumbnail?: Maybe<MagazineThumbnails>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeName?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesAggregate = {
  __typename?: 'MagazineSubscriptionFirstMagazinesAggregate';
  aggregate?: Maybe<MagazineSubscriptionFirstMagazinesAggregateFields>;
  nodes: Array<MagazineSubscriptionFirstMagazines>;
};

export type MagazineSubscriptionFirstMagazinesAggregateBoolExp = {
  count?: InputMaybe<MagazineSubscriptionFirstMagazinesAggregateBoolExpCount>;
};

/** aggregate fields of "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesAggregateFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesAggregateFields';
  avg?: Maybe<MagazineSubscriptionFirstMagazinesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MagazineSubscriptionFirstMagazinesMaxFields>;
  min?: Maybe<MagazineSubscriptionFirstMagazinesMinFields>;
  stddev?: Maybe<MagazineSubscriptionFirstMagazinesStddevFields>;
  stddevPop?: Maybe<MagazineSubscriptionFirstMagazinesStddevPopFields>;
  stddevSamp?: Maybe<MagazineSubscriptionFirstMagazinesStddevSampFields>;
  sum?: Maybe<MagazineSubscriptionFirstMagazinesSumFields>;
  varPop?: Maybe<MagazineSubscriptionFirstMagazinesVarPopFields>;
  varSamp?: Maybe<MagazineSubscriptionFirstMagazinesVarSampFields>;
  variance?: Maybe<MagazineSubscriptionFirstMagazinesVarianceFields>;
};


/** aggregate fields of "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesAggregateOrderBy = {
  avg?: InputMaybe<MagazineSubscriptionFirstMagazinesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MagazineSubscriptionFirstMagazinesMaxOrderBy>;
  min?: InputMaybe<MagazineSubscriptionFirstMagazinesMinOrderBy>;
  stddev?: InputMaybe<MagazineSubscriptionFirstMagazinesStddevOrderBy>;
  stddevPop?: InputMaybe<MagazineSubscriptionFirstMagazinesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MagazineSubscriptionFirstMagazinesStddevSampOrderBy>;
  sum?: InputMaybe<MagazineSubscriptionFirstMagazinesSumOrderBy>;
  varPop?: InputMaybe<MagazineSubscriptionFirstMagazinesVarPopOrderBy>;
  varSamp?: InputMaybe<MagazineSubscriptionFirstMagazinesVarSampOrderBy>;
  variance?: InputMaybe<MagazineSubscriptionFirstMagazinesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type MagazineSubscriptionFirstMagazinesAvgFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "magazine.subscription_first_magazines". All fields are combined with a logical 'AND'. */
export type MagazineSubscriptionFirstMagazinesBoolExp = {
  _and?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesBoolExp>>;
  _not?: InputMaybe<MagazineSubscriptionFirstMagazinesBoolExp>;
  _or?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  releasedAt?: InputMaybe<DateComparisonExp>;
  thumbnail?: InputMaybe<MagazineThumbnailsBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  volume?: InputMaybe<IntComparisonExp>;
  volumeName?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type MagazineSubscriptionFirstMagazinesMaxFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  releasedAt?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeName?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  releasedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
  volumeName?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MagazineSubscriptionFirstMagazinesMinFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  releasedAt?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeName?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  releasedAt?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
  volumeName?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "magazine.subscription_first_magazines". */
export type MagazineSubscriptionFirstMagazinesOrderBy = {
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  releasedAt?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<MagazineThumbnailsOrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
  volumeName?: InputMaybe<OrderBy>;
};

/** select columns of table "magazine.subscription_first_magazines" */
export enum MagazineSubscriptionFirstMagazinesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  ReleasedAt = 'releasedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Volume = 'volume',
  /** column name */
  VolumeName = 'volumeName'
}

/** aggregate stddev on columns */
export type MagazineSubscriptionFirstMagazinesStddevFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MagazineSubscriptionFirstMagazinesStddevPopFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MagazineSubscriptionFirstMagazinesStddevSampFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MagazineSubscriptionFirstMagazinesSumFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type MagazineSubscriptionFirstMagazinesVarPopFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MagazineSubscriptionFirstMagazinesVarSampFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MagazineSubscriptionFirstMagazinesVarianceFields = {
  __typename?: 'MagazineSubscriptionFirstMagazinesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "magazine.subscription_first_magazines" */
export type MagazineSubscriptionFirstMagazinesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "magazine.subscription". */
export type MagazineSubscriptionOrderBy = {
  digital?: InputMaybe<MagazineSubscriptionDigitalOrderBy>;
  firstMagazinesAggregate?: InputMaybe<MagazineSubscriptionFirstMagazinesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  paper?: InputMaybe<MagazineSubscriptionPaperOrderBy>;
};

/** columns and relationships of "magazine.subscription_paper" */
export type MagazineSubscriptionPaper = {
  __typename?: 'MagazineSubscriptionPaper';
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "magazine.subscription_paper". All fields are combined with a logical 'AND'. */
export type MagazineSubscriptionPaperBoolExp = {
  _and?: InputMaybe<Array<MagazineSubscriptionPaperBoolExp>>;
  _not?: InputMaybe<MagazineSubscriptionPaperBoolExp>;
  _or?: InputMaybe<Array<MagazineSubscriptionPaperBoolExp>>;
  productId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "magazine.subscription_paper". */
export type MagazineSubscriptionPaperOrderBy = {
  productId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "magazine.subscription" */
export enum MagazineSubscriptionSelectColumn {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "magazine_subscription" */
export type MagazineSubscriptionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MagazineSubscriptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MagazineSubscriptionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "magazine.thumbnails" */
export type MagazineThumbnails = {
  __typename?: 'MagazineThumbnails';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
};

/** Boolean expression to filter rows from the table "magazine.thumbnails". All fields are combined with a logical 'AND'. */
export type MagazineThumbnailsBoolExp = {
  _and?: InputMaybe<Array<MagazineThumbnailsBoolExp>>;
  _not?: InputMaybe<MagazineThumbnailsBoolExp>;
  _or?: InputMaybe<Array<MagazineThumbnailsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
};

/** Ordering options when selecting data from "magazine.thumbnails". */
export type MagazineThumbnailsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
};

/** columns and relationships of "monthly_periods" */
export type MonthlyPeriods = {
  __typename?: 'MonthlyPeriods';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "monthly_periods" */
export type MonthlyPeriodsAggregate = {
  __typename?: 'MonthlyPeriodsAggregate';
  aggregate?: Maybe<MonthlyPeriodsAggregateFields>;
  nodes: Array<MonthlyPeriods>;
};

/** aggregate fields of "monthly_periods" */
export type MonthlyPeriodsAggregateFields = {
  __typename?: 'MonthlyPeriodsAggregateFields';
  avg?: Maybe<MonthlyPeriodsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MonthlyPeriodsMaxFields>;
  min?: Maybe<MonthlyPeriodsMinFields>;
  stddev?: Maybe<MonthlyPeriodsStddevFields>;
  stddevPop?: Maybe<MonthlyPeriodsStddevPopFields>;
  stddevSamp?: Maybe<MonthlyPeriodsStddevSampFields>;
  sum?: Maybe<MonthlyPeriodsSumFields>;
  varPop?: Maybe<MonthlyPeriodsVarPopFields>;
  varSamp?: Maybe<MonthlyPeriodsVarSampFields>;
  variance?: Maybe<MonthlyPeriodsVarianceFields>;
};


/** aggregate fields of "monthly_periods" */
export type MonthlyPeriodsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MonthlyPeriodsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type MonthlyPeriodsAvgFields = {
  __typename?: 'MonthlyPeriodsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "monthly_periods". All fields are combined with a logical 'AND'. */
export type MonthlyPeriodsBoolExp = {
  _and?: InputMaybe<Array<MonthlyPeriodsBoolExp>>;
  _not?: InputMaybe<MonthlyPeriodsBoolExp>;
  _or?: InputMaybe<Array<MonthlyPeriodsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type MonthlyPeriodsMaxFields = {
  __typename?: 'MonthlyPeriodsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MonthlyPeriodsMinFields = {
  __typename?: 'MonthlyPeriodsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "monthly_periods". */
export type MonthlyPeriodsOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "monthly_periods" */
export enum MonthlyPeriodsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type MonthlyPeriodsStddevFields = {
  __typename?: 'MonthlyPeriodsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type MonthlyPeriodsStddevPopFields = {
  __typename?: 'MonthlyPeriodsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type MonthlyPeriodsStddevSampFields = {
  __typename?: 'MonthlyPeriodsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "monthly_periods" */
export type MonthlyPeriodsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MonthlyPeriodsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MonthlyPeriodsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type MonthlyPeriodsSumFields = {
  __typename?: 'MonthlyPeriodsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type MonthlyPeriodsVarPopFields = {
  __typename?: 'MonthlyPeriodsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type MonthlyPeriodsVarSampFields = {
  __typename?: 'MonthlyPeriodsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MonthlyPeriodsVarianceFields = {
  __typename?: 'MonthlyPeriodsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Int']['output'];
  at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  items: Array<OrderItem>;
  payment: Payment;
  shipping?: Maybe<OrderShipping>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  price: Price;
  quantity: Scalars['Int']['output'];
};

export type OrderShipping = {
  __typename?: 'OrderShipping';
  address?: Maybe<Address>;
  name: Scalars['String']['output'];
};

export type Orders = {
  __typename?: 'Orders';
  data: Array<Order>;
  hasMore: Scalars['Boolean']['output'];
};

export type OrganizationAddress = {
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  prefecture: Prefecture;
  zipCode: Scalars['String']['input'];
};

export type OrganizationClassification = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "organizer.classifications" */
export type OrganizerClassifications = {
  __typename?: 'OrganizerClassifications';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  organizations: Array<OrganizerOrganizations>;
};


/** columns and relationships of "organizer.classifications" */
export type OrganizerClassificationsOrganizationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizerOrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizerOrganizationsOrderBy>>;
  where?: InputMaybe<OrganizerOrganizationsBoolExp>;
};

/** aggregated selection of "organizer.classifications" */
export type OrganizerClassificationsAggregate = {
  __typename?: 'OrganizerClassificationsAggregate';
  aggregate?: Maybe<OrganizerClassificationsAggregateFields>;
  nodes: Array<OrganizerClassifications>;
};

/** aggregate fields of "organizer.classifications" */
export type OrganizerClassificationsAggregateFields = {
  __typename?: 'OrganizerClassificationsAggregateFields';
  avg?: Maybe<OrganizerClassificationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OrganizerClassificationsMaxFields>;
  min?: Maybe<OrganizerClassificationsMinFields>;
  stddev?: Maybe<OrganizerClassificationsStddevFields>;
  stddevPop?: Maybe<OrganizerClassificationsStddevPopFields>;
  stddevSamp?: Maybe<OrganizerClassificationsStddevSampFields>;
  sum?: Maybe<OrganizerClassificationsSumFields>;
  varPop?: Maybe<OrganizerClassificationsVarPopFields>;
  varSamp?: Maybe<OrganizerClassificationsVarSampFields>;
  variance?: Maybe<OrganizerClassificationsVarianceFields>;
};


/** aggregate fields of "organizer.classifications" */
export type OrganizerClassificationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizerClassificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OrganizerClassificationsAvgFields = {
  __typename?: 'OrganizerClassificationsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "organizer.classifications". All fields are combined with a logical 'AND'. */
export type OrganizerClassificationsBoolExp = {
  _and?: InputMaybe<Array<OrganizerClassificationsBoolExp>>;
  _not?: InputMaybe<OrganizerClassificationsBoolExp>;
  _or?: InputMaybe<Array<OrganizerClassificationsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizations?: InputMaybe<OrganizerOrganizationsBoolExp>;
};

/** aggregate max on columns */
export type OrganizerClassificationsMaxFields = {
  __typename?: 'OrganizerClassificationsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type OrganizerClassificationsMinFields = {
  __typename?: 'OrganizerClassificationsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "organizer.classifications". */
export type OrganizerClassificationsOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationsAggregate?: InputMaybe<OrganizerOrganizationsAggregateOrderBy>;
};

/** select columns of table "organizer.classifications" */
export enum OrganizerClassificationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type OrganizerClassificationsStddevFields = {
  __typename?: 'OrganizerClassificationsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type OrganizerClassificationsStddevPopFields = {
  __typename?: 'OrganizerClassificationsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type OrganizerClassificationsStddevSampFields = {
  __typename?: 'OrganizerClassificationsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "organizer_classifications" */
export type OrganizerClassificationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizerClassificationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizerClassificationsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OrganizerClassificationsSumFields = {
  __typename?: 'OrganizerClassificationsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type OrganizerClassificationsVarPopFields = {
  __typename?: 'OrganizerClassificationsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type OrganizerClassificationsVarSampFields = {
  __typename?: 'OrganizerClassificationsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OrganizerClassificationsVarianceFields = {
  __typename?: 'OrganizerClassificationsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "organizer.members" */
export type OrganizerMembers = {
  __typename?: 'OrganizerMembers';
  id: Scalars['Int']['output'];
  /** An object relationship */
  organization: OrganizerOrganizations;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** order by aggregate values of table "organizer.members" */
export type OrganizerMembersAggregateOrderBy = {
  avg?: InputMaybe<OrganizerMembersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizerMembersMaxOrderBy>;
  min?: InputMaybe<OrganizerMembersMinOrderBy>;
  stddev?: InputMaybe<OrganizerMembersStddevOrderBy>;
  stddevPop?: InputMaybe<OrganizerMembersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<OrganizerMembersStddevSampOrderBy>;
  sum?: InputMaybe<OrganizerMembersSumOrderBy>;
  varPop?: InputMaybe<OrganizerMembersVarPopOrderBy>;
  varSamp?: InputMaybe<OrganizerMembersVarSampOrderBy>;
  variance?: InputMaybe<OrganizerMembersVarianceOrderBy>;
};

/** order by avg() on columns of table "organizer.members" */
export type OrganizerMembersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organizer.members". All fields are combined with a logical 'AND'. */
export type OrganizerMembersBoolExp = {
  _and?: InputMaybe<Array<OrganizerMembersBoolExp>>;
  _not?: InputMaybe<OrganizerMembersBoolExp>;
  _or?: InputMaybe<Array<OrganizerMembersBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  organization?: InputMaybe<OrganizerOrganizationsBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** order by max() on columns of table "organizer.members" */
export type OrganizerMembersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "organizer.members" */
export type OrganizerMembersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "organizer.members". */
export type OrganizerMembersOrderBy = {
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizerOrganizationsOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** select columns of table "organizer.members" */
export enum OrganizerMembersSelectColumn {
  /** column name */
  Id = 'id'
}

/** order by stddev() on columns of table "organizer.members" */
export type OrganizerMembersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "organizer.members" */
export type OrganizerMembersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "organizer.members" */
export type OrganizerMembersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "organizer.members" */
export type OrganizerMembersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "organizer.members" */
export type OrganizerMembersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "organizer.members" */
export type OrganizerMembersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "organizer.members" */
export type OrganizerMembersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "organizer.organizations" */
export type OrganizerOrganizations = {
  __typename?: 'OrganizerOrganizations';
  /** An object relationship */
  classification?: Maybe<OrganizerClassifications>;
  /** An array relationship */
  contests: Array<Contests>;
  /** An aggregate relationship */
  contestsAggregate: ContestsAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  members: Array<OrganizerMembers>;
};


/** columns and relationships of "organizer.organizations" */
export type OrganizerOrganizationsContestsArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


/** columns and relationships of "organizer.organizations" */
export type OrganizerOrganizationsContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


/** columns and relationships of "organizer.organizations" */
export type OrganizerOrganizationsMembersArgs = {
  distinctOn?: InputMaybe<Array<OrganizerMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizerMembersOrderBy>>;
  where?: InputMaybe<OrganizerMembersBoolExp>;
};

/** order by aggregate values of table "organizer.organizations" */
export type OrganizerOrganizationsAggregateOrderBy = {
  avg?: InputMaybe<OrganizerOrganizationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizerOrganizationsMaxOrderBy>;
  min?: InputMaybe<OrganizerOrganizationsMinOrderBy>;
  stddev?: InputMaybe<OrganizerOrganizationsStddevOrderBy>;
  stddevPop?: InputMaybe<OrganizerOrganizationsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<OrganizerOrganizationsStddevSampOrderBy>;
  sum?: InputMaybe<OrganizerOrganizationsSumOrderBy>;
  varPop?: InputMaybe<OrganizerOrganizationsVarPopOrderBy>;
  varSamp?: InputMaybe<OrganizerOrganizationsVarSampOrderBy>;
  variance?: InputMaybe<OrganizerOrganizationsVarianceOrderBy>;
};

/** order by avg() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organizer.organizations". All fields are combined with a logical 'AND'. */
export type OrganizerOrganizationsBoolExp = {
  _and?: InputMaybe<Array<OrganizerOrganizationsBoolExp>>;
  _not?: InputMaybe<OrganizerOrganizationsBoolExp>;
  _or?: InputMaybe<Array<OrganizerOrganizationsBoolExp>>;
  classification?: InputMaybe<OrganizerClassificationsBoolExp>;
  contests?: InputMaybe<ContestsBoolExp>;
  contestsAggregate?: InputMaybe<ContestsAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  members?: InputMaybe<OrganizerMembersBoolExp>;
};

/** order by max() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "organizer.organizations". */
export type OrganizerOrganizationsOrderBy = {
  classification?: InputMaybe<OrganizerClassificationsOrderBy>;
  contestsAggregate?: InputMaybe<ContestsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  membersAggregate?: InputMaybe<OrganizerMembersAggregateOrderBy>;
};

/** select columns of table "organizer.organizations" */
export enum OrganizerOrganizationsSelectColumn {
  /** column name */
  Id = 'id'
}

/** order by stddev() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "organizer.organizations" */
export type OrganizerOrganizationsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

export type Payment = {
  __typename?: 'Payment';
  method?: Maybe<PaymentMethod>;
  status: Scalars['String']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  detail?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Prefecture = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "prefectures" */
export type Prefectures = {
  __typename?: 'Prefectures';
  id: Scalars['Int']['output'];
  isDomestic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  region: Regions;
  regionId: Scalars['Int']['output'];
};

/** order by aggregate values of table "prefectures" */
export type PrefecturesAggregateOrderBy = {
  avg?: InputMaybe<PrefecturesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PrefecturesMaxOrderBy>;
  min?: InputMaybe<PrefecturesMinOrderBy>;
  stddev?: InputMaybe<PrefecturesStddevOrderBy>;
  stddevPop?: InputMaybe<PrefecturesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PrefecturesStddevSampOrderBy>;
  sum?: InputMaybe<PrefecturesSumOrderBy>;
  varPop?: InputMaybe<PrefecturesVarPopOrderBy>;
  varSamp?: InputMaybe<PrefecturesVarSampOrderBy>;
  variance?: InputMaybe<PrefecturesVarianceOrderBy>;
};

/** order by avg() on columns of table "prefectures" */
export type PrefecturesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "prefectures". All fields are combined with a logical 'AND'. */
export type PrefecturesBoolExp = {
  _and?: InputMaybe<Array<PrefecturesBoolExp>>;
  _not?: InputMaybe<PrefecturesBoolExp>;
  _or?: InputMaybe<Array<PrefecturesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  isDomestic?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  region?: InputMaybe<RegionsBoolExp>;
  regionId?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "prefectures" */
export type PrefecturesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "prefectures" */
export type PrefecturesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "prefectures". */
export type PrefecturesOrderBy = {
  id?: InputMaybe<OrderBy>;
  isDomestic?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  region?: InputMaybe<RegionsOrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** select columns of table "prefectures" */
export enum PrefecturesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsDomestic = 'isDomestic',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'regionId'
}

/** order by stddev() on columns of table "prefectures" */
export type PrefecturesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "prefectures" */
export type PrefecturesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "prefectures" */
export type PrefecturesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "prefectures" */
export type PrefecturesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PrefecturesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PrefecturesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDomestic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "prefectures" */
export type PrefecturesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "prefectures" */
export type PrefecturesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "prefectures" */
export type PrefecturesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "prefectures" */
export type PrefecturesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  product: Product;
  recurring?: Maybe<PriceRecurring>;
};

export type PriceRecurring = {
  __typename?: 'PriceRecurring';
  interval: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  defaultPrice?: Maybe<Price>;
  digitalMagazine?: Maybe<MagazineDigitals>;
  digitalMagazineFeature?: Maybe<MagazineDigitalFeatures>;
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  paperMagazine?: Maybe<MagazinePapers>;
  prices: Array<Price>;
};

export type PublicVoteInputVoter = {
  ipAddress: Scalars['String']['input'];
  userAgent: Scalars['String']['input'];
};

export type PublicVoteInputWork = {
  id: Scalars['Int']['input'];
};

export type PublicVoteOutput = {
  __typename?: 'PublicVoteOutput';
  count: Scalars['Int']['output'];
};

/** columns and relationships of "regions" */
export type Regions = {
  __typename?: 'Regions';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  prefectures: Array<Prefectures>;
};


/** columns and relationships of "regions" */
export type RegionsPrefecturesArgs = {
  distinctOn?: InputMaybe<Array<PrefecturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrefecturesOrderBy>>;
  where?: InputMaybe<PrefecturesBoolExp>;
};

/** Boolean expression to filter rows from the table "regions". All fields are combined with a logical 'AND'. */
export type RegionsBoolExp = {
  _and?: InputMaybe<Array<RegionsBoolExp>>;
  _not?: InputMaybe<RegionsBoolExp>;
  _or?: InputMaybe<Array<RegionsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  prefectures?: InputMaybe<PrefecturesBoolExp>;
};

/** Ordering options when selecting data from "regions". */
export type RegionsOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  prefecturesAggregate?: InputMaybe<PrefecturesAggregateOrderBy>;
};

/** select columns of table "regions" */
export enum RegionsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "regions" */
export type RegionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RegionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RegionsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordForApplicantOutput = {
  __typename?: 'ResetPasswordForApplicantOutput';
  userId: Scalars['String']['output'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  user?: Maybe<Users>;
  userId: Scalars['String']['output'];
};

/** columns and relationships of "result.ceremonies" */
export type ResultCeremonies = {
  __typename?: 'ResultCeremonies';
  /** An object relationship */
  contest?: Maybe<Contests>;
  program?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  result?: Maybe<Results>;
  /** A computed field, executes function "result.ceremony_youtube_live_url" */
  youtubeLiveUrl?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "result.ceremonies" */
export type ResultCeremoniesYoutubeLiveUrlArgs = {
  args: YoutubeLiveUrl_Result_Ceremonies_Args;
};

/** aggregated selection of "result.ceremonies" */
export type ResultCeremoniesAggregate = {
  __typename?: 'ResultCeremoniesAggregate';
  aggregate?: Maybe<ResultCeremoniesAggregateFields>;
  nodes: Array<ResultCeremonies>;
};

/** aggregate fields of "result.ceremonies" */
export type ResultCeremoniesAggregateFields = {
  __typename?: 'ResultCeremoniesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ResultCeremoniesMaxFields>;
  min?: Maybe<ResultCeremoniesMinFields>;
};


/** aggregate fields of "result.ceremonies" */
export type ResultCeremoniesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResultCeremoniesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "result.ceremonies". All fields are combined with a logical 'AND'. */
export type ResultCeremoniesBoolExp = {
  _and?: InputMaybe<Array<ResultCeremoniesBoolExp>>;
  _not?: InputMaybe<ResultCeremoniesBoolExp>;
  _or?: InputMaybe<Array<ResultCeremoniesBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  program?: InputMaybe<StringComparisonExp>;
  result?: InputMaybe<ResultsBoolExp>;
};

/** aggregate max on columns */
export type ResultCeremoniesMaxFields = {
  __typename?: 'ResultCeremoniesMaxFields';
  program?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "result.ceremony_youtube_live_url" */
  youtubeLiveUrl?: Maybe<Scalars['String']['output']>;
};


/** aggregate max on columns */
export type ResultCeremoniesMaxFieldsYoutubeLiveUrlArgs = {
  args: YoutubeLiveUrl_Result_Ceremonies_Args;
};

/** aggregate min on columns */
export type ResultCeremoniesMinFields = {
  __typename?: 'ResultCeremoniesMinFields';
  program?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "result.ceremony_youtube_live_url" */
  youtubeLiveUrl?: Maybe<Scalars['String']['output']>;
};


/** aggregate min on columns */
export type ResultCeremoniesMinFieldsYoutubeLiveUrlArgs = {
  args: YoutubeLiveUrl_Result_Ceremonies_Args;
};

/** Ordering options when selecting data from "result.ceremonies". */
export type ResultCeremoniesOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  program?: InputMaybe<OrderBy>;
  result?: InputMaybe<ResultsOrderBy>;
};

/** select columns of table "result.ceremonies" */
export enum ResultCeremoniesSelectColumn {
  /** column name */
  Program = 'program'
}

/** Streaming cursor of the table "result_ceremonies" */
export type ResultCeremoniesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResultCeremoniesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResultCeremoniesStreamCursorValueInput = {
  program?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "result.taggings" */
export type ResultTaggings = {
  __typename?: 'ResultTaggings';
  /** An object relationship */
  tag?: Maybe<ResultTags>;
};

/** order by aggregate values of table "result.taggings" */
export type ResultTaggingsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "result.taggings". All fields are combined with a logical 'AND'. */
export type ResultTaggingsBoolExp = {
  _and?: InputMaybe<Array<ResultTaggingsBoolExp>>;
  _not?: InputMaybe<ResultTaggingsBoolExp>;
  _or?: InputMaybe<Array<ResultTaggingsBoolExp>>;
  tag?: InputMaybe<ResultTagsBoolExp>;
};

/** Ordering options when selecting data from "result.taggings". */
export type ResultTaggingsOrderBy = {
  tag?: InputMaybe<ResultTagsOrderBy>;
};

/** columns and relationships of "result.tags" */
export type ResultTags = {
  __typename?: 'ResultTags';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "result.tags" */
export type ResultTagsAggregate = {
  __typename?: 'ResultTagsAggregate';
  aggregate?: Maybe<ResultTagsAggregateFields>;
  nodes: Array<ResultTags>;
};

/** aggregate fields of "result.tags" */
export type ResultTagsAggregateFields = {
  __typename?: 'ResultTagsAggregateFields';
  avg?: Maybe<ResultTagsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ResultTagsMaxFields>;
  min?: Maybe<ResultTagsMinFields>;
  stddev?: Maybe<ResultTagsStddevFields>;
  stddevPop?: Maybe<ResultTagsStddevPopFields>;
  stddevSamp?: Maybe<ResultTagsStddevSampFields>;
  sum?: Maybe<ResultTagsSumFields>;
  varPop?: Maybe<ResultTagsVarPopFields>;
  varSamp?: Maybe<ResultTagsVarSampFields>;
  variance?: Maybe<ResultTagsVarianceFields>;
};


/** aggregate fields of "result.tags" */
export type ResultTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResultTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ResultTagsAvgFields = {
  __typename?: 'ResultTagsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "result.tags". All fields are combined with a logical 'AND'. */
export type ResultTagsBoolExp = {
  _and?: InputMaybe<Array<ResultTagsBoolExp>>;
  _not?: InputMaybe<ResultTagsBoolExp>;
  _or?: InputMaybe<Array<ResultTagsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ResultTagsMaxFields = {
  __typename?: 'ResultTagsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ResultTagsMinFields = {
  __typename?: 'ResultTagsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "result.tags". */
export type ResultTagsOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "result.tags" */
export enum ResultTagsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type ResultTagsStddevFields = {
  __typename?: 'ResultTagsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ResultTagsStddevPopFields = {
  __typename?: 'ResultTagsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ResultTagsStddevSampFields = {
  __typename?: 'ResultTagsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "result_tags" */
export type ResultTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResultTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResultTagsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ResultTagsSumFields = {
  __typename?: 'ResultTagsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ResultTagsVarPopFields = {
  __typename?: 'ResultTagsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ResultTagsVarSampFields = {
  __typename?: 'ResultTagsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ResultTagsVarianceFields = {
  __typename?: 'ResultTagsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "result.work_likes" */
export type ResultWorkLikes = {
  __typename?: 'ResultWorkLikes';
  /** An object relationship */
  creator?: Maybe<Creators>;
  likedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  work?: Maybe<ResultWorks>;
};

/** aggregated selection of "result.work_likes" */
export type ResultWorkLikesAggregate = {
  __typename?: 'ResultWorkLikesAggregate';
  aggregate?: Maybe<ResultWorkLikesAggregateFields>;
  nodes: Array<ResultWorkLikes>;
};

export type ResultWorkLikesAggregateBoolExp = {
  count?: InputMaybe<ResultWorkLikesAggregateBoolExpCount>;
};

/** aggregate fields of "result.work_likes" */
export type ResultWorkLikesAggregateFields = {
  __typename?: 'ResultWorkLikesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ResultWorkLikesMaxFields>;
  min?: Maybe<ResultWorkLikesMinFields>;
};


/** aggregate fields of "result.work_likes" */
export type ResultWorkLikesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResultWorkLikesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "result.work_likes" */
export type ResultWorkLikesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ResultWorkLikesMaxOrderBy>;
  min?: InputMaybe<ResultWorkLikesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "result.work_likes". All fields are combined with a logical 'AND'. */
export type ResultWorkLikesBoolExp = {
  _and?: InputMaybe<Array<ResultWorkLikesBoolExp>>;
  _not?: InputMaybe<ResultWorkLikesBoolExp>;
  _or?: InputMaybe<Array<ResultWorkLikesBoolExp>>;
  creator?: InputMaybe<CreatorsBoolExp>;
  likedAt?: InputMaybe<TimestampComparisonExp>;
  work?: InputMaybe<ResultWorksBoolExp>;
};

/** aggregate max on columns */
export type ResultWorkLikesMaxFields = {
  __typename?: 'ResultWorkLikesMaxFields';
  likedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "result.work_likes" */
export type ResultWorkLikesMaxOrderBy = {
  likedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ResultWorkLikesMinFields = {
  __typename?: 'ResultWorkLikesMinFields';
  likedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "result.work_likes" */
export type ResultWorkLikesMinOrderBy = {
  likedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "result.work_likes". */
export type ResultWorkLikesOrderBy = {
  creator?: InputMaybe<CreatorsOrderBy>;
  likedAt?: InputMaybe<OrderBy>;
  work?: InputMaybe<ResultWorksOrderBy>;
};

/** select columns of table "result.work_likes" */
export enum ResultWorkLikesSelectColumn {
  /** column name */
  LikedAt = 'likedAt'
}

/** columns and relationships of "result.work_reviewer_icons" */
export type ResultWorkReviewerIcons = {
  __typename?: 'ResultWorkReviewerIcons';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
  fileId?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "result.work_reviewer_icons". All fields are combined with a logical 'AND'. */
export type ResultWorkReviewerIconsBoolExp = {
  _and?: InputMaybe<Array<ResultWorkReviewerIconsBoolExp>>;
  _not?: InputMaybe<ResultWorkReviewerIconsBoolExp>;
  _or?: InputMaybe<Array<ResultWorkReviewerIconsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "result.work_reviewer_icons". */
export type ResultWorkReviewerIconsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "result.work_reviewers" */
export type ResultWorkReviewers = {
  __typename?: 'ResultWorkReviewers';
  /** An object relationship */
  icon?: Maybe<ResultWorkReviewerIcons>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  reviews: Array<ResultWorkReviews>;
};


/** columns and relationships of "result.work_reviewers" */
export type ResultWorkReviewersReviewsArgs = {
  distinctOn?: InputMaybe<Array<ResultWorkReviewsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorkReviewsOrderBy>>;
  where?: InputMaybe<ResultWorkReviewsBoolExp>;
};

/** Boolean expression to filter rows from the table "result.work_reviewers". All fields are combined with a logical 'AND'. */
export type ResultWorkReviewersBoolExp = {
  _and?: InputMaybe<Array<ResultWorkReviewersBoolExp>>;
  _not?: InputMaybe<ResultWorkReviewersBoolExp>;
  _or?: InputMaybe<Array<ResultWorkReviewersBoolExp>>;
  icon?: InputMaybe<ResultWorkReviewerIconsBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  reviews?: InputMaybe<ResultWorkReviewsBoolExp>;
};

/** Ordering options when selecting data from "result.work_reviewers". */
export type ResultWorkReviewersOrderBy = {
  icon?: InputMaybe<ResultWorkReviewerIconsOrderBy>;
  name?: InputMaybe<OrderBy>;
  reviewsAggregate?: InputMaybe<ResultWorkReviewsAggregateOrderBy>;
};

/** columns and relationships of "result.work_reviews" */
export type ResultWorkReviews = {
  __typename?: 'ResultWorkReviews';
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  reviewer?: Maybe<ResultWorkReviewers>;
};

/** order by aggregate values of table "result.work_reviews" */
export type ResultWorkReviewsAggregateOrderBy = {
  avg?: InputMaybe<ResultWorkReviewsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ResultWorkReviewsMaxOrderBy>;
  min?: InputMaybe<ResultWorkReviewsMinOrderBy>;
  stddev?: InputMaybe<ResultWorkReviewsStddevOrderBy>;
  stddevPop?: InputMaybe<ResultWorkReviewsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ResultWorkReviewsStddevSampOrderBy>;
  sum?: InputMaybe<ResultWorkReviewsSumOrderBy>;
  varPop?: InputMaybe<ResultWorkReviewsVarPopOrderBy>;
  varSamp?: InputMaybe<ResultWorkReviewsVarSampOrderBy>;
  variance?: InputMaybe<ResultWorkReviewsVarianceOrderBy>;
};

/** order by avg() on columns of table "result.work_reviews" */
export type ResultWorkReviewsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "result.work_reviews". All fields are combined with a logical 'AND'. */
export type ResultWorkReviewsBoolExp = {
  _and?: InputMaybe<Array<ResultWorkReviewsBoolExp>>;
  _not?: InputMaybe<ResultWorkReviewsBoolExp>;
  _or?: InputMaybe<Array<ResultWorkReviewsBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  reviewer?: InputMaybe<ResultWorkReviewersBoolExp>;
};

/** order by max() on columns of table "result.work_reviews" */
export type ResultWorkReviewsMaxOrderBy = {
  comment?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "result.work_reviews" */
export type ResultWorkReviewsMinOrderBy = {
  comment?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "result.work_reviews". */
export type ResultWorkReviewsOrderBy = {
  comment?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reviewer?: InputMaybe<ResultWorkReviewersOrderBy>;
};

/** select columns of table "result.work_reviews" */
export enum ResultWorkReviewsSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id'
}

/** order by stddev() on columns of table "result.work_reviews" */
export type ResultWorkReviewsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "result.work_reviews" */
export type ResultWorkReviewsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "result.work_reviews" */
export type ResultWorkReviewsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "result.work_reviews" */
export type ResultWorkReviewsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "result.work_reviews" */
export type ResultWorkReviewsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "result.work_reviews" */
export type ResultWorkReviewsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "result.work_reviews" */
export type ResultWorkReviewsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "result.work_thumbnails" */
export type ResultWorkThumbnails = {
  __typename?: 'ResultWorkThumbnails';
  /** An object relationship */
  file?: Maybe<StrapiFiles>;
  fileId?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "result.work_thumbnails". All fields are combined with a logical 'AND'. */
export type ResultWorkThumbnailsBoolExp = {
  _and?: InputMaybe<Array<ResultWorkThumbnailsBoolExp>>;
  _not?: InputMaybe<ResultWorkThumbnailsBoolExp>;
  _or?: InputMaybe<Array<ResultWorkThumbnailsBoolExp>>;
  file?: InputMaybe<StrapiFilesBoolExp>;
  fileId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "result.work_thumbnails". */
export type ResultWorkThumbnailsOrderBy = {
  file?: InputMaybe<StrapiFilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "result.works" */
export type ResultWorks = {
  __typename?: 'ResultWorks';
  applicant?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "result.work_is_liked" */
  isLiked?: Maybe<Scalars['Boolean']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  likes: Array<ResultWorkLikes>;
  /** An aggregate relationship */
  likesAggregate: ResultWorkLikesAggregate;
  prize?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  result?: Maybe<Results>;
  /** An array relationship */
  reviews: Array<ResultWorkReviews>;
  /** An object relationship */
  thumbnail?: Maybe<ResultWorkThumbnails>;
};


/** columns and relationships of "result.works" */
export type ResultWorksLikesArgs = {
  distinctOn?: InputMaybe<Array<ResultWorkLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorkLikesOrderBy>>;
  where?: InputMaybe<ResultWorkLikesBoolExp>;
};


/** columns and relationships of "result.works" */
export type ResultWorksLikesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultWorkLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorkLikesOrderBy>>;
  where?: InputMaybe<ResultWorkLikesBoolExp>;
};


/** columns and relationships of "result.works" */
export type ResultWorksReviewsArgs = {
  distinctOn?: InputMaybe<Array<ResultWorkReviewsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorkReviewsOrderBy>>;
  where?: InputMaybe<ResultWorkReviewsBoolExp>;
};

/** aggregated selection of "result.works" */
export type ResultWorksAggregate = {
  __typename?: 'ResultWorksAggregate';
  aggregate?: Maybe<ResultWorksAggregateFields>;
  nodes: Array<ResultWorks>;
};

export type ResultWorksAggregateBoolExp = {
  count?: InputMaybe<ResultWorksAggregateBoolExpCount>;
};

/** aggregate fields of "result.works" */
export type ResultWorksAggregateFields = {
  __typename?: 'ResultWorksAggregateFields';
  avg?: Maybe<ResultWorksAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ResultWorksMaxFields>;
  min?: Maybe<ResultWorksMinFields>;
  stddev?: Maybe<ResultWorksStddevFields>;
  stddevPop?: Maybe<ResultWorksStddevPopFields>;
  stddevSamp?: Maybe<ResultWorksStddevSampFields>;
  sum?: Maybe<ResultWorksSumFields>;
  varPop?: Maybe<ResultWorksVarPopFields>;
  varSamp?: Maybe<ResultWorksVarSampFields>;
  variance?: Maybe<ResultWorksVarianceFields>;
};


/** aggregate fields of "result.works" */
export type ResultWorksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResultWorksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "result.works" */
export type ResultWorksAggregateOrderBy = {
  avg?: InputMaybe<ResultWorksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ResultWorksMaxOrderBy>;
  min?: InputMaybe<ResultWorksMinOrderBy>;
  stddev?: InputMaybe<ResultWorksStddevOrderBy>;
  stddevPop?: InputMaybe<ResultWorksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ResultWorksStddevSampOrderBy>;
  sum?: InputMaybe<ResultWorksSumOrderBy>;
  varPop?: InputMaybe<ResultWorksVarPopOrderBy>;
  varSamp?: InputMaybe<ResultWorksVarSampOrderBy>;
  variance?: InputMaybe<ResultWorksVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ResultWorksAvgFields = {
  __typename?: 'ResultWorksAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "result.works" */
export type ResultWorksAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "result.works". All fields are combined with a logical 'AND'. */
export type ResultWorksBoolExp = {
  _and?: InputMaybe<Array<ResultWorksBoolExp>>;
  _not?: InputMaybe<ResultWorksBoolExp>;
  _or?: InputMaybe<Array<ResultWorksBoolExp>>;
  applicant?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isLiked?: InputMaybe<BooleanComparisonExp>;
  likeTotal?: InputMaybe<IntComparisonExp>;
  likes?: InputMaybe<ResultWorkLikesBoolExp>;
  likesAggregate?: InputMaybe<ResultWorkLikesAggregateBoolExp>;
  prize?: InputMaybe<StringComparisonExp>;
  result?: InputMaybe<ResultsBoolExp>;
  reviews?: InputMaybe<ResultWorkReviewsBoolExp>;
  thumbnail?: InputMaybe<ResultWorkThumbnailsBoolExp>;
};

/** aggregate max on columns */
export type ResultWorksMaxFields = {
  __typename?: 'ResultWorksMaxFields';
  applicant?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  prize?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "result.works" */
export type ResultWorksMaxOrderBy = {
  applicant?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  prize?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ResultWorksMinFields = {
  __typename?: 'ResultWorksMinFields';
  applicant?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  prize?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "result.works" */
export type ResultWorksMinOrderBy = {
  applicant?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  prize?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "result.works". */
export type ResultWorksOrderBy = {
  applicant?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isLiked?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  likesAggregate?: InputMaybe<ResultWorkLikesAggregateOrderBy>;
  prize?: InputMaybe<OrderBy>;
  result?: InputMaybe<ResultsOrderBy>;
  reviewsAggregate?: InputMaybe<ResultWorkReviewsAggregateOrderBy>;
  thumbnail?: InputMaybe<ResultWorkThumbnailsOrderBy>;
};

/** select columns of table "result.works" */
export enum ResultWorksSelectColumn {
  /** column name */
  Applicant = 'applicant',
  /** column name */
  Id = 'id',
  /** column name */
  LikeTotal = 'likeTotal',
  /** column name */
  Prize = 'prize'
}

/** aggregate stddev on columns */
export type ResultWorksStddevFields = {
  __typename?: 'ResultWorksStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "result.works" */
export type ResultWorksStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ResultWorksStddevPopFields = {
  __typename?: 'ResultWorksStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "result.works" */
export type ResultWorksStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ResultWorksStddevSampFields = {
  __typename?: 'ResultWorksStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "result.works" */
export type ResultWorksStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "result_works" */
export type ResultWorksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResultWorksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResultWorksStreamCursorValueInput = {
  applicant?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  likeTotal?: InputMaybe<Scalars['Int']['input']>;
  prize?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ResultWorksSumFields = {
  __typename?: 'ResultWorksSumFields';
  id?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "result.works" */
export type ResultWorksSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ResultWorksVarPopFields = {
  __typename?: 'ResultWorksVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "result.works" */
export type ResultWorksVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ResultWorksVarSampFields = {
  __typename?: 'ResultWorksVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "result.works" */
export type ResultWorksVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ResultWorksVarianceFields = {
  __typename?: 'ResultWorksVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "result.works" */
export type ResultWorksVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
};

export type SaveOrganizationInput = {
  address: OrganizationAddress;
  classification: OrganizationClassification;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type SaveOrganizationOutput = {
  __typename?: 'SaveOrganizationOutput';
  organization?: Maybe<OrganizerOrganizations>;
  organizationId: Scalars['Int']['output'];
};

export type SigninForApplicantInputUser = {
  email: Scalars['String']['input'];
  mypage: SigninForApplicantInputUserMypage;
  password: Scalars['String']['input'];
};

export type SigninForApplicantInputUserMypage = {
  id: Scalars['Int']['input'];
};

export type SigninForApplicantOutput = {
  __typename?: 'SigninForApplicantOutput';
  userId: Scalars['String']['output'];
};

export type SigninInputUser = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SigninOutput = {
  __typename?: 'SigninOutput';
  user?: Maybe<Users>;
  userId: Scalars['String']['output'];
};

export type SignupForApplicantInputUser = {
  email: Scalars['String']['input'];
  mypage: SignupForApplicantInputUserMypage;
  password: Scalars['String']['input'];
};

export type SignupForApplicantInputUserMypage = {
  id: Scalars['Int']['input'];
};

export type SignupForApplicantInputVerificationEmail = {
  body: Scalars['String']['input'];
  from: SignupForApplicantInputVerificationEmailFrom;
  placeholder: SignupForApplicantInputVerificationEmailPlaceholder;
  subject: Scalars['String']['input'];
};

export type SignupForApplicantInputVerificationEmailFrom = {
  name: Scalars['String']['input'];
};

export type SignupForApplicantInputVerificationEmailPlaceholder = {
  verificationToken: Scalars['String']['input'];
};

export type SignupForApplicantOutput = {
  __typename?: 'SignupForApplicantOutput';
  email: Scalars['String']['output'];
};

export type SignupInputUser = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignupInputVerificationEmail = {
  body: Scalars['String']['input'];
  from: SignupInputVerificationEmailFrom;
  placeholder: SignupInputVerificationEmailPlaceholder;
  subject: Scalars['String']['input'];
};

export type SignupInputVerificationEmailFrom = {
  name: Scalars['String']['input'];
};

export type SignupInputVerificationEmailPlaceholder = {
  receivesNewsletter?: InputMaybe<Scalars['String']['input']>;
  verificationToken: Scalars['String']['input'];
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  email: Scalars['String']['output'];
};

/** columns and relationships of "strapi.files" */
export type StrapiFiles = {
  __typename?: 'StrapiFiles';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['jsonb']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['numeric']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "strapi.files" */
export type StrapiFilesFormatsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "strapi.files". All fields are combined with a logical 'AND'. */
export type StrapiFilesBoolExp = {
  _and?: InputMaybe<Array<StrapiFilesBoolExp>>;
  _not?: InputMaybe<StrapiFilesBoolExp>;
  _or?: InputMaybe<Array<StrapiFilesBoolExp>>;
  alternativeText?: InputMaybe<StringComparisonExp>;
  caption?: InputMaybe<StringComparisonExp>;
  ext?: InputMaybe<StringComparisonExp>;
  formats?: InputMaybe<JsonbComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  mime?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  size?: InputMaybe<NumericComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "strapi.files". */
export type StrapiFilesOrderBy = {
  alternativeText?: InputMaybe<OrderBy>;
  caption?: InputMaybe<OrderBy>;
  ext?: InputMaybe<OrderBy>;
  formats?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mime?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

export type UnlikeContestInputContest = {
  id: Scalars['Int']['input'];
};

export type UnlikeContestOutput = {
  __typename?: 'UnlikeContestOutput';
  contest?: Maybe<Contests>;
  contestId: Scalars['Int']['output'];
};

export type UnlikeWorkInputWork = {
  id: Scalars['Int']['input'];
};

export type UnlikeWorkOutput = {
  __typename?: 'UnlikeWorkOutput';
  work?: Maybe<ResultWorks>;
  workId: Scalars['Int']['output'];
};

/** columns and relationships of "user.allowed_roles" */
export type UserAllowedRoles = {
  __typename?: 'UserAllowedRoles';
  name: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** order by aggregate values of table "user.allowed_roles" */
export type UserAllowedRolesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserAllowedRolesMaxOrderBy>;
  min?: InputMaybe<UserAllowedRolesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "user.allowed_roles". All fields are combined with a logical 'AND'. */
export type UserAllowedRolesBoolExp = {
  _and?: InputMaybe<Array<UserAllowedRolesBoolExp>>;
  _not?: InputMaybe<UserAllowedRolesBoolExp>;
  _or?: InputMaybe<Array<UserAllowedRolesBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "user.allowed_roles" */
export type UserAllowedRolesMaxOrderBy = {
  name?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "user.allowed_roles" */
export type UserAllowedRolesMinOrderBy = {
  name?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "user.allowed_roles". */
export type UserAllowedRolesOrderBy = {
  name?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "user.allowed_roles" */
export enum UserAllowedRolesSelectColumn {
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId'
}

export type VerifyEmailForApplicantOutput = {
  __typename?: 'VerifyEmailForApplicantOutput';
  userId: Scalars['String']['output'];
};

export type VerifyEmailOutput = {
  __typename?: 'VerifyEmailOutput';
  user?: Maybe<Users>;
  userId: Scalars['String']['output'];
};

/** columns and relationships of "entry.applicant_fields" */
export type ApplicantFields = {
  __typename?: 'applicantFields';
  /** An object relationship */
  address: EntryFormStaticFields;
  addressFieldId: Scalars['Int']['output'];
  /** An object relationship */
  birth: EntryFormStaticFields;
  birthFieldId: Scalars['Int']['output'];
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  /** An object relationship */
  gender: EntryFormStaticFields;
  genderFieldId: Scalars['Int']['output'];
  /** An object relationship */
  job: EntryFormStaticFields;
  jobFieldId: Scalars['Int']['output'];
  /** An object relationship */
  name: EntryFormStaticFields;
  nameFieldId: Scalars['Int']['output'];
  /** An object relationship */
  nameKana: EntryFormStaticFields;
  nameKanaFieldId: Scalars['Int']['output'];
  /** An object relationship */
  nickname: EntryFormStaticFields;
  nicknameFieldId: Scalars['Int']['output'];
  /** An object relationship */
  phoneNumber: EntryFormStaticFields;
  phoneNumberFieldId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.applicant_fields". All fields are combined with a logical 'AND'. */
export type ApplicantFieldsBoolExp = {
  _and?: InputMaybe<Array<ApplicantFieldsBoolExp>>;
  _not?: InputMaybe<ApplicantFieldsBoolExp>;
  _or?: InputMaybe<Array<ApplicantFieldsBoolExp>>;
  address?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  addressFieldId?: InputMaybe<IntComparisonExp>;
  birth?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  birthFieldId?: InputMaybe<IntComparisonExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  gender?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  genderFieldId?: InputMaybe<IntComparisonExp>;
  job?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  jobFieldId?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  nameFieldId?: InputMaybe<IntComparisonExp>;
  nameKana?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  nameKanaFieldId?: InputMaybe<IntComparisonExp>;
  nickname?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  nicknameFieldId?: InputMaybe<IntComparisonExp>;
  phoneNumber?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  phoneNumberFieldId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.applicant_fields". */
export type ApplicantFieldsOrderBy = {
  address?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  addressFieldId?: InputMaybe<OrderBy>;
  birth?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  birthFieldId?: InputMaybe<OrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  genderFieldId?: InputMaybe<OrderBy>;
  job?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  jobFieldId?: InputMaybe<OrderBy>;
  name?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  nameFieldId?: InputMaybe<OrderBy>;
  nameKana?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  nameKanaFieldId?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  nicknameFieldId?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  phoneNumberFieldId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.applicant_group_fields" */
export type ApplicantGroupFields = {
  __typename?: 'applicantGroupFields';
  /** An object relationship */
  address: EntryFormStaticFields;
  addressFieldId: Scalars['Int']['output'];
  /** An object relationship */
  gradeClass: EntryFormStaticFields;
  gradeClassFieldId: Scalars['Int']['output'];
  /** An object relationship */
  groupEntrySetting: GroupEntrySettings;
  /** An object relationship */
  groupName: EntryFormStaticFields;
  groupNameFieldId: Scalars['Int']['output'];
  mypageId: Scalars['Int']['output'];
  /** An object relationship */
  phoneNumber: EntryFormStaticFields;
  phoneNumberFieldId: Scalars['Int']['output'];
  /** An object relationship */
  representativeKana: EntryFormStaticFields;
  representativeKanaFieldId: Scalars['Int']['output'];
  /** An object relationship */
  representativeName: EntryFormStaticFields;
  representativeNameFieldId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.applicant_group_fields". All fields are combined with a logical 'AND'. */
export type ApplicantGroupFieldsBoolExp = {
  _and?: InputMaybe<Array<ApplicantGroupFieldsBoolExp>>;
  _not?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  _or?: InputMaybe<Array<ApplicantGroupFieldsBoolExp>>;
  address?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  addressFieldId?: InputMaybe<IntComparisonExp>;
  gradeClass?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  gradeClassFieldId?: InputMaybe<IntComparisonExp>;
  groupEntrySetting?: InputMaybe<GroupEntrySettingsBoolExp>;
  groupName?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  groupNameFieldId?: InputMaybe<IntComparisonExp>;
  mypageId?: InputMaybe<IntComparisonExp>;
  phoneNumber?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  phoneNumberFieldId?: InputMaybe<IntComparisonExp>;
  representativeKana?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  representativeKanaFieldId?: InputMaybe<IntComparisonExp>;
  representativeName?: InputMaybe<EntryFormStaticFieldsBoolExp>;
  representativeNameFieldId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.applicant_group_fields". */
export type ApplicantGroupFieldsOrderBy = {
  address?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  addressFieldId?: InputMaybe<OrderBy>;
  gradeClass?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  gradeClassFieldId?: InputMaybe<OrderBy>;
  groupEntrySetting?: InputMaybe<GroupEntrySettingsOrderBy>;
  groupName?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  groupNameFieldId?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  phoneNumberFieldId?: InputMaybe<OrderBy>;
  representativeKana?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  representativeKanaFieldId?: InputMaybe<OrderBy>;
  representativeName?: InputMaybe<EntryFormStaticFieldsOrderBy>;
  representativeNameFieldId?: InputMaybe<OrderBy>;
};

export type ApplicantGroupGradeClassByToken_ApplicantMypages_Args = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicantGroupIdByToken_ApplicantMypages_Args = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicantGroupNameByToken_ApplicantMypages_Args = {
  token?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "entry.mypages" */
export type ApplicantMypages = {
  __typename?: 'applicantMypages';
  /** A computed field, executes function "entry.mypage_applicant_group_grade_class_by_token" */
  applicantGroupGradeClassByToken?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "entry.mypage_applicant_group_id_by_token" */
  applicantGroupIdByToken?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "entry.mypage_applicant_group_id_by_user" */
  applicantGroupIdByUser?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "entry.mypage_applicant_group_name_by_token" */
  applicantGroupNameByToken?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "entry.mypage_applicant_id" */
  applicantId?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  customLinks: Array<EntryMypageCustomLinks>;
  enablesPreOpen: Scalars['Boolean']['output'];
  /** An object relationship */
  entryFeeSetting?: Maybe<EntryFeeSettings>;
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  /** An object relationship */
  groupEntrySetting?: Maybe<GroupEntrySettings>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  logo?: Maybe<Files>;
  logoFileId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  /** A computed field, executes function "entry.mypage_user_email" */
  userEmail?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "entry.mypage_user_id" */
  userId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "entry.mypages" */
export type ApplicantMypagesApplicantGroupGradeClassByTokenArgs = {
  args: ApplicantGroupGradeClassByToken_ApplicantMypages_Args;
};


/** columns and relationships of "entry.mypages" */
export type ApplicantMypagesApplicantGroupIdByTokenArgs = {
  args: ApplicantGroupIdByToken_ApplicantMypages_Args;
};


/** columns and relationships of "entry.mypages" */
export type ApplicantMypagesApplicantGroupNameByTokenArgs = {
  args: ApplicantGroupNameByToken_ApplicantMypages_Args;
};


/** columns and relationships of "entry.mypages" */
export type ApplicantMypagesCustomLinksArgs = {
  distinctOn?: InputMaybe<Array<EntryMypageCustomLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryMypageCustomLinksOrderBy>>;
  where?: InputMaybe<EntryMypageCustomLinksBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.mypages". All fields are combined with a logical 'AND'. */
export type ApplicantMypagesBoolExp = {
  _and?: InputMaybe<Array<ApplicantMypagesBoolExp>>;
  _not?: InputMaybe<ApplicantMypagesBoolExp>;
  _or?: InputMaybe<Array<ApplicantMypagesBoolExp>>;
  applicantGroupIdByUser?: InputMaybe<IntComparisonExp>;
  applicantId?: InputMaybe<IntComparisonExp>;
  customLinks?: InputMaybe<EntryMypageCustomLinksBoolExp>;
  enablesPreOpen?: InputMaybe<BooleanComparisonExp>;
  entryFeeSetting?: InputMaybe<EntryFeeSettingsBoolExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  groupEntrySetting?: InputMaybe<GroupEntrySettingsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  logo?: InputMaybe<FilesBoolExp>;
  logoFileId?: InputMaybe<IntComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  userEmail?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.mypages". */
export type ApplicantMypagesOrderBy = {
  applicantGroupIdByUser?: InputMaybe<OrderBy>;
  applicantId?: InputMaybe<OrderBy>;
  customLinksAggregate?: InputMaybe<EntryMypageCustomLinksAggregateOrderBy>;
  enablesPreOpen?: InputMaybe<OrderBy>;
  entryFeeSetting?: InputMaybe<EntryFeeSettingsOrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  groupEntrySetting?: InputMaybe<GroupEntrySettingsOrderBy>;
  id?: InputMaybe<OrderBy>;
  logo?: InputMaybe<FilesOrderBy>;
  logoFileId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  userEmail?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.mypages" */
export enum ApplicantMypagesSelectColumn {
  /** column name */
  EnablesPreOpen = 'enablesPreOpen',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logoFileId',
  /** column name */
  Slug = 'slug'
}

/** Streaming cursor of the table "applicantMypages" */
export type ApplicantMypagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ApplicantMypagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApplicantMypagesStreamCursorValueInput = {
  enablesPreOpen?: InputMaybe<Scalars['Boolean']['input']>;
  formId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logoFileId?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ArticlePaginationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ArticlePaginationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlePaginationsBoolExp>;
  predicate: IntComparisonExp;
};

export type ArticleTopPageMainViewBannersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ArticleTopPageMainViewBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticleTopPageMainViewBannersBoolExp>;
  predicate: IntComparisonExp;
};

export type ArticleTopPagePinnedArticlesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ArticleTopPagePinnedArticlesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticleTopPagePinnedArticlesBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "article.articles" */
export type Articles = {
  __typename?: 'articles';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  /** An array relationship */
  children: Array<Articles>;
  /** An aggregate relationship */
  childrenAggregate: ArticlesAggregate;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  keyVisual?: Maybe<ArticleKeyVisuals>;
  /** An object relationship */
  listView?: Maybe<ArticleListViews>;
  /** An object relationship */
  pagination?: Maybe<ArticlePaginations>;
  /** An object relationship */
  parent?: Maybe<Articles>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  showsOutline?: Maybe<Scalars['Boolean']['output']>;
  sortOrderAsChild?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  subcategory?: Maybe<ContestSubcategories>;
  /** An array relationship */
  taggings: Array<ArticleTaggings>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  writer?: Maybe<ArticleWriters>;
};


/** columns and relationships of "article.articles" */
export type ArticlesChildrenArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


/** columns and relationships of "article.articles" */
export type ArticlesChildrenAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


/** columns and relationships of "article.articles" */
export type ArticlesTaggingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTaggingsOrderBy>>;
  where?: InputMaybe<ArticleTaggingsBoolExp>;
};

/** aggregated selection of "article.articles" */
export type ArticlesAggregate = {
  __typename?: 'articlesAggregate';
  aggregate?: Maybe<ArticlesAggregateFields>;
  nodes: Array<Articles>;
};

export type ArticlesAggregateBoolExp = {
  avg?: InputMaybe<ArticlesAggregateBoolExpAvg>;
  bool_and?: InputMaybe<ArticlesAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ArticlesAggregateBoolExpBool_Or>;
  corr?: InputMaybe<ArticlesAggregateBoolExpCorr>;
  count?: InputMaybe<ArticlesAggregateBoolExpCount>;
  covar_samp?: InputMaybe<ArticlesAggregateBoolExpCovar_Samp>;
  max?: InputMaybe<ArticlesAggregateBoolExpMax>;
  min?: InputMaybe<ArticlesAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<ArticlesAggregateBoolExpStddev_Samp>;
  sum?: InputMaybe<ArticlesAggregateBoolExpSum>;
  var_samp?: InputMaybe<ArticlesAggregateBoolExpVar_Samp>;
};

export type ArticlesAggregateBoolExpAvg = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpBool_And = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ArticlesAggregateBoolExpBool_Or = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ArticlesAggregateBoolExpCorr = {
  arguments: ArticlesAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpCorrArguments = {
  X: ArticlesSelectColumnArticlesAggregateBoolExpCorrArgumentsColumns;
  Y: ArticlesSelectColumnArticlesAggregateBoolExpCorrArgumentsColumns;
};

export type ArticlesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ArticlesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: IntComparisonExp;
};

export type ArticlesAggregateBoolExpCovar_Samp = {
  arguments: ArticlesAggregateBoolExpCovar_SampArguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpCovar_SampArguments = {
  X: ArticlesSelectColumnArticlesAggregateBoolExpCovar_SampArgumentsColumns;
  Y: ArticlesSelectColumnArticlesAggregateBoolExpCovar_SampArgumentsColumns;
};

export type ArticlesAggregateBoolExpMax = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpMin = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpStddev_Samp = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpStddev_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpSum = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ArticlesAggregateBoolExpVar_Samp = {
  arguments: ArticlesSelectColumnArticlesAggregateBoolExpVar_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ArticlesBoolExp>;
  predicate: Float8ComparisonExp;
};

/** aggregate fields of "article.articles" */
export type ArticlesAggregateFields = {
  __typename?: 'articlesAggregateFields';
  avg?: Maybe<ArticlesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ArticlesMaxFields>;
  min?: Maybe<ArticlesMinFields>;
  stddev?: Maybe<ArticlesStddevFields>;
  stddevPop?: Maybe<ArticlesStddevPopFields>;
  stddevSamp?: Maybe<ArticlesStddevSampFields>;
  sum?: Maybe<ArticlesSumFields>;
  varPop?: Maybe<ArticlesVarPopFields>;
  varSamp?: Maybe<ArticlesVarSampFields>;
  variance?: Maybe<ArticlesVarianceFields>;
};


/** aggregate fields of "article.articles" */
export type ArticlesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ArticlesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "article.articles" */
export type ArticlesAggregateOrderBy = {
  avg?: InputMaybe<ArticlesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ArticlesMaxOrderBy>;
  min?: InputMaybe<ArticlesMinOrderBy>;
  stddev?: InputMaybe<ArticlesStddevOrderBy>;
  stddevPop?: InputMaybe<ArticlesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ArticlesStddevSampOrderBy>;
  sum?: InputMaybe<ArticlesSumOrderBy>;
  varPop?: InputMaybe<ArticlesVarPopOrderBy>;
  varSamp?: InputMaybe<ArticlesVarSampOrderBy>;
  variance?: InputMaybe<ArticlesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ArticlesAvgFields = {
  __typename?: 'articlesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "article.articles" */
export type ArticlesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "article.articles". All fields are combined with a logical 'AND'. */
export type ArticlesBoolExp = {
  _and?: InputMaybe<Array<ArticlesBoolExp>>;
  _not?: InputMaybe<ArticlesBoolExp>;
  _or?: InputMaybe<Array<ArticlesBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  children?: InputMaybe<ArticlesBoolExp>;
  childrenAggregate?: InputMaybe<ArticlesAggregateBoolExp>;
  html?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  keyVisual?: InputMaybe<ArticleKeyVisualsBoolExp>;
  listView?: InputMaybe<ArticleListViewsBoolExp>;
  pagination?: InputMaybe<ArticlePaginationsBoolExp>;
  parent?: InputMaybe<ArticlesBoolExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  showsOutline?: InputMaybe<BooleanComparisonExp>;
  sortOrderAsChild?: InputMaybe<Float8ComparisonExp>;
  subcategory?: InputMaybe<ContestSubcategoriesBoolExp>;
  taggings?: InputMaybe<ArticleTaggingsBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  writer?: InputMaybe<ArticleWritersBoolExp>;
};

/** aggregate max on columns */
export type ArticlesMaxFields = {
  __typename?: 'articlesMaxFields';
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  sortOrderAsChild?: Maybe<Scalars['float8']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "article.articles" */
export type ArticlesMaxOrderBy = {
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ArticlesMinFields = {
  __typename?: 'articlesMinFields';
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  sortOrderAsChild?: Maybe<Scalars['float8']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "article.articles" */
export type ArticlesMinOrderBy = {
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "article.articles". */
export type ArticlesOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  childrenAggregate?: InputMaybe<ArticlesAggregateOrderBy>;
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keyVisual?: InputMaybe<ArticleKeyVisualsOrderBy>;
  listView?: InputMaybe<ArticleListViewsOrderBy>;
  pagination?: InputMaybe<ArticlePaginationsOrderBy>;
  parent?: InputMaybe<ArticlesOrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  showsOutline?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
  subcategory?: InputMaybe<ContestSubcategoriesOrderBy>;
  taggingsAggregate?: InputMaybe<ArticleTaggingsAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  writer?: InputMaybe<ArticleWritersOrderBy>;
};

/** select columns of table "article.articles" */
export enum ArticlesSelectColumn {
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  ShowsOutline = 'showsOutline',
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "articlesAggregateBoolExpAvgArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpBool_andArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  ShowsOutline = 'showsOutline'
}

/** select "articlesAggregateBoolExpBool_orArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  ShowsOutline = 'showsOutline'
}

/** select "articlesAggregateBoolExpCorrArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpCovar_sampArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpCovar_SampArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpMaxArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpMinArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpMinArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpStddev_sampArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpStddev_SampArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpSumArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpSumArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** select "articlesAggregateBoolExpVar_sampArgumentsColumns" columns of table "article.articles" */
export enum ArticlesSelectColumnArticlesAggregateBoolExpVar_SampArgumentsColumns {
  /** column name */
  SortOrderAsChild = 'sortOrderAsChild'
}

/** aggregate stddev on columns */
export type ArticlesStddevFields = {
  __typename?: 'articlesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "article.articles" */
export type ArticlesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ArticlesStddevPopFields = {
  __typename?: 'articlesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "article.articles" */
export type ArticlesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ArticlesStddevSampFields = {
  __typename?: 'articlesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "article.articles" */
export type ArticlesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "articles" */
export type ArticlesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ArticlesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ArticlesStreamCursorValueInput = {
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  showsOutline?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrderAsChild?: InputMaybe<Scalars['float8']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type ArticlesSumFields = {
  __typename?: 'articlesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
  sortOrderAsChild?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "article.articles" */
export type ArticlesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ArticlesVarPopFields = {
  __typename?: 'articlesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "article.articles" */
export type ArticlesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ArticlesVarSampFields = {
  __typename?: 'articlesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "article.articles" */
export type ArticlesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ArticlesVarianceFields = {
  __typename?: 'articlesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  sortOrderAsChild?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "article.articles" */
export type ArticlesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  sortOrderAsChild?: InputMaybe<OrderBy>;
};

export type BannerContestCategoryBannersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BannerContestCategoryBannersBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "page.category_page_article_card_texts" */
export type CategoryPageArticleCardTexts = {
  __typename?: 'categoryPageArticleCardTexts';
  id?: Maybe<Scalars['Int']['output']>;
  showsNewBadge?: Maybe<Scalars['Boolean']['output']>;
  showsPublishedAt?: Maybe<Scalars['Boolean']['output']>;
  showsSubcategory?: Maybe<Scalars['Boolean']['output']>;
  showsTags?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "page.category_page_article_card_texts". All fields are combined with a logical 'AND'. */
export type CategoryPageArticleCardTextsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageArticleCardTextsBoolExp>>;
  _not?: InputMaybe<CategoryPageArticleCardTextsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageArticleCardTextsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  showsNewBadge?: InputMaybe<BooleanComparisonExp>;
  showsPublishedAt?: InputMaybe<BooleanComparisonExp>;
  showsSubcategory?: InputMaybe<BooleanComparisonExp>;
  showsTags?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_article_card_texts". */
export type CategoryPageArticleCardTextsOrderBy = {
  id?: InputMaybe<OrderBy>;
  showsNewBadge?: InputMaybe<OrderBy>;
  showsPublishedAt?: InputMaybe<OrderBy>;
  showsSubcategory?: InputMaybe<OrderBy>;
  showsTags?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_article_card_texts" */
export enum CategoryPageArticleCardTextsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ShowsNewBadge = 'showsNewBadge',
  /** column name */
  ShowsPublishedAt = 'showsPublishedAt',
  /** column name */
  ShowsSubcategory = 'showsSubcategory',
  /** column name */
  ShowsTags = 'showsTags'
}

/** Streaming cursor of the table "categoryPageArticleCardTexts" */
export type CategoryPageArticleCardTextsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageArticleCardTextsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageArticleCardTextsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  showsNewBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showsPublishedAt?: InputMaybe<Scalars['Boolean']['input']>;
  showsSubcategory?: InputMaybe<Scalars['Boolean']['input']>;
  showsTags?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "page.category_page_article_card_thumbnails" */
export type CategoryPageArticleCardThumbnails = {
  __typename?: 'categoryPageArticleCardThumbnails';
  id?: Maybe<Scalars['Int']['output']>;
  showsLead?: Maybe<Scalars['Boolean']['output']>;
  showsNewBadge?: Maybe<Scalars['Boolean']['output']>;
  showsSubcategory?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "page.category_page_article_card_thumbnails". All fields are combined with a logical 'AND'. */
export type CategoryPageArticleCardThumbnailsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageArticleCardThumbnailsBoolExp>>;
  _not?: InputMaybe<CategoryPageArticleCardThumbnailsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageArticleCardThumbnailsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  showsLead?: InputMaybe<BooleanComparisonExp>;
  showsNewBadge?: InputMaybe<BooleanComparisonExp>;
  showsSubcategory?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_article_card_thumbnails". */
export type CategoryPageArticleCardThumbnailsOrderBy = {
  id?: InputMaybe<OrderBy>;
  showsLead?: InputMaybe<OrderBy>;
  showsNewBadge?: InputMaybe<OrderBy>;
  showsSubcategory?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_article_card_thumbnails" */
export enum CategoryPageArticleCardThumbnailsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ShowsLead = 'showsLead',
  /** column name */
  ShowsNewBadge = 'showsNewBadge',
  /** column name */
  ShowsSubcategory = 'showsSubcategory'
}

/** Streaming cursor of the table "categoryPageArticleCardThumbnails" */
export type CategoryPageArticleCardThumbnailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageArticleCardThumbnailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageArticleCardThumbnailsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  showsLead?: InputMaybe<Scalars['Boolean']['input']>;
  showsNewBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showsSubcategory?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "page.category_page_article_cards" */
export type CategoryPageArticleCards = {
  __typename?: 'categoryPageArticleCards';
  /** An object relationship */
  article?: Maybe<Articles>;
  articleCardTextId?: Maybe<Scalars['Int']['output']>;
  articleCardThumbnailId?: Maybe<Scalars['Int']['output']>;
  articleId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  text?: Maybe<CategoryPageArticleCardTexts>;
  /** An object relationship */
  thumbnail?: Maybe<CategoryPageArticleCardThumbnails>;
};

/** Boolean expression to filter rows from the table "page.category_page_article_cards". All fields are combined with a logical 'AND'. */
export type CategoryPageArticleCardsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageArticleCardsBoolExp>>;
  _not?: InputMaybe<CategoryPageArticleCardsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageArticleCardsBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
  articleCardTextId?: InputMaybe<IntComparisonExp>;
  articleCardThumbnailId?: InputMaybe<IntComparisonExp>;
  articleId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<CategoryPageArticleCardTextsBoolExp>;
  thumbnail?: InputMaybe<CategoryPageArticleCardThumbnailsBoolExp>;
};

/** Ordering options when selecting data from "page.category_page_article_cards". */
export type CategoryPageArticleCardsOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
  articleCardTextId?: InputMaybe<OrderBy>;
  articleCardThumbnailId?: InputMaybe<OrderBy>;
  articleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  text?: InputMaybe<CategoryPageArticleCardTextsOrderBy>;
  thumbnail?: InputMaybe<CategoryPageArticleCardThumbnailsOrderBy>;
};

/** select columns of table "page.category_page_article_cards" */
export enum CategoryPageArticleCardsSelectColumn {
  /** column name */
  ArticleCardTextId = 'articleCardTextId',
  /** column name */
  ArticleCardThumbnailId = 'articleCardThumbnailId',
  /** column name */
  ArticleId = 'articleId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "categoryPageArticleCards" */
export type CategoryPageArticleCardsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageArticleCardsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageArticleCardsStreamCursorValueInput = {
  articleCardTextId?: InputMaybe<Scalars['Int']['input']>;
  articleCardThumbnailId?: InputMaybe<Scalars['Int']['input']>;
  articleId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticles = {
  __typename?: 'categoryPageArticleListArticles';
  /** An object relationship */
  article?: Maybe<Articles>;
  articleId?: Maybe<Scalars['Int']['output']>;
  articleListId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesAggregateOrderBy = {
  avg?: InputMaybe<CategoryPageArticleListArticlesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryPageArticleListArticlesMaxOrderBy>;
  min?: InputMaybe<CategoryPageArticleListArticlesMinOrderBy>;
  stddev?: InputMaybe<CategoryPageArticleListArticlesStddevOrderBy>;
  stddevPop?: InputMaybe<CategoryPageArticleListArticlesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CategoryPageArticleListArticlesStddevSampOrderBy>;
  sum?: InputMaybe<CategoryPageArticleListArticlesSumOrderBy>;
  varPop?: InputMaybe<CategoryPageArticleListArticlesVarPopOrderBy>;
  varSamp?: InputMaybe<CategoryPageArticleListArticlesVarSampOrderBy>;
  variance?: InputMaybe<CategoryPageArticleListArticlesVarianceOrderBy>;
};

/** order by avg() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesAvgOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "page.category_page_article_list_articles". All fields are combined with a logical 'AND'. */
export type CategoryPageArticleListArticlesBoolExp = {
  _and?: InputMaybe<Array<CategoryPageArticleListArticlesBoolExp>>;
  _not?: InputMaybe<CategoryPageArticleListArticlesBoolExp>;
  _or?: InputMaybe<Array<CategoryPageArticleListArticlesBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
  articleId?: InputMaybe<IntComparisonExp>;
  articleListId?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesMaxOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesMinOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "page.category_page_article_list_articles". */
export type CategoryPageArticleListArticlesOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_article_list_articles" */
export enum CategoryPageArticleListArticlesSelectColumn {
  /** column name */
  ArticleId = 'articleId',
  /** column name */
  ArticleListId = 'articleListId',
  /** column name */
  SortOrder = 'sortOrder'
}

/** order by stddev() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesStddevOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesStddevPopOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesStddevSampOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "categoryPageArticleListArticles" */
export type CategoryPageArticleListArticlesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageArticleListArticlesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageArticleListArticlesStreamCursorValueInput = {
  articleId?: InputMaybe<Scalars['Int']['input']>;
  articleListId?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesSumOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesVarPopOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesVarSampOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "page.category_page_article_list_articles" */
export type CategoryPageArticleListArticlesVarianceOrderBy = {
  articleId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "page.category_page_article_lists" */
export type CategoryPageArticleLists = {
  __typename?: 'categoryPageArticleLists';
  /** An array relationship */
  articles: Array<CategoryPageArticleListArticles>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  showsNewBadge?: Maybe<Scalars['Boolean']['output']>;
  showsPublishedAt?: Maybe<Scalars['Boolean']['output']>;
  showsSubcategory?: Maybe<Scalars['Boolean']['output']>;
  showsTags?: Maybe<Scalars['Boolean']['output']>;
  showsThumbnail?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "page.category_page_article_lists" */
export type CategoryPageArticleListsArticlesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleListArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleListArticlesOrderBy>>;
  where?: InputMaybe<CategoryPageArticleListArticlesBoolExp>;
};

/** Boolean expression to filter rows from the table "page.category_page_article_lists". All fields are combined with a logical 'AND'. */
export type CategoryPageArticleListsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageArticleListsBoolExp>>;
  _not?: InputMaybe<CategoryPageArticleListsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageArticleListsBoolExp>>;
  articles?: InputMaybe<CategoryPageArticleListArticlesBoolExp>;
  backgroundColor?: InputMaybe<StringComparisonExp>;
  emoji?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  showsNewBadge?: InputMaybe<BooleanComparisonExp>;
  showsPublishedAt?: InputMaybe<BooleanComparisonExp>;
  showsSubcategory?: InputMaybe<BooleanComparisonExp>;
  showsTags?: InputMaybe<BooleanComparisonExp>;
  showsThumbnail?: InputMaybe<BooleanComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_article_lists". */
export type CategoryPageArticleListsOrderBy = {
  articlesAggregate?: InputMaybe<CategoryPageArticleListArticlesAggregateOrderBy>;
  backgroundColor?: InputMaybe<OrderBy>;
  emoji?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  showsNewBadge?: InputMaybe<OrderBy>;
  showsPublishedAt?: InputMaybe<OrderBy>;
  showsSubcategory?: InputMaybe<OrderBy>;
  showsTags?: InputMaybe<OrderBy>;
  showsThumbnail?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_article_lists" */
export enum CategoryPageArticleListsSelectColumn {
  /** column name */
  BackgroundColor = 'backgroundColor',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  ShowsNewBadge = 'showsNewBadge',
  /** column name */
  ShowsPublishedAt = 'showsPublishedAt',
  /** column name */
  ShowsSubcategory = 'showsSubcategory',
  /** column name */
  ShowsTags = 'showsTags',
  /** column name */
  ShowsThumbnail = 'showsThumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "categoryPageArticleLists" */
export type CategoryPageArticleListsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageArticleListsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageArticleListsStreamCursorValueInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  showsNewBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showsPublishedAt?: InputMaybe<Scalars['Boolean']['input']>;
  showsSubcategory?: InputMaybe<Scalars['Boolean']['input']>;
  showsTags?: InputMaybe<Scalars['Boolean']['input']>;
  showsThumbnail?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "page.category_page_contest_card_texts" */
export type CategoryPageContestCardTexts = {
  __typename?: 'categoryPageContestCardTexts';
  id?: Maybe<Scalars['Int']['output']>;
  showsDeadline?: Maybe<Scalars['Boolean']['output']>;
  showsNewBadge?: Maybe<Scalars['Boolean']['output']>;
  showsOrganizer?: Maybe<Scalars['Boolean']['output']>;
  showsPrize?: Maybe<Scalars['Boolean']['output']>;
  showsPublishedAt?: Maybe<Scalars['Boolean']['output']>;
  showsSubcategory?: Maybe<Scalars['Boolean']['output']>;
  showsTags?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "page.category_page_contest_card_texts". All fields are combined with a logical 'AND'. */
export type CategoryPageContestCardTextsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageContestCardTextsBoolExp>>;
  _not?: InputMaybe<CategoryPageContestCardTextsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageContestCardTextsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  showsDeadline?: InputMaybe<BooleanComparisonExp>;
  showsNewBadge?: InputMaybe<BooleanComparisonExp>;
  showsOrganizer?: InputMaybe<BooleanComparisonExp>;
  showsPrize?: InputMaybe<BooleanComparisonExp>;
  showsPublishedAt?: InputMaybe<BooleanComparisonExp>;
  showsSubcategory?: InputMaybe<BooleanComparisonExp>;
  showsTags?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_contest_card_texts". */
export type CategoryPageContestCardTextsOrderBy = {
  id?: InputMaybe<OrderBy>;
  showsDeadline?: InputMaybe<OrderBy>;
  showsNewBadge?: InputMaybe<OrderBy>;
  showsOrganizer?: InputMaybe<OrderBy>;
  showsPrize?: InputMaybe<OrderBy>;
  showsPublishedAt?: InputMaybe<OrderBy>;
  showsSubcategory?: InputMaybe<OrderBy>;
  showsTags?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_contest_card_texts" */
export enum CategoryPageContestCardTextsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ShowsDeadline = 'showsDeadline',
  /** column name */
  ShowsNewBadge = 'showsNewBadge',
  /** column name */
  ShowsOrganizer = 'showsOrganizer',
  /** column name */
  ShowsPrize = 'showsPrize',
  /** column name */
  ShowsPublishedAt = 'showsPublishedAt',
  /** column name */
  ShowsSubcategory = 'showsSubcategory',
  /** column name */
  ShowsTags = 'showsTags'
}

/** Streaming cursor of the table "categoryPageContestCardTexts" */
export type CategoryPageContestCardTextsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageContestCardTextsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageContestCardTextsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  showsDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  showsNewBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showsOrganizer?: InputMaybe<Scalars['Boolean']['input']>;
  showsPrize?: InputMaybe<Scalars['Boolean']['input']>;
  showsPublishedAt?: InputMaybe<Scalars['Boolean']['input']>;
  showsSubcategory?: InputMaybe<Scalars['Boolean']['input']>;
  showsTags?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "page.category_page_contest_card_thumbnails" */
export type CategoryPageContestCardThumbnails = {
  __typename?: 'categoryPageContestCardThumbnails';
  id?: Maybe<Scalars['Int']['output']>;
  showsDeadline?: Maybe<Scalars['Boolean']['output']>;
  showsName?: Maybe<Scalars['Boolean']['output']>;
  showsNewBadge?: Maybe<Scalars['Boolean']['output']>;
  showsSubcategory?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "page.category_page_contest_card_thumbnails". All fields are combined with a logical 'AND'. */
export type CategoryPageContestCardThumbnailsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageContestCardThumbnailsBoolExp>>;
  _not?: InputMaybe<CategoryPageContestCardThumbnailsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageContestCardThumbnailsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  showsDeadline?: InputMaybe<BooleanComparisonExp>;
  showsName?: InputMaybe<BooleanComparisonExp>;
  showsNewBadge?: InputMaybe<BooleanComparisonExp>;
  showsSubcategory?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_contest_card_thumbnails". */
export type CategoryPageContestCardThumbnailsOrderBy = {
  id?: InputMaybe<OrderBy>;
  showsDeadline?: InputMaybe<OrderBy>;
  showsName?: InputMaybe<OrderBy>;
  showsNewBadge?: InputMaybe<OrderBy>;
  showsSubcategory?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_contest_card_thumbnails" */
export enum CategoryPageContestCardThumbnailsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ShowsDeadline = 'showsDeadline',
  /** column name */
  ShowsName = 'showsName',
  /** column name */
  ShowsNewBadge = 'showsNewBadge',
  /** column name */
  ShowsSubcategory = 'showsSubcategory'
}

/** Streaming cursor of the table "categoryPageContestCardThumbnails" */
export type CategoryPageContestCardThumbnailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageContestCardThumbnailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageContestCardThumbnailsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  showsDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  showsName?: InputMaybe<Scalars['Boolean']['input']>;
  showsNewBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showsSubcategory?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "page.category_page_contest_cards" */
export type CategoryPageContestCards = {
  __typename?: 'categoryPageContestCards';
  /** An object relationship */
  contest?: Maybe<Contests>;
  contestCardTextId?: Maybe<Scalars['Int']['output']>;
  contestCardThumbnailId?: Maybe<Scalars['Int']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  text?: Maybe<CategoryPageContestCardTexts>;
  /** An object relationship */
  thumbnail?: Maybe<CategoryPageContestCardThumbnails>;
};

/** Boolean expression to filter rows from the table "page.category_page_contest_cards". All fields are combined with a logical 'AND'. */
export type CategoryPageContestCardsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageContestCardsBoolExp>>;
  _not?: InputMaybe<CategoryPageContestCardsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageContestCardsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestCardTextId?: InputMaybe<IntComparisonExp>;
  contestCardThumbnailId?: InputMaybe<IntComparisonExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<CategoryPageContestCardTextsBoolExp>;
  thumbnail?: InputMaybe<CategoryPageContestCardThumbnailsBoolExp>;
};

/** Ordering options when selecting data from "page.category_page_contest_cards". */
export type CategoryPageContestCardsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestCardTextId?: InputMaybe<OrderBy>;
  contestCardThumbnailId?: InputMaybe<OrderBy>;
  contestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  text?: InputMaybe<CategoryPageContestCardTextsOrderBy>;
  thumbnail?: InputMaybe<CategoryPageContestCardThumbnailsOrderBy>;
};

/** select columns of table "page.category_page_contest_cards" */
export enum CategoryPageContestCardsSelectColumn {
  /** column name */
  ContestCardTextId = 'contestCardTextId',
  /** column name */
  ContestCardThumbnailId = 'contestCardThumbnailId',
  /** column name */
  ContestId = 'contestId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "categoryPageContestCards" */
export type CategoryPageContestCardsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageContestCardsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageContestCardsStreamCursorValueInput = {
  contestCardTextId?: InputMaybe<Scalars['Int']['input']>;
  contestCardThumbnailId?: InputMaybe<Scalars['Int']['input']>;
  contestId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "page.category_page_contest_list_contests" */
export type CategoryPageContestListContests = {
  __typename?: 'categoryPageContestListContests';
  /** An object relationship */
  contest?: Maybe<Contests>;
  contestId?: Maybe<Scalars['Int']['output']>;
  contestListId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsAggregateOrderBy = {
  avg?: InputMaybe<CategoryPageContestListContestsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryPageContestListContestsMaxOrderBy>;
  min?: InputMaybe<CategoryPageContestListContestsMinOrderBy>;
  stddev?: InputMaybe<CategoryPageContestListContestsStddevOrderBy>;
  stddevPop?: InputMaybe<CategoryPageContestListContestsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CategoryPageContestListContestsStddevSampOrderBy>;
  sum?: InputMaybe<CategoryPageContestListContestsSumOrderBy>;
  varPop?: InputMaybe<CategoryPageContestListContestsVarPopOrderBy>;
  varSamp?: InputMaybe<CategoryPageContestListContestsVarSampOrderBy>;
  variance?: InputMaybe<CategoryPageContestListContestsVarianceOrderBy>;
};

/** order by avg() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsAvgOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "page.category_page_contest_list_contests". All fields are combined with a logical 'AND'. */
export type CategoryPageContestListContestsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageContestListContestsBoolExp>>;
  _not?: InputMaybe<CategoryPageContestListContestsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageContestListContestsBoolExp>>;
  contest?: InputMaybe<ContestsBoolExp>;
  contestId?: InputMaybe<IntComparisonExp>;
  contestListId?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsMaxOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsMinOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "page.category_page_contest_list_contests". */
export type CategoryPageContestListContestsOrderBy = {
  contest?: InputMaybe<ContestsOrderBy>;
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_contest_list_contests" */
export enum CategoryPageContestListContestsSelectColumn {
  /** column name */
  ContestId = 'contestId',
  /** column name */
  ContestListId = 'contestListId',
  /** column name */
  SortOrder = 'sortOrder'
}

/** order by stddev() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsStddevOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsStddevPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsStddevSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "categoryPageContestListContests" */
export type CategoryPageContestListContestsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageContestListContestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageContestListContestsStreamCursorValueInput = {
  contestId?: InputMaybe<Scalars['Int']['input']>;
  contestListId?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsSumOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsVarPopOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsVarSampOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "page.category_page_contest_list_contests" */
export type CategoryPageContestListContestsVarianceOrderBy = {
  contestId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "page.category_page_contest_lists" */
export type CategoryPageContestLists = {
  __typename?: 'categoryPageContestLists';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  contests: Array<CategoryPageContestListContests>;
  emoji?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  showsDeadline?: Maybe<Scalars['Boolean']['output']>;
  showsNewBadge?: Maybe<Scalars['Boolean']['output']>;
  showsPrize?: Maybe<Scalars['Boolean']['output']>;
  showsPublishedAt?: Maybe<Scalars['Boolean']['output']>;
  showsSubcategory?: Maybe<Scalars['Boolean']['output']>;
  showsTags?: Maybe<Scalars['Boolean']['output']>;
  showsThumbnail?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "page.category_page_contest_lists" */
export type CategoryPageContestListsContestsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestListContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestListContestsOrderBy>>;
  where?: InputMaybe<CategoryPageContestListContestsBoolExp>;
};

/** Boolean expression to filter rows from the table "page.category_page_contest_lists". All fields are combined with a logical 'AND'. */
export type CategoryPageContestListsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageContestListsBoolExp>>;
  _not?: InputMaybe<CategoryPageContestListsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageContestListsBoolExp>>;
  backgroundColor?: InputMaybe<StringComparisonExp>;
  contests?: InputMaybe<CategoryPageContestListContestsBoolExp>;
  emoji?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  showsDeadline?: InputMaybe<BooleanComparisonExp>;
  showsNewBadge?: InputMaybe<BooleanComparisonExp>;
  showsPrize?: InputMaybe<BooleanComparisonExp>;
  showsPublishedAt?: InputMaybe<BooleanComparisonExp>;
  showsSubcategory?: InputMaybe<BooleanComparisonExp>;
  showsTags?: InputMaybe<BooleanComparisonExp>;
  showsThumbnail?: InputMaybe<BooleanComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_contest_lists". */
export type CategoryPageContestListsOrderBy = {
  backgroundColor?: InputMaybe<OrderBy>;
  contestsAggregate?: InputMaybe<CategoryPageContestListContestsAggregateOrderBy>;
  emoji?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  showsDeadline?: InputMaybe<OrderBy>;
  showsNewBadge?: InputMaybe<OrderBy>;
  showsPrize?: InputMaybe<OrderBy>;
  showsPublishedAt?: InputMaybe<OrderBy>;
  showsSubcategory?: InputMaybe<OrderBy>;
  showsTags?: InputMaybe<OrderBy>;
  showsThumbnail?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_contest_lists" */
export enum CategoryPageContestListsSelectColumn {
  /** column name */
  BackgroundColor = 'backgroundColor',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  ShowsDeadline = 'showsDeadline',
  /** column name */
  ShowsNewBadge = 'showsNewBadge',
  /** column name */
  ShowsPrize = 'showsPrize',
  /** column name */
  ShowsPublishedAt = 'showsPublishedAt',
  /** column name */
  ShowsSubcategory = 'showsSubcategory',
  /** column name */
  ShowsTags = 'showsTags',
  /** column name */
  ShowsThumbnail = 'showsThumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "categoryPageContestLists" */
export type CategoryPageContestListsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageContestListsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageContestListsStreamCursorValueInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  showsDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  showsNewBadge?: InputMaybe<Scalars['Boolean']['input']>;
  showsPrize?: InputMaybe<Scalars['Boolean']['input']>;
  showsPublishedAt?: InputMaybe<Scalars['Boolean']['input']>;
  showsSubcategory?: InputMaybe<Scalars['Boolean']['input']>;
  showsTags?: InputMaybe<Scalars['Boolean']['input']>;
  showsThumbnail?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategories = {
  __typename?: 'categoryPageWidgetSubcategories';
  /** An object relationship */
  subcategory?: Maybe<ContestSubcategories>;
  subcategoryId?: Maybe<Scalars['Int']['output']>;
  widgetId?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesAggregateOrderBy = {
  avg?: InputMaybe<CategoryPageWidgetSubcategoriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryPageWidgetSubcategoriesMaxOrderBy>;
  min?: InputMaybe<CategoryPageWidgetSubcategoriesMinOrderBy>;
  stddev?: InputMaybe<CategoryPageWidgetSubcategoriesStddevOrderBy>;
  stddevPop?: InputMaybe<CategoryPageWidgetSubcategoriesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CategoryPageWidgetSubcategoriesStddevSampOrderBy>;
  sum?: InputMaybe<CategoryPageWidgetSubcategoriesSumOrderBy>;
  varPop?: InputMaybe<CategoryPageWidgetSubcategoriesVarPopOrderBy>;
  varSamp?: InputMaybe<CategoryPageWidgetSubcategoriesVarSampOrderBy>;
  variance?: InputMaybe<CategoryPageWidgetSubcategoriesVarianceOrderBy>;
};

/** order by avg() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesAvgOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "page.category_page_widget_subcategories". All fields are combined with a logical 'AND'. */
export type CategoryPageWidgetSubcategoriesBoolExp = {
  _and?: InputMaybe<Array<CategoryPageWidgetSubcategoriesBoolExp>>;
  _not?: InputMaybe<CategoryPageWidgetSubcategoriesBoolExp>;
  _or?: InputMaybe<Array<CategoryPageWidgetSubcategoriesBoolExp>>;
  subcategory?: InputMaybe<ContestSubcategoriesBoolExp>;
  subcategoryId?: InputMaybe<IntComparisonExp>;
  widgetId?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesMaxOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesMinOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "page.category_page_widget_subcategories". */
export type CategoryPageWidgetSubcategoriesOrderBy = {
  subcategory?: InputMaybe<ContestSubcategoriesOrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_widget_subcategories" */
export enum CategoryPageWidgetSubcategoriesSelectColumn {
  /** column name */
  SubcategoryId = 'subcategoryId',
  /** column name */
  WidgetId = 'widgetId'
}

/** order by stddev() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesStddevOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesStddevPopOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesStddevSampOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "categoryPageWidgetSubcategories" */
export type CategoryPageWidgetSubcategoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageWidgetSubcategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageWidgetSubcategoriesStreamCursorValueInput = {
  subcategoryId?: InputMaybe<Scalars['Int']['input']>;
  widgetId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesSumOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesVarPopOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesVarSampOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "page.category_page_widget_subcategories" */
export type CategoryPageWidgetSubcategoriesVarianceOrderBy = {
  subcategoryId?: InputMaybe<OrderBy>;
  widgetId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "page.category_page_widgets" */
export type CategoryPageWidgets = {
  __typename?: 'categoryPageWidgets';
  /** An object relationship */
  articleCard?: Maybe<CategoryPageArticleCards>;
  articleCardId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  articleList?: Maybe<CategoryPageArticleLists>;
  articleListId?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  contestCard?: Maybe<CategoryPageContestCards>;
  contestCardId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  contestList?: Maybe<CategoryPageContestLists>;
  contestListId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  subcategories: Array<CategoryPageWidgetSubcategories>;
  /** An object relationship */
  workCard?: Maybe<CategoryPageWorkCards>;
  workCardId?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "page.category_page_widgets" */
export type CategoryPageWidgetsSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWidgetSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWidgetSubcategoriesOrderBy>>;
  where?: InputMaybe<CategoryPageWidgetSubcategoriesBoolExp>;
};

/** order by aggregate values of table "page.category_page_widgets" */
export type CategoryPageWidgetsAggregateOrderBy = {
  avg?: InputMaybe<CategoryPageWidgetsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryPageWidgetsMaxOrderBy>;
  min?: InputMaybe<CategoryPageWidgetsMinOrderBy>;
  stddev?: InputMaybe<CategoryPageWidgetsStddevOrderBy>;
  stddevPop?: InputMaybe<CategoryPageWidgetsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CategoryPageWidgetsStddevSampOrderBy>;
  sum?: InputMaybe<CategoryPageWidgetsSumOrderBy>;
  varPop?: InputMaybe<CategoryPageWidgetsVarPopOrderBy>;
  varSamp?: InputMaybe<CategoryPageWidgetsVarSampOrderBy>;
  variance?: InputMaybe<CategoryPageWidgetsVarianceOrderBy>;
};

/** order by avg() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsAvgOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "page.category_page_widgets". All fields are combined with a logical 'AND'. */
export type CategoryPageWidgetsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageWidgetsBoolExp>>;
  _not?: InputMaybe<CategoryPageWidgetsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageWidgetsBoolExp>>;
  articleCard?: InputMaybe<CategoryPageArticleCardsBoolExp>;
  articleCardId?: InputMaybe<IntComparisonExp>;
  articleList?: InputMaybe<CategoryPageArticleListsBoolExp>;
  articleListId?: InputMaybe<IntComparisonExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  contestCard?: InputMaybe<CategoryPageContestCardsBoolExp>;
  contestCardId?: InputMaybe<IntComparisonExp>;
  contestList?: InputMaybe<CategoryPageContestListsBoolExp>;
  contestListId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
  subcategories?: InputMaybe<CategoryPageWidgetSubcategoriesBoolExp>;
  workCard?: InputMaybe<CategoryPageWorkCardsBoolExp>;
  workCardId?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsMaxOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsMinOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "page.category_page_widgets". */
export type CategoryPageWidgetsOrderBy = {
  articleCard?: InputMaybe<CategoryPageArticleCardsOrderBy>;
  articleCardId?: InputMaybe<OrderBy>;
  articleList?: InputMaybe<CategoryPageArticleListsOrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCard?: InputMaybe<CategoryPageContestCardsOrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestList?: InputMaybe<CategoryPageContestListsOrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  subcategoriesAggregate?: InputMaybe<CategoryPageWidgetSubcategoriesAggregateOrderBy>;
  workCard?: InputMaybe<CategoryPageWorkCardsOrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_widgets" */
export enum CategoryPageWidgetsSelectColumn {
  /** column name */
  ArticleCardId = 'articleCardId',
  /** column name */
  ArticleListId = 'articleListId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  ContestCardId = 'contestCardId',
  /** column name */
  ContestListId = 'contestListId',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sortOrder',
  /** column name */
  WorkCardId = 'workCardId'
}

/** order by stddev() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsStddevOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsStddevPopOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsStddevSampOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "categoryPageWidgets" */
export type CategoryPageWidgetsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageWidgetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageWidgetsStreamCursorValueInput = {
  articleCardId?: InputMaybe<Scalars['Int']['input']>;
  articleListId?: InputMaybe<Scalars['Int']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  contestCardId?: InputMaybe<Scalars['Int']['input']>;
  contestListId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  workCardId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsSumOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsVarPopOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsVarSampOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "page.category_page_widgets" */
export type CategoryPageWidgetsVarianceOrderBy = {
  articleCardId?: InputMaybe<OrderBy>;
  articleListId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  contestCardId?: InputMaybe<OrderBy>;
  contestListId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
  workCardId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "page.category_page_work_cards" */
export type CategoryPageWorkCards = {
  __typename?: 'categoryPageWorkCards';
  id?: Maybe<Scalars['Int']['output']>;
  showsText?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  work?: Maybe<ResultWorks>;
  workId?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "page.category_page_work_cards". All fields are combined with a logical 'AND'. */
export type CategoryPageWorkCardsBoolExp = {
  _and?: InputMaybe<Array<CategoryPageWorkCardsBoolExp>>;
  _not?: InputMaybe<CategoryPageWorkCardsBoolExp>;
  _or?: InputMaybe<Array<CategoryPageWorkCardsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  showsText?: InputMaybe<BooleanComparisonExp>;
  work?: InputMaybe<ResultWorksBoolExp>;
  workId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "page.category_page_work_cards". */
export type CategoryPageWorkCardsOrderBy = {
  id?: InputMaybe<OrderBy>;
  showsText?: InputMaybe<OrderBy>;
  work?: InputMaybe<ResultWorksOrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** select columns of table "page.category_page_work_cards" */
export enum CategoryPageWorkCardsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ShowsText = 'showsText',
  /** column name */
  WorkId = 'workId'
}

/** Streaming cursor of the table "categoryPageWorkCards" */
export type CategoryPageWorkCardsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPageWorkCardsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPageWorkCardsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  showsText?: InputMaybe<Scalars['Boolean']['input']>;
  workId?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "page.category_pages" */
export type CategoryPages = {
  __typename?: 'categoryPages';
  /** An object relationship */
  category?: Maybe<ContestCategories>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  widgets: Array<CategoryPageWidgets>;
};


/** columns and relationships of "page.category_pages" */
export type CategoryPagesWidgetsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWidgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWidgetsOrderBy>>;
  where?: InputMaybe<CategoryPageWidgetsBoolExp>;
};

/** Boolean expression to filter rows from the table "page.category_pages". All fields are combined with a logical 'AND'. */
export type CategoryPagesBoolExp = {
  _and?: InputMaybe<Array<CategoryPagesBoolExp>>;
  _not?: InputMaybe<CategoryPagesBoolExp>;
  _or?: InputMaybe<Array<CategoryPagesBoolExp>>;
  category?: InputMaybe<ContestCategoriesBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  categoryName?: InputMaybe<StringComparisonExp>;
  widgets?: InputMaybe<CategoryPageWidgetsBoolExp>;
};

/** Ordering options when selecting data from "page.category_pages". */
export type CategoryPagesOrderBy = {
  category?: InputMaybe<ContestCategoriesOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  categoryName?: InputMaybe<OrderBy>;
  widgetsAggregate?: InputMaybe<CategoryPageWidgetsAggregateOrderBy>;
};

/** select columns of table "page.category_pages" */
export enum CategoryPagesSelectColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CategoryName = 'categoryName'
}

/** Streaming cursor of the table "categoryPages" */
export type CategoryPagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CategoryPagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryPagesStreamCursorValueInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
};

export type ContestLikesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestLikesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestLikesBoolExp>;
  predicate: IntComparisonExp;
};

export type ContestLocalizationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestLocalizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestLocalizationsBoolExp>;
  predicate: IntComparisonExp;
};

export type ContestQualificationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestQualificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestQualificationsBoolExp>;
  predicate: IntComparisonExp;
};

export type ContestReferencesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestReferencesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestReferencesBoolExp>;
  predicate: IntComparisonExp;
};

export type ContestSubcategoriesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestSubcategoriesBoolExp>;
  predicate: IntComparisonExp;
};

export type ContestTaggingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestTaggingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestTaggingsBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "contest.contests" */
export type Contests = {
  __typename?: 'contests';
  /** An object relationship */
  approvalRequestedMember?: Maybe<OrganizerMembers>;
  /** An object relationship */
  approvedMember?: Maybe<OrganizerMembers>;
  atsumaruFormUrl?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdMember?: Maybe<OrganizerMembers>;
  /** An object relationship */
  description: ContestDescriptions;
  descriptionId: Scalars['Int']['output'];
  /** An object relationship */
  entry: ContestEntries;
  /** An object relationship */
  form?: Maybe<EntryForms>;
  id: Scalars['Int']['output'];
  isHidden: Scalars['Boolean']['output'];
  /** A computed field, executes function "contest.contest_is_liked" */
  isLiked?: Maybe<Scalars['Boolean']['output']>;
  isRecommended: Scalars['Boolean']['output'];
  likeTotal: Scalars['Int']['output'];
  /** An array relationship */
  likes: Array<ContestLikes>;
  /** An aggregate relationship */
  likesAggregate: ContestLikesAggregate;
  /** An array relationship */
  localizations: Array<ContestLocalizations>;
  /** An aggregate relationship */
  localizationsAggregate: ContestLocalizationsAggregate;
  name: Scalars['String']['output'];
  /** An object relationship */
  organization?: Maybe<OrganizerOrganizations>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  organizer: ContestOrganizers;
  organizerId: Scalars['Int']['output'];
  /** An object relationship */
  prize?: Maybe<ContestPrizes>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  qualifications: Array<ContestQualifications>;
  /** An aggregate relationship */
  qualificationsAggregate: ContestQualificationsAggregate;
  /** An array relationship */
  references: Array<ContestReferences>;
  /** An aggregate relationship */
  referencesAggregate: ContestReferencesAggregate;
  /** An object relationship */
  restriction: ContestRestrictions;
  restrictionId: Scalars['Int']['output'];
  /** An object relationship */
  result?: Maybe<Results>;
  /** An object relationship */
  resultCeremony?: Maybe<ResultCeremonies>;
  /** An object relationship */
  resultPublication?: Maybe<ContestResultPublications>;
  sourceUrl: Scalars['String']['output'];
  /** An object relationship */
  status?: Maybe<ContestStatuses>;
  /** An object relationship */
  statusSnapshot?: Maybe<ContestStatusSnapshots>;
  /** An object relationship */
  subcategory: ContestSubcategories;
  subcategoryId: Scalars['Int']['output'];
  /** An array relationship */
  taggings: Array<ContestTaggings>;
  /** An aggregate relationship */
  taggingsAggregate: ContestTaggingsAggregate;
  /** An object relationship */
  thumbnail?: Maybe<ContestThumbnails>;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "contest.contests" */
export type ContestsLikesArgs = {
  distinctOn?: InputMaybe<Array<ContestLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestLikesOrderBy>>;
  where?: InputMaybe<ContestLikesBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsLikesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestLikesOrderBy>>;
  where?: InputMaybe<ContestLikesBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsLocalizationsArgs = {
  distinctOn?: InputMaybe<Array<ContestLocalizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestLocalizationsOrderBy>>;
  where?: InputMaybe<ContestLocalizationsBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsLocalizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestLocalizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestLocalizationsOrderBy>>;
  where?: InputMaybe<ContestLocalizationsBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsQualificationsArgs = {
  distinctOn?: InputMaybe<Array<ContestQualificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestQualificationsOrderBy>>;
  where?: InputMaybe<ContestQualificationsBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsQualificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestQualificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestQualificationsOrderBy>>;
  where?: InputMaybe<ContestQualificationsBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsReferencesArgs = {
  distinctOn?: InputMaybe<Array<ContestReferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestReferencesOrderBy>>;
  where?: InputMaybe<ContestReferencesBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsReferencesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestReferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestReferencesOrderBy>>;
  where?: InputMaybe<ContestReferencesBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsTaggingsArgs = {
  distinctOn?: InputMaybe<Array<ContestTaggingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTaggingsOrderBy>>;
  where?: InputMaybe<ContestTaggingsBoolExp>;
};


/** columns and relationships of "contest.contests" */
export type ContestsTaggingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestTaggingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTaggingsOrderBy>>;
  where?: InputMaybe<ContestTaggingsBoolExp>;
};

/** aggregated selection of "contest.contests" */
export type ContestsAggregate = {
  __typename?: 'contestsAggregate';
  aggregate?: Maybe<ContestsAggregateFields>;
  nodes: Array<Contests>;
};

export type ContestsAggregateBoolExp = {
  bool_and?: InputMaybe<ContestsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ContestsAggregateBoolExpBool_Or>;
  count?: InputMaybe<ContestsAggregateBoolExpCount>;
};

export type ContestsAggregateBoolExpBool_And = {
  arguments: ContestsSelectColumnContestsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ContestsAggregateBoolExpBool_Or = {
  arguments: ContestsSelectColumnContestsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ContestsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContestsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "contest.contests" */
export type ContestsAggregateFields = {
  __typename?: 'contestsAggregateFields';
  avg?: Maybe<ContestsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ContestsMaxFields>;
  min?: Maybe<ContestsMinFields>;
  stddev?: Maybe<ContestsStddevFields>;
  stddevPop?: Maybe<ContestsStddevPopFields>;
  stddevSamp?: Maybe<ContestsStddevSampFields>;
  sum?: Maybe<ContestsSumFields>;
  varPop?: Maybe<ContestsVarPopFields>;
  varSamp?: Maybe<ContestsVarSampFields>;
  variance?: Maybe<ContestsVarianceFields>;
};


/** aggregate fields of "contest.contests" */
export type ContestsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contest.contests" */
export type ContestsAggregateOrderBy = {
  avg?: InputMaybe<ContestsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContestsMaxOrderBy>;
  min?: InputMaybe<ContestsMinOrderBy>;
  stddev?: InputMaybe<ContestsStddevOrderBy>;
  stddevPop?: InputMaybe<ContestsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContestsStddevSampOrderBy>;
  sum?: InputMaybe<ContestsSumOrderBy>;
  varPop?: InputMaybe<ContestsVarPopOrderBy>;
  varSamp?: InputMaybe<ContestsVarSampOrderBy>;
  variance?: InputMaybe<ContestsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ContestsAvgFields = {
  __typename?: 'contestsAvgFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contest.contests" */
export type ContestsAvgOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contest.contests". All fields are combined with a logical 'AND'. */
export type ContestsBoolExp = {
  _and?: InputMaybe<Array<ContestsBoolExp>>;
  _not?: InputMaybe<ContestsBoolExp>;
  _or?: InputMaybe<Array<ContestsBoolExp>>;
  approvalRequestedMember?: InputMaybe<OrganizerMembersBoolExp>;
  approvedMember?: InputMaybe<OrganizerMembersBoolExp>;
  atsumaruFormUrl?: InputMaybe<StringComparisonExp>;
  createdMember?: InputMaybe<OrganizerMembersBoolExp>;
  description?: InputMaybe<ContestDescriptionsBoolExp>;
  descriptionId?: InputMaybe<IntComparisonExp>;
  entry?: InputMaybe<ContestEntriesBoolExp>;
  form?: InputMaybe<EntryFormsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  isHidden?: InputMaybe<BooleanComparisonExp>;
  isLiked?: InputMaybe<BooleanComparisonExp>;
  isRecommended?: InputMaybe<BooleanComparisonExp>;
  likeTotal?: InputMaybe<IntComparisonExp>;
  likes?: InputMaybe<ContestLikesBoolExp>;
  likesAggregate?: InputMaybe<ContestLikesAggregateBoolExp>;
  localizations?: InputMaybe<ContestLocalizationsBoolExp>;
  localizationsAggregate?: InputMaybe<ContestLocalizationsAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizerOrganizationsBoolExp>;
  organizationId?: InputMaybe<IntComparisonExp>;
  organizer?: InputMaybe<ContestOrganizersBoolExp>;
  organizerId?: InputMaybe<IntComparisonExp>;
  prize?: InputMaybe<ContestPrizesBoolExp>;
  publicUrl?: InputMaybe<StringComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  qualifications?: InputMaybe<ContestQualificationsBoolExp>;
  qualificationsAggregate?: InputMaybe<ContestQualificationsAggregateBoolExp>;
  references?: InputMaybe<ContestReferencesBoolExp>;
  referencesAggregate?: InputMaybe<ContestReferencesAggregateBoolExp>;
  restriction?: InputMaybe<ContestRestrictionsBoolExp>;
  restrictionId?: InputMaybe<IntComparisonExp>;
  result?: InputMaybe<ResultsBoolExp>;
  resultCeremony?: InputMaybe<ResultCeremoniesBoolExp>;
  resultPublication?: InputMaybe<ContestResultPublicationsBoolExp>;
  sourceUrl?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<ContestStatusesBoolExp>;
  statusSnapshot?: InputMaybe<ContestStatusSnapshotsBoolExp>;
  subcategory?: InputMaybe<ContestSubcategoriesBoolExp>;
  subcategoryId?: InputMaybe<IntComparisonExp>;
  taggings?: InputMaybe<ContestTaggingsBoolExp>;
  taggingsAggregate?: InputMaybe<ContestTaggingsAggregateBoolExp>;
  thumbnail?: InputMaybe<ContestThumbnailsBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type ContestsMaxFields = {
  __typename?: 'contestsMaxFields';
  atsumaruFormUrl?: Maybe<Scalars['String']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  organizerId?: Maybe<Scalars['Int']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  restrictionId?: Maybe<Scalars['Int']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "contest.contests" */
export type ContestsMaxOrderBy = {
  atsumaruFormUrl?: InputMaybe<OrderBy>;
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  publicUrl?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContestsMinFields = {
  __typename?: 'contestsMinFields';
  atsumaruFormUrl?: Maybe<Scalars['String']['output']>;
  descriptionId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  organizerId?: Maybe<Scalars['Int']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  restrictionId?: Maybe<Scalars['Int']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "contest.contests" */
export type ContestsMinOrderBy = {
  atsumaruFormUrl?: InputMaybe<OrderBy>;
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  publicUrl?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "contest.contests". */
export type ContestsOrderBy = {
  approvalRequestedMember?: InputMaybe<OrganizerMembersOrderBy>;
  approvedMember?: InputMaybe<OrganizerMembersOrderBy>;
  atsumaruFormUrl?: InputMaybe<OrderBy>;
  createdMember?: InputMaybe<OrganizerMembersOrderBy>;
  description?: InputMaybe<ContestDescriptionsOrderBy>;
  descriptionId?: InputMaybe<OrderBy>;
  entry?: InputMaybe<ContestEntriesOrderBy>;
  form?: InputMaybe<EntryFormsOrderBy>;
  id?: InputMaybe<OrderBy>;
  isHidden?: InputMaybe<OrderBy>;
  isLiked?: InputMaybe<OrderBy>;
  isRecommended?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  likesAggregate?: InputMaybe<ContestLikesAggregateOrderBy>;
  localizationsAggregate?: InputMaybe<ContestLocalizationsAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizerOrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<ContestOrganizersOrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  prize?: InputMaybe<ContestPrizesOrderBy>;
  publicUrl?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  qualificationsAggregate?: InputMaybe<ContestQualificationsAggregateOrderBy>;
  referencesAggregate?: InputMaybe<ContestReferencesAggregateOrderBy>;
  restriction?: InputMaybe<ContestRestrictionsOrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  result?: InputMaybe<ResultsOrderBy>;
  resultCeremony?: InputMaybe<ResultCeremoniesOrderBy>;
  resultPublication?: InputMaybe<ContestResultPublicationsOrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  status?: InputMaybe<ContestStatusesOrderBy>;
  statusSnapshot?: InputMaybe<ContestStatusSnapshotsOrderBy>;
  subcategory?: InputMaybe<ContestSubcategoriesOrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
  taggingsAggregate?: InputMaybe<ContestTaggingsAggregateOrderBy>;
  thumbnail?: InputMaybe<ContestThumbnailsOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "contest.contests" */
export enum ContestsSelectColumn {
  /** column name */
  AtsumaruFormUrl = 'atsumaruFormUrl',
  /** column name */
  DescriptionId = 'descriptionId',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsRecommended = 'isRecommended',
  /** column name */
  LikeTotal = 'likeTotal',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OrganizerId = 'organizerId',
  /** column name */
  PublicUrl = 'publicUrl',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  RestrictionId = 'restrictionId',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  SubcategoryId = 'subcategoryId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "contestsAggregateBoolExpBool_andArgumentsColumns" columns of table "contest.contests" */
export enum ContestsSelectColumnContestsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsRecommended = 'isRecommended'
}

/** select "contestsAggregateBoolExpBool_orArgumentsColumns" columns of table "contest.contests" */
export enum ContestsSelectColumnContestsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsRecommended = 'isRecommended'
}

/** aggregate stddev on columns */
export type ContestsStddevFields = {
  __typename?: 'contestsStddevFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contest.contests" */
export type ContestsStddevOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContestsStddevPopFields = {
  __typename?: 'contestsStddevPopFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "contest.contests" */
export type ContestsStddevPopOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContestsStddevSampFields = {
  __typename?: 'contestsStddevSampFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "contest.contests" */
export type ContestsStddevSampOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "contests" */
export type ContestsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContestsStreamCursorValueInput = {
  atsumaruFormUrl?: InputMaybe<Scalars['String']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isRecommended?: InputMaybe<Scalars['Boolean']['input']>;
  likeTotal?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  restrictionId?: InputMaybe<Scalars['Int']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type ContestsSumFields = {
  __typename?: 'contestsSumFields';
  descriptionId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  likeTotal?: Maybe<Scalars['Int']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  organizerId?: Maybe<Scalars['Int']['output']>;
  restrictionId?: Maybe<Scalars['Int']['output']>;
  subcategoryId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contest.contests" */
export type ContestsSumOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ContestsVarPopFields = {
  __typename?: 'contestsVarPopFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "contest.contests" */
export type ContestsVarPopOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContestsVarSampFields = {
  __typename?: 'contestsVarSampFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "contest.contests" */
export type ContestsVarSampOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContestsVarianceFields = {
  __typename?: 'contestsVarianceFields';
  descriptionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  likeTotal?: Maybe<Scalars['Float']['output']>;
  organizationId?: Maybe<Scalars['Float']['output']>;
  organizerId?: Maybe<Scalars['Float']['output']>;
  restrictionId?: Maybe<Scalars['Float']['output']>;
  subcategoryId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contest.contests" */
export type ContestsVarianceOrderBy = {
  descriptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  likeTotal?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  organizerId?: InputMaybe<OrderBy>;
  restrictionId?: InputMaybe<OrderBy>;
  subcategoryId?: InputMaybe<OrderBy>;
};

export type CorporateCeoBlogCategorizationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
  predicate: IntComparisonExp;
};

export type CorporateEmployeeBlogsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
  predicate: IntComparisonExp;
};

export type CreatorFolloweesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorFolloweesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorFolloweesBoolExp>;
  predicate: IntComparisonExp;
};

export type CreatorFollowersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorFollowersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorFollowersBoolExp>;
  predicate: IntComparisonExp;
};

export type CreatorInterestCategoriesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorInterestCategoriesBoolExp>;
  predicate: IntComparisonExp;
};

export type CreatorPostLikesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorPostLikesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorPostLikesBoolExp>;
  predicate: IntComparisonExp;
};

export type CreatorPostsAggregateBoolExpBool_And = {
  arguments: CreatorPostsSelectColumnCreatorPostsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorPostsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CreatorPostsAggregateBoolExpBool_Or = {
  arguments: CreatorPostsSelectColumnCreatorPostsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorPostsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CreatorPostsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorPostsBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "creator.creators" */
export type Creators = {
  __typename?: 'creators';
  /** An array relationship */
  followees: Array<CreatorFollowees>;
  /** An aggregate relationship */
  followeesAggregate: CreatorFolloweesAggregate;
  /** An array relationship */
  followers: Array<CreatorFollowers>;
  /** An aggregate relationship */
  followersAggregate: CreatorFollowersAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  interestCategories: Array<CreatorInterestCategories>;
  /** An aggregate relationship */
  interestCategoriesAggregate: CreatorInterestCategoriesAggregate;
  /** A computed field, executes function "creator.creator_is_followee" */
  isFollowee?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  job?: Maybe<CreatorJobs>;
  /** An array relationship */
  likedContests: Array<ContestLikes>;
  /** An aggregate relationship */
  likedContestsAggregate: ContestLikesAggregate;
  /** An array relationship */
  likedPosts: Array<CreatorPostLikes>;
  /** An aggregate relationship */
  likedPostsAggregate: CreatorPostLikesAggregate;
  /** An array relationship */
  likedWorks: Array<ResultWorkLikes>;
  /** An aggregate relationship */
  likedWorksAggregate: ResultWorkLikesAggregate;
  nickname: Scalars['String']['output'];
  /** An array relationship */
  posts: Array<CreatorPosts>;
  /** An aggregate relationship */
  postsAggregate: CreatorPostsAggregate;
  profile?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profileImage?: Maybe<Files>;
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsFolloweesArgs = {
  distinctOn?: InputMaybe<Array<CreatorFolloweesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorFolloweesOrderBy>>;
  where?: InputMaybe<CreatorFolloweesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsFolloweesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorFolloweesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorFolloweesOrderBy>>;
  where?: InputMaybe<CreatorFolloweesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsFollowersArgs = {
  distinctOn?: InputMaybe<Array<CreatorFollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorFollowersOrderBy>>;
  where?: InputMaybe<CreatorFollowersBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorFollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorFollowersOrderBy>>;
  where?: InputMaybe<CreatorFollowersBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsInterestCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorInterestCategoriesOrderBy>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsInterestCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorInterestCategoriesOrderBy>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsLikedContestsArgs = {
  distinctOn?: InputMaybe<Array<ContestLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestLikesOrderBy>>;
  where?: InputMaybe<ContestLikesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsLikedContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestLikesOrderBy>>;
  where?: InputMaybe<ContestLikesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsLikedPostsArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostLikesOrderBy>>;
  where?: InputMaybe<CreatorPostLikesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsLikedPostsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostLikesOrderBy>>;
  where?: InputMaybe<CreatorPostLikesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsLikedWorksArgs = {
  distinctOn?: InputMaybe<Array<ResultWorkLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorkLikesOrderBy>>;
  where?: InputMaybe<ResultWorkLikesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsLikedWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultWorkLikesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorkLikesOrderBy>>;
  where?: InputMaybe<ResultWorkLikesBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsPostsArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


/** columns and relationships of "creator.creators" */
export type CreatorsPostsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};

/** aggregated selection of "creator.creators" */
export type CreatorsAggregate = {
  __typename?: 'creatorsAggregate';
  aggregate?: Maybe<CreatorsAggregateFields>;
  nodes: Array<Creators>;
};

export type CreatorsAggregateBoolExp = {
  count?: InputMaybe<CreatorsAggregateBoolExpCount>;
};

export type CreatorsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CreatorsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "creator.creators" */
export type CreatorsAggregateFields = {
  __typename?: 'creatorsAggregateFields';
  avg?: Maybe<CreatorsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CreatorsMaxFields>;
  min?: Maybe<CreatorsMinFields>;
  stddev?: Maybe<CreatorsStddevFields>;
  stddevPop?: Maybe<CreatorsStddevPopFields>;
  stddevSamp?: Maybe<CreatorsStddevSampFields>;
  sum?: Maybe<CreatorsSumFields>;
  varPop?: Maybe<CreatorsVarPopFields>;
  varSamp?: Maybe<CreatorsVarSampFields>;
  variance?: Maybe<CreatorsVarianceFields>;
};


/** aggregate fields of "creator.creators" */
export type CreatorsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "creator.creators" */
export type CreatorsAggregateOrderBy = {
  avg?: InputMaybe<CreatorsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorsMaxOrderBy>;
  min?: InputMaybe<CreatorsMinOrderBy>;
  stddev?: InputMaybe<CreatorsStddevOrderBy>;
  stddevPop?: InputMaybe<CreatorsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CreatorsStddevSampOrderBy>;
  sum?: InputMaybe<CreatorsSumOrderBy>;
  varPop?: InputMaybe<CreatorsVarPopOrderBy>;
  varSamp?: InputMaybe<CreatorsVarSampOrderBy>;
  variance?: InputMaybe<CreatorsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type CreatorsAvgFields = {
  __typename?: 'creatorsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "creator.creators" */
export type CreatorsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "creator.creators". All fields are combined with a logical 'AND'. */
export type CreatorsBoolExp = {
  _and?: InputMaybe<Array<CreatorsBoolExp>>;
  _not?: InputMaybe<CreatorsBoolExp>;
  _or?: InputMaybe<Array<CreatorsBoolExp>>;
  followees?: InputMaybe<CreatorFolloweesBoolExp>;
  followeesAggregate?: InputMaybe<CreatorFolloweesAggregateBoolExp>;
  followers?: InputMaybe<CreatorFollowersBoolExp>;
  followersAggregate?: InputMaybe<CreatorFollowersAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  interestCategories?: InputMaybe<CreatorInterestCategoriesBoolExp>;
  interestCategoriesAggregate?: InputMaybe<CreatorInterestCategoriesAggregateBoolExp>;
  isFollowee?: InputMaybe<BooleanComparisonExp>;
  job?: InputMaybe<CreatorJobsBoolExp>;
  likedContests?: InputMaybe<ContestLikesBoolExp>;
  likedContestsAggregate?: InputMaybe<ContestLikesAggregateBoolExp>;
  likedPosts?: InputMaybe<CreatorPostLikesBoolExp>;
  likedPostsAggregate?: InputMaybe<CreatorPostLikesAggregateBoolExp>;
  likedWorks?: InputMaybe<ResultWorkLikesBoolExp>;
  likedWorksAggregate?: InputMaybe<ResultWorkLikesAggregateBoolExp>;
  nickname?: InputMaybe<StringComparisonExp>;
  posts?: InputMaybe<CreatorPostsBoolExp>;
  postsAggregate?: InputMaybe<CreatorPostsAggregateBoolExp>;
  profile?: InputMaybe<StringComparisonExp>;
  profileImage?: InputMaybe<FilesBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** aggregate max on columns */
export type CreatorsMaxFields = {
  __typename?: 'creatorsMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "creator.creators" */
export type CreatorsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorsMinFields = {
  __typename?: 'creatorsMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "creator.creators" */
export type CreatorsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  profile?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "creator.creators". */
export type CreatorsOrderBy = {
  followeesAggregate?: InputMaybe<CreatorFolloweesAggregateOrderBy>;
  followersAggregate?: InputMaybe<CreatorFollowersAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  interestCategoriesAggregate?: InputMaybe<CreatorInterestCategoriesAggregateOrderBy>;
  isFollowee?: InputMaybe<OrderBy>;
  job?: InputMaybe<CreatorJobsOrderBy>;
  likedContestsAggregate?: InputMaybe<ContestLikesAggregateOrderBy>;
  likedPostsAggregate?: InputMaybe<CreatorPostLikesAggregateOrderBy>;
  likedWorksAggregate?: InputMaybe<ResultWorkLikesAggregateOrderBy>;
  nickname?: InputMaybe<OrderBy>;
  postsAggregate?: InputMaybe<CreatorPostsAggregateOrderBy>;
  profile?: InputMaybe<OrderBy>;
  profileImage?: InputMaybe<FilesOrderBy>;
  url?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** select columns of table "creator.creators" */
export enum CreatorsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Profile = 'profile',
  /** column name */
  Url = 'url'
}

/** aggregate stddev on columns */
export type CreatorsStddevFields = {
  __typename?: 'creatorsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "creator.creators" */
export type CreatorsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CreatorsStddevPopFields = {
  __typename?: 'creatorsStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "creator.creators" */
export type CreatorsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CreatorsStddevSampFields = {
  __typename?: 'creatorsStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "creator.creators" */
export type CreatorsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "creators" */
export type CreatorsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CreatorsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CreatorsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CreatorsSumFields = {
  __typename?: 'creatorsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "creator.creators" */
export type CreatorsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CreatorsVarPopFields = {
  __typename?: 'creatorsVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "creator.creators" */
export type CreatorsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CreatorsVarSampFields = {
  __typename?: 'creatorsVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "creator.creators" */
export type CreatorsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CreatorsVarianceFields = {
  __typename?: 'creatorsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "creator.creators" */
export type CreatorsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.entry_accepted_emails" */
export type EntryAcceptedEmails = {
  __typename?: 'entryAcceptedEmails';
  /** An array relationship */
  forms: Array<EntryForms>;
  /** An aggregate relationship */
  formsAggregate: EntryFormsAggregate;
  senderName: Scalars['String']['output'];
};


/** columns and relationships of "entry.entry_accepted_emails" */
export type EntryAcceptedEmailsFormsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


/** columns and relationships of "entry.entry_accepted_emails" */
export type EntryAcceptedEmailsFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.entry_accepted_emails". All fields are combined with a logical 'AND'. */
export type EntryAcceptedEmailsBoolExp = {
  _and?: InputMaybe<Array<EntryAcceptedEmailsBoolExp>>;
  _not?: InputMaybe<EntryAcceptedEmailsBoolExp>;
  _or?: InputMaybe<Array<EntryAcceptedEmailsBoolExp>>;
  forms?: InputMaybe<EntryFormsBoolExp>;
  formsAggregate?: InputMaybe<EntryFormsAggregateBoolExp>;
  senderName?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.entry_accepted_emails". */
export type EntryAcceptedEmailsOrderBy = {
  formsAggregate?: InputMaybe<EntryFormsAggregateOrderBy>;
  senderName?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.entry_accepted_links" */
export type EntryAcceptedLinks = {
  __typename?: 'entryAcceptedLinks';
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "entry.entry_accepted_links". All fields are combined with a logical 'AND'. */
export type EntryAcceptedLinksBoolExp = {
  _and?: InputMaybe<Array<EntryAcceptedLinksBoolExp>>;
  _not?: InputMaybe<EntryAcceptedLinksBoolExp>;
  _or?: InputMaybe<Array<EntryAcceptedLinksBoolExp>>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.entry_accepted_links". */
export type EntryAcceptedLinksOrderBy = {
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.entry_completed_tweets" */
export type EntryCompletedTweets = {
  __typename?: 'entryCompletedTweets';
  /** An object relationship */
  form: EntryForms;
  formId: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "entry.entry_completed_tweets". All fields are combined with a logical 'AND'. */
export type EntryCompletedTweetsBoolExp = {
  _and?: InputMaybe<Array<EntryCompletedTweetsBoolExp>>;
  _not?: InputMaybe<EntryCompletedTweetsBoolExp>;
  _or?: InputMaybe<Array<EntryCompletedTweetsBoolExp>>;
  form?: InputMaybe<EntryFormsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.entry_completed_tweets". */
export type EntryCompletedTweetsOrderBy = {
  form?: InputMaybe<EntryFormsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

export type EntryCustomFieldsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryCustomFieldsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryCustomFieldsBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "entry.entry_fee_prices" */
export type EntryFeePrices = {
  __typename?: 'entryFeePrices';
  /** An object relationship */
  entryFeeSetting: EntryFeeSettings;
  formId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  maxQuantity: Scalars['Int']['output'];
  maxWorks: Scalars['Int']['output'];
  /** An object relationship */
  mypage: ApplicantMypages;
  mypageId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Price>;
  sortOrder: Scalars['Int']['output'];
};

/** aggregated selection of "entry.entry_fee_prices" */
export type EntryFeePricesAggregate = {
  __typename?: 'entryFeePricesAggregate';
  aggregate?: Maybe<EntryFeePricesAggregateFields>;
  nodes: Array<EntryFeePrices>;
};

export type EntryFeePricesAggregateBoolExp = {
  count?: InputMaybe<EntryFeePricesAggregateBoolExpCount>;
};

export type EntryFeePricesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFeePricesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFeePricesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "entry.entry_fee_prices" */
export type EntryFeePricesAggregateFields = {
  __typename?: 'entryFeePricesAggregateFields';
  avg?: Maybe<EntryFeePricesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EntryFeePricesMaxFields>;
  min?: Maybe<EntryFeePricesMinFields>;
  stddev?: Maybe<EntryFeePricesStddevFields>;
  stddevPop?: Maybe<EntryFeePricesStddevPopFields>;
  stddevSamp?: Maybe<EntryFeePricesStddevSampFields>;
  sum?: Maybe<EntryFeePricesSumFields>;
  varPop?: Maybe<EntryFeePricesVarPopFields>;
  varSamp?: Maybe<EntryFeePricesVarSampFields>;
  variance?: Maybe<EntryFeePricesVarianceFields>;
};


/** aggregate fields of "entry.entry_fee_prices" */
export type EntryFeePricesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EntryFeePricesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entry.entry_fee_prices" */
export type EntryFeePricesAggregateOrderBy = {
  avg?: InputMaybe<EntryFeePricesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EntryFeePricesMaxOrderBy>;
  min?: InputMaybe<EntryFeePricesMinOrderBy>;
  stddev?: InputMaybe<EntryFeePricesStddevOrderBy>;
  stddevPop?: InputMaybe<EntryFeePricesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<EntryFeePricesStddevSampOrderBy>;
  sum?: InputMaybe<EntryFeePricesSumOrderBy>;
  varPop?: InputMaybe<EntryFeePricesVarPopOrderBy>;
  varSamp?: InputMaybe<EntryFeePricesVarSampOrderBy>;
  variance?: InputMaybe<EntryFeePricesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type EntryFeePricesAvgFields = {
  __typename?: 'entryFeePricesAvgFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesAvgOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "entry.entry_fee_prices". All fields are combined with a logical 'AND'. */
export type EntryFeePricesBoolExp = {
  _and?: InputMaybe<Array<EntryFeePricesBoolExp>>;
  _not?: InputMaybe<EntryFeePricesBoolExp>;
  _or?: InputMaybe<Array<EntryFeePricesBoolExp>>;
  entryFeeSetting?: InputMaybe<EntryFeeSettingsBoolExp>;
  formId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  maxQuantity?: InputMaybe<IntComparisonExp>;
  maxWorks?: InputMaybe<IntComparisonExp>;
  mypage?: InputMaybe<ApplicantMypagesBoolExp>;
  mypageId?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sortOrder?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type EntryFeePricesMaxFields = {
  __typename?: 'entryFeePricesMaxFields';
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  maxWorks?: Maybe<Scalars['Int']['output']>;
  mypageId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesMaxOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EntryFeePricesMinFields = {
  __typename?: 'entryFeePricesMinFields';
  formId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  maxWorks?: Maybe<Scalars['Int']['output']>;
  mypageId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesMinOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "entry.entry_fee_prices". */
export type EntryFeePricesOrderBy = {
  entryFeeSetting?: InputMaybe<EntryFeeSettingsOrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypage?: InputMaybe<ApplicantMypagesOrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** select columns of table "entry.entry_fee_prices" */
export enum EntryFeePricesSelectColumn {
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  MaxQuantity = 'maxQuantity',
  /** column name */
  MaxWorks = 'maxWorks',
  /** column name */
  MypageId = 'mypageId',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sortOrder'
}

/** aggregate stddev on columns */
export type EntryFeePricesStddevFields = {
  __typename?: 'entryFeePricesStddevFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesStddevOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type EntryFeePricesStddevPopFields = {
  __typename?: 'entryFeePricesStddevPopFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesStddevPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type EntryFeePricesStddevSampFields = {
  __typename?: 'entryFeePricesStddevSampFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesStddevSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EntryFeePricesSumFields = {
  __typename?: 'entryFeePricesSumFields';
  formId?: Maybe<Scalars['Int']['output']>;
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  maxWorks?: Maybe<Scalars['Int']['output']>;
  mypageId?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesSumOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type EntryFeePricesVarPopFields = {
  __typename?: 'entryFeePricesVarPopFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesVarPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type EntryFeePricesVarSampFields = {
  __typename?: 'entryFeePricesVarSampFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesVarSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EntryFeePricesVarianceFields = {
  __typename?: 'entryFeePricesVarianceFields';
  formId?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxWorks?: Maybe<Scalars['Float']['output']>;
  mypageId?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "entry.entry_fee_prices" */
export type EntryFeePricesVarianceOrderBy = {
  formId?: InputMaybe<OrderBy>;
  maxQuantity?: InputMaybe<OrderBy>;
  maxWorks?: InputMaybe<OrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<OrderBy>;
};

/** columns and relationships of "entry.entry_fee_settings" */
export type EntryFeeSettings = {
  __typename?: 'entryFeeSettings';
  documentUrl?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mypage: ApplicantMypages;
  mypageId: Scalars['Int']['output'];
  /** An array relationship */
  prices: Array<EntryFeePrices>;
  /** An aggregate relationship */
  pricesAggregate: EntryFeePricesAggregate;
  stripeAccountId: Scalars['String']['output'];
};


/** columns and relationships of "entry.entry_fee_settings" */
export type EntryFeeSettingsPricesArgs = {
  distinctOn?: InputMaybe<Array<EntryFeePricesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFeePricesOrderBy>>;
  where?: InputMaybe<EntryFeePricesBoolExp>;
};


/** columns and relationships of "entry.entry_fee_settings" */
export type EntryFeeSettingsPricesAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFeePricesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFeePricesOrderBy>>;
  where?: InputMaybe<EntryFeePricesBoolExp>;
};

/** Boolean expression to filter rows from the table "entry.entry_fee_settings". All fields are combined with a logical 'AND'. */
export type EntryFeeSettingsBoolExp = {
  _and?: InputMaybe<Array<EntryFeeSettingsBoolExp>>;
  _not?: InputMaybe<EntryFeeSettingsBoolExp>;
  _or?: InputMaybe<Array<EntryFeeSettingsBoolExp>>;
  documentUrl?: InputMaybe<StringComparisonExp>;
  mypage?: InputMaybe<ApplicantMypagesBoolExp>;
  mypageId?: InputMaybe<IntComparisonExp>;
  prices?: InputMaybe<EntryFeePricesBoolExp>;
  pricesAggregate?: InputMaybe<EntryFeePricesAggregateBoolExp>;
  stripeAccountId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "entry.entry_fee_settings". */
export type EntryFeeSettingsOrderBy = {
  documentUrl?: InputMaybe<OrderBy>;
  mypage?: InputMaybe<ApplicantMypagesOrderBy>;
  mypageId?: InputMaybe<OrderBy>;
  pricesAggregate?: InputMaybe<EntryFeePricesAggregateOrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
};

export type EntryFormCheckboxFieldOptionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormCheckboxFieldOptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormCheckboxFieldOptionsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormCheckboxValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormCheckboxValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormCheckboxValuesBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormFieldCascadesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormFieldCascadesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormFieldCascadesBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormFieldEntryFeeCascadesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormFieldEntryFeeCascadesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormFieldEntryFeeCascadesBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormFieldsAggregateBoolExpBool_And = {
  arguments: EntryFormFieldsSelectColumnEntryFormFieldsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormFieldsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EntryFormFieldsAggregateBoolExpBool_Or = {
  arguments: EntryFormFieldsSelectColumnEntryFormFieldsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormFieldsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EntryFormFieldsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormFieldsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormFieldsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormFileFieldExtensionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormFileFieldExtensionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormFileFieldExtensionsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormImageFieldExtensionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormImageFieldExtensionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormImageFieldExtensionsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormRadioFieldOptionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormRadioFieldOptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormRadioFieldOptionsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormSelectboxFieldOptionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormSelectboxFieldOptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormSelectboxFieldOptionsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormValuesBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryFormsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryFormsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryFormsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryWorkFieldsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryWorkFieldsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryWorkFieldsBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryWorkValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryWorkValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryWorkValuesBoolExp>;
  predicate: IntComparisonExp;
};

export type EntryWorksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EntryWorksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EntryWorksBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "entry.group_entry_settings" */
export type GroupEntrySettings = {
  __typename?: 'groupEntrySettings';
  allowsIndividual: Scalars['Boolean']['output'];
  /** An object relationship */
  applicantGroupFields: ApplicantGroupFields;
  applicantGroupFieldsId: Scalars['Int']['output'];
  /** An object relationship */
  mypage: ApplicantMypages;
  mypageId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "entry.group_entry_settings". All fields are combined with a logical 'AND'. */
export type GroupEntrySettingsBoolExp = {
  _and?: InputMaybe<Array<GroupEntrySettingsBoolExp>>;
  _not?: InputMaybe<GroupEntrySettingsBoolExp>;
  _or?: InputMaybe<Array<GroupEntrySettingsBoolExp>>;
  allowsIndividual?: InputMaybe<BooleanComparisonExp>;
  applicantGroupFields?: InputMaybe<ApplicantGroupFieldsBoolExp>;
  applicantGroupFieldsId?: InputMaybe<IntComparisonExp>;
  mypage?: InputMaybe<ApplicantMypagesBoolExp>;
  mypageId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "entry.group_entry_settings". */
export type GroupEntrySettingsOrderBy = {
  allowsIndividual?: InputMaybe<OrderBy>;
  applicantGroupFields?: InputMaybe<ApplicantGroupFieldsOrderBy>;
  applicantGroupFieldsId?: InputMaybe<OrderBy>;
  mypage?: InputMaybe<ApplicantMypagesOrderBy>;
  mypageId?: InputMaybe<OrderBy>;
};

export type LandingPageBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "page.landing_pages" */
export type LandingPages = {
  __typename?: 'landingPages';
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

export type LandingPagesAggregate = {
  __typename?: 'landingPagesAggregate';
  aggregate?: Maybe<LandingPagesAggregateFields>;
  nodes: Array<LandingPages>;
};

/** aggregate fields of "page.landing_pages" */
export type LandingPagesAggregateFields = {
  __typename?: 'landingPagesAggregateFields';
  avg?: Maybe<LandingPagesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LandingPagesMaxFields>;
  min?: Maybe<LandingPagesMinFields>;
  stddev?: Maybe<LandingPagesStddevFields>;
  stddevPop?: Maybe<LandingPagesStddevPopFields>;
  stddevSamp?: Maybe<LandingPagesStddevSampFields>;
  sum?: Maybe<LandingPagesSumFields>;
  varPop?: Maybe<LandingPagesVarPopFields>;
  varSamp?: Maybe<LandingPagesVarSampFields>;
  variance?: Maybe<LandingPagesVarianceFields>;
};


/** aggregate fields of "page.landing_pages" */
export type LandingPagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LandingPagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type LandingPagesAvgFields = {
  __typename?: 'landingPagesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "page.landing_pages". All fields are combined with a logical 'AND'. */
export type LandingPagesBoolExp = {
  _and?: InputMaybe<Array<LandingPagesBoolExp>>;
  _not?: InputMaybe<LandingPagesBoolExp>;
  _or?: InputMaybe<Array<LandingPagesBoolExp>>;
  html?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  layout?: InputMaybe<StringComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type LandingPagesMaxFields = {
  __typename?: 'landingPagesMaxFields';
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type LandingPagesMinFields = {
  __typename?: 'landingPagesMinFields';
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "page.landing_pages". */
export type LandingPagesOrderBy = {
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  layout?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "page.landing_pages" */
export enum LandingPagesSelectColumn {
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Layout = 'layout',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type LandingPagesStddevFields = {
  __typename?: 'landingPagesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type LandingPagesStddevPopFields = {
  __typename?: 'landingPagesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type LandingPagesStddevSampFields = {
  __typename?: 'landingPagesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "landingPages" */
export type LandingPagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LandingPagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LandingPagesStreamCursorValueInput = {
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type LandingPagesSumFields = {
  __typename?: 'landingPagesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type LandingPagesVarPopFields = {
  __typename?: 'landingPagesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type LandingPagesVarSampFields = {
  __typename?: 'landingPagesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type LandingPagesVarianceFields = {
  __typename?: 'landingPagesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type MagazineArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MagazineSubscriptionFirstMagazinesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MagazineSubscriptionFirstMagazinesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MagazineSubscriptionFirstMagazinesBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "magazine.magazines" */
export type Magazines = {
  __typename?: 'magazines';
  /** An object relationship */
  digital?: Maybe<MagazineDigitals>;
  /** An object relationship */
  digitalFeature?: Maybe<MagazineDigitalFeatures>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  introduction?: Maybe<MagazineIntroductions>;
  /** An object relationship */
  paper?: Maybe<MagazinePapers>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  releasedAt?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  thumbnail?: Maybe<MagazineThumbnails>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeName?: Maybe<Scalars['String']['output']>;
};

export type MagazinesAggregate = {
  __typename?: 'magazinesAggregate';
  aggregate?: Maybe<MagazinesAggregateFields>;
  nodes: Array<Magazines>;
};

/** aggregate fields of "magazine.magazines" */
export type MagazinesAggregateFields = {
  __typename?: 'magazinesAggregateFields';
  avg?: Maybe<MagazinesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MagazinesMaxFields>;
  min?: Maybe<MagazinesMinFields>;
  stddev?: Maybe<MagazinesStddevFields>;
  stddevPop?: Maybe<MagazinesStddevPopFields>;
  stddevSamp?: Maybe<MagazinesStddevSampFields>;
  sum?: Maybe<MagazinesSumFields>;
  varPop?: Maybe<MagazinesVarPopFields>;
  varSamp?: Maybe<MagazinesVarSampFields>;
  variance?: Maybe<MagazinesVarianceFields>;
};


/** aggregate fields of "magazine.magazines" */
export type MagazinesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MagazinesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type MagazinesAvgFields = {
  __typename?: 'magazinesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "magazine.magazines". All fields are combined with a logical 'AND'. */
export type MagazinesBoolExp = {
  _and?: InputMaybe<Array<MagazinesBoolExp>>;
  _not?: InputMaybe<MagazinesBoolExp>;
  _or?: InputMaybe<Array<MagazinesBoolExp>>;
  digital?: InputMaybe<MagazineDigitalsBoolExp>;
  digitalFeature?: InputMaybe<MagazineDigitalFeaturesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  introduction?: InputMaybe<MagazineIntroductionsBoolExp>;
  paper?: InputMaybe<MagazinePapersBoolExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  releasedAt?: InputMaybe<DateComparisonExp>;
  thumbnail?: InputMaybe<MagazineThumbnailsBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  volume?: InputMaybe<IntComparisonExp>;
  volumeName?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type MagazinesMaxFields = {
  __typename?: 'magazinesMaxFields';
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  releasedAt?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MagazinesMinFields = {
  __typename?: 'magazinesMinFields';
  id?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  releasedAt?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeName?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "magazine.magazines". */
export type MagazinesOrderBy = {
  digital?: InputMaybe<MagazineDigitalsOrderBy>;
  digitalFeature?: InputMaybe<MagazineDigitalFeaturesOrderBy>;
  id?: InputMaybe<OrderBy>;
  introduction?: InputMaybe<MagazineIntroductionsOrderBy>;
  paper?: InputMaybe<MagazinePapersOrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  releasedAt?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<MagazineThumbnailsOrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  volume?: InputMaybe<OrderBy>;
  volumeName?: InputMaybe<OrderBy>;
};

/** select columns of table "magazine.magazines" */
export enum MagazinesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  ReleasedAt = 'releasedAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Volume = 'volume',
  /** column name */
  VolumeName = 'volumeName'
}

/** aggregate stddev on columns */
export type MagazinesStddevFields = {
  __typename?: 'magazinesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type MagazinesStddevPopFields = {
  __typename?: 'magazinesStddevPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type MagazinesStddevSampFields = {
  __typename?: 'magazinesStddevSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "magazines" */
export type MagazinesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MagazinesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MagazinesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  releasedAt?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  volume?: InputMaybe<Scalars['Int']['input']>;
  volumeName?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type MagazinesSumFields = {
  __typename?: 'magazinesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type MagazinesVarPopFields = {
  __typename?: 'magazinesVarPopFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type MagazinesVarSampFields = {
  __typename?: 'magazinesVarSampFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MagazinesVarianceFields = {
  __typename?: 'magazinesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  checkoutCart: CheckoutCartOutput;
  entryContest: EntryContestOutput;
  forgotPassword: ForgotPasswordOutput;
  forgotPasswordForApplicant: ForgotPasswordForApplicantOutput;
  generateUrlForPutFile: GenerateUrlForPutFileOutput;
  inquire: InquireOutput;
  likeContest: LikeContestOutput;
  likeWork: LikeWorkOutput;
  publicVote: PublicVoteOutput;
  resetPassword: ResetPasswordOutput;
  resetPasswordForApplicant: ResetPasswordForApplicantOutput;
  saveOrganization: SaveOrganizationOutput;
  signin: SigninOutput;
  signinForApplicant: SigninForApplicantOutput;
  signup: SignupOutput;
  signupForApplicant: SignupForApplicantOutput;
  unlikeContest: UnlikeContestOutput;
  unlikeWork: UnlikeWorkOutput;
  verifyEmail: VerifyEmailOutput;
  verifyEmailForApplicant: VerifyEmailForApplicantOutput;
};


/** mutation root */
export type Mutation_RootCheckoutCartArgs = {
  cancelUrl: Scalars['String']['input'];
  items: Array<CheckoutCartInputItem>;
  successUrl: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootEntryContestArgs = {
  applicant: ApplicantInput;
  byOrganizer: Scalars['Boolean']['input'];
  form: EntryContestInputForm;
  includesHidden: Scalars['Boolean']['input'];
  works: Array<EntryContestInputWork>;
};


/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  passwordResetEmail: ForgotPasswordInputPasswordResetEmail;
};


/** mutation root */
export type Mutation_RootForgotPasswordForApplicantArgs = {
  mypage: ForgotPasswordForApplicantInputMypage;
  passwordResetEmail: ForgotPasswordForApplicantInputPasswordResetEmail;
};


/** mutation root */
export type Mutation_RootGenerateUrlForPutFileArgs = {
  fileName: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInquireArgs = {
  confirmationEmail?: InputMaybe<InquireInputConfirmationEmail>;
  inquiryEmail: InquireInputInquiryEmail;
};


/** mutation root */
export type Mutation_RootLikeContestArgs = {
  contest: LikeContestInputContest;
};


/** mutation root */
export type Mutation_RootLikeWorkArgs = {
  work: LikeWorkInputWork;
};


/** mutation root */
export type Mutation_RootPublicVoteArgs = {
  voter: PublicVoteInputVoter;
  works: Array<PublicVoteInputWork>;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootResetPasswordForApplicantArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSaveOrganizationArgs = {
  organization: SaveOrganizationInput;
};


/** mutation root */
export type Mutation_RootSigninArgs = {
  user: SigninInputUser;
};


/** mutation root */
export type Mutation_RootSigninForApplicantArgs = {
  user: SigninForApplicantInputUser;
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  receivesNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  user: SignupInputUser;
  verificationEmail: SignupInputVerificationEmail;
};


/** mutation root */
export type Mutation_RootSignupForApplicantArgs = {
  user: SignupForApplicantInputUser;
  verificationEmail: SignupForApplicantInputVerificationEmail;
};


/** mutation root */
export type Mutation_RootUnlikeContestArgs = {
  contest: UnlikeContestInputContest;
};


/** mutation root */
export type Mutation_RootUnlikeWorkArgs = {
  work: UnlikeWorkInputWork;
};


/** mutation root */
export type Mutation_RootVerifyEmailArgs = {
  receivesNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootVerifyEmailForApplicantArgs = {
  token: Scalars['String']['input'];
};

export type Query_Root = {
  __typename?: 'query_root';
  addressesByZipCode: Array<AddressesByZipCodeOutput>;
  /** fetch data from the table: "entry.mypages" */
  applicantMypages: Array<ApplicantMypages>;
  /** fetch data from the table: "entry.mypages" using primary key columns */
  applicantMypagesByPk?: Maybe<ApplicantMypages>;
  /** fetch data from the table: "article.rss_prtimes_items" */
  articleRssPrtimesItems: Array<ArticleRssPrtimesItems>;
  /** fetch aggregated fields from the table: "article.rss_prtimes_items" */
  articleRssPrtimesItemsAggregate: ArticleRssPrtimesItemsAggregate;
  /** fetch data from the table: "article.rss_prtimes_items" using primary key columns */
  articleRssPrtimesItemsByPk?: Maybe<ArticleRssPrtimesItems>;
  /** fetch data from the table: "article.tags" */
  articleTags: Array<ArticleTags>;
  /** fetch aggregated fields from the table: "article.tags" */
  articleTagsAggregate: ArticleTagsAggregate;
  /** fetch data from the table: "article.top_page" */
  articleTopPage: Array<ArticleTopPage>;
  /** fetch data from the table: "article.writers" */
  articleWriters: Array<ArticleWriters>;
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articlesAggregate: ArticlesAggregate;
  /** fetch data from the table: "banner.category_top_new_contests" */
  bannerCategoryTopNewContests: Array<BannerCategoryTopNewContests>;
  /** fetch aggregated fields from the table: "banner.category_top_new_contests" */
  bannerCategoryTopNewContestsAggregate: BannerCategoryTopNewContestsAggregate;
  /** fetch data from the table: "banner.category_top_premium_banners" */
  bannerCategoryTopPremiumBanners: Array<BannerCategoryTopPremiumBanners>;
  /** fetch aggregated fields from the table: "banner.category_top_premium_banners" */
  bannerCategoryTopPremiumBannersAggregate: BannerCategoryTopPremiumBannersAggregate;
  /** fetch data from the table: "banner.category_top_standard_banners" */
  bannerCategoryTopStandardBanners: Array<BannerCategoryTopStandardBanners>;
  /** fetch aggregated fields from the table: "banner.category_top_standard_banners" */
  bannerCategoryTopStandardBannersAggregate: BannerCategoryTopStandardBannersAggregate;
  /** fetch data from the table: "banner.category_top_subcategories" */
  bannerCategoryTopSubcategories: Array<BannerCategoryTopSubcategories>;
  /** fetch data from the table: "banner.company_banners" */
  bannerCompanyBanners: Array<BannerCompanyBanners>;
  /** fetch aggregated fields from the table: "banner.company_banners" */
  bannerCompanyBannersAggregate: BannerCompanyBannersAggregate;
  /** fetch data from the table: "banner.contest_category_banners" */
  bannerContestCategoryBanners: Array<BannerContestCategoryBanners>;
  /** fetch aggregated fields from the table: "banner.contest_category_banners" */
  bannerContestCategoryBannersAggregate: BannerContestCategoryBannersAggregate;
  /** fetch data from the table: "banner.contest_top_banners" */
  bannerContestTopBanners: Array<BannerContestTopBanners>;
  /** fetch aggregated fields from the table: "banner.contest_top_banners" */
  bannerContestTopBannersAggregate: BannerContestTopBannersAggregate;
  /** fetch data from the table: "banner.footer_banners" */
  bannerFooterBanners: Array<BannerFooterBanners>;
  /** fetch aggregated fields from the table: "banner.footer_banners" */
  bannerFooterBannersAggregate: BannerFooterBannersAggregate;
  /** fetch data from the table: "banner.new_contests" */
  bannerNewContests: Array<BannerNewContests>;
  /** fetch aggregated fields from the table: "banner.new_contests" */
  bannerNewContestsAggregate: BannerNewContestsAggregate;
  /** fetch data from the table: "banner.pickup_banners" */
  bannerPickupBanners: Array<BannerPickupBanners>;
  /** fetch aggregated fields from the table: "banner.pickup_banners" */
  bannerPickupBannersAggregate: BannerPickupBannersAggregate;
  /** fetch data from the table: "banner.premium_banners" */
  bannerPremiumBanners: Array<BannerPremiumBanners>;
  /** fetch aggregated fields from the table: "banner.premium_banners" */
  bannerPremiumBannersAggregate: BannerPremiumBannersAggregate;
  /** fetch data from the table: "banner.rectangle_banners" */
  bannerRectangleBanners: Array<BannerRectangleBanners>;
  /** fetch aggregated fields from the table: "banner.rectangle_banners" */
  bannerRectangleBannersAggregate: BannerRectangleBannersAggregate;
  /** fetch data from the table: "banner.search_result_contests" */
  bannerSearchResultContests: Array<BannerSearchResultContests>;
  /** fetch aggregated fields from the table: "banner.search_result_contests" */
  bannerSearchResultContestsAggregate: BannerSearchResultContestsAggregate;
  /** fetch data from the table: "banner.sidebar_banners" */
  bannerSidebarBanners: Array<BannerSidebarBanners>;
  /** fetch aggregated fields from the table: "banner.sidebar_banners" */
  bannerSidebarBannersAggregate: BannerSidebarBannersAggregate;
  /** fetch data from the table: "banner.standard_banners" */
  bannerStandardBanners: Array<BannerStandardBanners>;
  /** fetch aggregated fields from the table: "banner.standard_banners" */
  bannerStandardBannersAggregate: BannerStandardBannersAggregate;
  /** fetch data from the table: "banner.super_banners" */
  bannerSuperBanners: Array<BannerSuperBanners>;
  /** fetch aggregated fields from the table: "banner.super_banners" */
  bannerSuperBannersAggregate: BannerSuperBannersAggregate;
  /** fetch data from the table: "banner.text_links" */
  bannerTextLinks: Array<BannerTextLinks>;
  /** fetch aggregated fields from the table: "banner.text_links" */
  bannerTextLinksAggregate: BannerTextLinksAggregate;
  /** fetch data from the table: "page.category_page_article_card_texts" */
  categoryPageArticleCardTexts: Array<CategoryPageArticleCardTexts>;
  /** fetch data from the table: "page.category_page_article_card_thumbnails" */
  categoryPageArticleCardThumbnails: Array<CategoryPageArticleCardThumbnails>;
  /** fetch data from the table: "page.category_page_article_cards" */
  categoryPageArticleCards: Array<CategoryPageArticleCards>;
  /** fetch data from the table: "page.category_page_article_list_articles" */
  categoryPageArticleListArticles: Array<CategoryPageArticleListArticles>;
  /** fetch data from the table: "page.category_page_article_lists" */
  categoryPageArticleLists: Array<CategoryPageArticleLists>;
  /** fetch data from the table: "page.category_page_contest_card_texts" */
  categoryPageContestCardTexts: Array<CategoryPageContestCardTexts>;
  /** fetch data from the table: "page.category_page_contest_card_thumbnails" */
  categoryPageContestCardThumbnails: Array<CategoryPageContestCardThumbnails>;
  /** fetch data from the table: "page.category_page_contest_cards" */
  categoryPageContestCards: Array<CategoryPageContestCards>;
  /** fetch data from the table: "page.category_page_contest_list_contests" */
  categoryPageContestListContests: Array<CategoryPageContestListContests>;
  /** fetch data from the table: "page.category_page_contest_lists" */
  categoryPageContestLists: Array<CategoryPageContestLists>;
  /** fetch data from the table: "page.category_page_widget_subcategories" */
  categoryPageWidgetSubcategories: Array<CategoryPageWidgetSubcategories>;
  /** fetch data from the table: "page.category_page_widgets" */
  categoryPageWidgets: Array<CategoryPageWidgets>;
  /** fetch data from the table: "page.category_page_work_cards" */
  categoryPageWorkCards: Array<CategoryPageWorkCards>;
  /** fetch data from the table: "page.category_pages" */
  categoryPages: Array<CategoryPages>;
  /** fetch data from the table: "contest.categories" */
  contestCategories: Array<ContestCategories>;
  /** fetch aggregated fields from the table: "contest.categories" */
  contestCategoriesAggregate: ContestCategoriesAggregate;
  /** fetch data from the table: "contest.categories" using primary key columns */
  contestCategoriesByPk?: Maybe<ContestCategories>;
  /** fetch data from the table: "contest.jobs" */
  contestJobs: Array<ContestJobs>;
  /** fetch aggregated fields from the table: "contest.jobs" */
  contestJobsAggregate: ContestJobsAggregate;
  /** fetch data from the table: "contest.jobs" using primary key columns */
  contestJobsByPk?: Maybe<ContestJobs>;
  /** fetch data from the table: "contest.restrictions" */
  contestRestrictions: Array<ContestRestrictions>;
  /** fetch aggregated fields from the table: "contest.restrictions" */
  contestRestrictionsAggregate: ContestRestrictionsAggregate;
  /** fetch data from the table: "contest.restrictions" using primary key columns */
  contestRestrictionsByPk?: Maybe<ContestRestrictions>;
  /** fetch data from the table: "contest.subcategories" */
  contestSubcategories: Array<ContestSubcategories>;
  /** fetch aggregated fields from the table: "contest.subcategories" */
  contestSubcategoriesAggregate: ContestSubcategoriesAggregate;
  /** fetch data from the table: "contest.subcategories" using primary key columns */
  contestSubcategoriesByPk?: Maybe<ContestSubcategories>;
  /** fetch data from the table: "contest.tags" */
  contestTags: Array<ContestTags>;
  /** fetch aggregated fields from the table: "contest.tags" */
  contestTagsAggregate: ContestTagsAggregate;
  /** fetch data from the table: "contest.tags" using primary key columns */
  contestTagsByPk?: Maybe<ContestTags>;
  /** An array relationship */
  contests: Array<Contests>;
  /** An aggregate relationship */
  contestsAggregate: ContestsAggregate;
  /** fetch data from the table: "contest.contests" using primary key columns */
  contestsByPk?: Maybe<Contests>;
  /** fetch data from the table: "corporate.case_studies" */
  corporateCaseStudies: Array<CorporateCaseStudies>;
  /** fetch aggregated fields from the table: "corporate.case_studies" */
  corporateCaseStudiesAggregate: CorporateCaseStudiesAggregate;
  /** fetch data from the table: "corporate.ceo_blog_categories" */
  corporateCeoBlogCategories: Array<CorporateCeoBlogCategories>;
  /** fetch aggregated fields from the table: "corporate.ceo_blog_categories" */
  corporateCeoBlogCategoriesAggregate: CorporateCeoBlogCategoriesAggregate;
  /** fetch data from the table: "corporate.ceo_blog_categorizations" */
  corporateCeoBlogCategorizations: Array<CorporateCeoBlogCategorizations>;
  /** fetch aggregated fields from the table: "corporate.ceo_blog_categorizations" */
  corporateCeoBlogCategorizationsAggregate: CorporateCeoBlogCategorizationsAggregate;
  /** fetch data from the table: "corporate.ceo_blogs" */
  corporateCeoBlogs: Array<CorporateCeoBlogs>;
  /** fetch aggregated fields from the table: "corporate.ceo_blogs" */
  corporateCeoBlogsAggregate: CorporateCeoBlogsAggregate;
  /** fetch data from the table: "corporate.contest_knowledges" */
  corporateContestKnowledges: Array<CorporateContestKnowledges>;
  /** fetch aggregated fields from the table: "corporate.contest_knowledges" */
  corporateContestKnowledgesAggregate: CorporateContestKnowledgesAggregate;
  /** fetch data from the table: "corporate.employee_blog_categories" */
  corporateEmployeeBlogCategories: Array<CorporateEmployeeBlogCategories>;
  /** fetch aggregated fields from the table: "corporate.employee_blog_categories" */
  corporateEmployeeBlogCategoriesAggregate: CorporateEmployeeBlogCategoriesAggregate;
  /** fetch data from the table: "corporate.employee_blogs" */
  corporateEmployeeBlogs: Array<CorporateEmployeeBlogs>;
  /** fetch aggregated fields from the table: "corporate.employee_blogs" */
  corporateEmployeeBlogsAggregate: CorporateEmployeeBlogsAggregate;
  /** fetch data from the table: "corporate.recruitments" */
  corporateRecruitments: Array<CorporateRecruitments>;
  /** fetch data from the table: "corporate.sales_documents" */
  corporateSalesDocuments: Array<CorporateSalesDocuments>;
  /** fetch aggregated fields from the table: "corporate.sales_documents" */
  corporateSalesDocumentsAggregate: CorporateSalesDocumentsAggregate;
  /** fetch data from the table: "creator.interest_categories" */
  creatorInterestCategories: Array<CreatorInterestCategories>;
  /** fetch aggregated fields from the table: "creator.interest_categories" */
  creatorInterestCategoriesAggregate: CreatorInterestCategoriesAggregate;
  /** fetch data from the table: "creator.interest_categories" using primary key columns */
  creatorInterestCategoriesByPk?: Maybe<CreatorInterestCategories>;
  /** fetch data from the table: "creator.jobs" */
  creatorJobs: Array<CreatorJobs>;
  /** fetch aggregated fields from the table: "creator.jobs" */
  creatorJobsAggregate: CreatorJobsAggregate;
  /** fetch data from the table: "creator.jobs" using primary key columns */
  creatorJobsByPk?: Maybe<CreatorJobs>;
  /** fetch data from the table: "creator.notifications" */
  creatorNotifications: Array<CreatorNotifications>;
  /** fetch aggregated fields from the table: "creator.notifications" */
  creatorNotificationsAggregate: CreatorNotificationsAggregate;
  /** fetch data from the table: "creator.posts" */
  creatorPosts: Array<CreatorPosts>;
  /** fetch aggregated fields from the table: "creator.posts" */
  creatorPostsAggregate: CreatorPostsAggregate;
  /** fetch data from the table: "creator.posts" using primary key columns */
  creatorPostsByPk?: Maybe<CreatorPosts>;
  /** An array relationship */
  creators: Array<Creators>;
  /** An aggregate relationship */
  creatorsAggregate: CreatorsAggregate;
  /** fetch data from the table: "creator.creators" using primary key columns */
  creatorsByPk?: Maybe<Creators>;
  customer?: Maybe<Customer>;
  customerByApplicantUser?: Maybe<ConnectedCustomer>;
  customerByUser?: Maybe<Customer>;
  /** fetch data from the table: "entry.form_field_group_entry_cascades" */
  entryFormFieldGroupEntryCascades: Array<EntryFormFieldGroupEntryCascades>;
  /** fetch data from the table: "entry.form_field_group_entry_cascades" using primary key columns */
  entryFormFieldGroupEntryCascadesByPk?: Maybe<EntryFormFieldGroupEntryCascades>;
  /** fetch data from the table: "entry.form_field_groupings" */
  entryFormFieldGroupings: Array<EntryFormFieldGroupings>;
  /** fetch data from the table: "entry.form_field_groupings" using primary key columns */
  entryFormFieldGroupingsByPk?: Maybe<EntryFormFieldGroupings>;
  /** fetch data from the table: "entry.form_field_repeat_settings" */
  entryFormFieldRepeatSettings: Array<EntryFormFieldRepeatSettings>;
  /** fetch data from the table: "entry.form_field_repeat_settings" using primary key columns */
  entryFormFieldRepeatSettingsByPk?: Maybe<EntryFormFieldRepeatSettings>;
  /** fetch data from the table: "entry.form_group_fields" */
  entryFormGroupFields: Array<EntryFormGroupFields>;
  /** fetch data from the table: "entry.form_group_fields" using primary key columns */
  entryFormGroupFieldsByPk?: Maybe<EntryFormGroupFields>;
  /** fetch data from the table: "entry.form_precautions" */
  entryFormPrecautions: Array<EntryFormPrecautions>;
  /** fetch data from the table: "entry.form_precautions" using primary key columns */
  entryFormPrecautionsByPk?: Maybe<EntryFormPrecautions>;
  /** fetch data from the table: "entry.forms" */
  entryForms: Array<EntryForms>;
  /** fetch aggregated fields from the table: "entry.forms" */
  entryFormsAggregate: EntryFormsAggregate;
  /** fetch data from the table: "entry.forms" using primary key columns */
  entryFormsByPk?: Maybe<EntryForms>;
  /** fetch data from the table: "entry.works" using primary key columns */
  entryWorksByPk?: Maybe<EntryWorks>;
  /** fetch data from the table: "genders" */
  genders: Array<Genders>;
  /** fetch aggregated fields from the table: "genders" */
  gendersAggregate: GendersAggregate;
  /** fetch data from the table: "genders" using primary key columns */
  gendersByPk?: Maybe<Genders>;
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch data from the table: "images" using primary key columns */
  imagesByPk?: Maybe<Images>;
  /** execute function "page.landing_page_by_slug" which returns "page.landing_pages" */
  landingPageBySlug?: Maybe<LandingPages>;
  /** execute function "page.landing_page_by_slug" and query aggregates on result of table type "page.landing_pages" */
  landingPageBySlug_aggregate: LandingPagesAggregate;
  /** fetch data from the table: "page.landing_pages" */
  landingPages: Array<LandingPages>;
  /** fetch aggregated fields from the table: "page.landing_pages" */
  landingPagesAggregate: LandingPagesAggregate;
  /** execute function "magazine.magazine" which returns "magazine.magazines" */
  magazine?: Maybe<Magazines>;
  /** fetch data from the table: "magazine.subscription" */
  magazineSubscription: Array<MagazineSubscription>;
  /** execute function "magazine.magazine" and query aggregates on result of table type "magazine.magazines" */
  magazine_aggregate: MagazinesAggregate;
  /** fetch data from the table: "magazine.magazines" */
  magazines: Array<Magazines>;
  /** fetch aggregated fields from the table: "magazine.magazines" */
  magazinesAggregate: MagazinesAggregate;
  /** execute function "user.me" which returns "user.users" */
  me?: Maybe<Users>;
  /** fetch data from the table: "monthly_periods" */
  monthlyPeriods: Array<MonthlyPeriods>;
  /** fetch aggregated fields from the table: "monthly_periods" */
  monthlyPeriodsAggregate: MonthlyPeriodsAggregate;
  /** fetch data from the table: "monthly_periods" using primary key columns */
  monthlyPeriodsByPk?: Maybe<MonthlyPeriods>;
  order?: Maybe<Order>;
  orders: Orders;
  /** fetch data from the table: "organizer.classifications" */
  organizerClassifications: Array<OrganizerClassifications>;
  /** fetch aggregated fields from the table: "organizer.classifications" */
  organizerClassificationsAggregate: OrganizerClassificationsAggregate;
  /** fetch data from the table: "organizer.classifications" using primary key columns */
  organizerClassificationsByPk?: Maybe<OrganizerClassifications>;
  /** An array relationship */
  prefectures: Array<Prefectures>;
  /** fetch data from the table: "prefectures" using primary key columns */
  prefecturesByPk?: Maybe<Prefectures>;
  price?: Maybe<Price>;
  prices: Array<Price>;
  product?: Maybe<Product>;
  products: Array<Product>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch data from the table: "regions" using primary key columns */
  regionsByPk?: Maybe<Regions>;
  /** fetch data from the table: "result.ceremonies" */
  resultCeremonies: Array<ResultCeremonies>;
  /** fetch aggregated fields from the table: "result.ceremonies" */
  resultCeremoniesAggregate: ResultCeremoniesAggregate;
  /** fetch data from the table: "result.tags" */
  resultTags: Array<ResultTags>;
  /** fetch aggregated fields from the table: "result.tags" */
  resultTagsAggregate: ResultTagsAggregate;
  /** fetch data from the table: "result.works" */
  resultWorks: Array<ResultWorks>;
  /** fetch aggregated fields from the table: "result.works" */
  resultWorksAggregate: ResultWorksAggregate;
  /** fetch data from the table: "result.results" */
  results: Array<Results>;
  /** fetch aggregated fields from the table: "result.results" */
  resultsAggregate: ResultsAggregate;
};


export type Query_RootAddressesByZipCodeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  zipCode: Scalars['String']['input'];
};


export type Query_RootApplicantMypagesArgs = {
  distinctOn?: InputMaybe<Array<ApplicantMypagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ApplicantMypagesOrderBy>>;
  where?: InputMaybe<ApplicantMypagesBoolExp>;
};


export type Query_RootApplicantMypagesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootArticleRssPrtimesItemsArgs = {
  distinctOn?: InputMaybe<Array<ArticleRssPrtimesItemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleRssPrtimesItemsOrderBy>>;
  where?: InputMaybe<ArticleRssPrtimesItemsBoolExp>;
};


export type Query_RootArticleRssPrtimesItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticleRssPrtimesItemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleRssPrtimesItemsOrderBy>>;
  where?: InputMaybe<ArticleRssPrtimesItemsBoolExp>;
};


export type Query_RootArticleRssPrtimesItemsByPkArgs = {
  guid: Scalars['String']['input'];
};


export type Query_RootArticleTagsArgs = {
  distinctOn?: InputMaybe<Array<ArticleTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTagsOrderBy>>;
  where?: InputMaybe<ArticleTagsBoolExp>;
};


export type Query_RootArticleTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticleTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTagsOrderBy>>;
  where?: InputMaybe<ArticleTagsBoolExp>;
};


export type Query_RootArticleTopPageArgs = {
  distinctOn?: InputMaybe<Array<ArticleTopPageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTopPageOrderBy>>;
  where?: InputMaybe<ArticleTopPageBoolExp>;
};


export type Query_RootArticleWritersArgs = {
  distinctOn?: InputMaybe<Array<ArticleWritersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleWritersOrderBy>>;
  where?: InputMaybe<ArticleWritersBoolExp>;
};


export type Query_RootArticlesArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


export type Query_RootArticlesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


export type Query_RootBannerCategoryTopNewContestsArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopNewContestsOrderBy>>;
  where?: InputMaybe<BannerCategoryTopNewContestsBoolExp>;
};


export type Query_RootBannerCategoryTopNewContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopNewContestsOrderBy>>;
  where?: InputMaybe<BannerCategoryTopNewContestsBoolExp>;
};


export type Query_RootBannerCategoryTopPremiumBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopPremiumBannersBoolExp>;
};


export type Query_RootBannerCategoryTopPremiumBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopPremiumBannersBoolExp>;
};


export type Query_RootBannerCategoryTopStandardBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopStandardBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopStandardBannersBoolExp>;
};


export type Query_RootBannerCategoryTopStandardBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopStandardBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopStandardBannersBoolExp>;
};


export type Query_RootBannerCategoryTopSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopSubcategoriesOrderBy>>;
  where?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
};


export type Query_RootBannerCompanyBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerCompanyBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCompanyBannersOrderBy>>;
  where?: InputMaybe<BannerCompanyBannersBoolExp>;
};


export type Query_RootBannerCompanyBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCompanyBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCompanyBannersOrderBy>>;
  where?: InputMaybe<BannerCompanyBannersBoolExp>;
};


export type Query_RootBannerContestCategoryBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestCategoryBannersOrderBy>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


export type Query_RootBannerContestCategoryBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestCategoryBannersOrderBy>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


export type Query_RootBannerContestTopBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerContestTopBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestTopBannersOrderBy>>;
  where?: InputMaybe<BannerContestTopBannersBoolExp>;
};


export type Query_RootBannerContestTopBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerContestTopBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestTopBannersOrderBy>>;
  where?: InputMaybe<BannerContestTopBannersBoolExp>;
};


export type Query_RootBannerFooterBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerFooterBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerFooterBannersOrderBy>>;
  where?: InputMaybe<BannerFooterBannersBoolExp>;
};


export type Query_RootBannerFooterBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerFooterBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerFooterBannersOrderBy>>;
  where?: InputMaybe<BannerFooterBannersBoolExp>;
};


export type Query_RootBannerNewContestsArgs = {
  distinctOn?: InputMaybe<Array<BannerNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerNewContestsOrderBy>>;
  where?: InputMaybe<BannerNewContestsBoolExp>;
};


export type Query_RootBannerNewContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerNewContestsOrderBy>>;
  where?: InputMaybe<BannerNewContestsBoolExp>;
};


export type Query_RootBannerPickupBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerPickupBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPickupBannersOrderBy>>;
  where?: InputMaybe<BannerPickupBannersBoolExp>;
};


export type Query_RootBannerPickupBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerPickupBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPickupBannersOrderBy>>;
  where?: InputMaybe<BannerPickupBannersBoolExp>;
};


export type Query_RootBannerPremiumBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerPremiumBannersBoolExp>;
};


export type Query_RootBannerPremiumBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerPremiumBannersBoolExp>;
};


export type Query_RootBannerRectangleBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerRectangleBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerRectangleBannersOrderBy>>;
  where?: InputMaybe<BannerRectangleBannersBoolExp>;
};


export type Query_RootBannerRectangleBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerRectangleBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerRectangleBannersOrderBy>>;
  where?: InputMaybe<BannerRectangleBannersBoolExp>;
};


export type Query_RootBannerSearchResultContestsArgs = {
  distinctOn?: InputMaybe<Array<BannerSearchResultContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSearchResultContestsOrderBy>>;
  where?: InputMaybe<BannerSearchResultContestsBoolExp>;
};


export type Query_RootBannerSearchResultContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerSearchResultContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSearchResultContestsOrderBy>>;
  where?: InputMaybe<BannerSearchResultContestsBoolExp>;
};


export type Query_RootBannerSidebarBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerSidebarBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSidebarBannersOrderBy>>;
  where?: InputMaybe<BannerSidebarBannersBoolExp>;
};


export type Query_RootBannerSidebarBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerSidebarBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSidebarBannersOrderBy>>;
  where?: InputMaybe<BannerSidebarBannersBoolExp>;
};


export type Query_RootBannerStandardBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerStandardBannersOrderBy>>;
  where?: InputMaybe<BannerStandardBannersBoolExp>;
};


export type Query_RootBannerStandardBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerStandardBannersOrderBy>>;
  where?: InputMaybe<BannerStandardBannersBoolExp>;
};


export type Query_RootBannerSuperBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerSuperBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSuperBannersOrderBy>>;
  where?: InputMaybe<BannerSuperBannersBoolExp>;
};


export type Query_RootBannerSuperBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerSuperBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSuperBannersOrderBy>>;
  where?: InputMaybe<BannerSuperBannersBoolExp>;
};


export type Query_RootBannerTextLinksArgs = {
  distinctOn?: InputMaybe<Array<BannerTextLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerTextLinksOrderBy>>;
  where?: InputMaybe<BannerTextLinksBoolExp>;
};


export type Query_RootBannerTextLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerTextLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerTextLinksOrderBy>>;
  where?: InputMaybe<BannerTextLinksBoolExp>;
};


export type Query_RootCategoryPageArticleCardTextsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleCardTextsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleCardTextsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleCardTextsBoolExp>;
};


export type Query_RootCategoryPageArticleCardThumbnailsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleCardThumbnailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleCardThumbnailsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleCardThumbnailsBoolExp>;
};


export type Query_RootCategoryPageArticleCardsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleCardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleCardsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleCardsBoolExp>;
};


export type Query_RootCategoryPageArticleListArticlesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleListArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleListArticlesOrderBy>>;
  where?: InputMaybe<CategoryPageArticleListArticlesBoolExp>;
};


export type Query_RootCategoryPageArticleListsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleListsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleListsBoolExp>;
};


export type Query_RootCategoryPageContestCardTextsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestCardTextsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestCardTextsOrderBy>>;
  where?: InputMaybe<CategoryPageContestCardTextsBoolExp>;
};


export type Query_RootCategoryPageContestCardThumbnailsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestCardThumbnailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestCardThumbnailsOrderBy>>;
  where?: InputMaybe<CategoryPageContestCardThumbnailsBoolExp>;
};


export type Query_RootCategoryPageContestCardsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestCardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestCardsOrderBy>>;
  where?: InputMaybe<CategoryPageContestCardsBoolExp>;
};


export type Query_RootCategoryPageContestListContestsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestListContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestListContestsOrderBy>>;
  where?: InputMaybe<CategoryPageContestListContestsBoolExp>;
};


export type Query_RootCategoryPageContestListsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestListsOrderBy>>;
  where?: InputMaybe<CategoryPageContestListsBoolExp>;
};


export type Query_RootCategoryPageWidgetSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWidgetSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWidgetSubcategoriesOrderBy>>;
  where?: InputMaybe<CategoryPageWidgetSubcategoriesBoolExp>;
};


export type Query_RootCategoryPageWidgetsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWidgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWidgetsOrderBy>>;
  where?: InputMaybe<CategoryPageWidgetsBoolExp>;
};


export type Query_RootCategoryPageWorkCardsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWorkCardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWorkCardsOrderBy>>;
  where?: InputMaybe<CategoryPageWorkCardsBoolExp>;
};


export type Query_RootCategoryPagesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPagesOrderBy>>;
  where?: InputMaybe<CategoryPagesBoolExp>;
};


export type Query_RootContestCategoriesArgs = {
  distinctOn?: InputMaybe<Array<ContestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestCategoriesOrderBy>>;
  where?: InputMaybe<ContestCategoriesBoolExp>;
};


export type Query_RootContestCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestCategoriesOrderBy>>;
  where?: InputMaybe<ContestCategoriesBoolExp>;
};


export type Query_RootContestCategoriesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContestJobsArgs = {
  distinctOn?: InputMaybe<Array<ContestJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestJobsOrderBy>>;
  where?: InputMaybe<ContestJobsBoolExp>;
};


export type Query_RootContestJobsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestJobsOrderBy>>;
  where?: InputMaybe<ContestJobsBoolExp>;
};


export type Query_RootContestJobsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContestRestrictionsArgs = {
  distinctOn?: InputMaybe<Array<ContestRestrictionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestRestrictionsOrderBy>>;
  where?: InputMaybe<ContestRestrictionsBoolExp>;
};


export type Query_RootContestRestrictionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestRestrictionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestRestrictionsOrderBy>>;
  where?: InputMaybe<ContestRestrictionsBoolExp>;
};


export type Query_RootContestRestrictionsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContestSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestSubcategoriesOrderBy>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};


export type Query_RootContestSubcategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestSubcategoriesOrderBy>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};


export type Query_RootContestSubcategoriesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContestTagsArgs = {
  distinctOn?: InputMaybe<Array<ContestTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTagsOrderBy>>;
  where?: InputMaybe<ContestTagsBoolExp>;
};


export type Query_RootContestTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTagsOrderBy>>;
  where?: InputMaybe<ContestTagsBoolExp>;
};


export type Query_RootContestTagsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContestsArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


export type Query_RootContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


export type Query_RootContestsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCorporateCaseStudiesArgs = {
  distinctOn?: InputMaybe<Array<CorporateCaseStudiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCaseStudiesOrderBy>>;
  where?: InputMaybe<CorporateCaseStudiesBoolExp>;
};


export type Query_RootCorporateCaseStudiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCaseStudiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCaseStudiesOrderBy>>;
  where?: InputMaybe<CorporateCaseStudiesBoolExp>;
};


export type Query_RootCorporateCeoBlogCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
};


export type Query_RootCorporateCeoBlogCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
};


export type Query_RootCorporateCeoBlogCategorizationsArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


export type Query_RootCorporateCeoBlogCategorizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


export type Query_RootCorporateCeoBlogsArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogsBoolExp>;
};


export type Query_RootCorporateCeoBlogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogsBoolExp>;
};


export type Query_RootCorporateContestKnowledgesArgs = {
  distinctOn?: InputMaybe<Array<CorporateContestKnowledgesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateContestKnowledgesOrderBy>>;
  where?: InputMaybe<CorporateContestKnowledgesBoolExp>;
};


export type Query_RootCorporateContestKnowledgesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateContestKnowledgesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateContestKnowledgesOrderBy>>;
  where?: InputMaybe<CorporateContestKnowledgesBoolExp>;
};


export type Query_RootCorporateEmployeeBlogCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
};


export type Query_RootCorporateEmployeeBlogCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
};


export type Query_RootCorporateEmployeeBlogsArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogsOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};


export type Query_RootCorporateEmployeeBlogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogsOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};


export type Query_RootCorporateRecruitmentsArgs = {
  distinctOn?: InputMaybe<Array<CorporateRecruitmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateRecruitmentsOrderBy>>;
  where?: InputMaybe<CorporateRecruitmentsBoolExp>;
};


export type Query_RootCorporateSalesDocumentsArgs = {
  distinctOn?: InputMaybe<Array<CorporateSalesDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateSalesDocumentsOrderBy>>;
  where?: InputMaybe<CorporateSalesDocumentsBoolExp>;
};


export type Query_RootCorporateSalesDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateSalesDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateSalesDocumentsOrderBy>>;
  where?: InputMaybe<CorporateSalesDocumentsBoolExp>;
};


export type Query_RootCreatorInterestCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorInterestCategoriesOrderBy>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


export type Query_RootCreatorInterestCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorInterestCategoriesOrderBy>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


export type Query_RootCreatorInterestCategoriesByPkArgs = {
  categoryId: Scalars['Int']['input'];
  creatorId: Scalars['Int']['input'];
};


export type Query_RootCreatorJobsArgs = {
  distinctOn?: InputMaybe<Array<CreatorJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorJobsOrderBy>>;
  where?: InputMaybe<CreatorJobsBoolExp>;
};


export type Query_RootCreatorJobsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorJobsOrderBy>>;
  where?: InputMaybe<CreatorJobsBoolExp>;
};


export type Query_RootCreatorJobsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCreatorNotificationsArgs = {
  distinctOn?: InputMaybe<Array<CreatorNotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorNotificationsOrderBy>>;
  where?: InputMaybe<CreatorNotificationsBoolExp>;
};


export type Query_RootCreatorNotificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorNotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorNotificationsOrderBy>>;
  where?: InputMaybe<CreatorNotificationsBoolExp>;
};


export type Query_RootCreatorPostsArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


export type Query_RootCreatorPostsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


export type Query_RootCreatorPostsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCreatorsArgs = {
  distinctOn?: InputMaybe<Array<CreatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorsOrderBy>>;
  where?: InputMaybe<CreatorsBoolExp>;
};


export type Query_RootCreatorsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorsOrderBy>>;
  where?: InputMaybe<CreatorsBoolExp>;
};


export type Query_RootCreatorsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCustomerArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootEntryFormFieldGroupEntryCascadesArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
};


export type Query_RootEntryFormFieldGroupEntryCascadesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootEntryFormFieldGroupingsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldGroupingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldGroupingsOrderBy>>;
  where?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
};


export type Query_RootEntryFormFieldGroupingsByPkArgs = {
  fieldId: Scalars['Int']['input'];
  groupFieldId: Scalars['Int']['input'];
};


export type Query_RootEntryFormFieldRepeatSettingsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldRepeatSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldRepeatSettingsOrderBy>>;
  where?: InputMaybe<EntryFormFieldRepeatSettingsBoolExp>;
};


export type Query_RootEntryFormFieldRepeatSettingsByPkArgs = {
  fieldId: Scalars['Int']['input'];
};


export type Query_RootEntryFormGroupFieldsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormGroupFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormGroupFieldsOrderBy>>;
  where?: InputMaybe<EntryFormGroupFieldsBoolExp>;
};


export type Query_RootEntryFormGroupFieldsByPkArgs = {
  fieldId: Scalars['Int']['input'];
};


export type Query_RootEntryFormPrecautionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormPrecautionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormPrecautionsOrderBy>>;
  where?: InputMaybe<EntryFormPrecautionsBoolExp>;
};


export type Query_RootEntryFormPrecautionsByPkArgs = {
  formId: Scalars['Int']['input'];
};


export type Query_RootEntryFormsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


export type Query_RootEntryFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


export type Query_RootEntryFormsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootEntryWorksByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootGendersArgs = {
  distinctOn?: InputMaybe<Array<GendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
  where?: InputMaybe<GendersBoolExp>;
};


export type Query_RootGendersAggregateArgs = {
  distinctOn?: InputMaybe<Array<GendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
  where?: InputMaybe<GendersBoolExp>;
};


export type Query_RootGendersByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootImagesArgs = {
  distinctOn?: InputMaybe<Array<ImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImagesOrderBy>>;
  where?: InputMaybe<ImagesBoolExp>;
};


export type Query_RootImagesByPkArgs = {
  fileId: Scalars['Int']['input'];
};


export type Query_RootLandingPageBySlugArgs = {
  args: LandingPageBySlugArgs;
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Query_RootLandingPageBySlug_AggregateArgs = {
  args: LandingPageBySlugArgs;
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Query_RootLandingPagesArgs = {
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Query_RootLandingPagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Query_RootMagazineArgs = {
  args: MagazineArgs;
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Query_RootMagazineSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<MagazineSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazineSubscriptionOrderBy>>;
  where?: InputMaybe<MagazineSubscriptionBoolExp>;
};


export type Query_RootMagazine_AggregateArgs = {
  args: MagazineArgs;
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Query_RootMagazinesArgs = {
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Query_RootMagazinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Query_RootMeArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Query_RootMonthlyPeriodsArgs = {
  distinctOn?: InputMaybe<Array<MonthlyPeriodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MonthlyPeriodsOrderBy>>;
  where?: InputMaybe<MonthlyPeriodsBoolExp>;
};


export type Query_RootMonthlyPeriodsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MonthlyPeriodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MonthlyPeriodsOrderBy>>;
  where?: InputMaybe<MonthlyPeriodsBoolExp>;
};


export type Query_RootMonthlyPeriodsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootOrderArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
};


export type Query_RootOrganizerClassificationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizerClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizerClassificationsOrderBy>>;
  where?: InputMaybe<OrganizerClassificationsBoolExp>;
};


export type Query_RootOrganizerClassificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizerClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizerClassificationsOrderBy>>;
  where?: InputMaybe<OrganizerClassificationsBoolExp>;
};


export type Query_RootOrganizerClassificationsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPrefecturesArgs = {
  distinctOn?: InputMaybe<Array<PrefecturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrefecturesOrderBy>>;
  where?: InputMaybe<PrefecturesBoolExp>;
};


export type Query_RootPrefecturesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPriceArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPricesArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type Query_RootProductArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProductsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type Query_RootRegionsArgs = {
  distinctOn?: InputMaybe<Array<RegionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RegionsOrderBy>>;
  where?: InputMaybe<RegionsBoolExp>;
};


export type Query_RootRegionsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootResultCeremoniesArgs = {
  distinctOn?: InputMaybe<Array<ResultCeremoniesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultCeremoniesOrderBy>>;
  where?: InputMaybe<ResultCeremoniesBoolExp>;
};


export type Query_RootResultCeremoniesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultCeremoniesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultCeremoniesOrderBy>>;
  where?: InputMaybe<ResultCeremoniesBoolExp>;
};


export type Query_RootResultTagsArgs = {
  distinctOn?: InputMaybe<Array<ResultTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultTagsOrderBy>>;
  where?: InputMaybe<ResultTagsBoolExp>;
};


export type Query_RootResultTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultTagsOrderBy>>;
  where?: InputMaybe<ResultTagsBoolExp>;
};


export type Query_RootResultWorksArgs = {
  distinctOn?: InputMaybe<Array<ResultWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorksOrderBy>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};


export type Query_RootResultWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorksOrderBy>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};


export type Query_RootResultsArgs = {
  distinctOn?: InputMaybe<Array<ResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultsOrderBy>>;
  where?: InputMaybe<ResultsBoolExp>;
};


export type Query_RootResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultsOrderBy>>;
  where?: InputMaybe<ResultsBoolExp>;
};

export type ResultWorkLikesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ResultWorkLikesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ResultWorkLikesBoolExp>;
  predicate: IntComparisonExp;
};

export type ResultWorksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ResultWorksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ResultWorksBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "result.results" */
export type Results = {
  __typename?: 'results';
  /** An object relationship */
  article?: Maybe<Articles>;
  articleId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  ceremony?: Maybe<ResultCeremonies>;
  /** An object relationship */
  contest?: Maybe<Contests>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  taggings: Array<ResultTaggings>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  works: Array<ResultWorks>;
  /** An aggregate relationship */
  worksAggregate: ResultWorksAggregate;
};


/** columns and relationships of "result.results" */
export type ResultsTaggingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultTaggingsOrderBy>>;
  where?: InputMaybe<ResultTaggingsBoolExp>;
};


/** columns and relationships of "result.results" */
export type ResultsWorksArgs = {
  distinctOn?: InputMaybe<Array<ResultWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorksOrderBy>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};


/** columns and relationships of "result.results" */
export type ResultsWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorksOrderBy>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};

/** aggregated selection of "result.results" */
export type ResultsAggregate = {
  __typename?: 'resultsAggregate';
  aggregate?: Maybe<ResultsAggregateFields>;
  nodes: Array<Results>;
};

/** aggregate fields of "result.results" */
export type ResultsAggregateFields = {
  __typename?: 'resultsAggregateFields';
  avg?: Maybe<ResultsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ResultsMaxFields>;
  min?: Maybe<ResultsMinFields>;
  stddev?: Maybe<ResultsStddevFields>;
  stddevPop?: Maybe<ResultsStddevPopFields>;
  stddevSamp?: Maybe<ResultsStddevSampFields>;
  sum?: Maybe<ResultsSumFields>;
  varPop?: Maybe<ResultsVarPopFields>;
  varSamp?: Maybe<ResultsVarSampFields>;
  variance?: Maybe<ResultsVarianceFields>;
};


/** aggregate fields of "result.results" */
export type ResultsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResultsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ResultsAvgFields = {
  __typename?: 'resultsAvgFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "result.results". All fields are combined with a logical 'AND'. */
export type ResultsBoolExp = {
  _and?: InputMaybe<Array<ResultsBoolExp>>;
  _not?: InputMaybe<ResultsBoolExp>;
  _or?: InputMaybe<Array<ResultsBoolExp>>;
  article?: InputMaybe<ArticlesBoolExp>;
  articleId?: InputMaybe<IntComparisonExp>;
  ceremony?: InputMaybe<ResultCeremoniesBoolExp>;
  contest?: InputMaybe<ContestsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<StringComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  taggings?: InputMaybe<ResultTaggingsBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  works?: InputMaybe<ResultWorksBoolExp>;
  worksAggregate?: InputMaybe<ResultWorksAggregateBoolExp>;
};

/** aggregate max on columns */
export type ResultsMaxFields = {
  __typename?: 'resultsMaxFields';
  articleId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type ResultsMinFields = {
  __typename?: 'resultsMinFields';
  articleId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** Ordering options when selecting data from "result.results". */
export type ResultsOrderBy = {
  article?: InputMaybe<ArticlesOrderBy>;
  articleId?: InputMaybe<OrderBy>;
  ceremony?: InputMaybe<ResultCeremoniesOrderBy>;
  contest?: InputMaybe<ContestsOrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  taggingsAggregate?: InputMaybe<ResultTaggingsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  worksAggregate?: InputMaybe<ResultWorksAggregateOrderBy>;
};

/** select columns of table "result.results" */
export enum ResultsSelectColumn {
  /** column name */
  ArticleId = 'articleId',
  /** column name */
  Id = 'id',
  /** column name */
  Lead = 'lead',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type ResultsStddevFields = {
  __typename?: 'resultsStddevFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type ResultsStddevPopFields = {
  __typename?: 'resultsStddevPopFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type ResultsStddevSampFields = {
  __typename?: 'resultsStddevSampFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "results" */
export type ResultsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResultsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResultsStreamCursorValueInput = {
  articleId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type ResultsSumFields = {
  __typename?: 'resultsSumFields';
  articleId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varPop on columns */
export type ResultsVarPopFields = {
  __typename?: 'resultsVarPopFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type ResultsVarSampFields = {
  __typename?: 'resultsVarSampFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ResultsVarianceFields = {
  __typename?: 'resultsVarianceFields';
  articleId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "entry.mypages" */
  applicantMypages: Array<ApplicantMypages>;
  /** fetch data from the table: "entry.mypages" using primary key columns */
  applicantMypagesByPk?: Maybe<ApplicantMypages>;
  /** fetch data from the table in a streaming manner: "entry.mypages" */
  applicantMypagesStream: Array<ApplicantMypages>;
  /** fetch data from the table: "article.rss_prtimes_items" */
  articleRssPrtimesItems: Array<ArticleRssPrtimesItems>;
  /** fetch aggregated fields from the table: "article.rss_prtimes_items" */
  articleRssPrtimesItemsAggregate: ArticleRssPrtimesItemsAggregate;
  /** fetch data from the table: "article.rss_prtimes_items" using primary key columns */
  articleRssPrtimesItemsByPk?: Maybe<ArticleRssPrtimesItems>;
  /** fetch data from the table in a streaming manner: "article.rss_prtimes_items" */
  articleRssPrtimesItemsStream: Array<ArticleRssPrtimesItems>;
  /** fetch data from the table: "article.tags" */
  articleTags: Array<ArticleTags>;
  /** fetch aggregated fields from the table: "article.tags" */
  articleTagsAggregate: ArticleTagsAggregate;
  /** fetch data from the table in a streaming manner: "article.tags" */
  articleTagsStream: Array<ArticleTags>;
  /** fetch data from the table: "article.top_page" */
  articleTopPage: Array<ArticleTopPage>;
  /** fetch data from the table in a streaming manner: "article.top_page" */
  articleTopPageStream: Array<ArticleTopPage>;
  /** fetch data from the table: "article.writers" */
  articleWriters: Array<ArticleWriters>;
  /** fetch data from the table in a streaming manner: "article.writers" */
  articleWritersStream: Array<ArticleWriters>;
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articlesAggregate: ArticlesAggregate;
  /** fetch data from the table in a streaming manner: "article.articles" */
  articlesStream: Array<Articles>;
  /** fetch data from the table: "banner.category_top_new_contests" */
  bannerCategoryTopNewContests: Array<BannerCategoryTopNewContests>;
  /** fetch aggregated fields from the table: "banner.category_top_new_contests" */
  bannerCategoryTopNewContestsAggregate: BannerCategoryTopNewContestsAggregate;
  /** fetch data from the table in a streaming manner: "banner.category_top_new_contests" */
  bannerCategoryTopNewContestsStream: Array<BannerCategoryTopNewContests>;
  /** fetch data from the table: "banner.category_top_premium_banners" */
  bannerCategoryTopPremiumBanners: Array<BannerCategoryTopPremiumBanners>;
  /** fetch aggregated fields from the table: "banner.category_top_premium_banners" */
  bannerCategoryTopPremiumBannersAggregate: BannerCategoryTopPremiumBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.category_top_premium_banners" */
  bannerCategoryTopPremiumBannersStream: Array<BannerCategoryTopPremiumBanners>;
  /** fetch data from the table: "banner.category_top_standard_banners" */
  bannerCategoryTopStandardBanners: Array<BannerCategoryTopStandardBanners>;
  /** fetch aggregated fields from the table: "banner.category_top_standard_banners" */
  bannerCategoryTopStandardBannersAggregate: BannerCategoryTopStandardBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.category_top_standard_banners" */
  bannerCategoryTopStandardBannersStream: Array<BannerCategoryTopStandardBanners>;
  /** fetch data from the table: "banner.category_top_subcategories" */
  bannerCategoryTopSubcategories: Array<BannerCategoryTopSubcategories>;
  /** fetch data from the table in a streaming manner: "banner.category_top_subcategories" */
  bannerCategoryTopSubcategoriesStream: Array<BannerCategoryTopSubcategories>;
  /** fetch data from the table: "banner.company_banners" */
  bannerCompanyBanners: Array<BannerCompanyBanners>;
  /** fetch aggregated fields from the table: "banner.company_banners" */
  bannerCompanyBannersAggregate: BannerCompanyBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.company_banners" */
  bannerCompanyBannersStream: Array<BannerCompanyBanners>;
  /** fetch data from the table: "banner.contest_category_banners" */
  bannerContestCategoryBanners: Array<BannerContestCategoryBanners>;
  /** fetch aggregated fields from the table: "banner.contest_category_banners" */
  bannerContestCategoryBannersAggregate: BannerContestCategoryBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.contest_category_banners" */
  bannerContestCategoryBannersStream: Array<BannerContestCategoryBanners>;
  /** fetch data from the table: "banner.contest_top_banners" */
  bannerContestTopBanners: Array<BannerContestTopBanners>;
  /** fetch aggregated fields from the table: "banner.contest_top_banners" */
  bannerContestTopBannersAggregate: BannerContestTopBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.contest_top_banners" */
  bannerContestTopBannersStream: Array<BannerContestTopBanners>;
  /** fetch data from the table: "banner.footer_banners" */
  bannerFooterBanners: Array<BannerFooterBanners>;
  /** fetch aggregated fields from the table: "banner.footer_banners" */
  bannerFooterBannersAggregate: BannerFooterBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.footer_banners" */
  bannerFooterBannersStream: Array<BannerFooterBanners>;
  /** fetch data from the table: "banner.new_contests" */
  bannerNewContests: Array<BannerNewContests>;
  /** fetch aggregated fields from the table: "banner.new_contests" */
  bannerNewContestsAggregate: BannerNewContestsAggregate;
  /** fetch data from the table in a streaming manner: "banner.new_contests" */
  bannerNewContestsStream: Array<BannerNewContests>;
  /** fetch data from the table: "banner.pickup_banners" */
  bannerPickupBanners: Array<BannerPickupBanners>;
  /** fetch aggregated fields from the table: "banner.pickup_banners" */
  bannerPickupBannersAggregate: BannerPickupBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.pickup_banners" */
  bannerPickupBannersStream: Array<BannerPickupBanners>;
  /** fetch data from the table: "banner.premium_banners" */
  bannerPremiumBanners: Array<BannerPremiumBanners>;
  /** fetch aggregated fields from the table: "banner.premium_banners" */
  bannerPremiumBannersAggregate: BannerPremiumBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.premium_banners" */
  bannerPremiumBannersStream: Array<BannerPremiumBanners>;
  /** fetch data from the table: "banner.rectangle_banners" */
  bannerRectangleBanners: Array<BannerRectangleBanners>;
  /** fetch aggregated fields from the table: "banner.rectangle_banners" */
  bannerRectangleBannersAggregate: BannerRectangleBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.rectangle_banners" */
  bannerRectangleBannersStream: Array<BannerRectangleBanners>;
  /** fetch data from the table: "banner.search_result_contests" */
  bannerSearchResultContests: Array<BannerSearchResultContests>;
  /** fetch aggregated fields from the table: "banner.search_result_contests" */
  bannerSearchResultContestsAggregate: BannerSearchResultContestsAggregate;
  /** fetch data from the table in a streaming manner: "banner.search_result_contests" */
  bannerSearchResultContestsStream: Array<BannerSearchResultContests>;
  /** fetch data from the table: "banner.sidebar_banners" */
  bannerSidebarBanners: Array<BannerSidebarBanners>;
  /** fetch aggregated fields from the table: "banner.sidebar_banners" */
  bannerSidebarBannersAggregate: BannerSidebarBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.sidebar_banners" */
  bannerSidebarBannersStream: Array<BannerSidebarBanners>;
  /** fetch data from the table: "banner.standard_banners" */
  bannerStandardBanners: Array<BannerStandardBanners>;
  /** fetch aggregated fields from the table: "banner.standard_banners" */
  bannerStandardBannersAggregate: BannerStandardBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.standard_banners" */
  bannerStandardBannersStream: Array<BannerStandardBanners>;
  /** fetch data from the table: "banner.super_banners" */
  bannerSuperBanners: Array<BannerSuperBanners>;
  /** fetch aggregated fields from the table: "banner.super_banners" */
  bannerSuperBannersAggregate: BannerSuperBannersAggregate;
  /** fetch data from the table in a streaming manner: "banner.super_banners" */
  bannerSuperBannersStream: Array<BannerSuperBanners>;
  /** fetch data from the table: "banner.text_links" */
  bannerTextLinks: Array<BannerTextLinks>;
  /** fetch aggregated fields from the table: "banner.text_links" */
  bannerTextLinksAggregate: BannerTextLinksAggregate;
  /** fetch data from the table in a streaming manner: "banner.text_links" */
  bannerTextLinksStream: Array<BannerTextLinks>;
  /** fetch data from the table: "page.category_page_article_card_texts" */
  categoryPageArticleCardTexts: Array<CategoryPageArticleCardTexts>;
  /** fetch data from the table in a streaming manner: "page.category_page_article_card_texts" */
  categoryPageArticleCardTextsStream: Array<CategoryPageArticleCardTexts>;
  /** fetch data from the table: "page.category_page_article_card_thumbnails" */
  categoryPageArticleCardThumbnails: Array<CategoryPageArticleCardThumbnails>;
  /** fetch data from the table in a streaming manner: "page.category_page_article_card_thumbnails" */
  categoryPageArticleCardThumbnailsStream: Array<CategoryPageArticleCardThumbnails>;
  /** fetch data from the table: "page.category_page_article_cards" */
  categoryPageArticleCards: Array<CategoryPageArticleCards>;
  /** fetch data from the table in a streaming manner: "page.category_page_article_cards" */
  categoryPageArticleCardsStream: Array<CategoryPageArticleCards>;
  /** fetch data from the table: "page.category_page_article_list_articles" */
  categoryPageArticleListArticles: Array<CategoryPageArticleListArticles>;
  /** fetch data from the table in a streaming manner: "page.category_page_article_list_articles" */
  categoryPageArticleListArticlesStream: Array<CategoryPageArticleListArticles>;
  /** fetch data from the table: "page.category_page_article_lists" */
  categoryPageArticleLists: Array<CategoryPageArticleLists>;
  /** fetch data from the table in a streaming manner: "page.category_page_article_lists" */
  categoryPageArticleListsStream: Array<CategoryPageArticleLists>;
  /** fetch data from the table: "page.category_page_contest_card_texts" */
  categoryPageContestCardTexts: Array<CategoryPageContestCardTexts>;
  /** fetch data from the table in a streaming manner: "page.category_page_contest_card_texts" */
  categoryPageContestCardTextsStream: Array<CategoryPageContestCardTexts>;
  /** fetch data from the table: "page.category_page_contest_card_thumbnails" */
  categoryPageContestCardThumbnails: Array<CategoryPageContestCardThumbnails>;
  /** fetch data from the table in a streaming manner: "page.category_page_contest_card_thumbnails" */
  categoryPageContestCardThumbnailsStream: Array<CategoryPageContestCardThumbnails>;
  /** fetch data from the table: "page.category_page_contest_cards" */
  categoryPageContestCards: Array<CategoryPageContestCards>;
  /** fetch data from the table in a streaming manner: "page.category_page_contest_cards" */
  categoryPageContestCardsStream: Array<CategoryPageContestCards>;
  /** fetch data from the table: "page.category_page_contest_list_contests" */
  categoryPageContestListContests: Array<CategoryPageContestListContests>;
  /** fetch data from the table in a streaming manner: "page.category_page_contest_list_contests" */
  categoryPageContestListContestsStream: Array<CategoryPageContestListContests>;
  /** fetch data from the table: "page.category_page_contest_lists" */
  categoryPageContestLists: Array<CategoryPageContestLists>;
  /** fetch data from the table in a streaming manner: "page.category_page_contest_lists" */
  categoryPageContestListsStream: Array<CategoryPageContestLists>;
  /** fetch data from the table: "page.category_page_widget_subcategories" */
  categoryPageWidgetSubcategories: Array<CategoryPageWidgetSubcategories>;
  /** fetch data from the table in a streaming manner: "page.category_page_widget_subcategories" */
  categoryPageWidgetSubcategoriesStream: Array<CategoryPageWidgetSubcategories>;
  /** fetch data from the table: "page.category_page_widgets" */
  categoryPageWidgets: Array<CategoryPageWidgets>;
  /** fetch data from the table in a streaming manner: "page.category_page_widgets" */
  categoryPageWidgetsStream: Array<CategoryPageWidgets>;
  /** fetch data from the table: "page.category_page_work_cards" */
  categoryPageWorkCards: Array<CategoryPageWorkCards>;
  /** fetch data from the table in a streaming manner: "page.category_page_work_cards" */
  categoryPageWorkCardsStream: Array<CategoryPageWorkCards>;
  /** fetch data from the table: "page.category_pages" */
  categoryPages: Array<CategoryPages>;
  /** fetch data from the table in a streaming manner: "page.category_pages" */
  categoryPagesStream: Array<CategoryPages>;
  /** fetch data from the table: "contest.categories" */
  contestCategories: Array<ContestCategories>;
  /** fetch aggregated fields from the table: "contest.categories" */
  contestCategoriesAggregate: ContestCategoriesAggregate;
  /** fetch data from the table: "contest.categories" using primary key columns */
  contestCategoriesByPk?: Maybe<ContestCategories>;
  /** fetch data from the table in a streaming manner: "contest.categories" */
  contestCategoriesStream: Array<ContestCategories>;
  /** fetch data from the table: "contest.jobs" */
  contestJobs: Array<ContestJobs>;
  /** fetch aggregated fields from the table: "contest.jobs" */
  contestJobsAggregate: ContestJobsAggregate;
  /** fetch data from the table: "contest.jobs" using primary key columns */
  contestJobsByPk?: Maybe<ContestJobs>;
  /** fetch data from the table in a streaming manner: "contest.jobs" */
  contestJobsStream: Array<ContestJobs>;
  /** fetch data from the table: "contest.restrictions" */
  contestRestrictions: Array<ContestRestrictions>;
  /** fetch aggregated fields from the table: "contest.restrictions" */
  contestRestrictionsAggregate: ContestRestrictionsAggregate;
  /** fetch data from the table: "contest.restrictions" using primary key columns */
  contestRestrictionsByPk?: Maybe<ContestRestrictions>;
  /** fetch data from the table in a streaming manner: "contest.restrictions" */
  contestRestrictionsStream: Array<ContestRestrictions>;
  /** fetch data from the table: "contest.subcategories" */
  contestSubcategories: Array<ContestSubcategories>;
  /** fetch aggregated fields from the table: "contest.subcategories" */
  contestSubcategoriesAggregate: ContestSubcategoriesAggregate;
  /** fetch data from the table: "contest.subcategories" using primary key columns */
  contestSubcategoriesByPk?: Maybe<ContestSubcategories>;
  /** fetch data from the table in a streaming manner: "contest.subcategories" */
  contestSubcategoriesStream: Array<ContestSubcategories>;
  /** fetch data from the table: "contest.tags" */
  contestTags: Array<ContestTags>;
  /** fetch aggregated fields from the table: "contest.tags" */
  contestTagsAggregate: ContestTagsAggregate;
  /** fetch data from the table: "contest.tags" using primary key columns */
  contestTagsByPk?: Maybe<ContestTags>;
  /** fetch data from the table in a streaming manner: "contest.tags" */
  contestTagsStream: Array<ContestTags>;
  /** An array relationship */
  contests: Array<Contests>;
  /** An aggregate relationship */
  contestsAggregate: ContestsAggregate;
  /** fetch data from the table: "contest.contests" using primary key columns */
  contestsByPk?: Maybe<Contests>;
  /** fetch data from the table in a streaming manner: "contest.contests" */
  contestsStream: Array<Contests>;
  /** fetch data from the table: "corporate.case_studies" */
  corporateCaseStudies: Array<CorporateCaseStudies>;
  /** fetch aggregated fields from the table: "corporate.case_studies" */
  corporateCaseStudiesAggregate: CorporateCaseStudiesAggregate;
  /** fetch data from the table in a streaming manner: "corporate.case_studies" */
  corporateCaseStudiesStream: Array<CorporateCaseStudies>;
  /** fetch data from the table: "corporate.ceo_blog_categories" */
  corporateCeoBlogCategories: Array<CorporateCeoBlogCategories>;
  /** fetch aggregated fields from the table: "corporate.ceo_blog_categories" */
  corporateCeoBlogCategoriesAggregate: CorporateCeoBlogCategoriesAggregate;
  /** fetch data from the table in a streaming manner: "corporate.ceo_blog_categories" */
  corporateCeoBlogCategoriesStream: Array<CorporateCeoBlogCategories>;
  /** fetch data from the table: "corporate.ceo_blog_categorizations" */
  corporateCeoBlogCategorizations: Array<CorporateCeoBlogCategorizations>;
  /** fetch aggregated fields from the table: "corporate.ceo_blog_categorizations" */
  corporateCeoBlogCategorizationsAggregate: CorporateCeoBlogCategorizationsAggregate;
  /** fetch data from the table in a streaming manner: "corporate.ceo_blog_categorizations" */
  corporateCeoBlogCategorizationsStream: Array<CorporateCeoBlogCategorizations>;
  /** fetch data from the table: "corporate.ceo_blogs" */
  corporateCeoBlogs: Array<CorporateCeoBlogs>;
  /** fetch aggregated fields from the table: "corporate.ceo_blogs" */
  corporateCeoBlogsAggregate: CorporateCeoBlogsAggregate;
  /** fetch data from the table in a streaming manner: "corporate.ceo_blogs" */
  corporateCeoBlogsStream: Array<CorporateCeoBlogs>;
  /** fetch data from the table: "corporate.contest_knowledges" */
  corporateContestKnowledges: Array<CorporateContestKnowledges>;
  /** fetch aggregated fields from the table: "corporate.contest_knowledges" */
  corporateContestKnowledgesAggregate: CorporateContestKnowledgesAggregate;
  /** fetch data from the table in a streaming manner: "corporate.contest_knowledges" */
  corporateContestKnowledgesStream: Array<CorporateContestKnowledges>;
  /** fetch data from the table: "corporate.employee_blog_categories" */
  corporateEmployeeBlogCategories: Array<CorporateEmployeeBlogCategories>;
  /** fetch aggregated fields from the table: "corporate.employee_blog_categories" */
  corporateEmployeeBlogCategoriesAggregate: CorporateEmployeeBlogCategoriesAggregate;
  /** fetch data from the table in a streaming manner: "corporate.employee_blog_categories" */
  corporateEmployeeBlogCategoriesStream: Array<CorporateEmployeeBlogCategories>;
  /** fetch data from the table: "corporate.employee_blogs" */
  corporateEmployeeBlogs: Array<CorporateEmployeeBlogs>;
  /** fetch aggregated fields from the table: "corporate.employee_blogs" */
  corporateEmployeeBlogsAggregate: CorporateEmployeeBlogsAggregate;
  /** fetch data from the table in a streaming manner: "corporate.employee_blogs" */
  corporateEmployeeBlogsStream: Array<CorporateEmployeeBlogs>;
  /** fetch data from the table: "corporate.recruitments" */
  corporateRecruitments: Array<CorporateRecruitments>;
  /** fetch data from the table in a streaming manner: "corporate.recruitments" */
  corporateRecruitmentsStream: Array<CorporateRecruitments>;
  /** fetch data from the table: "corporate.sales_documents" */
  corporateSalesDocuments: Array<CorporateSalesDocuments>;
  /** fetch aggregated fields from the table: "corporate.sales_documents" */
  corporateSalesDocumentsAggregate: CorporateSalesDocumentsAggregate;
  /** fetch data from the table in a streaming manner: "corporate.sales_documents" */
  corporateSalesDocumentsStream: Array<CorporateSalesDocuments>;
  /** fetch data from the table: "creator.interest_categories" */
  creatorInterestCategories: Array<CreatorInterestCategories>;
  /** fetch aggregated fields from the table: "creator.interest_categories" */
  creatorInterestCategoriesAggregate: CreatorInterestCategoriesAggregate;
  /** fetch data from the table: "creator.interest_categories" using primary key columns */
  creatorInterestCategoriesByPk?: Maybe<CreatorInterestCategories>;
  /** fetch data from the table in a streaming manner: "creator.interest_categories" */
  creatorInterestCategoriesStream: Array<CreatorInterestCategories>;
  /** fetch data from the table: "creator.jobs" */
  creatorJobs: Array<CreatorJobs>;
  /** fetch aggregated fields from the table: "creator.jobs" */
  creatorJobsAggregate: CreatorJobsAggregate;
  /** fetch data from the table: "creator.jobs" using primary key columns */
  creatorJobsByPk?: Maybe<CreatorJobs>;
  /** fetch data from the table in a streaming manner: "creator.jobs" */
  creatorJobsStream: Array<CreatorJobs>;
  /** fetch data from the table: "creator.notifications" */
  creatorNotifications: Array<CreatorNotifications>;
  /** fetch aggregated fields from the table: "creator.notifications" */
  creatorNotificationsAggregate: CreatorNotificationsAggregate;
  /** fetch data from the table in a streaming manner: "creator.notifications" */
  creatorNotificationsStream: Array<CreatorNotifications>;
  /** fetch data from the table: "creator.posts" */
  creatorPosts: Array<CreatorPosts>;
  /** fetch aggregated fields from the table: "creator.posts" */
  creatorPostsAggregate: CreatorPostsAggregate;
  /** fetch data from the table: "creator.posts" using primary key columns */
  creatorPostsByPk?: Maybe<CreatorPosts>;
  /** fetch data from the table in a streaming manner: "creator.posts" */
  creatorPostsStream: Array<CreatorPosts>;
  /** An array relationship */
  creators: Array<Creators>;
  /** An aggregate relationship */
  creatorsAggregate: CreatorsAggregate;
  /** fetch data from the table: "creator.creators" using primary key columns */
  creatorsByPk?: Maybe<Creators>;
  /** fetch data from the table in a streaming manner: "creator.creators" */
  creatorsStream: Array<Creators>;
  /** fetch data from the table: "entry.form_field_group_entry_cascades" */
  entryFormFieldGroupEntryCascades: Array<EntryFormFieldGroupEntryCascades>;
  /** fetch data from the table: "entry.form_field_group_entry_cascades" using primary key columns */
  entryFormFieldGroupEntryCascadesByPk?: Maybe<EntryFormFieldGroupEntryCascades>;
  /** fetch data from the table in a streaming manner: "entry.form_field_group_entry_cascades" */
  entryFormFieldGroupEntryCascadesStream: Array<EntryFormFieldGroupEntryCascades>;
  /** fetch data from the table: "entry.form_field_groupings" */
  entryFormFieldGroupings: Array<EntryFormFieldGroupings>;
  /** fetch data from the table: "entry.form_field_groupings" using primary key columns */
  entryFormFieldGroupingsByPk?: Maybe<EntryFormFieldGroupings>;
  /** fetch data from the table in a streaming manner: "entry.form_field_groupings" */
  entryFormFieldGroupingsStream: Array<EntryFormFieldGroupings>;
  /** fetch data from the table: "entry.form_field_repeat_settings" */
  entryFormFieldRepeatSettings: Array<EntryFormFieldRepeatSettings>;
  /** fetch data from the table: "entry.form_field_repeat_settings" using primary key columns */
  entryFormFieldRepeatSettingsByPk?: Maybe<EntryFormFieldRepeatSettings>;
  /** fetch data from the table in a streaming manner: "entry.form_field_repeat_settings" */
  entryFormFieldRepeatSettingsStream: Array<EntryFormFieldRepeatSettings>;
  /** fetch data from the table: "entry.form_group_fields" */
  entryFormGroupFields: Array<EntryFormGroupFields>;
  /** fetch data from the table: "entry.form_group_fields" using primary key columns */
  entryFormGroupFieldsByPk?: Maybe<EntryFormGroupFields>;
  /** fetch data from the table in a streaming manner: "entry.form_group_fields" */
  entryFormGroupFieldsStream: Array<EntryFormGroupFields>;
  /** fetch data from the table: "entry.form_precautions" */
  entryFormPrecautions: Array<EntryFormPrecautions>;
  /** fetch data from the table: "entry.form_precautions" using primary key columns */
  entryFormPrecautionsByPk?: Maybe<EntryFormPrecautions>;
  /** fetch data from the table in a streaming manner: "entry.form_precautions" */
  entryFormPrecautionsStream: Array<EntryFormPrecautions>;
  /** fetch data from the table: "entry.forms" */
  entryForms: Array<EntryForms>;
  /** fetch aggregated fields from the table: "entry.forms" */
  entryFormsAggregate: EntryFormsAggregate;
  /** fetch data from the table: "entry.forms" using primary key columns */
  entryFormsByPk?: Maybe<EntryForms>;
  /** fetch data from the table in a streaming manner: "entry.forms" */
  entryFormsStream: Array<EntryForms>;
  /** fetch data from the table: "entry.works" using primary key columns */
  entryWorksByPk?: Maybe<EntryWorks>;
  /** fetch data from the table: "genders" */
  genders: Array<Genders>;
  /** fetch aggregated fields from the table: "genders" */
  gendersAggregate: GendersAggregate;
  /** fetch data from the table: "genders" using primary key columns */
  gendersByPk?: Maybe<Genders>;
  /** fetch data from the table in a streaming manner: "genders" */
  gendersStream: Array<Genders>;
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch data from the table: "images" using primary key columns */
  imagesByPk?: Maybe<Images>;
  /** fetch data from the table in a streaming manner: "images" */
  imagesStream: Array<Images>;
  /** execute function "page.landing_page_by_slug" which returns "page.landing_pages" */
  landingPageBySlug?: Maybe<LandingPages>;
  /** execute function "page.landing_page_by_slug" and query aggregates on result of table type "page.landing_pages" */
  landingPageBySlug_aggregate: LandingPagesAggregate;
  /** fetch data from the table: "page.landing_pages" */
  landingPages: Array<LandingPages>;
  /** fetch aggregated fields from the table: "page.landing_pages" */
  landingPagesAggregate: LandingPagesAggregate;
  /** fetch data from the table in a streaming manner: "page.landing_pages" */
  landingPagesStream: Array<LandingPages>;
  /** execute function "magazine.magazine" which returns "magazine.magazines" */
  magazine?: Maybe<Magazines>;
  /** fetch data from the table: "magazine.subscription" */
  magazineSubscription: Array<MagazineSubscription>;
  /** fetch data from the table in a streaming manner: "magazine.subscription" */
  magazineSubscriptionStream: Array<MagazineSubscription>;
  /** execute function "magazine.magazine" and query aggregates on result of table type "magazine.magazines" */
  magazine_aggregate: MagazinesAggregate;
  /** fetch data from the table: "magazine.magazines" */
  magazines: Array<Magazines>;
  /** fetch aggregated fields from the table: "magazine.magazines" */
  magazinesAggregate: MagazinesAggregate;
  /** fetch data from the table in a streaming manner: "magazine.magazines" */
  magazinesStream: Array<Magazines>;
  /** execute function "user.me" which returns "user.users" */
  me?: Maybe<Users>;
  /** fetch data from the table: "monthly_periods" */
  monthlyPeriods: Array<MonthlyPeriods>;
  /** fetch aggregated fields from the table: "monthly_periods" */
  monthlyPeriodsAggregate: MonthlyPeriodsAggregate;
  /** fetch data from the table: "monthly_periods" using primary key columns */
  monthlyPeriodsByPk?: Maybe<MonthlyPeriods>;
  /** fetch data from the table in a streaming manner: "monthly_periods" */
  monthlyPeriodsStream: Array<MonthlyPeriods>;
  /** fetch data from the table: "organizer.classifications" */
  organizerClassifications: Array<OrganizerClassifications>;
  /** fetch aggregated fields from the table: "organizer.classifications" */
  organizerClassificationsAggregate: OrganizerClassificationsAggregate;
  /** fetch data from the table: "organizer.classifications" using primary key columns */
  organizerClassificationsByPk?: Maybe<OrganizerClassifications>;
  /** fetch data from the table in a streaming manner: "organizer.classifications" */
  organizerClassificationsStream: Array<OrganizerClassifications>;
  /** An array relationship */
  prefectures: Array<Prefectures>;
  /** fetch data from the table: "prefectures" using primary key columns */
  prefecturesByPk?: Maybe<Prefectures>;
  /** fetch data from the table in a streaming manner: "prefectures" */
  prefecturesStream: Array<Prefectures>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch data from the table: "regions" using primary key columns */
  regionsByPk?: Maybe<Regions>;
  /** fetch data from the table in a streaming manner: "regions" */
  regionsStream: Array<Regions>;
  /** fetch data from the table: "result.ceremonies" */
  resultCeremonies: Array<ResultCeremonies>;
  /** fetch aggregated fields from the table: "result.ceremonies" */
  resultCeremoniesAggregate: ResultCeremoniesAggregate;
  /** fetch data from the table in a streaming manner: "result.ceremonies" */
  resultCeremoniesStream: Array<ResultCeremonies>;
  /** fetch data from the table: "result.tags" */
  resultTags: Array<ResultTags>;
  /** fetch aggregated fields from the table: "result.tags" */
  resultTagsAggregate: ResultTagsAggregate;
  /** fetch data from the table in a streaming manner: "result.tags" */
  resultTagsStream: Array<ResultTags>;
  /** fetch data from the table: "result.works" */
  resultWorks: Array<ResultWorks>;
  /** fetch aggregated fields from the table: "result.works" */
  resultWorksAggregate: ResultWorksAggregate;
  /** fetch data from the table in a streaming manner: "result.works" */
  resultWorksStream: Array<ResultWorks>;
  /** fetch data from the table: "result.results" */
  results: Array<Results>;
  /** fetch aggregated fields from the table: "result.results" */
  resultsAggregate: ResultsAggregate;
  /** fetch data from the table in a streaming manner: "result.results" */
  resultsStream: Array<Results>;
};


export type Subscription_RootApplicantMypagesArgs = {
  distinctOn?: InputMaybe<Array<ApplicantMypagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ApplicantMypagesOrderBy>>;
  where?: InputMaybe<ApplicantMypagesBoolExp>;
};


export type Subscription_RootApplicantMypagesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootApplicantMypagesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApplicantMypagesStreamCursorInput>>;
  where?: InputMaybe<ApplicantMypagesBoolExp>;
};


export type Subscription_RootArticleRssPrtimesItemsArgs = {
  distinctOn?: InputMaybe<Array<ArticleRssPrtimesItemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleRssPrtimesItemsOrderBy>>;
  where?: InputMaybe<ArticleRssPrtimesItemsBoolExp>;
};


export type Subscription_RootArticleRssPrtimesItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticleRssPrtimesItemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleRssPrtimesItemsOrderBy>>;
  where?: InputMaybe<ArticleRssPrtimesItemsBoolExp>;
};


export type Subscription_RootArticleRssPrtimesItemsByPkArgs = {
  guid: Scalars['String']['input'];
};


export type Subscription_RootArticleRssPrtimesItemsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ArticleRssPrtimesItemsStreamCursorInput>>;
  where?: InputMaybe<ArticleRssPrtimesItemsBoolExp>;
};


export type Subscription_RootArticleTagsArgs = {
  distinctOn?: InputMaybe<Array<ArticleTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTagsOrderBy>>;
  where?: InputMaybe<ArticleTagsBoolExp>;
};


export type Subscription_RootArticleTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticleTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTagsOrderBy>>;
  where?: InputMaybe<ArticleTagsBoolExp>;
};


export type Subscription_RootArticleTagsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ArticleTagsStreamCursorInput>>;
  where?: InputMaybe<ArticleTagsBoolExp>;
};


export type Subscription_RootArticleTopPageArgs = {
  distinctOn?: InputMaybe<Array<ArticleTopPageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleTopPageOrderBy>>;
  where?: InputMaybe<ArticleTopPageBoolExp>;
};


export type Subscription_RootArticleTopPageStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ArticleTopPageStreamCursorInput>>;
  where?: InputMaybe<ArticleTopPageBoolExp>;
};


export type Subscription_RootArticleWritersArgs = {
  distinctOn?: InputMaybe<Array<ArticleWritersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticleWritersOrderBy>>;
  where?: InputMaybe<ArticleWritersBoolExp>;
};


export type Subscription_RootArticleWritersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ArticleWritersStreamCursorInput>>;
  where?: InputMaybe<ArticleWritersBoolExp>;
};


export type Subscription_RootArticlesArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


export type Subscription_RootArticlesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ArticlesOrderBy>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


export type Subscription_RootArticlesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ArticlesStreamCursorInput>>;
  where?: InputMaybe<ArticlesBoolExp>;
};


export type Subscription_RootBannerCategoryTopNewContestsArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopNewContestsOrderBy>>;
  where?: InputMaybe<BannerCategoryTopNewContestsBoolExp>;
};


export type Subscription_RootBannerCategoryTopNewContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopNewContestsOrderBy>>;
  where?: InputMaybe<BannerCategoryTopNewContestsBoolExp>;
};


export type Subscription_RootBannerCategoryTopNewContestsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerCategoryTopNewContestsStreamCursorInput>>;
  where?: InputMaybe<BannerCategoryTopNewContestsBoolExp>;
};


export type Subscription_RootBannerCategoryTopPremiumBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopPremiumBannersBoolExp>;
};


export type Subscription_RootBannerCategoryTopPremiumBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopPremiumBannersBoolExp>;
};


export type Subscription_RootBannerCategoryTopPremiumBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerCategoryTopPremiumBannersStreamCursorInput>>;
  where?: InputMaybe<BannerCategoryTopPremiumBannersBoolExp>;
};


export type Subscription_RootBannerCategoryTopStandardBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopStandardBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopStandardBannersBoolExp>;
};


export type Subscription_RootBannerCategoryTopStandardBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopStandardBannersOrderBy>>;
  where?: InputMaybe<BannerCategoryTopStandardBannersBoolExp>;
};


export type Subscription_RootBannerCategoryTopStandardBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerCategoryTopStandardBannersStreamCursorInput>>;
  where?: InputMaybe<BannerCategoryTopStandardBannersBoolExp>;
};


export type Subscription_RootBannerCategoryTopSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<BannerCategoryTopSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCategoryTopSubcategoriesOrderBy>>;
  where?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
};


export type Subscription_RootBannerCategoryTopSubcategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerCategoryTopSubcategoriesStreamCursorInput>>;
  where?: InputMaybe<BannerCategoryTopSubcategoriesBoolExp>;
};


export type Subscription_RootBannerCompanyBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerCompanyBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCompanyBannersOrderBy>>;
  where?: InputMaybe<BannerCompanyBannersBoolExp>;
};


export type Subscription_RootBannerCompanyBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerCompanyBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerCompanyBannersOrderBy>>;
  where?: InputMaybe<BannerCompanyBannersBoolExp>;
};


export type Subscription_RootBannerCompanyBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerCompanyBannersStreamCursorInput>>;
  where?: InputMaybe<BannerCompanyBannersBoolExp>;
};


export type Subscription_RootBannerContestCategoryBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestCategoryBannersOrderBy>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


export type Subscription_RootBannerContestCategoryBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerContestCategoryBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestCategoryBannersOrderBy>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


export type Subscription_RootBannerContestCategoryBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerContestCategoryBannersStreamCursorInput>>;
  where?: InputMaybe<BannerContestCategoryBannersBoolExp>;
};


export type Subscription_RootBannerContestTopBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerContestTopBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestTopBannersOrderBy>>;
  where?: InputMaybe<BannerContestTopBannersBoolExp>;
};


export type Subscription_RootBannerContestTopBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerContestTopBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerContestTopBannersOrderBy>>;
  where?: InputMaybe<BannerContestTopBannersBoolExp>;
};


export type Subscription_RootBannerContestTopBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerContestTopBannersStreamCursorInput>>;
  where?: InputMaybe<BannerContestTopBannersBoolExp>;
};


export type Subscription_RootBannerFooterBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerFooterBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerFooterBannersOrderBy>>;
  where?: InputMaybe<BannerFooterBannersBoolExp>;
};


export type Subscription_RootBannerFooterBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerFooterBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerFooterBannersOrderBy>>;
  where?: InputMaybe<BannerFooterBannersBoolExp>;
};


export type Subscription_RootBannerFooterBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerFooterBannersStreamCursorInput>>;
  where?: InputMaybe<BannerFooterBannersBoolExp>;
};


export type Subscription_RootBannerNewContestsArgs = {
  distinctOn?: InputMaybe<Array<BannerNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerNewContestsOrderBy>>;
  where?: InputMaybe<BannerNewContestsBoolExp>;
};


export type Subscription_RootBannerNewContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerNewContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerNewContestsOrderBy>>;
  where?: InputMaybe<BannerNewContestsBoolExp>;
};


export type Subscription_RootBannerNewContestsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerNewContestsStreamCursorInput>>;
  where?: InputMaybe<BannerNewContestsBoolExp>;
};


export type Subscription_RootBannerPickupBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerPickupBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPickupBannersOrderBy>>;
  where?: InputMaybe<BannerPickupBannersBoolExp>;
};


export type Subscription_RootBannerPickupBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerPickupBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPickupBannersOrderBy>>;
  where?: InputMaybe<BannerPickupBannersBoolExp>;
};


export type Subscription_RootBannerPickupBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerPickupBannersStreamCursorInput>>;
  where?: InputMaybe<BannerPickupBannersBoolExp>;
};


export type Subscription_RootBannerPremiumBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerPremiumBannersBoolExp>;
};


export type Subscription_RootBannerPremiumBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerPremiumBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerPremiumBannersOrderBy>>;
  where?: InputMaybe<BannerPremiumBannersBoolExp>;
};


export type Subscription_RootBannerPremiumBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerPremiumBannersStreamCursorInput>>;
  where?: InputMaybe<BannerPremiumBannersBoolExp>;
};


export type Subscription_RootBannerRectangleBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerRectangleBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerRectangleBannersOrderBy>>;
  where?: InputMaybe<BannerRectangleBannersBoolExp>;
};


export type Subscription_RootBannerRectangleBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerRectangleBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerRectangleBannersOrderBy>>;
  where?: InputMaybe<BannerRectangleBannersBoolExp>;
};


export type Subscription_RootBannerRectangleBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerRectangleBannersStreamCursorInput>>;
  where?: InputMaybe<BannerRectangleBannersBoolExp>;
};


export type Subscription_RootBannerSearchResultContestsArgs = {
  distinctOn?: InputMaybe<Array<BannerSearchResultContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSearchResultContestsOrderBy>>;
  where?: InputMaybe<BannerSearchResultContestsBoolExp>;
};


export type Subscription_RootBannerSearchResultContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerSearchResultContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSearchResultContestsOrderBy>>;
  where?: InputMaybe<BannerSearchResultContestsBoolExp>;
};


export type Subscription_RootBannerSearchResultContestsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerSearchResultContestsStreamCursorInput>>;
  where?: InputMaybe<BannerSearchResultContestsBoolExp>;
};


export type Subscription_RootBannerSidebarBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerSidebarBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSidebarBannersOrderBy>>;
  where?: InputMaybe<BannerSidebarBannersBoolExp>;
};


export type Subscription_RootBannerSidebarBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerSidebarBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSidebarBannersOrderBy>>;
  where?: InputMaybe<BannerSidebarBannersBoolExp>;
};


export type Subscription_RootBannerSidebarBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerSidebarBannersStreamCursorInput>>;
  where?: InputMaybe<BannerSidebarBannersBoolExp>;
};


export type Subscription_RootBannerStandardBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerStandardBannersOrderBy>>;
  where?: InputMaybe<BannerStandardBannersBoolExp>;
};


export type Subscription_RootBannerStandardBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerStandardBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerStandardBannersOrderBy>>;
  where?: InputMaybe<BannerStandardBannersBoolExp>;
};


export type Subscription_RootBannerStandardBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerStandardBannersStreamCursorInput>>;
  where?: InputMaybe<BannerStandardBannersBoolExp>;
};


export type Subscription_RootBannerSuperBannersArgs = {
  distinctOn?: InputMaybe<Array<BannerSuperBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSuperBannersOrderBy>>;
  where?: InputMaybe<BannerSuperBannersBoolExp>;
};


export type Subscription_RootBannerSuperBannersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerSuperBannersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerSuperBannersOrderBy>>;
  where?: InputMaybe<BannerSuperBannersBoolExp>;
};


export type Subscription_RootBannerSuperBannersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerSuperBannersStreamCursorInput>>;
  where?: InputMaybe<BannerSuperBannersBoolExp>;
};


export type Subscription_RootBannerTextLinksArgs = {
  distinctOn?: InputMaybe<Array<BannerTextLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerTextLinksOrderBy>>;
  where?: InputMaybe<BannerTextLinksBoolExp>;
};


export type Subscription_RootBannerTextLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<BannerTextLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BannerTextLinksOrderBy>>;
  where?: InputMaybe<BannerTextLinksBoolExp>;
};


export type Subscription_RootBannerTextLinksStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BannerTextLinksStreamCursorInput>>;
  where?: InputMaybe<BannerTextLinksBoolExp>;
};


export type Subscription_RootCategoryPageArticleCardTextsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleCardTextsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleCardTextsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleCardTextsBoolExp>;
};


export type Subscription_RootCategoryPageArticleCardTextsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageArticleCardTextsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageArticleCardTextsBoolExp>;
};


export type Subscription_RootCategoryPageArticleCardThumbnailsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleCardThumbnailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleCardThumbnailsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleCardThumbnailsBoolExp>;
};


export type Subscription_RootCategoryPageArticleCardThumbnailsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageArticleCardThumbnailsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageArticleCardThumbnailsBoolExp>;
};


export type Subscription_RootCategoryPageArticleCardsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleCardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleCardsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleCardsBoolExp>;
};


export type Subscription_RootCategoryPageArticleCardsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageArticleCardsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageArticleCardsBoolExp>;
};


export type Subscription_RootCategoryPageArticleListArticlesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleListArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleListArticlesOrderBy>>;
  where?: InputMaybe<CategoryPageArticleListArticlesBoolExp>;
};


export type Subscription_RootCategoryPageArticleListArticlesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageArticleListArticlesStreamCursorInput>>;
  where?: InputMaybe<CategoryPageArticleListArticlesBoolExp>;
};


export type Subscription_RootCategoryPageArticleListsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageArticleListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageArticleListsOrderBy>>;
  where?: InputMaybe<CategoryPageArticleListsBoolExp>;
};


export type Subscription_RootCategoryPageArticleListsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageArticleListsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageArticleListsBoolExp>;
};


export type Subscription_RootCategoryPageContestCardTextsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestCardTextsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestCardTextsOrderBy>>;
  where?: InputMaybe<CategoryPageContestCardTextsBoolExp>;
};


export type Subscription_RootCategoryPageContestCardTextsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageContestCardTextsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageContestCardTextsBoolExp>;
};


export type Subscription_RootCategoryPageContestCardThumbnailsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestCardThumbnailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestCardThumbnailsOrderBy>>;
  where?: InputMaybe<CategoryPageContestCardThumbnailsBoolExp>;
};


export type Subscription_RootCategoryPageContestCardThumbnailsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageContestCardThumbnailsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageContestCardThumbnailsBoolExp>;
};


export type Subscription_RootCategoryPageContestCardsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestCardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestCardsOrderBy>>;
  where?: InputMaybe<CategoryPageContestCardsBoolExp>;
};


export type Subscription_RootCategoryPageContestCardsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageContestCardsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageContestCardsBoolExp>;
};


export type Subscription_RootCategoryPageContestListContestsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestListContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestListContestsOrderBy>>;
  where?: InputMaybe<CategoryPageContestListContestsBoolExp>;
};


export type Subscription_RootCategoryPageContestListContestsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageContestListContestsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageContestListContestsBoolExp>;
};


export type Subscription_RootCategoryPageContestListsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageContestListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageContestListsOrderBy>>;
  where?: InputMaybe<CategoryPageContestListsBoolExp>;
};


export type Subscription_RootCategoryPageContestListsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageContestListsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageContestListsBoolExp>;
};


export type Subscription_RootCategoryPageWidgetSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWidgetSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWidgetSubcategoriesOrderBy>>;
  where?: InputMaybe<CategoryPageWidgetSubcategoriesBoolExp>;
};


export type Subscription_RootCategoryPageWidgetSubcategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageWidgetSubcategoriesStreamCursorInput>>;
  where?: InputMaybe<CategoryPageWidgetSubcategoriesBoolExp>;
};


export type Subscription_RootCategoryPageWidgetsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWidgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWidgetsOrderBy>>;
  where?: InputMaybe<CategoryPageWidgetsBoolExp>;
};


export type Subscription_RootCategoryPageWidgetsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageWidgetsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageWidgetsBoolExp>;
};


export type Subscription_RootCategoryPageWorkCardsArgs = {
  distinctOn?: InputMaybe<Array<CategoryPageWorkCardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPageWorkCardsOrderBy>>;
  where?: InputMaybe<CategoryPageWorkCardsBoolExp>;
};


export type Subscription_RootCategoryPageWorkCardsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPageWorkCardsStreamCursorInput>>;
  where?: InputMaybe<CategoryPageWorkCardsBoolExp>;
};


export type Subscription_RootCategoryPagesArgs = {
  distinctOn?: InputMaybe<Array<CategoryPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryPagesOrderBy>>;
  where?: InputMaybe<CategoryPagesBoolExp>;
};


export type Subscription_RootCategoryPagesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CategoryPagesStreamCursorInput>>;
  where?: InputMaybe<CategoryPagesBoolExp>;
};


export type Subscription_RootContestCategoriesArgs = {
  distinctOn?: InputMaybe<Array<ContestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestCategoriesOrderBy>>;
  where?: InputMaybe<ContestCategoriesBoolExp>;
};


export type Subscription_RootContestCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestCategoriesOrderBy>>;
  where?: InputMaybe<ContestCategoriesBoolExp>;
};


export type Subscription_RootContestCategoriesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContestCategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContestCategoriesStreamCursorInput>>;
  where?: InputMaybe<ContestCategoriesBoolExp>;
};


export type Subscription_RootContestJobsArgs = {
  distinctOn?: InputMaybe<Array<ContestJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestJobsOrderBy>>;
  where?: InputMaybe<ContestJobsBoolExp>;
};


export type Subscription_RootContestJobsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestJobsOrderBy>>;
  where?: InputMaybe<ContestJobsBoolExp>;
};


export type Subscription_RootContestJobsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContestJobsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContestJobsStreamCursorInput>>;
  where?: InputMaybe<ContestJobsBoolExp>;
};


export type Subscription_RootContestRestrictionsArgs = {
  distinctOn?: InputMaybe<Array<ContestRestrictionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestRestrictionsOrderBy>>;
  where?: InputMaybe<ContestRestrictionsBoolExp>;
};


export type Subscription_RootContestRestrictionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestRestrictionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestRestrictionsOrderBy>>;
  where?: InputMaybe<ContestRestrictionsBoolExp>;
};


export type Subscription_RootContestRestrictionsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContestRestrictionsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContestRestrictionsStreamCursorInput>>;
  where?: InputMaybe<ContestRestrictionsBoolExp>;
};


export type Subscription_RootContestSubcategoriesArgs = {
  distinctOn?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestSubcategoriesOrderBy>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};


export type Subscription_RootContestSubcategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestSubcategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestSubcategoriesOrderBy>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};


export type Subscription_RootContestSubcategoriesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContestSubcategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContestSubcategoriesStreamCursorInput>>;
  where?: InputMaybe<ContestSubcategoriesBoolExp>;
};


export type Subscription_RootContestTagsArgs = {
  distinctOn?: InputMaybe<Array<ContestTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTagsOrderBy>>;
  where?: InputMaybe<ContestTagsBoolExp>;
};


export type Subscription_RootContestTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestTagsOrderBy>>;
  where?: InputMaybe<ContestTagsBoolExp>;
};


export type Subscription_RootContestTagsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContestTagsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContestTagsStreamCursorInput>>;
  where?: InputMaybe<ContestTagsBoolExp>;
};


export type Subscription_RootContestsArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


export type Subscription_RootContestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContestsOrderBy>>;
  where?: InputMaybe<ContestsBoolExp>;
};


export type Subscription_RootContestsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContestsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContestsStreamCursorInput>>;
  where?: InputMaybe<ContestsBoolExp>;
};


export type Subscription_RootCorporateCaseStudiesArgs = {
  distinctOn?: InputMaybe<Array<CorporateCaseStudiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCaseStudiesOrderBy>>;
  where?: InputMaybe<CorporateCaseStudiesBoolExp>;
};


export type Subscription_RootCorporateCaseStudiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCaseStudiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCaseStudiesOrderBy>>;
  where?: InputMaybe<CorporateCaseStudiesBoolExp>;
};


export type Subscription_RootCorporateCaseStudiesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateCaseStudiesStreamCursorInput>>;
  where?: InputMaybe<CorporateCaseStudiesBoolExp>;
};


export type Subscription_RootCorporateCeoBlogCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
};


export type Subscription_RootCorporateCeoBlogCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
};


export type Subscription_RootCorporateCeoBlogCategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateCeoBlogCategoriesStreamCursorInput>>;
  where?: InputMaybe<CorporateCeoBlogCategoriesBoolExp>;
};


export type Subscription_RootCorporateCeoBlogCategorizationsArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


export type Subscription_RootCorporateCeoBlogCategorizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogCategorizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogCategorizationsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


export type Subscription_RootCorporateCeoBlogCategorizationsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateCeoBlogCategorizationsStreamCursorInput>>;
  where?: InputMaybe<CorporateCeoBlogCategorizationsBoolExp>;
};


export type Subscription_RootCorporateCeoBlogsArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogsBoolExp>;
};


export type Subscription_RootCorporateCeoBlogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateCeoBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateCeoBlogsOrderBy>>;
  where?: InputMaybe<CorporateCeoBlogsBoolExp>;
};


export type Subscription_RootCorporateCeoBlogsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateCeoBlogsStreamCursorInput>>;
  where?: InputMaybe<CorporateCeoBlogsBoolExp>;
};


export type Subscription_RootCorporateContestKnowledgesArgs = {
  distinctOn?: InputMaybe<Array<CorporateContestKnowledgesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateContestKnowledgesOrderBy>>;
  where?: InputMaybe<CorporateContestKnowledgesBoolExp>;
};


export type Subscription_RootCorporateContestKnowledgesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateContestKnowledgesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateContestKnowledgesOrderBy>>;
  where?: InputMaybe<CorporateContestKnowledgesBoolExp>;
};


export type Subscription_RootCorporateContestKnowledgesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateContestKnowledgesStreamCursorInput>>;
  where?: InputMaybe<CorporateContestKnowledgesBoolExp>;
};


export type Subscription_RootCorporateEmployeeBlogCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
};


export type Subscription_RootCorporateEmployeeBlogCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogCategoriesOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
};


export type Subscription_RootCorporateEmployeeBlogCategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateEmployeeBlogCategoriesStreamCursorInput>>;
  where?: InputMaybe<CorporateEmployeeBlogCategoriesBoolExp>;
};


export type Subscription_RootCorporateEmployeeBlogsArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogsOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};


export type Subscription_RootCorporateEmployeeBlogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateEmployeeBlogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateEmployeeBlogsOrderBy>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};


export type Subscription_RootCorporateEmployeeBlogsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateEmployeeBlogsStreamCursorInput>>;
  where?: InputMaybe<CorporateEmployeeBlogsBoolExp>;
};


export type Subscription_RootCorporateRecruitmentsArgs = {
  distinctOn?: InputMaybe<Array<CorporateRecruitmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateRecruitmentsOrderBy>>;
  where?: InputMaybe<CorporateRecruitmentsBoolExp>;
};


export type Subscription_RootCorporateRecruitmentsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateRecruitmentsStreamCursorInput>>;
  where?: InputMaybe<CorporateRecruitmentsBoolExp>;
};


export type Subscription_RootCorporateSalesDocumentsArgs = {
  distinctOn?: InputMaybe<Array<CorporateSalesDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateSalesDocumentsOrderBy>>;
  where?: InputMaybe<CorporateSalesDocumentsBoolExp>;
};


export type Subscription_RootCorporateSalesDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CorporateSalesDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CorporateSalesDocumentsOrderBy>>;
  where?: InputMaybe<CorporateSalesDocumentsBoolExp>;
};


export type Subscription_RootCorporateSalesDocumentsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CorporateSalesDocumentsStreamCursorInput>>;
  where?: InputMaybe<CorporateSalesDocumentsBoolExp>;
};


export type Subscription_RootCreatorInterestCategoriesArgs = {
  distinctOn?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorInterestCategoriesOrderBy>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


export type Subscription_RootCreatorInterestCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorInterestCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorInterestCategoriesOrderBy>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


export type Subscription_RootCreatorInterestCategoriesByPkArgs = {
  categoryId: Scalars['Int']['input'];
  creatorId: Scalars['Int']['input'];
};


export type Subscription_RootCreatorInterestCategoriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CreatorInterestCategoriesStreamCursorInput>>;
  where?: InputMaybe<CreatorInterestCategoriesBoolExp>;
};


export type Subscription_RootCreatorJobsArgs = {
  distinctOn?: InputMaybe<Array<CreatorJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorJobsOrderBy>>;
  where?: InputMaybe<CreatorJobsBoolExp>;
};


export type Subscription_RootCreatorJobsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorJobsOrderBy>>;
  where?: InputMaybe<CreatorJobsBoolExp>;
};


export type Subscription_RootCreatorJobsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCreatorJobsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CreatorJobsStreamCursorInput>>;
  where?: InputMaybe<CreatorJobsBoolExp>;
};


export type Subscription_RootCreatorNotificationsArgs = {
  distinctOn?: InputMaybe<Array<CreatorNotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorNotificationsOrderBy>>;
  where?: InputMaybe<CreatorNotificationsBoolExp>;
};


export type Subscription_RootCreatorNotificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorNotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorNotificationsOrderBy>>;
  where?: InputMaybe<CreatorNotificationsBoolExp>;
};


export type Subscription_RootCreatorNotificationsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CreatorNotificationsStreamCursorInput>>;
  where?: InputMaybe<CreatorNotificationsBoolExp>;
};


export type Subscription_RootCreatorPostsArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


export type Subscription_RootCreatorPostsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorPostsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorPostsOrderBy>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


export type Subscription_RootCreatorPostsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCreatorPostsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CreatorPostsStreamCursorInput>>;
  where?: InputMaybe<CreatorPostsBoolExp>;
};


export type Subscription_RootCreatorsArgs = {
  distinctOn?: InputMaybe<Array<CreatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorsOrderBy>>;
  where?: InputMaybe<CreatorsBoolExp>;
};


export type Subscription_RootCreatorsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CreatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorsOrderBy>>;
  where?: InputMaybe<CreatorsBoolExp>;
};


export type Subscription_RootCreatorsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCreatorsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CreatorsStreamCursorInput>>;
  where?: InputMaybe<CreatorsBoolExp>;
};


export type Subscription_RootEntryFormFieldGroupEntryCascadesArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldGroupEntryCascadesOrderBy>>;
  where?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
};


export type Subscription_RootEntryFormFieldGroupEntryCascadesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootEntryFormFieldGroupEntryCascadesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EntryFormFieldGroupEntryCascadesStreamCursorInput>>;
  where?: InputMaybe<EntryFormFieldGroupEntryCascadesBoolExp>;
};


export type Subscription_RootEntryFormFieldGroupingsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldGroupingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldGroupingsOrderBy>>;
  where?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
};


export type Subscription_RootEntryFormFieldGroupingsByPkArgs = {
  fieldId: Scalars['Int']['input'];
  groupFieldId: Scalars['Int']['input'];
};


export type Subscription_RootEntryFormFieldGroupingsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EntryFormFieldGroupingsStreamCursorInput>>;
  where?: InputMaybe<EntryFormFieldGroupingsBoolExp>;
};


export type Subscription_RootEntryFormFieldRepeatSettingsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormFieldRepeatSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormFieldRepeatSettingsOrderBy>>;
  where?: InputMaybe<EntryFormFieldRepeatSettingsBoolExp>;
};


export type Subscription_RootEntryFormFieldRepeatSettingsByPkArgs = {
  fieldId: Scalars['Int']['input'];
};


export type Subscription_RootEntryFormFieldRepeatSettingsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EntryFormFieldRepeatSettingsStreamCursorInput>>;
  where?: InputMaybe<EntryFormFieldRepeatSettingsBoolExp>;
};


export type Subscription_RootEntryFormGroupFieldsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormGroupFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormGroupFieldsOrderBy>>;
  where?: InputMaybe<EntryFormGroupFieldsBoolExp>;
};


export type Subscription_RootEntryFormGroupFieldsByPkArgs = {
  fieldId: Scalars['Int']['input'];
};


export type Subscription_RootEntryFormGroupFieldsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EntryFormGroupFieldsStreamCursorInput>>;
  where?: InputMaybe<EntryFormGroupFieldsBoolExp>;
};


export type Subscription_RootEntryFormPrecautionsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormPrecautionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormPrecautionsOrderBy>>;
  where?: InputMaybe<EntryFormPrecautionsBoolExp>;
};


export type Subscription_RootEntryFormPrecautionsByPkArgs = {
  formId: Scalars['Int']['input'];
};


export type Subscription_RootEntryFormPrecautionsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EntryFormPrecautionsStreamCursorInput>>;
  where?: InputMaybe<EntryFormPrecautionsBoolExp>;
};


export type Subscription_RootEntryFormsArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


export type Subscription_RootEntryFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EntryFormsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntryFormsOrderBy>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


export type Subscription_RootEntryFormsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootEntryFormsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EntryFormsStreamCursorInput>>;
  where?: InputMaybe<EntryFormsBoolExp>;
};


export type Subscription_RootEntryWorksByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootGendersArgs = {
  distinctOn?: InputMaybe<Array<GendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
  where?: InputMaybe<GendersBoolExp>;
};


export type Subscription_RootGendersAggregateArgs = {
  distinctOn?: InputMaybe<Array<GendersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
  where?: InputMaybe<GendersBoolExp>;
};


export type Subscription_RootGendersByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootGendersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GendersStreamCursorInput>>;
  where?: InputMaybe<GendersBoolExp>;
};


export type Subscription_RootImagesArgs = {
  distinctOn?: InputMaybe<Array<ImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImagesOrderBy>>;
  where?: InputMaybe<ImagesBoolExp>;
};


export type Subscription_RootImagesByPkArgs = {
  fileId: Scalars['Int']['input'];
};


export type Subscription_RootImagesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImagesStreamCursorInput>>;
  where?: InputMaybe<ImagesBoolExp>;
};


export type Subscription_RootLandingPageBySlugArgs = {
  args: LandingPageBySlugArgs;
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Subscription_RootLandingPageBySlug_AggregateArgs = {
  args: LandingPageBySlugArgs;
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Subscription_RootLandingPagesArgs = {
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Subscription_RootLandingPagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<LandingPagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LandingPagesOrderBy>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Subscription_RootLandingPagesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LandingPagesStreamCursorInput>>;
  where?: InputMaybe<LandingPagesBoolExp>;
};


export type Subscription_RootMagazineArgs = {
  args: MagazineArgs;
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Subscription_RootMagazineSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<MagazineSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazineSubscriptionOrderBy>>;
  where?: InputMaybe<MagazineSubscriptionBoolExp>;
};


export type Subscription_RootMagazineSubscriptionStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MagazineSubscriptionStreamCursorInput>>;
  where?: InputMaybe<MagazineSubscriptionBoolExp>;
};


export type Subscription_RootMagazine_AggregateArgs = {
  args: MagazineArgs;
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Subscription_RootMagazinesArgs = {
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Subscription_RootMagazinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MagazinesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MagazinesOrderBy>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Subscription_RootMagazinesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MagazinesStreamCursorInput>>;
  where?: InputMaybe<MagazinesBoolExp>;
};


export type Subscription_RootMeArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootMonthlyPeriodsArgs = {
  distinctOn?: InputMaybe<Array<MonthlyPeriodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MonthlyPeriodsOrderBy>>;
  where?: InputMaybe<MonthlyPeriodsBoolExp>;
};


export type Subscription_RootMonthlyPeriodsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MonthlyPeriodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MonthlyPeriodsOrderBy>>;
  where?: InputMaybe<MonthlyPeriodsBoolExp>;
};


export type Subscription_RootMonthlyPeriodsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMonthlyPeriodsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MonthlyPeriodsStreamCursorInput>>;
  where?: InputMaybe<MonthlyPeriodsBoolExp>;
};


export type Subscription_RootOrganizerClassificationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizerClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizerClassificationsOrderBy>>;
  where?: InputMaybe<OrganizerClassificationsBoolExp>;
};


export type Subscription_RootOrganizerClassificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizerClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizerClassificationsOrderBy>>;
  where?: InputMaybe<OrganizerClassificationsBoolExp>;
};


export type Subscription_RootOrganizerClassificationsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootOrganizerClassificationsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrganizerClassificationsStreamCursorInput>>;
  where?: InputMaybe<OrganizerClassificationsBoolExp>;
};


export type Subscription_RootPrefecturesArgs = {
  distinctOn?: InputMaybe<Array<PrefecturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrefecturesOrderBy>>;
  where?: InputMaybe<PrefecturesBoolExp>;
};


export type Subscription_RootPrefecturesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootPrefecturesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PrefecturesStreamCursorInput>>;
  where?: InputMaybe<PrefecturesBoolExp>;
};


export type Subscription_RootRegionsArgs = {
  distinctOn?: InputMaybe<Array<RegionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RegionsOrderBy>>;
  where?: InputMaybe<RegionsBoolExp>;
};


export type Subscription_RootRegionsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootRegionsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RegionsStreamCursorInput>>;
  where?: InputMaybe<RegionsBoolExp>;
};


export type Subscription_RootResultCeremoniesArgs = {
  distinctOn?: InputMaybe<Array<ResultCeremoniesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultCeremoniesOrderBy>>;
  where?: InputMaybe<ResultCeremoniesBoolExp>;
};


export type Subscription_RootResultCeremoniesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultCeremoniesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultCeremoniesOrderBy>>;
  where?: InputMaybe<ResultCeremoniesBoolExp>;
};


export type Subscription_RootResultCeremoniesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ResultCeremoniesStreamCursorInput>>;
  where?: InputMaybe<ResultCeremoniesBoolExp>;
};


export type Subscription_RootResultTagsArgs = {
  distinctOn?: InputMaybe<Array<ResultTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultTagsOrderBy>>;
  where?: InputMaybe<ResultTagsBoolExp>;
};


export type Subscription_RootResultTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultTagsOrderBy>>;
  where?: InputMaybe<ResultTagsBoolExp>;
};


export type Subscription_RootResultTagsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ResultTagsStreamCursorInput>>;
  where?: InputMaybe<ResultTagsBoolExp>;
};


export type Subscription_RootResultWorksArgs = {
  distinctOn?: InputMaybe<Array<ResultWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorksOrderBy>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};


export type Subscription_RootResultWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultWorksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultWorksOrderBy>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};


export type Subscription_RootResultWorksStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ResultWorksStreamCursorInput>>;
  where?: InputMaybe<ResultWorksBoolExp>;
};


export type Subscription_RootResultsArgs = {
  distinctOn?: InputMaybe<Array<ResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultsOrderBy>>;
  where?: InputMaybe<ResultsBoolExp>;
};


export type Subscription_RootResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ResultsOrderBy>>;
  where?: InputMaybe<ResultsBoolExp>;
};


export type Subscription_RootResultsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ResultsStreamCursorInput>>;
  where?: InputMaybe<ResultsBoolExp>;
};

/** columns and relationships of "user.users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  allowedRoles: Array<UserAllowedRoles>;
  /** An object relationship */
  creator?: Maybe<Creators>;
  customer?: Maybe<Customer>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  organizer?: Maybe<OrganizerMembers>;
};


/** columns and relationships of "user.users" */
export type UsersAllowedRolesArgs = {
  distinctOn?: InputMaybe<Array<UserAllowedRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAllowedRolesOrderBy>>;
  where?: InputMaybe<UserAllowedRolesBoolExp>;
};

/** Boolean expression to filter rows from the table "user.users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  allowedRoles?: InputMaybe<UserAllowedRolesBoolExp>;
  creator?: InputMaybe<CreatorsBoolExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerMembersBoolExp>;
};

/** Ordering options when selecting data from "user.users". */
export type UsersOrderBy = {
  allowedRolesAggregate?: InputMaybe<UserAllowedRolesAggregateOrderBy>;
  creator?: InputMaybe<CreatorsOrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerMembersOrderBy>;
};

/** select columns of table "user.users" */
export enum UsersSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

export type YoutubeLiveUrl_Result_Ceremonies_Args = {
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicantForgotPasswordPageForgotPasswordForApplicantMutationVariables = Exact<{
  mypage: ForgotPasswordForApplicantInputMypage;
  passwordResetEmail: ForgotPasswordForApplicantInputPasswordResetEmail;
}>;


export type ApplicantForgotPasswordPageForgotPasswordForApplicantMutation = { __typename?: 'mutation_root', forgotPasswordForApplicant: { __typename?: 'ForgotPasswordForApplicantOutput', email: string } };

export type ApplicantFormInputAddressesByZipCodeQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;


export type ApplicantFormInputAddressesByZipCodeQuery = { __typename?: 'query_root', addressesByZipCode: Array<{ __typename?: 'AddressesByZipCodeOutput', line1: string, prefecture: { __typename?: 'AddressesByZipCodeOutputPrefecture', id: number } }> };

export type ApplicantGroupFormInputAddressesByZipCodeQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;


export type ApplicantGroupFormInputAddressesByZipCodeQuery = { __typename?: 'query_root', addressesByZipCode: Array<{ __typename?: 'AddressesByZipCodeOutput', line1: string, prefecture: { __typename?: 'AddressesByZipCodeOutputPrefecture', id: number } }> };

export type ApplicantPasswordResetFormResetPasswordForApplicantMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type ApplicantPasswordResetFormResetPasswordForApplicantMutation = { __typename?: 'mutation_root', resetPasswordForApplicant: { __typename?: 'ResetPasswordForApplicantOutput', userId: string } };

export type ApplicantSigninFormSingInForApplicantMutationVariables = Exact<{
  user: SigninForApplicantInputUser;
}>;


export type ApplicantSigninFormSingInForApplicantMutation = { __typename?: 'mutation_root', signinForApplicant: { __typename?: 'SigninForApplicantOutput', userId: string } };

export type AuthLoginFormLoginMutationVariables = Exact<{
  user: SigninInputUser;
}>;


export type AuthLoginFormLoginMutation = { __typename?: 'mutation_root', signin: { __typename?: 'SigninOutput', userId: string } };

export type AuthSignupFormSignupMutationVariables = Exact<{
  user: SignupInputUser;
  verificationEmail: SignupInputVerificationEmail;
}>;


export type AuthSignupFormSignupMutation = { __typename?: 'mutation_root', signup: { __typename?: 'SignupOutput', email: string } };

export type OrganizationFormUpsertOrganizationMutationVariables = Exact<{
  organization: SaveOrganizationInput;
}>;


export type OrganizationFormUpsertOrganizationMutation = { __typename?: 'mutation_root', saveOrganization: { __typename?: 'SaveOrganizationOutput', organizationId: number } };

export type ScreeningJurorFormAddressesByZipCodeQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;


export type ScreeningJurorFormAddressesByZipCodeQuery = { __typename?: 'query_root', addressesByZipCode: Array<{ __typename?: 'AddressesByZipCodeOutput', line1: string, prefecture: { __typename?: 'AddressesByZipCodeOutputPrefecture', id: number } }> };

export type ApplicantEmailVerificationPageVerifyEmailForApplicantMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ApplicantEmailVerificationPageVerifyEmailForApplicantMutation = { __typename?: 'mutation_root', verifyEmailForApplicant: { __typename?: 'VerifyEmailForApplicantOutput', userId: string } };

export type InputFileCreateFilePutUrlMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
}>;


export type InputFileCreateFilePutUrlMutation = { __typename?: 'mutation_root', generateUrlForPutFile: { __typename?: 'GenerateUrlForPutFileOutput', url: string } };

export type UseUserQueryVariables = Exact<{ [key: string]: never; }>;


export type UseUserQuery = { __typename?: 'query_root', me?: { __typename?: 'users', id: string, email: string, creator?: { __typename?: 'creators', id: number, profileImage?: { __typename?: 'Files', url: string } | null } | null, allowedRoles: Array<{ __typename?: 'UserAllowedRoles', name: string }> } | null };


export const ApplicantForgotPasswordPageForgotPasswordForApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApplicantForgotPasswordPageForgotPasswordForApplicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mypage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ForgotPasswordForApplicantInputMypage"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"passwordResetEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ForgotPasswordForApplicantInputPasswordResetEmail"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forgotPasswordForApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mypage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mypage"}}},{"kind":"Argument","name":{"kind":"Name","value":"passwordResetEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"passwordResetEmail"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<ApplicantForgotPasswordPageForgotPasswordForApplicantMutation, ApplicantForgotPasswordPageForgotPasswordForApplicantMutationVariables>;
export const ApplicantFormInputAddressesByZipCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ApplicantFormInputAddressesByZipCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"zipCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressesByZipCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"zipCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"zipCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"prefecture"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"line1"}}]}}]}}]} as unknown as DocumentNode<ApplicantFormInputAddressesByZipCodeQuery, ApplicantFormInputAddressesByZipCodeQueryVariables>;
export const ApplicantGroupFormInputAddressesByZipCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ApplicantGroupFormInputAddressesByZipCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"zipCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressesByZipCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"zipCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"zipCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"prefecture"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"line1"}}]}}]}}]} as unknown as DocumentNode<ApplicantGroupFormInputAddressesByZipCodeQuery, ApplicantGroupFormInputAddressesByZipCodeQueryVariables>;
export const ApplicantPasswordResetFormResetPasswordForApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApplicantPasswordResetFormResetPasswordForApplicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPasswordForApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}},{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<ApplicantPasswordResetFormResetPasswordForApplicantMutation, ApplicantPasswordResetFormResetPasswordForApplicantMutationVariables>;
export const ApplicantSigninFormSingInForApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApplicantSigninFormSingInForApplicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SigninForApplicantInputUser"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signinForApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<ApplicantSigninFormSingInForApplicantMutation, ApplicantSigninFormSingInForApplicantMutationVariables>;
export const AuthLoginFormLoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthLoginFormLogin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SigninInputUser"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<AuthLoginFormLoginMutation, AuthLoginFormLoginMutationVariables>;
export const AuthSignupFormSignupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthSignupFormSignup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignupInputUser"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"verificationEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignupInputVerificationEmail"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user"}}},{"kind":"Argument","name":{"kind":"Name","value":"verificationEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"verificationEmail"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<AuthSignupFormSignupMutation, AuthSignupFormSignupMutationVariables>;
export const OrganizationFormUpsertOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OrganizationFormUpsertOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organization"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveOrganizationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveOrganization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organization"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organization"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationId"}}]}}]}}]} as unknown as DocumentNode<OrganizationFormUpsertOrganizationMutation, OrganizationFormUpsertOrganizationMutationVariables>;
export const ScreeningJurorFormAddressesByZipCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ScreeningJurorFormAddressesByZipCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"zipCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressesByZipCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"zipCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"zipCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"prefecture"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"line1"}}]}}]}}]} as unknown as DocumentNode<ScreeningJurorFormAddressesByZipCodeQuery, ScreeningJurorFormAddressesByZipCodeQueryVariables>;
export const ApplicantEmailVerificationPageVerifyEmailForApplicantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApplicantEmailVerificationPageVerifyEmailForApplicant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyEmailForApplicant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<ApplicantEmailVerificationPageVerifyEmailForApplicantMutation, ApplicantEmailVerificationPageVerifyEmailForApplicantMutationVariables>;
export const InputFileCreateFilePutUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InputFileCreateFilePutUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fileName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateUrlForPutFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"fileName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fileName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode<InputFileCreateFilePutUrlMutation, InputFileCreateFilePutUrlMutationVariables>;
export const UseUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UseUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"creator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profileImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"allowedRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<UseUserQuery, UseUserQueryVariables>;